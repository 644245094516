import styled from "styled-components";
import PropTypes from "prop-types";
import ResponsiveImage from "./ResponsiveImage";

const Image = ({ imgSrc, alt, className, ...props }) => {
  return (
    <StyledImage
      className={className}
      {...props}
    >
      <ResponsiveImage src={imgSrc} alt={alt} />
    </StyledImage>
  );
};

const StyledImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 991px) {
    & > img {
      max-width: 100%;
    }
  }
`;

Image.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: "",
};

export default Image;
