import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { FaRegImage, FaTimes } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import {
  eventTableOrder,
  getSinglePastEventData,
} from "src/utils/tournamentUtils"; // Fetch all events data
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { formatAmount } from "src/utils/formatters";

const PastEventWinners = () => {
  // const [toggleImage, setToggleImage] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const location = useLocation();
  const imageRef = useRef();
  const { i18n, t } = useTranslation();

  // Fetch the current event data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      const event = await getSinglePastEventData(location, i18n); // Fetch single event data
      setCurrentEvent(event);
    };
    fetchData();
  }, [i18n.language]);

  const [showWinnerModal, setShowWinnerModal] = useState(false);

  const handleCloseWinnerModal = () => {
    setShowWinnerModal(false);
  };

  const handleShowWinnerModal = (index) => {
    setSelectedItemIndex(index);
    setShowWinnerModal(true);
  };

  return (
    <Div>
      {currentEvent && (
        <>
          <div>
            <ResponsiveImage
              src={currentEvent?.image}
              alt={currentEvent?.title}
            />
            <ResponsiveImage
              src={currentEvent?.image_mobile}
              alt={currentEvent?.url}
            />
          </div>
          <div>
            {currentEvent?.winnerDetail?.name && (
              <>
                <h1>{t("tournament.winners.winnerDetails")}</h1>
                <span></span>
                <FirstMain>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t("tournament.winners.winner")}</td>
                        <td>{currentEvent.winnerDetail.name}</td>
                      </tr>
                      <tr>
                        <td>{t("tournament.winners.origin")}</td>
                        <td>
                          {currentEvent.winnerDetail.origin}
                          <ResponsiveImage
                            src={`flags/${
                              currentEvent.winnerDetail.country.toLowerCase() ===
                              "usa"
                                ? "american"
                                : currentEvent.winnerDetail.country.toLowerCase()
                            }_flag.png`}
                            alt={currentEvent.winnerDetail.country}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("tournament.winners.event")}</td>
                        <td>{currentEvent.winnerDetail.event}</td>
                      </tr>
                      {currentEvent.winnerDetail.prize && (
                        <tr>
                          <td>{t("tournament.winners.prize")}</td>
                          <td>
                            {formatAmount(
                              currentEvent.winnerDetail.prize,
                              i18n.language
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div>
                    <ResponsiveImage
                      src={currentEvent.winnerDetail.image}
                      alt={currentEvent.winnerDetail.name}
                    />
                  </div>
                </FirstMain>
              </>
            )}
            {currentEvent?.table?.length > 0 && (
              <TableDiv>
                <table>
                  <thead>
                    <tr>
                      <th>{t("tournament.winners.event")}</th>
                      {currentEvent.table[0].tournament && (
                        <th>{t("tournament.winners.tournament")}</th>
                      )}
                      <th>{t("tournament.winners.winner")}</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEvent.table.map((el, ind) => (
                      <tr key={el.image}>
                        <td>{el.event || eventTableOrder(ind + 1)}</td>
                        {el.tournament && <td>{el.tournament}</td>}
                        <td>{el.winner}</td>
                        <td>
                          {el.image !== "" && (
                            <span onClick={() => handleShowWinnerModal(ind)}>
                              <FaRegImage /> {t("tournament.winners.winner")}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableDiv>
            )}
            <StyledModal
              show={showWinnerModal}
              onHide={handleCloseWinnerModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="winner-pic-modal"
            >
              <Modal.Header>
                <Modal.Title>
                  <small>
                    {t("tournament.winners.event")}{" "}
                    {currentEvent?.table[selectedItemIndex]?.event ||
                      `#${selectedItemIndex + 1}`}
                  </small>
                  <br />
                  {currentEvent?.table[selectedItemIndex]?.tournament}{" "}
                  {t("tournament.winners.winner")}{" "}
                </Modal.Title>
                <FaTimes onClick={handleCloseWinnerModal} />
              </Modal.Header>
              <Modal.Body>
                <h5>{currentEvent?.table[selectedItemIndex]?.winner}</h5>
              </Modal.Body>
              <Modal.Body className="image-holder">
                <ResponsiveImage
                  src={currentEvent?.table[selectedItemIndex]?.image}
                  alt={currentEvent?.table[selectedItemIndex]?.winner}
                />
              </Modal.Body>
            </StyledModal>
          </div>
        </>
      )}
    </Div>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Div = styled.div`
  height: auto;
  width: 100%;
  background: var(--black);
  position: relative;
  z-index: 1;
  & > div:first-child {
    height: fit-content;
    width: 100%;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img {
      width: 100%;
      height: fit-content;
      object-fit: cover;
      object-position: center;
    }
    & > img:last-child {
      display: none;
    }
  }
  & > div:nth-child(2) {
    padding-top: 20px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    & > h1:first-child {
      text-align: center;
      color: var(--white);
      font-weight: 900;
      margin-bottom: 10px;
      font-size: 45px;
    }
    & > span:nth-child(2) {
      margin: 0 auto;
      width: 100%;
      max-width: 275px;
      border-bottom: 2px solid var(--red);
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      padding: 80px 0px 0px;
      & > img:first-child {
        display: none;
      }
      & > img:last-child {
        display: block;
      }
    }
    & > div:nth-child(2) {
      padding: 0;
      & > h1:first-child {
        font-size: 30px;
      }
    }
  }
`;
const FirstMain = styled.div`
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  margin: 50px auto;
  background: var(--lightblack);
  display: flex;
  flex-flow: row nowrap;
  color: var(--white);
  & > table {
    flex-basis: 50%;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    tbody {
      width: 100%;
      tr {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;
        font-size: 20px;
        td {
          margin: 5px 0;
          font-size: 22px;
          & > img {
            max-width: 22px;
            height: auto;
            object-fit: contain;
            display: inline-flex;
            margin-left: 5px;
          }
        }
        td:first-child {
          border-left: 4px solid var(--red);
          padding-left: 10px;
          font-weight: 700;
        }
      }
    }
  }
  & > div {
    flex-basis: 50%;
    & > img {
      object-fit: contain;
      height: auto;
      width: calc(100% - 35px);
      position: relative;
      top: 35px;
    }
    & > video {
      object-fit: contain;
      height: auto;
      width: calc(100% - 35px);
      position: relative;
      top: 35px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    margin: 20px auto;
    padding: 20px;
    & > table {
      width: 100%;
      height: 100%;
      padding: 0;
      margin-bottom: 20px;
      tbody {
        tr {
          td {
            font-size: 16px;
            gap: 10px;
          }
        }
      }
    }
    & > div {
      & > img {
        display: block;
        position: unset;
        width: 100%;
      }
    }
  }
`;
const TableDiv = styled.div`
  margin: 40px auto 20px auto;
  border: 1px solid var(--lightblack);
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  overflow-x: hidden;
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        border-top: 1px solid var(--lightblack);
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        & > td {
          border-right: 1px solid var(--lightblack);
          border-top: none;
          text-align: center;
          color: var(--white);
          font-size: 15px;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: middle;
          & > svg {
            vertical-align: middle;
          }
          & > img {
            max-width: 22px;
            height: auto;
            object-fit: contain;
          }
        }
        & > td:last-child {
          cursor: pointer;
          &:hover {
            color: var(--red);
            border-bottom: 1px solid var(--red);
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 12px;
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 12px;
            font-weight: 300;
          }
        }
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-header {
    background-color: var(--lightblack);
    justify-content: space-between;
    text-align: left;
    align-items: flex-start;
    svg {
      color: var(--grey);
    }
    .modal-title {
      color: var(--white);
      font-weight: 700;
    }
  }
  .modal-body {
    h5 {
      margin: 0;
    }
    &.image-holder {
      padding-top: 0;
      min-height: 60px;
    }
  }
`;

export default PastEventWinners;
