import i18n from "src/middleware/i18n";
//change DEV to PROD for prod api for user auth
const BASE_API = process.env.REACT_APP_BASE_URL;

export async function live_cash_games() {
  try {
    const accessToken = localStorage.getItem("access_token");
    const headers = {};
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    const response = await fetch(`${BASE_API}/poker/live_cash_games`, {
      method: "GET",
      headers: headers,
    });
    const { data, message } = await response.json();

    if (response.ok) {
      return data;
    } else {
      console.log(message);
    }
  } catch (error) {
    console.error("Error fetching live cash games data:", error);
    throw error;
  }
}

export const tournaments = async (startDate, endDate, event_keyword) => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const headers = {};

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    // URL with optional event_keyword
    let url = `${BASE_API}/poker/tournaments?start_date=${startDate}&end_date=${endDate}`;
    if (event_keyword) {
      url += `&event_keyword=${event_keyword}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    const { data, message } = await response.json();

    if (response.ok) {
      // Filter results to include only published tournaments
      return data.filter(tournament => tournament.published);
    } else {
      return null;
    }
  } catch (error) {
    console.error(
      `Error fetching tournaments data between ${startDate} and ${endDate}:`,
      error
    );
    throw error;
  }
};

export const tournament = async (gid) => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const headers = {};

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    const response = await fetch(`${BASE_API}/poker/tournaments/${gid}`, {
      method: "GET",
      headers: headers,
    });

    const { data, message } = await response.json();

    if (response.ok) {
      return data;
    } else {
      console.log(message);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching tournament data for GID: ${gid}`, error);
    throw error;
  }
};

export const leaderboards = async (type, year, month) => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const headers = {};

    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    // Build query parameters dynamically based on provided values
    const queryParams = new URLSearchParams();
    if (year) queryParams.append("year", year);
    if (month) queryParams.append("month", month);

    const endpoint = `${BASE_API}/poker/leaderboards/${encodeURIComponent(type)}`;

    // Check if queryParams exists and has any parameters
    if (queryParams && queryParams.toString()) {
      endpoint += `?${queryParams.toString()}`;
    }

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      const { data } = await response.json();
      return data;
    } else {
      const { message } = await response.json();
      console.warn(message);
      return null;
    }
  } catch (error) {
    console.error(
      `Error fetching leaderboards of type '${type}' for ${year}-${month}:`,
      error
    );
    throw error;
  }
};

export const sendFilmingConsentAPI = async (formData) => {
  const authToken = localStorage.getItem("access_token");
  // Error handling if authorization token is missing
  if (!authToken) {
    console.error("Authorization token not found");
    return {
      success: false,
      data: "Authorization token not found. Please sign in again.",
    };
  }

  try {
    // Create the payload
    const payload = {
      filming_dates: formData.getAll("dates[]"), // Collect dates from FormData
      facebook_handle: formData.get("facebookHandle") || "",
      instagram_handle: formData.get("instagramHandle") || "",
      youtube_handle: formData.get("youtubeHandle") || "",
      twitch_handle: formData.get("twitchHandle") || "",
      x_handle: formData.get("xHandle") || "",
      tiktok_handle: formData.get("tiktokHandle") || "",
      terms_accepted: formData.get("consent") === "true", // Convert string to boolean
    };
    // Make the API POST request to the new endpoint
    const response = await fetch(`${BASE_API}/filming_requests`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Language": i18n.language,
        Authorization: `${authToken}`, // Include the Authorization token in headers
      },
      body: JSON.stringify(payload), // Send the payload as JSON
    });

    // Check the response status
    if (response.ok) {
      const responseData = await response.json();
      return {
        success: true,
        data: responseData,
      };
    } else {
      // Handle non-200 responses
      const errorText = await response.json();
      console.error("API error:", errorText);
      return {
        success: false,
        data: errorText.message,
      };
    }
  } catch (error) {
    // Handle network or other errors
    console.error("Something went wrong:", error);
    return {
      success: false,
      data: error.message || "Something went wrong.",
    };
  }
};
