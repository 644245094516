import { useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { Table, Row, Col } from "react-bootstrap";
import Button from "../Button/Button";

const PlayerBenefits = () => {
  const [benefits, setBenefits] = useState(2);
  const { t } = useTranslation();
  return (
    <>
      <MainDiv benefits={benefits}>
        <StatusRow className="g-0 gx-0 d-flex d-lg-none">
          <Col>
            <Button
              onClick={() => setBenefits(2)}
              className={`silver ${benefits === 2 ? "active" : undefined}`}
              size="small"
              label={t("rewards.section-2-status-silver")}
            />
          </Col>
          <Col>
            <Button
              onClick={() => setBenefits(3)}
              className={`gold ${benefits === 3 ? "active" : undefined}`}
              size="small"
              label={t("rewards.section-2-status-gold")}
            />
          </Col>
          <Col>
            <Button
              onClick={() => setBenefits(4)}
              className={`red ${benefits === 4 ? "active" : undefined}`}
              size="small"
              label={t("rewards.section-2-status-red")}
            />
          </Col>
          <Col>
            <Button
              onClick={() => setBenefits(5)}
              className={`black ${benefits === 5 ? "active" : undefined}`}
              size="small"
              label={t("rewards.section-2-status-black")}
            />
          </Col>
          <Col>
            <Button
              onClick={() => setBenefits(6)}
              className={`white ${benefits === 6 ? "active" : undefined}`}
              size="small"
              label={t("rewards.section-2-status-white")}
            />
          </Col>
        </StatusRow>

        <div className="d-lg-none">
          <h2>
            {benefits === 2 && t("rewards.section-2-status-silver")}
            {benefits === 3 && t("rewards.section-2-status-gold")}
            {benefits === 4 && t("rewards.section-2-status-red")}
            {benefits === 5 && t("rewards.section-2-status-black")}
            {benefits === 6 && t("rewards.section-2-status-white")}
          </h2>
        </div>

        <Table striped variant="dark">
          <thead>
            <tr>
              <th></th>
              <th>{t("rewards.section-2-status-silver")}</th>
              <th>{t("rewards.section-2-status-gold")}</th>
              <th>{t("rewards.section-2-status-red")}</th>
              <th>{t("rewards.section-2-status-black")}</th>
              <th>{t("rewards.section-2-status-white")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={`status-${benefits}`}></td>
              <td className={`status-2`}>{t("rewards.section-2-points-1")}</td>
              <td className={`status-3`}>{t("rewards.section-2-points-2")}</td>
              <td className={`status-4`}>{t("rewards.section-2-points-3")}</td>
              <td className={`status-5`}>{t("rewards.section-2-points-4")}</td>
              <td className={`status-6`}>{t("rewards.section-2-points-white")}</td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-1")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-2")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-3")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-4")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-5")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-6")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-7")}</td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-8")}</td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-9")}</td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-10")}</td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-11")}</td>
              <td></td>
              <td></td>
              <td>15%</td>
              <td>25%</td>
              <td>25%</td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-12")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-13")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-14")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-15")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-16")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-17")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
            <tr>
              <td>{t("rewards.benefits-table.row-18")}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <ResponsiveImage src={"svg/Check.svg"} alt="check" />
              </td>
            </tr>
          </tbody>
        </Table>
      </MainDiv>
    </>
  );
};
const MainDiv = styled.div`
  background-color: white;
  padding: 1rem;
  h1 {
    color: var(--white);
  }
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  & > div {
    display: none;
  }
  & > table {
    margin-top: 0;
    width: 100%;
    border-collapse: collapse;
    color: white;
    td {
      border: 1px solid var(--rewards_grid);
      padding: 10px;
    }
    & > thead {
      tr {
        th {
          font-weight: 500;
          text-align: center;
        }
        th:nth-child(1) {
          border: none;
        }
        th:nth-child(2) {
          background: #71706e;
        }
        th:nth-child(3) {
          background: #cfa736;
        }
        th:nth-child(4) {
          background: var(--red);
        }
        th:nth-child(5) {
          background: #101010;
        }
        th:nth-child(6) {
          background: var(--mutedwhite);
          color: var(--lightblack);
        }
      }
    }
    & > tbody {
      tr {
        @media (max-width: 991px) {
          td {
            &.status-2 {
              --bs-table-bg-type: #71706e!important;
            }
            &.status-3 {
              --bs-table-bg-type: #cfa736!important;
            }
            &.status-4 {
              --bs-table-bg-type: var(--red)!important;
            }
            &.status-5 {
              --bs-table-bg-type: #101010!important;
            }
            &.status-6 {
              --bs-table-bg-type: var(--white)!important;
              color: var(--black);
            }
          }
        }
        td:nth-child(1) {
          text-align: left;
        }
        td {
          text-align: center;
          img {
            display: unset;
            width: 20px;
            height: 20px;
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px 60px 20px;
    & > div {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      & > h2 {
        text-align: center;
        width: 100%;
        padding: 8px;
        font-size: 18px;
        background: var(--white);
        color: var(--black);
      }
      .active {
        background: ${(props) => props.benefits === 2 && "#71706e"};
        background: ${(props) => props.benefits === 3 && "#cfa736"};
        background: ${(props) => props.benefits === 4 && "var(--red)"};
        background: ${(props) => props.benefits === 5 && "#101010"};
        color: var(--white);
      }
    }
    & > table {
      & > thead {
        display: none;
        font-size: 14px;
      }
      & > tbody {
        tr {
          display: flex;
          font-size: 14px;
          td {
            display: none;
          }
          td:nth-child(1) {
            display: flex;
            flex-basis: 50%;
          }
          ${(props) => css`
            td:nth-child(${props.benefits}) {
              display: flex;
              flex-basis: 50%;
              align-items: center;
              justify-content: center;
            }
          `}
        }
      }
    }
  }
`;

const StatusRow = styled(Row)`
  button {
    margin: 0;
    background-color: #fff;
    color: white!important;
    width: 100%;
    background: #2c3035;
    &.active {
      background: ${(props) => props.benefits === 2 && "#71706e"};
      background: ${(props) => props.benefits === 3 && "#cfa736"};
      background: ${(props) => props.benefits === 4 && "var(--red)"};
      background: ${(props) => props.benefits === 5 && "#101010"};
      color: var(--white)!important;
      &.black {
        /* border: solid 1px #656464; */
        background: var(--lightblack);
      }
      &.white {
        background: var(--white);
        color: var(--black)!important;
      }
    }
  }
`;

export default PlayerBenefits;
