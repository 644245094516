import React from "react";
import styled from "styled-components";
import { t } from "i18next";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

import { useTranslation } from 'react-i18next';

const TournamentList = () => {
  const { i18n } = useTranslation();
  const tournaments = require(`../../locales/${i18n.language}/tournaments.json`);
  const tournamentArr = Object.entries(tournaments.tournaments || {}).reverse();

  const renderTournamentLinks = (tournamentData, tournamentKey) => {
    return (
      <>
        <tr key={tournamentKey}>
          <th colSpan="2">{t(`seo.${tournamentKey}.title`)} ({tournamentKey})</th>
        </tr>
        {Object.entries(tournamentData).map(([key, path]) => (
          <tr key={key}>
            <td>{key}</td>
            <td><Link to={path}>{t(path)}</Link></td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <Div>
      <h1>Tournament List</h1>
      <Table bordered hover variant="dark">
        <tbody>
          {tournamentArr.map(([tournamentKey, tournamentData]) =>
            renderTournamentLinks(tournamentData, tournamentKey)
          )}
        </tbody>
      </Table>
    </Div>
  );
};

const Div = styled.div`
  background: #282828;
  color: #fff;
  padding: 150px 50px 50px;
  font-family: 'Courier New', Courier, monospace;
  h2 {
    padding: 40px 0 10px;
  }
  a {
    color: cyan;
    &:hover {
      color: pink;
    }
  }
`;

export default TournamentList;