import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Button from "../Button/Button";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

const PokerJackpotRulesCard = ({ title, text, amount, buttonSettings, className }) => {

  return (
    <Col key={title} className={`mt-2 mt-md-0 ${className || ''}`}>
      <JackpotRulesCard>
        <Card.Body>
          <Card.Title><h3>{title}</h3></Card.Title>
          <Card.Text className="amount-holder">
            {amount}
          </Card.Text>
          <div className="text-holder">
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button {...buttonSettings} />
        </Card.Footer>
      </JackpotRulesCard>
    </Col>
  );
};


const JackpotRulesCard = styled(Card)`
  height: 100%;
  text-align: center;
  border-radius: 0;
  .card-title h3 {
    color: #fff;
    font-weight: 700;
    background: var(--black);
    padding: 20px 10px;
    font-size: 1.25rem;
  }
  .card-body {
    color: black;
    padding-bottom: 0;
  }
  .text-holder {
    strong {
      color: var(--red);
    }
  }
  .amount-holder {
    font-size: 7vw;
    color: var(--red);
    font-weight: 900;
    position: relative;
    padding: 0px;
    margin: 0px;
    z-index: 2;
    background: linear-gradient(
      to right,
      #cd0000 20%,
      #f13d3d 40%,
      #f13d3d 60%,
      #cd0000 80%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    animation: shine 1.5s linear infinite;
  }
  .card-footer {
    border-top: 0;
    padding-top: 0;
    h6 {
      color: var(--mustard);
      font-weight: 900;
      font-size: clamp(1rem, 26vw, 2.2rem);
      text-shadow: 1px 0px 10px var(--black);
      white-space: nowrap;
      @media (min-width: 1200px) {
        font-size: clamp(1rem, 26vw, 3rem);
      }
    }
  }
`;

PokerJackpotRulesCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  amount: PropTypes.string
};

export default PokerJackpotRulesCard;
