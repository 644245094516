import { useState, useEffect } from "react";
import { useWindowWidth } from "./windowUtils";

const IMAGE_CDN_BASE_URL = "https://assets.playground.ca/pgfe/img/";

// Define breakpoints and corresponding image sizes in an array of objects
const sizeMapping = [
  { width: 1400, size: "original" },
  { width: 1200, size: "original" },
  { width: 992, size: "2000x800" },
  { width: 768, size: "2000x800" },
  { width: 640, size: "2000x800" },
  { width: 480, size: "2000x800" },
  { width: 0, size: "2000x800" },
];
//changed default values to original sizes as the images are already optimized but needs to be updated
// const sizeMapping = [
//   { width: 1400, size: "1600x1600" },
//   { width: 1200, size: "1200x1200" },
//   { width: 992, size: "992x992" },
//   { width: 768, size: "768x768" },
//   { width: 640, size: "640x640" },
//   { width: 480, size: "320x320" },
//   { width: 0, size: "320x320" },
// ];

// Function to get the appropriate image size based on the current window width
const getImageSizeForWidth = (width) => {
  for (const { width: bpWidth, size } of sizeMapping) {
    if (width >= bpWidth) return size;
  }
  return sizeMapping[sizeMapping.length - 1].size; // Default to smallest size
};

// Hook to get the responsive image URL
const useResponsiveImageUrl = (image) => {
  const [imageUrl, setImageUrl] = useState("");
  const width = useWindowWidth();

  useEffect(() => {
    const updateImageUrl = () => {
      const size = getImageSizeForWidth(width);
      const imageUrl =
        size === "original"
          ? `${IMAGE_CDN_BASE_URL}${image}`
          : `${IMAGE_CDN_BASE_URL}fit-in/${size}/${image}`;
      setImageUrl(imageUrl);
    };

    updateImageUrl(); // Set image URL on component mount

    window.addEventListener("resize", updateImageUrl);
    return () => window.removeEventListener("resize", updateImageUrl);
  }, [image]);

  return imageUrl;
};

export default useResponsiveImageUrl;
// const desktopImage = "home/drunken-dragon-home-desktop2.webp";
// const mobileImage = "home/drunken-dragon-home-mobile.webp";
// Determine the correct image based on window width
// const bgImage = window.innerWidth >= 991 ? desktopImage : mobileImage;
// const responsiveBgImage = useResponsiveImageUrl(bgImage);
// bgImage={responsiveBgImage}
// bgImage={useResponsiveImageUrl(
//   windowWidth >= 991
//     ? "home/drunken-dragon-home-desktop2.webp"
//     : "home/drunken-dragon-home-mobile.webp"
// )}
