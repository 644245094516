// gameListUtils.js

export const PROGRESSIVE_THRESHOLD = 35_000;

/**
 * Finds the highest progressive jackpot for a specific game.
 *
 * @param {Object} game - The game object containing the game name.
 * @param {Array} jackpotList - List of jackpot objects containing the amount and name fields.
 * @param {number} threshold - Optional threshold to filter jackpots by a minimum amount.
 * @returns {Object|null} - The highest matching jackpot if it meets the threshold, otherwise null.
 */
export const getHighestJackpotForGame = (game, jackpotList, threshold = PROGRESSIVE_THRESHOLD) => {
  // Ensure game and jackpotList are valid
  if (!jackpotList || !game) return null;

  // Find all jackpots that match the game name
  const matchingJackpots = jackpotList.filter(jackpot => jackpot.name === game.game_name);

  // If there are no matching jackpots, return null
  if (!matchingJackpots.length) return null;

  // Find the highest jackpot amount
  const highestJackpot = matchingJackpots.reduce((max, jackpot) =>
    jackpot.amount > max.amount ? jackpot : max
  );

  // Return the highest jackpot if it's >= threshold, otherwise return null
  return highestJackpot.amount >= threshold ? highestJackpot : null;
};

/**
 * Finds all games with valid jackpots, and returns the highest jackpot for each game.
 *
 * @param {Array} jackpotList - List of jackpot objects containing the amount and name fields.
 * @param {Array} gameList - List of game objects containing the game name.
 * @param {number} threshold - Optional threshold to filter jackpots by a minimum amount.
 * @returns {Array} - An array of objects where each object contains the highest jackpot and matching game.
 */
export const getJackpotGames = (jackpotList, gameList, threshold = PROGRESSIVE_THRESHOLD) => {
  // Ensure jackpotList and gameList are valid
  if (!jackpotList || !gameList) {
    console.error("Error: Missing jackpot or game data.");
    return null;
  } else if (!Array.isArray(jackpotList) || !Array.isArray(gameList)) {
    console.error("Error: Invalid jackpot or game data format.");
    return null;
  } else if (jackpotList.length === 0 || gameList.length === 0) {
    console.error("Error: Empty jackpot or game data.");
    return null;
  } else if (typeof threshold !== "number" || threshold < 0) {
    console.error("Error: Invalid threshold value.");
    return null;
  }

  // Filter jackpots greater than or equal to the threshold
  const validJackpots = jackpotList.filter(jackpot => jackpot.amount >= threshold);

  // Create a new array with the highest jackpot for each game
  const jackpotMap = validJackpots.reduce((map, jackpot) => {
    const game = gameList.find(g => g.game_name === jackpot.name);

    if (game) {
      if (!map[jackpot.name] || jackpot.amount > map[jackpot.name].amount) {
        map[jackpot.name] = { ...jackpot, game };
      }
    }

    return map;
  }, {});

  // Return the matched games with their highest jackpots
  return Object.values(jackpotMap);
};

/**
 * Returns the total amount of progressive jackpots from the jackpot list.
 *
 * @param {Array} jackpotList - List of jackpot objects containing the amount field.
 * @param {number} threshold - Optional threshold to filter jackpots by a minimum amount.
 * @returns {number} - The total sum of progressive jackpot amounts.
 */
export const getTotalJackpotAmount = (jackpotList, threshold = 0) => {
  if (!Array.isArray(jackpotList)) return 0;

  // Filter jackpots greater than or equal to the threshold
  const validJackpots = jackpotList.filter(jackpot => jackpot.amount >= threshold);

  // Sum all valid jackpots
  const totalAmount = validJackpots.reduce((total, jackpot) => {
    return total + jackpot.amount;
  }, 0);

  return totalAmount;
};
