import { createGlobalStyle, keyframes, css } from "styled-components";
import { Form } from "react-bootstrap";
import styled from "styled-components";

export const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-0.3em);
  }
  60% {
    transform: translateY(-0.1em);
  }
`;

export const bounceIn = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  70% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const containerStyles = css`
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &:after,
  &:before {
    content: "";
    display: table;
  }
`;


const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  :root {
    --red: #cd0000;
    --mutedred: #4b0b0d;
    --black: #000000;
    --lightblack: #111111;
    --grey: #818181;
    --darkgrey: #3d3d3d;
    --bluegrey: #e8ebef;
    --white: #fff;
    --mutedwhite: #d2d2d2;
    --lightgrey: #f9f9f9;
    --mustard: #fcb441;
    --darkmustard: #8d5e11;
    --orange: #ff7b00;
    --rewards_grid: #2C2C2C;
    --green: #00BA00;
    --bluelinkdark: #7fc3cd;
    --bluelinklight: #008194;
    --pinklinkdark: #e78797;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1500px;
    --fc-today-bg-color: #292a31;
    --fc-tournament-all: #506767;
    --fc-tournament-all-muted: #50676769;
    --fc-tournament-daily: #2088e1;
    --fc-tournament-daily-muted: #04182f;
    --fc-tournament-satellite: #64af36;
    --fc-tournament-satellite-muted: #0f2207;
    --fc-tournament-online: #f07700;
    --fc-tournament-online-muted: #321303;
    --fc-tournament-festival: #c13132;
    --fc-tournament-festival-muted: #1c0304;
    --fc-tournament-festival-time: #fff;

  }
  html {
    height: fill-available;
    height: -webkit-fill-available;
    font-size: 16px;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    background: var(--black);
    /* Apply scroll-margin-top globally to all elements with an ID */
    [id] {
      &.anchor-with-scroll-offset {
        position: relative;
        top: -100px;
      }
      /* scroll-margin-top: 500px; Adjust the value as per your offset needs */
    }
    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    p {
      font-size: 16px;
      margin: 0 0 10px;
      white-space: pre-wrap;
      a {
        color: var(--bluelinkdark);
        &.light {
          color: var(--bluelinklight);
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    h1, h2, h3, h4, h5, h6 {
      margin: 10px 0;
    }
    .small, .small p {
      font-size: 0.8rem;
    }
    .smaller, .smaller p {
      font-size: 0.65rem;
    }
    img {
      max-width: 100%;
    }
  }
  .bold {
    font-weight: 700;
  }
  .bolder {
    font-weight: 900;
  }
  .red {
    color: var(--red)!important;
  }
  .grey {
    color: var(--grey)!important;
  }
  .bluelinkdark {
    color: var(--bluelinkdark)!important;
  }
  .pinklinkdark {
    color: var(--pinklinkdark)!important;
  }
  .white {
    color: var(--white)!important;
  }
  .mutedwhite {
    color: var(--mutedwhite)!important;
  }
  .mustard {
    color: var(--mustard)!important;
  }
  .link {
    align-self: center;
    font-weight: 600;
    font-size: 18px;
    &:hover {
      color: var(--red);
    }
  }
  .overlay-trigger {
    border-bottom: 1px dashed var(--green);
    }
    .tooltip-inner {
      font-size: 1.2em;
      padding: 1rem;
      box-shadow: 0px 0px 6px rgb(157 255 113 / 35%);
  }
  .modal-body {
    max-height: 100vh;
    overflow-y: auto;
  }
  .modal-content,
  .modal-header {
    border-radius: 0;
  }
  .modal-footer {
    button {
      margin: 0;
    }
  }
  .grey-text {
    color: #555;
    font-size: 14px;
    margin: 0;
  }
  .bump-up {
    position: relative;
    bottom: 2px;
    &.more {
      bottom: 4px;
    }
  }
  divider-wpt {
    border-top: 2px solid #bebebe;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .modal-backdrop {
    z-index: 9950;
  }
  .modal.show {
    z-index: 9999;
  }
  switcheroo-holder {
    display: flex;
    flex-direction: column;
    .title-section {
      order: 2;
    }
    .hero-carousel {
      order: 1;
    }
  }
  container-div {
    ${containerStyles}
    max-width: 1200px;
  }
  container-wide {
    ${containerStyles}
    max-width: 1440px;
  }
  container-fluid {
    ${containerStyles}
    max-width: initial;
  }
  grid-container {
    padding: 0 20px;
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 20px;
    & > div {
      width: 22%;
      padding: 0;
      border: 1px solid #e8ebef;
      & > img {
        width: 100%;
        height: 100%;
        max-height: 265px;
        object-fit: cover;
      }
      & > div {
        padding: 15px;
        & > h3 {
          margin: 0;
        }
        & > p {
          font-size: 14px;
        }
      }
    }
    @media (max-width: 991px) {
      & > div {
        width: 100%;
      }
    }
  }
  center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .bounce {
    animation: ${bounce} 2s infinite;
  }
  .bounce-in {
    animation: ${bounceIn} 0.3 ease-in-out;
  }
  auth-page {
    background: var(--black);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    position: relative;
    color: var(--mutedwhite);
    padding: 0 1rem;
  }
  #offcanvasNavbar {
    margin-top: 80px;
    background: var(--black);
    color: var(--white);
    z-index: 1060;
    .offcanvas-header {
      .btn-close {
        filter: invert(1); /* This inverts the color of the close button to white */
      }
    }
    @media (min-width: 992.98px) {
      margin-top: 0;
    }
  }
  .offcanvas {
    @media (max-width: 543.98px) {
      width: 100%!important;
    }
    .dark {
      background: var(--black);
      color: var(--white);
    }
  }

  .modal {
    padding: 80px 0 20px!important;
  }
`;

export const StyledForm = styled(Form)`
  max-width: 500px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  background: var(--lightblack);
  border-radius: 15px;
  border: solid 1px #282828;
  padding: 2rem;
  margin: 1rem auto;
  @media (max-width: 576px) {
    padding: 1rem;
    margin: 1rem 0;
  }

  button,
  .button {
    margin-top: 12px;
  }

  .form-link,
  .form-label {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .label-link-container {
    display: flex;
    justify-content: space-between;
    .form-link {
      color: var(--bluelinkdark);
      text-align: right;
      &:hover {
        color: var(--white);
      }
    }
  }
`;
export default GlobalStyle;

