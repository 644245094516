// scrollPosition.reducer.js
import { scrollPositionActionTypes as actions } from './scrollPosition.actions';

export const scrollPositionReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: payload, // Update the scroll position state
      };
    default:
      return state;
  }
};
