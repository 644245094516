import { useReducer, useMemo, useContext } from "react";
import { entertainmentReducer } from "./entertainment.reducer";
import { entertainmentActionTypes as actions } from "./entertainment.actions";
import { EntertainmentState } from "./entertainment.state";

const ENTERTAINMENT_STATE = {
  selectEvent: "",
  selectEventToggle: false,
  isReadMore: true,
  isReadMoreValue: "",
};

export const EntertainmentStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    entertainmentReducer,
    ENTERTAINMENT_STATE
  );

  const value = useMemo(
    () => ({
      ...state,
      selectEventFn: (value) => {
        dispatch({ type: actions.SELECT_EVENT, payload: value });
      },
      selectEventToggleFn2: (value) => {
        dispatch({ type: actions.SELECT_EVENT_TOGGLE, payload: value });
      },
      selectEventToggleFn: (value) => {
        dispatch({ type: actions.SELECT_EVENT_TOGGLE, payload: value });
      },
      isReadMoreFn: (value) => {
        dispatch({ type: actions.READ_MORE, payload: value });
      },
    }),
    [state]
  );
  return (
    <EntertainmentState.Provider value={value}>
      {children}
    </EntertainmentState.Provider>
  );
};
export const useEntertainment = () => useContext(EntertainmentState);
