import styled from "styled-components";
import { useState } from "react";
import { forgot_password } from "../../api/auth";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import { StyledForm as GlobalStyledForm } from '../../GlobalStyle';
import Button from "src/components/Button/Button";
import { Link } from "react-router-dom";
import AuthServiceInfo from "src/components/AuthServiceInfo";
import TopLogo from "src/components/Auth/TopLogo";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ForgotPassword = () => {
  const { i18n, t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const email = formData.get("email");

    if (!email) {
      setErrorMessage(
        i18n.language === "en"
          ? "The email field is empty"
          : "Le champ courriel est vide"
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await forgot_password(email);

      if (response.success) {
        window.location.href = t("routes.requestPasswordSuccess");
      } else {
        setErrorMessage(
          i18n.language === "en"
            ? "Unsuccessful password reset"
            : "Réinitialisation du mot de passe non réussie"
        );
      }
    } catch (error) {
      console.error("Error requesting password reset:", error);
      setErrorMessage(
        i18n.language === "en"
          ? "Error requesting password reset"
          : "Erreur dans la demande de réinitialisation du mot de passe"
      );
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000); // delay for 3 seconds
    }
  };

  return (
    <auth-page>
      <TopLogo />
      <Row xs={1} md={2} className="g-4">
        <Col className="px-2">
          <GlobalStyledForm onSubmit={handleSubmit}>
            <SubTitleSection as={'h1'} subTitle={t("auth.forgotPassword.title")} text={t("auth.forgotPassword.description")} className="py-0" />
            <FormGroup controlId="email">
              <FormLabel>{t("auth.form.email.label")}</FormLabel>
              <FormControl type="email" name="email" placeholder={t("auth.form.email.placeholder")} required />
            </FormGroup>
            <Form.Control.Feedback type="invalid">
              {t("auth.form.email.requiredMessage")}
            </Form.Control.Feedback>
            <Button
              type="submit"
              disabled={isLoading}
              bgColor="var(--red)"
              color="var(--white)"
              hoverColor="var(--black)"
              hoverBgColor="var(--white)"
              fluid
              label={
                isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  t("submit")
                )
              }
            />
            <Link to={t("routes.homepage")} className="link">{t("button.return-home")}</Link>
          </GlobalStyledForm>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <AuthServiceInfo />
        </Col>
      </Row>
    </auth-page>
  );
};

export default ForgotPassword;
