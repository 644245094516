// src/components/Nav/HeaderNav.jsx
import React, { useEffect, useState } from "react";
import { useHomepage } from "src/context/homepage/homepage.provider";
// import { useLocation } from "react-router-dom";
import { Navbar, Nav, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { navbarLinks } from "../../config/navbarLinks";
import StyledBurger from "./StyledBurger";
import SecondaryNav from "./SecondaryNav";
import NavQuickLinks from "./NavQuickLinks";
import { isScrolling, renderLinks } from "src/utils/navigation";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const HeaderNav = () => {
  const { t } = useTranslation();
  const { setMenuBarToggle } = useHomepage();
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  // const openOffcanvas = () => {
  //   setOffcanvasOpen(true);
  //   setMenuBarToggle(true);
  // };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
    setMenuBarToggle(false);
  };

  const closeAllMenus = () => {
    setOffcanvasOpen(false);
    setMenuBarToggle(false);
  };

  const changeBackground = () => {
    setScrolling(isScrolling());
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  return (
    <>
      <StyledNavbar
        expand="md"
        fixed="top"
        theme={{ isScrolling: scrolling }}
        id="header-navbar"
      >
        <StyledBurger isOpen={isOffcanvasOpen} setOpen={setOffcanvasOpen} closeOffcanvas={closeOffcanvas} />
        <NavLogo to={t("routes.homepage")} theme={{ isScrolling: scrolling }}>
          <ResponsiveImage
            src="svg/PLAYGROUND-LOGO-HORIZONTAL_WHITE_TEXT.svg"
            alt="Playground Logo"
          />
        </NavLogo>
        <NavQuickLinks closeAllMenus={closeAllMenus} scrolling={scrolling} />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          show={isOffcanvasOpen}
          onHide={closeOffcanvas}
        >
          <Offcanvas.Body>
            <StyledNav
              role="navigation"
              className="flex-grow-1"
              theme={{ isScrolling: scrolling }}
            >
              {renderLinks(navbarLinks, closeAllMenus)}
              <SecondaryNav closeAllMenus={closeAllMenus} />
            </StyledNav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </StyledNavbar>
    </>
  );
};

const NavLogo = styled(NavLink)`
  position: fixed;
  left: calc(50% - 91px);
  z-index: 2000;
  img {
    height: 80px;
    width: 182px;
    object-fit: contain;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    left: 1rem;
  }

  @media (min-width: 992px) {
    position: initial;
    align-self: center;
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    max-width: ${(props) => (props.theme.isScrolling ? "200px" : "300px")};
    max-height: ${(props) => (props.theme.isScrolling ? "80px" : "190px")};
    transition: 0.3s all;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
`;

const StyledNavbar = styled(Navbar)`
  z-index: 1060;
  height: 80px;
  background: var(--black);
  padding: 0 1rem;
  @media (min-width: 768px) {
    background: rgba(0, 0, 0, 0.9);
  }
  @media (min-width: 992px) {
    will-change: transform;
    transition: 0.3s;
    height: ${(props) => (props.theme.isScrolling ? "80px" : "140px")};
    background: ${(props) =>
      props.theme.isScrolling
        ? "rgba(0,0,0,0.7)"
        : "linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.1) 100%)"};
    z-index: 10;
  }
`;

const StyledNav = styled(Nav)`
  justify-content: flex-end;
  z-index: 1030;

  // Reset styles
  .dropdown-menu, .dropdown-item {
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    background-color: var(--black);
  }

  .dropdown-toggle {
    &:after {
      display: none;
    }
    .toggle-icon {
      transition: transform 0.3s;
    }
    &.show {
      .toggle-icon {
        transform: rotate(-180deg);
      }
    }
  }

  // Dropdown submenu styles
  .dropdown-submenu {
    padding: 0.4rem 0;
    // Nested dropdown toggle
    .dropdown-toggle {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 0;
    }
    // Nested dropdown menu panel
    .dropdown-menu {
      position: relative;
      left: 0;
      z-index: 5;
      padding: 0 1rem 0 0;
      margin: 0 0.77rem 0.5rem 0;
      border-left: solid 5px #2d1616;
      .dropdown-item {
        padding-left: 3.3rem;
        font-size: 1rem;
        z-index: 15;
        position: relative;
        background: transparent;
        white-space: unset;
        color: var(--mutedwhite);
        svg {
          margin-right: 0.5rem;
          position: relative;
          bottom: 1px;
          width: 2.2rem;
          max-height: 1rem;
        }
        &.active {
          margin-left: -5px;
        }
        &.category-title {
          color: var(--white);
          padding-left: 0.5rem;
          text-transform: uppercase;
          font-weight: bold;
          margin-top: 1.1rem;
        }
        .badge {
          display: none;
          position: relative;
          bottom: 2px;
          text-transform: uppercase;
          font-size: 0.7rem;
          line-height: 0.5rem;
          &.bg-free {
            background-color: #0b492c;
          }
          &.bg-buy-tickets {
            background-color: var(--red);
          }
          &.bg-new {
            background-color: var(--orange);
          }
        }
      }
      // Bg image overlay
      &.show {
      }
    }
  }

  // Base link styles
  .nav-link, .dropdown-toggle, .dropdown-item {
    font-weight: 500;
    color: var(--white);
    font-size: 1.1rem;
    margin: 0.4rem 0;
    padding: 0;
    transition: color 0.3s;
    border-color: transparent;

    &.active {
      border-left: solid 5px var(--red);
      padding-left: calc(1rem - 5px);
    }
  }
  .dropdown-item:hover {
    border-left: solid 5px var(--red);
    margin-left: -5px;
    &.category-title {
    }
  }

  // Mega menu for tablets and up
  @media (min-width: 768px) {
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    > .nav-item {
        position: relative;
        z-index: 1060;
        text-align: center;
      .nav-link {
        padding: 0;
        &.active {
          border-bottom: solid 3px var(--red);
          border-left: none;
          color: var(--white)
        }
        :hover {
          color: var(--red);
        }
        &.show {
          color: var(--white);
        }
      }
      &.dropdown {
        .dropdown-toggle {
          position: relative;
          z-index: 1065;
          .toggle-icon {
            padding: 0 0.3rem;
          }
        }
      }
      > .dropdown-menu.show {
        display: flex;
        position: fixed;
        transition: all 0.3s;
        background: rgb(0 0 0 / 95%);
        padding: 120px 1rem 2rem;
        z-index: 1050;
        top: 0px;
        justify-content: center;
        width: 100vw;
        gap: 2rem;
        max-height: 90vh;
        overflow: auto;
        &:after {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 120px;
          background: rgba(0, 0, 0, 0.7);
          z-index: 1070;
        }

        // Dropdown submenu styles
        .dropdown-submenu {
          // Nested dropdown menu panel
          // No toggle on tablet and up
          width: 50%;
          max-width: 720px;
          .dropdown-menu {
            position: initial;
            padding: 0;
            margin: 0;
            border-left: none;
            .dropdown-item:not(.category-title) {
              &:hover, &.active {
                margin-left: 0;
                padding-left: calc(3.3rem - 5px);
              }
            }
            .category-title {
              &:hover, &.active {
                margin-left: 0;
                width: calc(100% + 0px);
              }
              &:not(.img-link) {
                &:hover, &.active {
                  padding-left: calc(0.5rem - 5px);
                }
              }
              &.img-link {
                overflow: hidden;
                &:hover, &.active {
                  margin-left: 0;
                  width: calc(100% + 0px);
                  border-left: none;
                  span.has-img {
                    border-left: solid 5px var(--red);
                    margin-left: -8px;
                    padding-left: calc(1rem - 13px);
                  }
                  img {
                    transform: scale(1.03);
                    opacity: 0.9;
                  }
                }
              }
            }
            img {
              height: 8rem;
              width: 100%;
              object-fit: cover;
              margin-bottom: -1.5rem;
              margin-left: -1rem;
              position: relative;
              z-index: 10;
              transition: all 0.3s;
            }
            span.has-img {
              background: rgba(0, 0, 0, 0.7);
              position: relative;
              z-index: 11;
              display: block;
              padding-top: 0.5rem;
              padding-left: 1rem;
              margin-left: -1rem;
              font-size: 1.25rem;
            }
          }
        }
      }
    }
  }

    // Adjust navBar for tablets
  @media (min-width: 768px) and (max-width: 991.98px) {
    justify-content: space-between;
    top: 80px;
    transition: all 0.4s;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    width: 100%;
    justify-content: space-between;
    padding: 0 2rem;
  }

  @media (min-width: 991.98px) {
    > .nav-item > .dropdown-menu.show {
      padding-top: ${(props) => (props.theme.isScrolling ? "80px" : "120px")};
      &:after {
        height: ${(props) => (props.theme.isScrolling ? "80px" : "120px")};
      }
    }
  }

  @media (min-width: 1200px) {
    gap: 1.5rem;
  }
`;

export default HeaderNav;
