import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { t } from "i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { Modal } from "react-bootstrap";
import Button from "src/components/Button/Button";
import { FaTimes } from "react-icons/fa";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import Spinner from "src/components/Spinner";
import CoverageAndResultsBanner from "src/components/Poker/Tournaments/CoverageAndResultsBanner";
import { Link, useSearchParams } from "react-router-dom";
import VideoComponent from "src/components/HomePage/VideoComponent";

const NewYearsEve2025 = () => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const showFormParam = searchParams.get("rsvp");
    if (showFormParam && showFormParam.toLowerCase() === "true") {
      setShowFormModal(true);
    }
  }, [searchParams]);

  const handleClose = () => {
    setShowFormModal(false);
  };

  const handleShowFormModal = () => {
    setShowFormModal(true);
  };
  const contentItems = t("entertainment.newYearsEve2025.content.part3.items", {
    returnObjects: true,
  });

  return (
    <>
      <WrapperDiv className="mb-5">
        {/* <Hero
          arrowBounce=""
          bgImage="entertainment/variety/new-years-eve-2025/LP-NYE-2024-Banner.jpg"
          buttonLink=""
          buttonText=""
          title=""
          description=""
          descriptionBgColor=""
          image={t("entertainment.newYearsEve2025.hero.image")}
          imageAlt={t("entertainment.newYearsEve2025.hero.imageAlt")}
          showArrow=""
        /> */}
        <VideoComponent
          desktop="/videos/PG-NYE-Mashup-Video-Full-Size.mp4"
          mobile="/videos/NYE-Mashup-Mobile.mp4"
          soundControl={false}
        />
        <CoverageAndResultsBanner
          label={t("entertainment.newYearsEve2025.banner.label")}
          // resultsLink={`https://www.playground.ca${t("routes.newYearsEve2025")}?rsvp=true`}
          hotels="light"
          // buttonOptions={{ onClick: handleShowFormModal }}
        />
        <MainContentDiv
          bgImage={useResponsiveImageUrl(
            "entertainment/variety/new-years-eve-2025/nye-2024-background.jpg"
          )}
        >
          <container-div>
            <TitleSection
              as={"h1"}
              title={t("entertainment.newYearsEve2025.content.part1.title")}
              text={t(
                "entertainment.newYearsEve2025.content.part1.description"
              )}
              dark={false}
              borderColor="#D59947"
            />
            <Row className="g-4 mb-5" id="">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  src={t(
                    "entertainment.newYearsEve2025.content.part2.partyPeople.image"
                  )}
                  alt={t(
                    "entertainment.newYearsEve2025.content.part2.partyPeople.title"
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t(
                    "entertainment.newYearsEve2025.content.part2.partyPeople.title"
                  )}
                  borderColor="#D59947"
                />
                <ReactMarkdown>
                  {t(
                    "entertainment.newYearsEve2025.content.part2.partyPeople.description"
                  )}
                </ReactMarkdown>
              </Col>
            </Row>
            <Row className="g-4 mb-4" id="">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  src={t(
                    "entertainment.newYearsEve2025.content.part2.bracelet.image"
                  )}
                  alt="Get Your Party Bracelet – Access All Floors and Perks!"
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t(
                    "entertainment.newYearsEve2025.content.part2.bracelet.title"
                  )}
                  borderColor="#D59947"
                />
                <ReactMarkdown>
                  {t(
                    "entertainment.newYearsEve2025.content.part2.bracelet.description"
                  )}
                </ReactMarkdown>
                <p>
                  {t(
                    "entertainment.newYearsEve2025.content.part2.bracelet.description-2"
                  )}
                  <Link to={t("routes.pokerNewYearsEve2025")}>{t("entertainment.newYearsEve2025.content.part2.bracelet.buttonText")}</Link>
                  {t(
                    "entertainment.newYearsEve2025.content.part2.bracelet.description-3"
                  )}
                </p>
                <ReactMarkdown>
                  {t(
                    "entertainment.newYearsEve2025.content.part2.bracelet.description-4"
                  )}
                </ReactMarkdown>
                {/* <Button
                  href={t("routes.poker")}
                  hoverBgColor="#e6b366"
                  hoverColor="var(--lightblack)"
                  label={"Poker"}
                /> */}

                <StyledModal
                  show={showFormModal}
                  onHide={handleClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="fraud-warning-modal"
                >
                  <Modal.Header>
                    <Modal.Title>
                      {t("entertainment.newYearsEve2025.modal.title")}
                    </Modal.Title>
                    <FaTimes onClick={handleClose} />
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      {t("entertainment.newYearsEve2025.modal.description")}
                    </p>
                  </Modal.Body>
                  <Modal.Body className="frame-holder">
                    <iframe
                      src="https://docs.google.com/forms/d/e/1FAIpQLSf7NZrKOF0t9h5UMUDmS9CV8FZjfuVvtp7sWabeNPyUQGzhRA/viewform?embedded=true"
                      width="100%"
                      height="500"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Loading…
                    </iframe>
                    <Spinner />
                  </Modal.Body>
                </StyledModal>
              </Col>
            </Row>
          </container-div>
          <container-div>
            <Row className="g-4 mb-3">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  src={t(
                    "entertainment.newYearsEve2025.content.part2.countdown.image"
                  )}
                  alt="There’s a countdown somewhere!"
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t(
                    "entertainment.newYearsEve2025.content.part2.countdown.title"
                  )}
                  borderColor="#D59947"
                />
                <ReactMarkdown>
                  {t(
                    "entertainment.newYearsEve2025.content.part2.countdown.description"
                  )}
                </ReactMarkdown>
              </Col>
            </Row>
            <TitleSection
              as={"h3"}
              title={t("entertainment.newYearsEve2025.content.part3.title")}
              text={t(
                "entertainment.newYearsEve2025.content.part3.description"
              )}
              dark={false}
              borderColor="#D59947"
            />
            <div className="grid-content">
              <Row className="mb-3">
                {Object.values(contentItems)?.map((item, index) => (
                  <Col
                    xs={12}
                    md={item.double ? 12 : 6}
                    lg={index === contentItems.length - 1 ? 12 : 6}
                    key={item.id || index}
                  >
                    <Card>
                      <ResponsiveImage
                        src={item.image}
                        alt={item.description}
                      />
                      <div>
                        <ResponsiveImage
                          src={item.logo}
                          alt={item.description}
                        />
                        <ReactMarkdown>{item.title}</ReactMarkdown>
                        <ReactMarkdown>{item.description}</ReactMarkdown>
                        {item.subtext && (
                          <p>
                            <i>{item.subtext}</i>
                          </p>
                        )}
                        <Button
                          bgColor={`linear-gradient(to right, #8b4513, #ffcc66, #8b4513)`}
                          href={item.menu}
                          color="var(--lightblack)"
                          hoverColor="#e6b366"
                          hoverBgColor="#e6b366"
                          specialHover
                          label={t("button.view-menu")}
                        />
                        {!item.image.includes("sold") && (
                          <ReactMarkdown>
                            {t(
                              "entertainment.newYearsEve2025.content.part3.cta-text"
                            )}
                          </ReactMarkdown>
                        )}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <ReactMarkdown className="outro">
                {t("entertainment.newYearsEve2025.content.bottomText")}
              </ReactMarkdown>
            </div>
          </container-div>
        </MainContentDiv>
      </WrapperDiv>
    </>
  );
};

const WrapperDiv = styled.div`
  .outro {
    max-width: 80%;
    padding-bottom: 2rem;
    margin: 0 auto;
  }
  /* background: transparent;
  text-align: center;
  display: flex;
  flex-direction: column;
  .hero_image_container {
    img {
      max-height: 500px;
      max-width: 80%;
    }
  }
  .hero_text_container {
    background-color: transparent;
    backdrop-filter: none;
  } */
  .grid-content {
    text-align: center;
  }
`;
const MainContentDiv = styled.div`
  background: ${(props) => `url(${props.bgImage})`};
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  container-div {
    background: rgba(0, 0, 0, 0.5);
  }
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  color: var(--white);
  text-align: left;
  .row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    &:nth-child(3) {
      flex-direction: row-reverse;
    }
    .col-12 {
      .subtitle-section {
        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
    }
  }
`;

const Card = styled.div`
  padding: 1rem;
  @media (max-width: 992px) {
    padding: 0;
  }
  color: var(--white);
  margin-bottom: 24px;
  position: relative;
  & > div {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--lightblack);
    border-top: 4px solid #d59947;
    position: relative;
    top: -20px;
    margin: 0 auto;
    width: 90%;
    img {
      width: auto;
      max-width: 55%;
      display: inline-block;
      height: 100px;
    }
    h4 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      text-align: center;
    }
    p {
      text-align: center;
      max-width: 80%;
      i {
        max-width: 80%;
        display: inline-block;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-header {
    background-color: var(--lightblack);
    justify-content: space-between;
    text-align: left;
    svg {
      color: var(--grey);
    }
    .modal-title {
      color: var(--white);
      font-weight: 700;
    }
  }
  .modal-body {
    &.frame-holder {
      padding: 0;
      iframe {
        position: relative;
        z-index: 5;
      }
      .spinner {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
      }
    }
  }
  .modal-content,
  .modal-header {
    border-radius: 0;
  }
  .modal-footer {
    button {
      margin: 0;
    }
  }
`;
export default NewYearsEve2025;
