// src/utils/windowUtils.js
// Following the convention of
// https://getbootstrap.com/docs/5.3/layout/breakpoints/

import { useState, useEffect } from 'react';

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
};

export const useIsXsScreenUp = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 0; // Always true since xs is the smallest breakpoint
};

export const useIsXsScreenDown = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 575.98;
};

export const useIsSmScreenUp = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 576;
};

export const useIsSmScreenDown = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 767.98;
};

export const useIsMdScreenUp = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 768;
};

export const useIsMdScreenDown = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 991.98;
};

export const useIsLgScreenUp = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 992;
};

export const useIsLgScreenDown = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 1199.98;
};

export const useIsXlScreenUp = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 1200;
};

export const useIsXlScreenDown = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 1399.98;
};

export const useIsXxlScreenUp = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 1400;
};

export const useIsXxlScreenDown = () => {
  const windowWidth = useWindowWidth();
  return windowWidth < 1399.98;
};

// Utility functions for exact ranges

export const useIsSmallScreenOnly = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 576 && windowWidth < 767.98;
};

export const useIsMediumScreenOnly = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 768 && windowWidth < 991.98;
};

export const useIsLargeScreenOnly = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 992 && windowWidth < 1199.98;
};

export const useIsXlScreenOnly = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 1200 && windowWidth < 1399.98;
};

export const useIsXxlScreenOnly = () => {
  const windowWidth = useWindowWidth();
  return windowWidth >= 1400 && windowWidth < 1599.98; // Assuming XXL extends up to 1599.98
};
