import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { tournaments, tournament } from "../../api/poker";
import TournamentDetailsCard from "./Tournaments/TournamentDetailsCard";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import {
  useIsXsScreenDown,
  useIsSmallScreenOnly,
  useIsSmScreenDown,
  useIsMediumScreenOnly,
  useIsLgScreenUp,
} from "src/utils/windowUtils";
import Button from "../Button/Button";
import moment from "moment";

const Tournaments = () => {
  const { i18n, t } = useTranslation();
  const currentLocale = i18n.language;
  const upcomingDays = 12;

  const [tournamentsData, setTournamentsData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch tournaments for today and the next 2 days
  useEffect(() => {
    const fetchTournaments = async () => {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment().add(upcomingDays, "days").format("YYYY-MM-DD");

      try {
        // Fetch the basic tournament data
        const basicData = await tournaments(startDate, endDate);

        // Fetch detailed data for each tournament using gid
        const detailedData = await Promise.all(
          basicData.map(async (tournamentBasic) => {
            const details = await tournament(tournamentBasic.gid);
            return { ...tournamentBasic, ...details };
          })
        );

        // Filter out tournaments that have already started
        const futureTournaments = detailedData.filter((tournament) => {
          // Use end_entry_time if available, otherwise use start_at
          // Don't use max() because it can be unpredictable if end_entry_time is null
          const endEntryMoment = tournament.end_entry_time 
            ? moment(tournament.end_entry_time) 
            : moment(tournament.start_at);
          return endEntryMoment.isAfter(moment());
        });
        setTournamentsData(futureTournaments);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
        setLoading(false);
      }
    };

    fetchTournaments();
  }, []);

  // Limit the data based on the screen size
  const isXsScreenDown = useIsXsScreenDown();
  const isSmScreenOnly = useIsSmallScreenOnly();
  const isSmScreenDown = useIsSmScreenDown();
  const isMediumScreenOnly = useIsMediumScreenOnly();
  const isLgScreenUp = useIsLgScreenUp();

  let limitedTournamentsData = [];
  let slidesToShow = 1;

  if (isXsScreenDown) {
    limitedTournamentsData = tournamentsData.slice(0, 6);
    slidesToShow = 1;
  } else if (isSmScreenOnly) {
    limitedTournamentsData = tournamentsData.slice(0, 12);
    slidesToShow = 1;
  } else if (isMediumScreenOnly) {
    limitedTournamentsData = tournamentsData.slice(0, 24);
    slidesToShow = 2;
  } else if (isLgScreenUp) {
    limitedTournamentsData = tournamentsData; // Show all for larger screens
    slidesToShow = 3;
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    lazyLoad: true,
    speed: 800,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    adaptiveHeight: true,
  };

  const sliderRef = React.createRef();

  if (loading) {
    return <div>Loading tournaments...</div>;
  }

  return (
    <TournamentsDiv>
      <container-wide>
        <Button
          className="all-tournaments-btn"
          color="var(--white)"
          hoverBgColor="var(--white)"
          hoverColor="var(--black)"
          href={t("routes.pokerTournamentCalendar")}
          label={t("poker.upcomingTournaments.viewAll")}
          fluid={isSmScreenDown ? true : false}
        />
        <SubTitleSection subTitle={t("poker.upcomingTournaments.title")} text={t("poker.upcomingTournaments.upcomingDays", {days: upcomingDays})} />
        <TournamentSlider {...settings} ref={sliderRef} className="tournaments-slider">
          {limitedTournamentsData?.map((eventDetails) => (
            <TournamentDetailsCard
              key={eventDetails?.id}
              collapseDetails={true}
              title={eventDetails[`name_${currentLocale}`]}
              tournamentDetails={eventDetails}
              currentLocale={currentLocale}
              structure_file_url={eventDetails[`structure_file_${currentLocale}`]}
            />
          ))}
        </TournamentSlider>
      </container-wide>
    </TournamentsDiv>
  );
};

const TournamentsDiv = styled.div`
  width: 100%;
  height: auto;
  background: var(--lightblack);
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0;
  .all-tournaments-btn {
    order: 1;
  }

  @media (min-width: 768px) {
    container-wide {
      display: block;
      padding-bottom: 2rem;
    }
    .all-tournaments-btn {
      float: right;
    }
  }
`;

const TournamentSlider = styled(Slider)`
  .slick-prev, .slick-next {
    bottom: -12px;
    @media (min-width: 768px) {
      bottom: -40px;
    }
  }
  .slick-dots {
    bottom: 8px;
    @media (min-width: 768px) {
      bottom: 0;
    }
    li {
      width: 20px;
      height: 20px;
      button {
        width: 20px;
        height: 20px;
      }
      &:not(.slick-active) button:before {
        font-size: 10px;
      }
    }
  }
  .slick-list {
    margin: 0 -10px;
    padding: 0 10px;
    overflow-y: visible;
    transition: all 0.4s;
  }
  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-slide {
    padding: 0 10px;
    height: auto !important;
    align-items: stretch;
  }
  margin-top: 1rem;
  padding-bottom: 50px;

  .card {
    padding: 0;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .collapse {
      overflow: hidden;
    }
  }
`;

export default Tournaments;
