import React from "react";
import styled from "styled-components";
import BlogFirst from "../components/Blog/BlogFirst";
import BlogSecond from "../components/Blog/BlogSecond";

const Blog = () => {
  return (
    <BlogDiv>
      <BlogFirst />
      <BlogSecond />
    </BlogDiv>
  );
};
const BlogDiv = styled.div`
  width: 100%;
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    background: var(--black);
  }
`;
export default Blog;
