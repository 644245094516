import React from "react";
import styled from "styled-components";
import fadeIn from "../../GlobalStyle";
import BlogBackButton from "./BlogBackButton.jsx";

const BlogContent = ({ children }) => {
  return (
    <>
      <BlogBackButton />
      <ContentDiv>{children}</ContentDiv>
    </>
  );
};
const ContentDiv = styled.div`
  background: var(--white);
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 40px auto;
  display: flex;
  flex-flow: column wrap;
  a {
    color: var(--red);
    font-size: 14px;
    &:hover {
      border-bottom: 1px solid var(--red);
    }
  }
  img {
    display: block;
    animation: ${fadeIn} 0.3s linear;
    object-fit: contain;
    object-position: center;
    height: auto;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
  }
`;
export default BlogContent;
