import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Hero from "src/components/Hero/Hero";

import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import LiveGames from "src/components/Poker/LiveGames";
import CashGameType from "src/components/Poker/CashGameType";

import { useTranslation } from "react-i18next";

const CashGames = () => {
  const { t } = useTranslation();
  return (
    <CashGamesDiv>
      <Hero
        bgColor=""
        bgImage="games/header_bg.jpg"
        description={t("cash-games.hero-text")}
        descriptionBgColor="#00000080"
        image=""
        imageAlt=""
        mainHeroText={t("cash-games.hero-title_h1")}
        title=""
      />
      <LiveGames />
      <container-wide>
        <SubTitleSection subTitle={t("cash-games.types-title")} />
        <GameTypesContainer classname="mb-4">
          <CashGameType
            gameName={t("cash-games.types-content-1-title")}
            desc={t("cash-games.types-content-1-text")}
            tableTypes={t("cash-games.types-content-1-list")}
            imgSrc="games/nlhe.jpg"
            imgAlt="NLHE"
          />
          <CashGameType
            gameName={t("cash-games.types-content-2-title")}
            desc={t("cash-games.types-content-2-text")}
            tableTypes={t("cash-games.types-content-2-list")}
            imgSrc="poker/plo_2.jpg"
            imgAlt="PLO"
            />
          <CashGameType
            gameName={t("cash-games.types-content-3-title")}
            desc={t("cash-games.types-content-3-text")}
            tableTypes={t("cash-games.types-content-3-list")}
            imgSrc="poker/high-stakes.jpg"
            imgAlt="High Stakes"
          />
        </GameTypesContainer>
      </container-wide>
    </CashGamesDiv>
  );
};

const CashGamesDiv = styled.div`
  position: relative;
  z-index: 0;
  height: auto;
  width: 100%;
  position: relative;
  background: var(--black);
  color: white;
`;

const GameTypesContainer = styled.div`
`;

export default CashGames;
