// components/Auth/CookieConsentBanner.jsx
import React, { useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import styled from "styled-components/macro";
import Button from "src/components/Button/Button";
import MarkdownWithRouter from '../../components/MarkdownWithRouter';
import { t } from 'i18next';

// Shared cookie settings
const cookieSettings = {
  expires: 365,
  sameSite: "Strict",
  secure: true,
};

const cookieName = "playground";

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleCookieAccept = () => {
    // console.log('accept clicked');
    Cookies.set(cookieName, "true", cookieSettings);
    setShowBanner(false);
  };

  const handleCookieDecline = () => {
    // console.log('decline clicked');
    Cookies.set(cookieName, "false", cookieSettings);
    setShowBanner(false);
  };


  if (!showBanner) {
    return null;
  }

  return (
    <BannerWrapper>
      <CookieConsent
        disableStyles
        buttonClasses="d-none"
        cookieName={cookieName}
        // debug={true}
      >
          <p>{t("auth.cookie.title")}</p>
          <p className="small">{t("auth.cookie.desc")}</p>
          <MarkdownWithRouter className="small">
          {t("auth.cookie.privacyPolicy")}
        </MarkdownWithRouter>
        <ButtonContainer>
          <Button label={t("auth.cookie.decline")}
            className="decline-cookie"
            onClick={handleCookieDecline}
            bgColor="white"
            color="grey"
            hoverBgColor="red"
            hoverColor="white"
           />
          <Button label={t("auth.cookie.accept")} onClick={handleCookieAccept} className="accept-cookie" />
        </ButtonContainer>
      </CookieConsent>
    </BannerWrapper>
  )
};

const BannerWrapper = styled.div`
  background-color: rgb(210 210 210 / 96%);
  position: fixed;
  bottom: 0;
  z-index: 6000;
  text-align: center;
  width: 100%;
  > div {
    padding: 1rem;
  }
  a {
    color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  button {
    margin: 0;
  }
`;

export default CookieConsentBanner;
