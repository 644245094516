import React from "react";
import styled from "styled-components";
import PlaygroundCaresFirst from "../components/PlaygroundCares/PlaygroundCaresFirst";
import PlaygroundCaresSecond from "../components/PlaygroundCares/PlaygroundCaresSecond";

const PlaygroundCares = () => {
  return (
    <PlaygroundCaresDiv>
      <PlaygroundCaresFirst />
      <PlaygroundCaresSecond />
      
    </PlaygroundCaresDiv>
  );
};
const PlaygroundCaresDiv = styled.div`
  width: 100%;
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    background: var(--black);
  }
`;
export default PlaygroundCares;
