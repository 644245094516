import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const MarriottHotel = ({headerAs='h2'}) => {
  const { t } = useTranslation();

  return (
    <>
      <SubTitleSection as={headerAs} subTitle={t("visit-us.stay-content-3-text-1")} />
      <p>
        {t("visit-us.stay-content-3-text-2")}
      </p>
      <ResponsiveImage
        src="hotels/marriott_hotel.jpg"
        alt="marriott_hotel"
      />
      <ul>
        <li>{t("visit-us.stay-content-3-li-1")}</li>
        <li>
          {t("visit-us.stay-content-3-li-2")}{" "}
          <a href="tel:+15146312424">1-514-631-2424</a>
        </li>
        <li>
          {t("visit-us.stay-content-3-li-3")}{" "}
          <a href="https://www.marriott.com" target="_blanc">
            https://www.marriott.com
          </a>
        </li>
        <li>{t("visit-us.stay-content-3-li-4")}</li>
        <li>
          {t("visit-us.stay-content-3-li-5")} <strong>5PZ</strong>
        </li>
      </ul>
    </>
  );
};

export default MarriottHotel;