import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import SectionalTabs from "src/components/SectionalTabs/SectionalTabs";
import TabContent from "src/components/EatAndPlay/TabContent";

import { Link } from "react-router-dom";

const EatAndPlay = () => {

  const { i18n, t } = useTranslation();
  const location = useLocation();

  const routeCategory = "eatAndPlay"; // Define the routeCategory
  const categories = require(`src/locales/${i18n.language}/pseudoRoutes.json`);
  const categoryArr = useMemo(() => Object.values(categories.pseudoRoutes[routeCategory] || {}), [i18n.language]);

  // Manage selected category and active path
  const [selectedCategory, setSelectedCategory] = useState({
    title: categoryArr[0]?.title || "",
    path: categoryArr[0]?.path || ""
  });


  useEffect(() => {
    const currentTab = categoryArr.find((el) => el.path === location.pathname);

    // If the user lands on `/eatAndPlay`, show the first tab content and mark it as active without navigating
    if (location.pathname === t("routes.eatAndPlay") && categoryArr[0] && selectedCategory.path !== categoryArr[0].path) {
      setSelectedCategory({
        title: categoryArr[0].title,
        path: categoryArr[0].path
      });
    }

    // If a valid tab is in the URL, set it as selectedCategory
    if (currentTab && currentTab.path !== selectedCategory.path) {
      setSelectedCategory({
        title: currentTab.title,
        path: currentTab.path
      });
    }
  }, [location.pathname, categoryArr, selectedCategory.path]);


  return (
    <SnackAndSpinDiv>
      <Hero
        bgColor=""
        bgImage={"poker/eat-and-play-hero-bg-2.jpg"}
        buttonLink=""
        buttonText=""
        description={t("eatAndPlay.header.description")}
        descriptionBgColor=""
        image=""
        imageAlt=""
        mainHeroText={t("eatAndPlay.header.title")}
        title=""
      />
      <container-div>
        <p className="white text-center mt-4">
          {t("eatAndPlay.banner.part-1")}&nbsp;
          <Link to={t("routes.diningLounges")} >
            {t("eatAndPlay.banner.part-2")}
          </Link>
          &nbsp;{t("eatAndPlay.banner.part-3")}
        </p>
      </container-div>

      <SecondPart>
        <container-wide>
          <SectionalTabs
            className="mt-4 mb-4"
            routeData={{
              route: t(`routes.${routeCategory}`),
              routeCategory,
              sectionTitle: categoryArr[0]?.title || "",
              selectCategory: location.pathname === t("routes.egdGameList") ? categoryArr[0]?.title : selectedCategory.title, // Highlight first tab when on the base route
            }}
            isDynamic={true}
            selectedCategory={selectedCategory.title} // Active category
            bgColor="var(--mutedred)"
            textColor="var(--white)"
            activeBgColor="var(--red)"
            activeTextColor="var(--white)"
          />
        </container-wide>

        {selectedCategory.title === t("pseudoRoutes.eatAndPlay.cashBreakfast.title") &&
          <TabContent categoryKey="cashBreakfast" t={t} />
        }
        {selectedCategory.title === t("pseudoRoutes.eatAndPlay.tournamentBreakfast.title") &&
          <TabContent categoryKey="tournamentBreakfast" t={t} />
        }
        {selectedCategory.title === t("pseudoRoutes.eatAndPlay.cashMenu.title") &&
          <TabContent categoryKey="cashMenu" t={t} />
        }
        {selectedCategory.title === t("pseudoRoutes.eatAndPlay.tournamentMenu.title") &&
          <TabContent categoryKey="tournamentMenu" t={t} />
        }

      </SecondPart>
    </SnackAndSpinDiv>
  );
};

const SnackAndSpinDiv = styled.div`
  width: 100%;
  background: var(--black);
`;

const SecondPart = styled.div`
  .tabs-row {
    button {
      margin: 0;
    }
  }
  height: auto;
  width: 100%;
  background: var(--black);
  color: var(--white);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  letter-spacing: 1px;
  overflow-x: hidden;

`;

export default EatAndPlay;
