import { t } from "i18next";

const { formatAmount } = require("./formatters");

/**
 * Helper function to display either guarantee_seats, price, or nothing.
 *
 * @param {Object} event - The event object containing guarantee_seats and price.
 * @param {Function} formatAmount - The function to format the amount (e.g., price).
 * @param {string} locale - The current locale to use for formatting.
 * @returns {string} - Formatted value of guarantee_seats, price, or an empty string.
 *
 * @example
 * // Guarantee seats is provided and greater than 0
 * const event1 = { guarantee_seats: 10, guarantee_amount: 5000 };
 * displayValue(event1, 'en');
 * // Returns: "10 seats"
 *
 * @example
 * // Guarantee amount is provided, but no guarantee seats
 * const event2 = { guarantee_amount: 10000 };
 * displayValue(event2, 'en');
 * // Returns: "$10,000"
 *
 * @example
 * // Neither guarantee seats nor guarantee amount is provided
 * const event3 = {};
 * displayValue(event3, 'en');
 * // Returns: "" (empty string)
 *
 * @example
 * // Guarantee seats is 0 and guarantee amount is provided
 * const event4 = { guarantee_seats: 0, guarantee_amount: 2000 };
 * displayValue(event4, 'en');
 * // Returns: "$2,000"
 */
export const displayValue = (event, locale) => {
  if (event?.guarantee_seats && event.guarantee_seats !== 0) {
    return event.guarantee_seats + ` ${t("poker.seats")}`; // Display guarantee_seats if it's not 0 or null
  } else if (event?.guarantee_amount) {
    return formatAmount(event.guarantee_amount, locale); // Display formatted price if available
  } else {
    return ""; // Display nothing if both are 0, null, or undefined
  }
};
