import styled from "styled-components/macro";
import { t } from "i18next";
import Hero from "../../Hero/Hero";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const TournamentHero = ({
  image,
  bgImage,
  imageAlt,
  descriptionBgColor,
  description,
  title,
  logo,
}) => {
  return (
    <>
      <Div logo={logo}>
        <Hero
          image={image}
          imageAlt={imageAlt}
          bgImage={bgImage}
          title={title}
          description={description}
          descriptionBgColor={descriptionBgColor}
          fluidImage={true}
        />
        {logo && (
          <ResponsiveImage className="logo" src={logo} alt={title + "-logo"} />
        )}
      </Div>
    </>
  );
};

const Div = styled.div`
  position: relative;
  .hero_container {
		/* padding-top: 100px; */
    max-height: 600px;
    background-position: center center;
    @media (min-width: 992px) {
      ::before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 140px;
        backdrop-filter: contrast(50%) blur(3px);
      }
    }
  }
  .hero_image {
		position: relative;
    object-fit: cover;
    min-height: 200px;
		max-height: 600px;
  }
  .hero_text_container {
    backdrop-filter: contrast(50%) blur(3px);
    background-color: #e8ebef7a !important;
    color: #000;
    p {
      margin-bottom: 0;
    }
  }
  .logo {
    position: absolute;
    z-index: 5;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    max-width: 400px;
    width: 100%;
    height: auto;
  }
  @media (max-width: 991px) {
    .hero_container {
      height: auto;
    }
    .logo {
      padding: 0 20px;
      max-width: 220px;
			bottom: 20px;
    }
  }
`;

export default TournamentHero;
