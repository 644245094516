import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const OptInAndWin = () => {
  const { i18n } = useTranslation();
  return (
    <PromoDetailDiv
      bgImage={useResponsiveImageUrl("promotions/opt-in-background.jpg")}
    >
      {i18n.language === "en" ? (
        <div>
          <div>
            <h1>OPT-IN AND WIN!</h1>
          </div>
          <div>
            <div>
              <h2>
                Provide us with your email address <br /> by visiting Player
                Services and get $10 in free play!
              </h2>
              <p>
                Plus when you opt-in for the Playground Newsletter you receive:{" "}
                <br />
                <strong>
                  Exclusive Offers - Unique Promotions - Special Events - News &
                  more
                </strong>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <h1>INSCRIVEZ-VOUS POUR GAGNER!</h1>
          </div>
          <div>
            <div>
              <h2>
                Fournissez-nous votre adresse courriel en visitant <br /> bles
                Services aux Joueurs et obtenez 10$ en crédits-jeu!
              </h2>
              <p>
                De plus, en vous inscrivant à l’infolettre Playground, vous
                recevrez: <br />
                <strong>
                  Offres exclusives - Promotions uniques - Événements spéciaux -
                  Actualités et plus encore.
                </strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {i18n.language === "en" ? (
        <div>
          <h2>Terms and Conditions</h2>
          <div>
            <ul>
              <li>
                This promotion is limited to new accounts and accounts with an
                invalid or no email address on file.
              </li>
              <li>
                Once a valid email address is entered into your account an email
                will be sent with your $10 free play voucher.
              </li>
              <li>
                To claim your $10 free-play voucher, you must show a copy,
                either printed or directly on your phone, of the email to the
                cashier.
              </li>
              <li>
                Limit one (1) “Opt-in and win” $10 free play voucher per member.
              </li>
              <li>
                $10 free play vouchers are valid on all gaming machines that
                accept free play.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <h2>Conditions Générales</h2>
          <div>
            <ul>
              <li>
                Cette promotion est limitée aux nouveaux comptes et aux comptes
                dont l'adresse courriel est invalide ou ne figure pas dans le
                dossier.
              </li>
              <li>
                Une fois qu'une adresse courriel valide est entrée dans votre
                compte, un courriel sera envoyé avec votre bon de 10$ en
                crédits-jeu.
              </li>
              <li>
                Pour réclamer votre bon de 10$ en crédits-jeu, vous devez
                montrer une copie, imprimée ou directement sur votre téléphone,
                du courriel à la caissière.
              </li>
              <li>
                Limite d'un (1) bon de 10$ en crédits-jeu par membre pour la
                promotion “Inscrivez-vous et gagnez!”
              </li>
              <li>
                Les bons de 10$ en crédits-jeu sont valables sur tous les tables
                de jeux qui acceptent les crédits-jeu.
              </li>
            </ul>
          </div>
        </div>
      )}
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #e8ebef;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 50% 100% fixed no-repeat;
    z-index: 0;
    height: fit-content;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > div:first-child {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      text-align: center;
      & > h1 {
        margin: 0 0 10px;
        font-size: 130px;
        line-height: 140px;
        padding: 100px 20px;
        font-weight: 700;
        color: #fff;
        text-shadow: 1px 3px 1px #000;
      }
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 40px 0px;
      float: left;
      & > div {
        max-width: 800px;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        & > h2:first-child {
          font-size: 32px;
          line-height: 40px;
          color: #fff;
          font-weight: 700;
          margin: 0px;
        }
        & > p:nth-child(2) {
          background-color: #141f3d;
          padding: 20px;
          font-size: 18px;
          line-height: 30px;
          margin: 0 0 10px;
          margin-top: 20px;
          color: #f1f1f1;
        }
      }
    }
  }
  & > div:nth-child(2) {
    max-width: 1440px;
    width: 100%;
    height: fit-content;
    color: #555;
    margin: 0 auto 40px auto;
    & > h2:first-child {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin: 40px auto 30px auto;
    }
    & > div:last-child {
      width: 100%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      text-align: left;
      & > ul {
        background: #fff;
        list-style-type: disc;
        padding: 30px;
        padding-left: 80px;
        & > li {
          padding: 3px;
          font-size: 14px;
          color: #555;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    & > div:first-child {
      background: url(${(props) => props.bgImage}) 70% 100% fixed no-repeat;
      & > div:first-child {
        & > h1 {
          font-size: 80px;
          line-height: 90px;
          padding: 20px;
        }
      }
      & > div:nth-child(2) {
        padding-left: 15px;
        padding-right: 15px;
        & > div {
          & > h2:first-child {
            font-size: 26px;
            line-height: 38px;
            padding: 0 15px;
          }
          & > p:nth-child(2) {
            background-color: #141f3d;
            padding: 20px;
            font-size: 18px;
          }
        }
      }
    }
    & > div:nth-child(2) {
    }
  }
`;
export default OptInAndWin;
