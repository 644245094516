import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";


import { useNavigate } from "react-router-dom";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const snack = [
  {
    name: "Pringles Original",
    price: "2",
    category: "Salty Snacks",
    name_fr: "Pringles Original",
  },
  {
    name: "Pringles BBQ",
    price: "2",
    category: "Salty Snacks",
    name_fr: "Pringles BBQ",
  },
  {
    name: "Plantain chips",
    price: "2",
    category: "Salty Snacks",
    name_fr: "Copeaux de plantain",
  },
  {
    name: "Pretzels",
    price: "2",
    category: "Salty Snacks",
    name_fr: "Breztels",
  },
  {
    name: "Mini Ritz cheese bites",
    price: "2",
    category: "Salty Snacks",
    name_fr: "Mini Ritz bouchées au fromage",
  },
  {
    name: "Aero chocolate bar",
    price: "2",
    category: "Chocolate / Granola",
    name_fr: "Barre de chocolat Aero",
  },
  {
    name: "Kit kat chocolate bar",
    price: "2",
    category: "Chocolate / Granola",
    name_fr: "Barre de chocolat Kit Kat",
  },
  {
    name: "Granola bar (assorted)",
    price: "2",
    category: "Chocolate / Granola",
    name_fr: "Barre granola (assorties)",
  },
  {
    name: "M&M chocolate candy",
    price: "2",
    category: "Chocolate / Granola",
    name_fr: "Friandises au chocolat M&M",
  },
  {
    name: "Glosette almonds",
    price: "2",
    category: "Chocolate / Granola",
    name_fr: "Glosette aux amandes",
  },
  {
    name: "Glosette raisins",
    price: "2",
    category: "Chocolate / Granola",
    name_fr: "Glosette aux raisins",
  },
  {
    name: "Gummy bears",
    price: "2",
    category: "Candies & Gum",
    name_fr: "Oursons",
  },
  {
    name: "Sour gummy bears",
    price: "2",
    category: "Candies & Gum",
    name_fr: "Oursons sûrs",
  },
  {
    name: "Red raspberries",
    price: "2",
    category: "Candies & Gum",
    name_fr: "Framboises rouges",
  },
  {
    name: "Menthos (assorted)",
    price: "2",
    category: "Candies & Gum",
    name_fr: "Menthos (assortis)",
  },
  {
    name: "Excel gum (assorted)",
    price: "2",
    category: "Candies & Gum",
    name_fr: "Gomme Excel (assorties)",
  },
];
const drinks = [
  {
    name: "Molson Export",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Molson Canadian",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Heineken",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "HEINEKEN 0.0",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Coors Light",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Miller Genuine Draft",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Belgian Moon",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Rickard's Red",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Sol",
    description: "",
    price: "",
    category: "Beers",
  },
  {
    name: "Red Bull Yellow",
    description: "Bacardi Superior rum, pineapple juice, Red Bull Tropical.",
    description_fr: "Rhum Bacardi Superior, jus d’ananas, Red Bull Tropical.",
    price: "",
    category: "Cocktails",
  },
  {
    name: "Red Bull Green",
    description: "Bacardi Superior rum, orange liqueur, Red Bull Kiwi.",
    description_fr: "Rhum Bacardi Superior, liqueur d’orange, Red Bull Kiwi.",
    price: "",
    category: "Cocktails",
  },
  {
    name: "Red or White Sangria",
    description: "Manager's Recipe.",
    description_fr: "Recette du Gérant.",
    price: "",
    category: "Cocktails",
  },
  {
    name: "Amaretto Sour",
    description: "Disaronno amaretto, lime juice, sugar.",
    description_fr: "Amaretto Disaronno, jus de lime, sucre.",
    price: "",
    category: "Cocktails",
  },
  {
    name: "Bloody Mary",
    description: "Smirnoff vodka, tomato juice, Worcestershire, Tabasco, salt.",
    description_fr:
      "Vodka Smirnoff, jus de tomate, Worcestershire, Tabasco, sel.",
    price: "",
    category: "Cocktails",
  },
  {
    name: "Bloody Caesar",
    description:
      "Smirnoff vodka, Clamato, Worcestershire, Tabasco, celery salt.",
    description_fr:
      "Vodka Smirnoff, Clamato, Worcestershire, Tabasco, sel de céleri.",
    price: "",
    category: "Cocktails",
  },
  {
    name: "Soft Drinks",
    name_fr: "Boissons Gazeuses.",
    description:
      "Coca-Cola, Diet Coke, Sprite Zero, Nestea, ginger ale, root beer.",
    description_fr:
      "Coca-Cola, Coke Diète, Sprite Zero, Nestea, soda au gingembre, racinette.",
    price: "",
    category: "Beverages",
  },
  {
    name: "Juice, Etc.",
    name_fr: "Jus, Etc.",
    description:
      "Orange, apple, cranberry, grapefruit, pineapple, milk, chocolate milk.",
    description_fr:
      "Orange, pomme, canneberge, pamplemousse, ananas, lait, lait au chocolat.",
    price: "",
    category: "Beverages",
  },
  {
    name: "Coffees",
    name_fr: "Cafés",
    description:
      "Filtered, latté, espresso, cappuccino, iced cappuccino, teas, hot chocolate.",
    description_fr:
      "Filtre, latté, espresso, cappuccino, cappuccino glacé, thés, chocolat chaud.",
    price: "",
    category: "Beverages",
  },
  {
    name: "House Red",
    name_fr: "Vin Rouge Maison",
    description: "Chianti.",
    description_fr: "Chianti.",
    price: "",
    category: "Red Wines",
  },
  {
    name: "SMOKY BAY RESERVE, CABERNET SAUVIGNON",
    name_fr: "SMOKY BAY RESERVE, CABERNET SAUVIGNON",
    description: "",
    price: "6",
    category: "Red Wines",
  },
  {
    name: "CARPINETO ORIGINALE, SANGIOVESE",
    name_fr: "CARPINETO ORIGINALE, SANGIOVESE",
    description: "",
    price: "8",
    category: "Red Wines",
  },
  {
    name: "LE ORME, BARBERA",
    name_fr: "LE ORME, BARBERA",
    description: "",
    price: "8",
    category: "Red Wines",
  },
  {
    name: "SAINT-ANTOINE, MERLOT",
    name_fr: "SAINT-ANTOINE, MERLOT",
    description: "",
    price: "9",
    category: "Red Wines",
  },
  {
    name: "House White",
    name_fr: "Vin Blanc Maison",
    description: "Sauvignon blanc.",
    description_fr: "Sauvignon Blanc.",
    price: "",
    category: "White Wines",
  },
  {
    name: "SMOKY BAY RESERVE, PINOT GRIGIO",
    name_fr: "SMOKY BAY RESERVE, PINOT GRIGIO",
    description: "",
    price: "6",
    category: "White Wines",
  },
  {
    name: "FORTANT, CHARDONNAY",
    name_fr: "FORTANT, CHARDONNAY",
    description: "",
    price: "8",
    category: "White Wines",
  },
  {
    name: "UMBERTO CESARI MOMA,TREBBIANO",
    name_fr: "UMBERTO CESARI MOMA,TREBBIANO",
    description: "",
    price: "8",
    category: "White Wines",
  },
  {
    name: "Tia Maria Coffee",
    name_fr: "Café Tia Maria",
    description: "",
    price: "",
    category: "Specialty Coffees",
  },
  {
    name: "Baileys Coffee",
    name_fr: "Café Baileys",
    description: "",
    price: "",
    category: "Specialty Coffees",
  },
  {
    name: "Espresso Corretto",
    name_fr: "Espresso Corretto",
    description: "Espresso, brandy.",
    price: "",
    category: "Specialty Coffees",
  },
  {
    name: "Brazilian",
    name_fr: "Brézilien",
    description: "Grand Marnier, Tia Maria, Chemineaud.",
    price: "8",
    category: "Specialty Coffees",
  },
  {
    name: "Spanish",
    name_fr: "Espagnol",
    description: "Tia Maria, Chemineaud.",
    price: "7",
    category: "Specialty Coffees",
  },
  {
    name: "Irish",
    name_fr: "Irlandais",
    description: "Jameson, Baileys.",
    price: "7",
    category: "Specialty Coffees",
  },
  {
    name: "Original",
    name_fr: "Original",
    description: "",
    price: "",
    category: "Red Bulls",
  },
  {
    name: "Sugar Free",
    name_fr: "Sans sucre",
    description: "",
    price: "",
    category: "Red Bulls",
  },
  {
    name: "Flavoured",
    name_fr: "Aromatisé",
    description: "",
    price: "",
    category: "Red Bulls",
  },
  {
    name: "Strawberry Daiquiri",
    name_fr: "Daiquiri aux fraises",
    description: "Rum.",
    description_fr: "Rhum.",
    price: "",
    category: "Frozen Cocktails",
  },
  {
    name: "Piña Colada",
    name_fr: "Piña Colada",
    description: "Rum.",
    description_fr: "Rhum.",
    price: "",
    category: "Frozen Cocktails",
  },
  {
    name: "Margarita",
    name_fr: "Margarita",
    description: "Tequila.",
    description_fr: "Tequila.",
    price: "",
    category: "Frozen Cocktails",
  },
  {
    name: "Vodka",
    description: [
      { name: "Smirnoff, Ketel One", price: "8" },
      { name: "Grey Goose", price: "8" },
      { name: "Crystal Head ", price: "12" },
    ],
    price: "",
    category: "Alcohol",
  },
  {
    name: "Rum",
    description: [
      {
        name: "Bacardi Superior, Captain Morgan Dark, Bacardi Oakheart Spiced Rum",
        price: "8",
      },
      { name: "Appleton Estate Reserve", price: "8" },
    ],
    price: "",
    category: "Alcohol",
  },
  {
    name: "Gin",
    description: [
      { name: "Beefeater, Bombay Sapphire", price: "8" },
      { name: "Tanqueray", price: "8" },
      { name: "Hendrick's Gin", price: "10" },
    ],
    price: "",
    category: "Alcohol",
  },
  {
    name: "Tequila",
    description: [
      { name: "Sauza Silver, Sauza Gold, Patrón Silver", price: "12" },
    ],
    price: "",
    category: "Alcohol",
  },
  {
    name: "Scotch / Whiskey",
    description: [
      {
        name: "Dewar's, Jack Daniel's, Johnnie Walker Red Label, Johnnie Walker Black Label",
        price: "10",
      },
      { name: "Chivas Regal 12 years", price: "10" },
      { name: "Glenfiddich 12 years", price: "11" },
      { name: "The Glenlivet 12 years", price: "12" },
    ],
    price: "",
    category: "Alcohol",
  },
  {
    name: "Brandy / Cognac",
    description: [{ name: "Chemineaud, Hennessy V.S.", price: "12" }],
    price: "",
    category: "Alcohol",
  },
  {
    name: "Digestif",
    description: [
      { name: "Disaronno, Baileys, Tia Maria, Kahlúa, Frangelico", price: "7" },
      { name: "Taylor Fladgate", price: "8" },
      { name: "Grand Marnier", price: "10" },
    ],
    price: "",
    category: "Alcohol",
  },
];

const SnackAndSpin = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("snack");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Get the fragment from the pathname
    const fragmentFromPath = window.location.hash;

    // Update the state with the fragment
    fragmentFromPath.slice(1) && setIsActive(fragmentFromPath.slice(1)); // Remove the '#' symbol

    // Optional: Add event listener for hash changes
    const handleHashChange = () => {
      setIsActive(window.location.hash.slice(1));
    };

    window.addEventListener("hashchange", handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  const getButtonClassName = (nav) => (isActive === nav ? "active" : undefined);
  return (
    <SnackAndSpinDiv>
      <FirstPart bgImage={useResponsiveImageUrl("rail/header_bg_egd.jpg")}>
        <div>
          <ResponsiveImage src="rail/header_bg_egd.jpg" alt="header_bg_egd" />
          <ResponsiveImage src="svg/rail_logo.svg" alt="rail_logo" />
          <h1>{t("snack-spin.header-title")}</h1>
          <p>{t("pg-cares.header-text")}</p>
        </div>
        <div>
          {i18n.language === "en" ? (
            <p>
              Visit our <a href={t("routes.theRail")}>Dining</a> section for
              more info on The Rail Coal Fire Bistro.
            </p>
          ) : (
            <p>
              Pour en savoir plus sur The Rail Coal Fire Bistro, visitez notre
              section <a href={t("routes.theRail")}>Restauration</a>.
            </p>
          )}
        </div>
      </FirstPart>
      <SecondPart>
        <div>
          <button
            className={getButtonClassName("snack")}
            onClick={() => {
              navigate(`${t("routes.snackSpin")}#snack`);
              setIsActive("snack");
            }}
          >
            {t("snack-spin.nav-1")}
          </button>
          <button
            className={getButtonClassName("drinks")}
            onClick={() => {
              navigate(`${t("routes.snackSpin")}#drinks`);
              setIsActive("drinks");
            }}
          >
            {t("snack-spin.nav-2")}
          </button>
        </div>
        {isActive === "snack" ? (
          <SecondMainSnack id="snack">
            <div>
              <div>
                <ResponsiveImage src="rail/pringles.jpg" alt="pringles" />
                <h3>
                  {i18n.language === "en"
                    ? "Salty Snack"
                    : "Grignotines Salées"}
                </h3>
              </div>
              {snack?.map(
                (el, ind) =>
                  el?.category === "Salty Snacks" && (
                    <div key={Math.random() * 140000 + ind}>
                      <h4>{i18n.language === "en" ? el?.name : el?.name_fr}</h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/chocolate.jpg" alt="chocolate" />
                <h3>
                  {i18n.language === "en"
                    ? "Chocolate / Granola"
                    : "Chocolat et Granola"}
                </h3>
              </div>
              {snack?.map(
                (el, ind) =>
                  el?.category === "Chocolate / Granola" && (
                    <div key={Math.random() * 140000 + ind}>
                      <h4>{i18n.language === "en" ? el?.name : el?.name_fr}</h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/candies.jpg" alt="candies" />
                <h3>
                  {i18n.language === "en"
                    ? "Candies & Gum"
                    : "Bonbons et Gomme"}
                </h3>
              </div>
              {snack?.map(
                (el, ind) =>
                  el?.category === "Candies & Gum" && (
                    <div key={Math.random() * 140000 + ind}>
                      <h4>{i18n.language === "en" ? el?.name : el?.name_fr}</h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
          </SecondMainSnack>
        ) : null}
        {isActive === "drinks" ? (
          <SecondMainDrinks id="drinks">
            <div>
              <div>
                <ResponsiveImage src="rail/beer.jpg" alt="beer" />
                <h3>{i18n.language === "en" ? "Beers" : "Bières"}</h3>
              </div>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Beers" && (
                    <div key={el?.name}>
                      <h4>{el?.name}</h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/mix.jpg" alt="mix" />
                <h3>Cocktails</h3>
              </div>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Cocktails" && (
                    <div key={el?.name}>
                      <h4>
                        {el?.name}
                        <br />
                        {el?.description !== "" && (
                          <small>
                            {i18n.language === "en"
                              ? el?.description
                              : el?.description_fr}
                          </small>
                        )}
                      </h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/beverages.jpg" alt="beverages" />
                <h3>{i18n.language === "en" ? "Beverages" : "Boissons"}</h3>
              </div>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Beverages" && (
                    <div key={el?.name}>
                      <h4>
                        {el?.name}
                        <br />
                        {el?.description !== "" && (
                          <small>
                            {i18n.language === "en"
                              ? el?.description
                              : el?.description_fr}
                          </small>
                        )}
                      </h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/wine.jpg" alt="wine" />
                <h3>{i18n.language === "en" ? "Wines" : "Vins"}</h3>
              </div>
              <h3>{i18n.language === "en" ? "Red" : "Rouge"}</h3>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Red Wines" && (
                    <div key={el?.name}>
                      <h4>
                        {i18n.language === "en" ? el?.name : el?.name_fr} <br />
                        {el?.description !== "" && (
                          <small>
                            {i18n.language === "en"
                              ? el?.description
                              : el?.description_fr}
                          </small>
                        )}
                      </h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
              <h3>{i18n.language === "en" ? "White" : "Blanc"}</h3>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "White Wines" && (
                    <div key={el?.name}>
                      <h4>
                        {i18n.language === "en" ? el?.name : el?.name_fr} <br />
                        {el?.description !== "" && (
                          <small>
                            {i18n.language === "en"
                              ? el?.description
                              : el?.description_fr}
                          </small>
                        )}
                      </h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/coffee.jpg" alt="coffee" />
                <h3>
                  {i18n.language === "en"
                    ? "Specialty Coffees"
                    : "Cafés Spécialisés"}
                </h3>
              </div>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Specialty Coffees" && (
                    <div key={el?.name}>
                      <h4>
                        {i18n.language === "en" ? el?.name : el?.name_fr} <br />
                        <small>{el?.description}</small>
                      </h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/redbull.jpg" alt="redbull" />
                <h3>Red Bulls</h3>
              </div>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Red Bulls" && (
                    <div key={el?.name}>
                      <h4>{i18n.language === "en" ? el?.name : el?.name_fr}</h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/frozen.jpg" alt="frozen" />
                <h3>
                  {i18n.language === "en"
                    ? "Frozen Cocktails"
                    : "Cocktails Glacés"}
                </h3>
              </div>
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Frozen Cocktails" && (
                    <div key={el?.name}>
                      <h4>
                        {i18n.language === "en" ? el?.name : el?.name_fr} <br />
                        <small>
                          {i18n.language === "en"
                            ? el?.description
                            : el?.description_fr}
                        </small>
                      </h4>
                      <span>{el?.price}</span>
                    </div>
                  )
              )}
            </div>
            <div>
              <div>
                <ResponsiveImage src="rail/scotch.jpg" alt="scotch" />
                <h3>{i18n.language === "en" ? "Alcohol" : "Alcool"}</h3>
              </div>
              {i18n.language === "en" ? (
                <p>SERVED WITH RED BULL, SOFT DRINK, JUICE, OR ON ICE</p>
              ) : (
                <p>SERVI AVEC RED BULL, BOISSON GAZEUSE, JUS, OU SUR GLACE</p>
              )}
              {drinks?.map(
                (el, ind) =>
                  el?.category === "Alcohol" && (
                    <React.Fragment key={`alcohol-${ind}`}>
                      <h3>{el?.name}</h3>
                      {el?.description.map((item, itemInd) => (
                        <div key={`${el?.name}-${itemInd}`}>
                          <h4>{item?.name}</h4>
                          <span>{item?.price}</span>
                        </div>
                      ))}
                    </React.Fragment>
                  )
              )}
            </div>
          </SecondMainDrinks>
        ) : null}
      </SecondPart>
      
      
    </SnackAndSpinDiv>
  );
};
const SnackAndSpinDiv = styled.div`
  width: 100%;
  background: var(--black);
`;
const FirstPart = styled.div`
  z-index: 0;
  height: auto;
  width: 100%;
  transition: 0.5s drinks;
  padding: 100px 0 0 0;
  background: url(${(props) => props.bgImage}) 0% 80% no-repeat;
  overflow: hidden;
  background-size: cover;
  &::after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
  & > div:first-child {
    padding: 40px;
    width: 100%;
    max-width: 1440px;
    margin: 20px auto;
    color: var(--white);
    text-align: center;
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      max-width: 300px;
      object-fit: contain;
      margin: 0 auto;
    }
    & > h1 {
      font-size: 125px;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 110px;
      margin: 0px;
      margin-bottom: 10px;
    }
    & > p {
      font-weight: 400;
      font-size: 22px;
      margin: 0px;
      line-height: 33px;
    }
  }
  & > div:last-child {
    background: var(--red);
    color: var(--white);
    padding: 20px 40px;
    text-align: center;
    & > p {
      font-weight: 400;
      font-size: 22px;
      margin: 0px;
      line-height: 33px;
      & > a {
        font-weight: bold;
        color: var(--black);
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    padding: 0;
    background: none;
    & > div:first-child {
      background: var(--black);
      text-align: center;
      padding: 40px 0;
      margin: 0;
      position: relative;
      & > img:first-child {
        display: block;
        max-width: 100%;
        height: auto;
      }
      & > img:nth-child(2) {
        max-width: 200px;
      }
      & > h1 {
        font-size: 45px;
        line-height: 42px;
        padding: 0 20px;
      }
      & > p {
        font-size: 16px;
        line-height: 30px;
        padding: 0 20px;
      }
    }
    & > div:last-child {
      & > p {
        font-size: 16px;
        line-height: 30px;
        padding: 0 20px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      & > img:nth-child(2) {
        position: relative;
        top: -50px;
      }
    }
  }
`;
const SecondPart = styled.div`
  padding: 60px 0;
  height: fit-content;
  width: 100%;
  background: var(--black);
  color: var(--white);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  letter-spacing: 1px;
  overflow-x: hidden;
  & > div:first-child {
    text-align: center;
    display: flex;
    gap: 20px;
    & > button {
      text-transform: uppercase;
      background: var(--white);
      color: var(--black);
      transition: drinks 0.3s;
      &.active {
        background: var(--red);
        color: var(--white);
      }
      &:hover {
        background: var(--red);
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px;
    & > div:first-child {
      & > button {
        font-size: 14px;
        padding: 8px 25px;
      }
    }
  }
`;
const SecondMainSnack = styled.main`
  width: 100%;
  max-width: 1440px;
  height: auto;
  margin-top: 60px;
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
  gap: 20px;
  & > div {
    height: 100%;
    width: 100%;
    max-width: 32%;
    padding: 20px;
    background: var(--lightblack);
    & > div:first-child {
      display: flex;
      flex-direction: column;
      & > img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: auto;
      }
      & > h3 {
        margin: 0;
        text-align: center;
        background: var(--red);
        padding: 10px;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    & > div:nth-child(n + 2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid var(--rewards_grid);
      & > h4 {
        margin: 0;
        font-weight: normal;
      }
      & > span {
        color: var(--red);
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    flex-direction: column;
    margin-top: 20px;
    & > div {
      max-width: unset;
    }
  }
`;
const SecondMainDrinks = styled.main`
  width: 100%;
  max-width: 1440px;
  height: auto;
  max-height: 2000px;
  margin-top: 60px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
  & > div {
    height: 100%;
    width: calc((100% - 40px) / 3); /* Calculate width with gap included */
    max-width: 450px; /* Adjust the maximum width as needed */
    height: auto;
    padding: 20px;
    background: var(--lightblack);
    & > div:first-child {
      display: flex;
      flex-direction: column;
      & > img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: auto;
      }
      & > h3 {
        margin: 0;
        text-align: center;
        background: var(--red);
        padding: 10px;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    & > h3 {
      margin: 0;
      margin-top: 20px;
    }
    & > div:nth-child(n + 2) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid var(--rewards_grid);
      & > h4 {
        margin: 0;
        font-weight: normal;
        & > small {
          font-weight: 200;
        }
      }
      & > span {
        color: var(--red);
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    grid-template-columns: repeat(1, 1fr);
    margin-top: 60px;
    max-height: none;
    & > div {
      max-width: unset;
      width: 100%;
      padding: 10px;
    }
  }
`;
export default SnackAndSpin;
