import React, { useEffect } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import { useHomepage } from "src/context/homepage/homepage.provider";

import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const SweetSaturdays = () => {
  const videoUrl = "https://www.youtube.com/watch?v=mljsnc4tSMM";
  // Set the cookie with the appropriate attributes
  useEffect(() => {
    // Set the cookie with the appropriate attributes
    document.cookie = "sweetSaturdays=mljsnc4tSMM; SameSite=None; Secure";
  }, []);
  const { pathname } = useLocation();
  const { darkThemeFn } = useHomepage();
  useEffect(() => {
    darkThemeFn(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const responsiveBgImage = useResponsiveImageUrl("promotions/sweet-saturday-bg.jpg");
  return (
    <PromoDetailDiv>
      <PartOne bgImage={responsiveBgImage}>
        <ResponsiveImage
          src="promotions/SWEET-SATURDAYS-LOGO-EN.png"
          alt="SWEET-SATURDAYS-LOGO-EN"
        />
        <div>
          <div>
            <ReactPlayer
              url={videoUrl}
              width="100%"
              height="100%"
              controls={true}
              playing={false}
            />
            <div>
              <p>
                Sweet Saturdays, Playground’s latest (and possibly most
                delicious) offering, helps you indulge your sweet tooth on the
                best night of the week.{" "}
                <strong>And it is absolutely free!</strong>
              </p>
              <p>
                Running <strong>as of 10:00pm every Saturday night</strong> in
                our Promotions Zone, Sweet Saturdays puts out a spread of
                delicious options that you can sample to your heart’s content.
              </p>
              <p>
                The sweet table is the perfect spot to start or end your evening
                without sacrificing your budget.
              </p>
            </div>
          </div>
        </div>
      </PartOne>
      <PartTwo>
        <div>
          <h2>SWEET</h2>
          <div>
            <p>Mignardises</p>
            <p>Macaroons</p>
            <p>Donut wall</p>
            <p>Assorted candies</p>
            <p>Fruit platters</p>
          </div>
        </div>
        <div>
          <h2>SAVOURY</h2>
          <div>
            <p>Charcuterie platters</p>
            <p>Various cold cuts, pickles, olives, cucumbers.</p>
            <br />
            <p>Cheese platters</p>
            <p>Brie, Cheddar, Oka, and Swiss with focaccia and crackers.</p>
          </div>
        </div>
      </PartTwo>
      <Img
        src="promotions/food.jpg"
        alt="sweet-saturdays-2022"
      />
      
    </PromoDetailDiv>
  );
};

const PromoDetailDiv = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #e6e6e6;
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
const PartOne = styled.div`
  background: url(${(props) => props.bgImage})
    no-repeat fixed;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  padding: 140px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  z-index: 0;
  height: fit-content;
  overflow: hidden;
  text-align: center;
  position: relative;
  top: 0;
  & > img:first-child {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }
  & > div:nth-child(2) {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 80px 20px 60px;
    height: fit-content;
    & > div {
      margin: 0 auto;
      width: 100%;
      max-width: 1440px;
      height: fit-content;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      flex: 0.8fr 1.2fr;
      gap: 40px;
      & > div:first-child {
        height: fit-content;
        width: 100%;
        max-width: 450px;
        border: 10px solid white;
        position: relative;
        padding-top: 40px;
        padding-bottom: 280px;
        & > div {
          height: 100%;
          width: 100%;
          & > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 85%;
          }
        }
      }
      & > div:nth-child(2) {
        align-self: flex-start;
        height: fit-content;
        width: 100%;
        max-width: 550px;
        & > p {
          color: #ffe7e3;
          font-size: 16px;
          line-height: 1.6;
          font-weight: 400;
          text-align: left;
          margin: 0 0 10px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles and medium tablet styles */
    & > div:nth-child(2) {
      padding: 40px 20px;
      & > div {
        gap: 0;
        & > div:first-child {
          border: none;
          max-width: 320px;
          padding-bottom: 200px;
        }
        & > div:nth-child(2) {
          max-width: 320px;
        }
      }
    }
  }
`;
const PartTwo = styled.div`
  background: #e6e6e6;
  height: fit-content;
  width: 100%;
  padding: 80px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  max-width: 1440px;
  margin: 20px auto;
  & > div:first-child {
    border: solid 2px #891134;
    height: fit-content;
    width: 100%;
    padding: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    & > h2 {
      font-size: 120px;
      color: #891134;
      font-family: "Oswald", sans-serif;
      margin: 40px 0;
      width: 50%;
      font-weight: 700;
    }
    & > div {
      width: 50%;
      margin: 0 15px;
      & > p {
        color: #891134;
        font-size: 20px;
        font-weight: 900;
        font-family: "Oswald", sans-serif;
        padding: 5px 0px;
        background: #d7d7d7;
        margin: 0 0 10px;
      }
    }
  }
  & > div:last-child {
    border: solid 2px #891134;
    height: fit-content;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    & > h2 {
      font-size: 120px;
      color: #891134;
      font-family: "Oswald", sans-serif;
      margin: 40px 0px;
      width: 50%;
      font-weight: 700;
    }
    & > div {
      width: 50%;
      margin: 0 15px;
      & > p:first-child,
      & > p:nth-child(4) {
        color: #891134;
        font-size: 20px;
        font-weight: 900;
        font-family: "Oswald", sans-serif;
        padding: 5px 0px;
        background: #d7d7d7;
        margin: 0 0 10px;
      }
      & > p:nth-child(2),
      & > p:nth-child(5) {
        color: #891134;
        font-size: 16px;
        font-weight: 400;
        margin: 0 0 10px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles and medium tablet styles */
    & > div:first-child {
      flex-flow: row wrap;
      justify-content: center;
      & > h2 {
        font-size: 18vw;
        width: 100%;
        margin: 20px 0;
      }
      & > div {
        width: 100%;
      }
    }
    & > div:last-child {
      flex-flow: row wrap;
      justify-content: center;
      & > h2 {
        width: 100%;
        font-size: 18vw;
        margin: 20px 0;
      }
      & > div {
        width: 100%;
      }
    }
  }
`;
const Img = styled(ResponsiveImage)`
  width: 100%;
  height: auto;
  vertical-align: middle;
`;
export default SweetSaturdays;
