import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const TheHostHotel = ({headerAs='h2'}) => {
  const { t } = useTranslation();

  return (
    <>
      <SubTitleSection as={headerAs} subTitle={t("visit-us.stay-content-1-text-1")} />
      <p>
        {t("visit-us.stay-content-1-text-2")}
      </p>
      <ResponsiveImage
        src="events/wsop_2018/host_hotel.jpg"
        alt="host_hotel"
      />
      <ul>
        <li>{t("visit-us.stay-content-2-li-1")}</li>
        <li>
          {t("visit-us.stay-content-2-li-2")}{" "}
          <a href="tel:+14506324678">1-450-632-4678</a>
        </li>
        <li>
          {t("visit-us.stay-content-2-li-3")}{" "}
          <a href="https://www.hosthotel.ca" target="_blanc">
            https://www.hosthotel.ca
          </a>
        </li>
        <li>{t("visit-us.stay-content-2-li-4")}</li>
      </ul>
    </>
  );
};

export default TheHostHotel;
