import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const PartyPoker = () => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;

  return (
    <PartyPokerDiv bgImage={useResponsiveImageUrl(`events/wcc_2021/wcc-og-image-2021-${lng}.png`)}>
      <div>
        <div>
          <h1>{t("poker.partyPoker.qualifyWorldCup")}</h1>
          <span></span>
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.daily001")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.worldCupCentroll")}</p>
                    <ReactMarkdown>
                      {t("poker.partyPoker.phase1SeatsGuaranteed")}
                    </ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.daily440")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.phase1")}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.daily202")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.phase2")}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.july1MegaSatellite")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.megaSatellite")}</p>
                    <ReactMarkdown>
                      {t("poker.partyPoker.megaSatelliteSeatsGuaranteed")}
                    </ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.targetEvent")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.targetEventDescription")}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <h2>
            <ReactMarkdown>
              {t("poker.partyPoker.additionalSatellites")}
            </ReactMarkdown>
          </h2>
        </div>
      </div>
      <div></div>
      <div id="malibu-big-game">
        <div>
          <h1>{t("poker.partyPoker.qualifyMalibu")}</h1>
          <span></span>
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.malibuDaily001")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.malibuCentroll")}</p>
                    <ReactMarkdown>
                      {t("poker.partyPoker.malibuFeederSeatsGuaranteed")}
                    </ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.malibuDaily330")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.malibuFeeder")}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.malibuDaily202")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ReactMarkdown>
                      {t("poker.partyPoker.malibuPhase1")}
                    </ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.malibuDaily1009")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ReactMarkdown>
                      {t("poker.partyPoker.malibuPhase2")}
                    </ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.malibuDaily50030")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ReactMarkdown>
                      {t("poker.partyPoker.malibuPhase3")}
                    </ReactMarkdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <ResponsiveImage
            src="events/wcc_2019/chart_arrow.png"
            alt="chart_arrow"
          />
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.partyPoker.targetEvent")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>{t("poker.partyPoker.malibuTargetEvent")}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
        </div>
      </div>
    </PartyPokerDiv>
  );
};

const PartyPokerDiv = styled.div`
  width: 100%;
  text-align: center;
  color: var(--white);
  z-index: 0;
  background: url(${(props) => props.bgImage}) no-repeat fixed;
  background-position: 50% 50%;
  background-size: cover;
  & > div {
    width: 100%;
    height: fit-content;
    background: var(--black);
    padding: 20px 0;
    & > div {
      margin: 0 auto;
      width: 100%;
      max-width: 1440px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      & > h1 {
        color: var(--white);
        font-weight: 900;
        line-height: 35px;
        margin-bottom: 10px;
        font-size: 30px;
      }
      & > span:nth-child(2) {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
        max-width: 275px;
        border-bottom: 2px solid var(--red);
      }
      & > img {
        width: 60px;
        margin: 0 auto;
      }
    }
  }
  & > div:nth-child(2) {
    position: relative;
    width: 100%;
    height: 400px;
    background: inherit;
  }
  @media screen and (max-width: 991px) {
    & > div {
      padding: 0 20px;
      & > div {
        & > h1 {
          font-weight: 700;
          font-size: 25px;
        }
        & > h2 {
          font-weight: 500;
          font-size: 20px;
        }
      }
    }
  }
`;

const TableDiv = styled.div`
  margin: 20px auto;
  border: 1px solid var(--lightblack);
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        border-top: 1px solid var(--lightblack);
        background-color: rgba(255, 255, 255, 0.1);
        & > td {
          border-right: 1px solid var(--lightblack);
          border-top: none;
          text-align: center;
          color: var(--white);
          padding: 8px;
          line-height: 1.42857143;
          font-size: 16px;
          & > p {
            margin: 10px 0;
            padding: 5px;
            & > span {
              color: var(--red);
            }
          }
          & > p:first-child {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 12px;
            padding: 5px;
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 12px;
            padding: 5px;
            font-weight: 300;
            & > p:first-child {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`;

export default PartyPoker;
