import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { recover } from "../../api/accounts";
import { StyledForm } from "../../GlobalStyle";
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import Button from "src/components/Button/Button";
import AuthServiceInfo from "src/components/AuthServiceInfo";
import TopLogo from "src/components/Auth/TopLogo";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import NewMemberSection from "src/components/Auth/NewMemberSection";
import IdSection from "src/components/Auth/IdSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SignUpExistingFAQs from "./SignUpExistingFAQs";


const SignUpExistingUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    // Validate file input
    const id_image = formData.get("id_image");
    if (id_image.size > 4 * 1024 * 1024) {
      setErrorMessage(
        i18n.language === "en"
          ? "The file size should be less than 4 MB"
          : "La taille du fichier doit être inférieure à 4 Mo"
      );
      return;
    }

    const fileExtension = id_image.name.split(".").pop().toLowerCase();
    const allowedExtensions = ["jpg", "jpeg", "png"];
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage(
        i18n.language === "en"
          ? "Please select a valid image file (JPG or PNG)"
          : "Veuillez sélectionner un fichier image valide (JPG ou PNG)"
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage(null); // Clear any previous error messages

    try {
      const result = await recover(Object.fromEntries(formData.entries()));
      if (result.success) {
        navigate(t("routes.addExistingUserSuccess"));
      } else {
        setErrorMessage(
          i18n.language === "en"
            ? "Account recovery request failed"
            : "Échec de la demande de récupération du compte"
        );
      }
    } catch (error) {
      setErrorMessage(
        i18n.language === "en"
          ? "Error during account recovery request"
          : "Erreur lors de la demande de récupération du compte"
      );
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  return (
    <auth-page>
      <TopLogo />
      <Row sm={1} md={2}>
        <Col>
          <StyledForm onSubmit={handleSubmit}>
          <SubTitleSection as={'h1'} subTitle={t("auth.signUpExistingUser.recover")} className="pt-0 pb-2" />
            <FormGroup>
              <FormLabel htmlFor="first_name">
                {t("auth.form.firstName.label")}
              </FormLabel>
              <FormControl
                type="text"
                id="first_name"
                name="first_name"
                placeholder={t("auth.form.firstName.placeholder")}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("auth.form.firstName.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="last_name">
                {t("auth.form.lastName.label")}
              </FormLabel>
              <FormControl
                type="text"
                id="last_name"
                name="last_name"
                placeholder={t("auth.form.lastName.placeholder")}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("auth.form.lastName.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="email">{t("auth.form.email.label")}</FormLabel>
              <FormControl
                type="email"
                id="email"
                name="email"
                placeholder={t("auth.form.email.placeholder")}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("auth.form.email.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="message">
                {t("auth.form.message.label")}
              </FormLabel>
              <FormControl
                type="text"
                id="message"
                name="message"
                placeholder={t("auth.form.message.placeholder")}
                as="textarea"
              />
            </FormGroup>
            <IdSection />
            <Button
              type="submit"
              disabled={isLoading}
              bgColor="var(--red)"
              color="var(--white)"
              hoverColor="var(--black)"
              hoverBgColor="var(--white)"
              fluid
              label={
                isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  t("submit")
                )
              }
            />

            <NewMemberSection />
          </StyledForm>
        </Col>
        <MaxWidthCol className="d-flex flex-column align-items-center justify-content-center">
          <SignUpExistingFAQs />
          <AuthServiceInfo />
        </MaxWidthCol>
      </Row>
    </auth-page>
  );
};

const MaxWidthCol = styled(Col)`
  max-width: 500px;
`;


export default SignUpExistingUser;
