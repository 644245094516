import styled from "styled-components";
// import HomePageHero from "../components/HomePage/HomePageHero";
import VideoComponent from "../components/HomePage/VideoComponent";
import JoinTheClub from "../components/HomePage/JoinTheClub";
// import Merch from "../components/Shop/Merch";
import TitleSection from "../components/TitleSection/TitleSection";
import MoreThanShows from "../components/HomePage/MoreThanShows";
import BbjHero from "../components/Poker/BbjHero";
import { useHomepage } from "../context/homepage/homepage.provider";
import { t } from "i18next";

const HomePage = () => {
  const { bbjData } = useHomepage();
  return (
    <HomePageDiv>
      {/* <HomePageHero /> */}
      <VideoComponent
          desktop="/videos/PG-NYE-Mashup-Video-Full-Size.mp4"
          mobile="/videos/NYE-Mashup-Mobile.mp4"
          soundControl={false}
        />
      {/* <VideoComponent
        desktop="/videos/PG-CommercialHD-Poker-focused.mp4"
        mobile="/videos/PG-CommercialHD-Poker-focused-mobile4x3-30s.mp4"
      /> */}
      <TitleSection
        title={t("homepage.welcome-to-pg")}
        text={t("homepage.welcome-to-pg-text")}
      />
      <MoreThanShows />
      <BbjHero bbjData={bbjData} compact />
      <JoinTheClub />
      {/* <Merch /> */}
    </HomePageDiv>
  );
};

const HomePageDiv = styled.div`
  width: 100%;
`;

export default HomePage;
