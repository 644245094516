import React            from "react";
import styled           from "styled-components/macro";
import Hero             from "src/components/Hero/Hero";
import DiningPromo      from "src/components/DiningPromo/DiningPromo";
import useScrollToHash  from "src/utils/useScrollToHash";
import { t }            from 'i18next';
import ReactMarkdown    from 'react-markdown';


const ClassicAmericanBreakfast = () => {

  const ids = [
    t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.anchor')
    // Add other section IDs
  ];
  // Scroll to the first promotion on page load
  const refs = useScrollToHash(ids, 100);

  return (
    <>
      <Wrapper>
        <Hero
          image="promotions/dining_promotion_desktop_header_july.jpg"
          title={t('diningPromos.classicAmericanBreakfast.hero.title')}
          descriptionBgColor="#e8ebef"
          fluidImage
          />
          <container-div>
            <DiningPromo containerClassName="shadow"
              // ref={refs[t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.anchor')]}
              image={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.image')}
              imageAlt={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.imageAlt')}
              imagePosition='right'
              time={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.time')}
              title={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.title')}
              description={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.description')}
              />
            { /* Example of another block with image on the right */}
            { /*
            <DiningPromo containerClassName="shadow"
              ref={refs.breakfast_5_2}
              image={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.image')}
              imageAlt={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.imageAlt')}
              imagePosition='right'
              imageSide='right'
              time={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.time')}
              title={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.title')}
              description={t('diningPromos.classicAmericanBreakfast.promotions.breakfast_5.description')}
              />
              */}
          <Disclaimer>
            <ReactMarkdown>
              {t('diningPromos.classicAmericanBreakfast.disclaimer', '*Conditions apply')}
            </ReactMarkdown>
          </Disclaimer>
          </container-div>
      </Wrapper>
      
    </>
  );
};

const Wrapper = styled.div`
  background: #e8ebef;
  text-align: center;
  h1 {
    color: #000;
  }
  img.hero_image {
    object-fit: cover;
    min-height: 200px;
    @media (min-width: 426px) {
      min-height: 400px;
    }
    @media (min-width: 992px) {
      min-height: 500px;
    }
  }
`;

const Disclaimer = styled.p`
  margin: 0 0 2rem;
`;

export default ClassicAmericanBreakfast;
