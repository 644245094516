import React, { useState } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const InstaWin2024 = () => {
  const [viewBtn, setViewBtn] = useState(false);
  const { i18n } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl("promotions/instawin-background.jpg");

  return (
    <PromoDetailDiv bgImage={responsiveBgImage}>
      <div>
        <ResponsiveImage
          src={`promotions/instawin_logo_${i18n.language}.png`}
          alt="Free Play Friday"
        />
        <div>
          {i18n.language === "en" ? (
            <div>
              <h1>InstaWin</h1>
              <p>Follow our Instagram page and Score</p>
              <h2>$10 in Free Play!</h2>
            </div>
          ) : (
            <div>
              <h1>InstaCrédits</h1>
              <p>De 12h00 à 20h00</p>
              <h2>1000$ en crédits-jeu sont versés toutes les heures!</h2>
            </div>
          )}
        </div>
      </div>
      {i18n.language === "en" ? (
        <div>
          <p>
            To get your $10 in Free Play, make sure you are logged-in to your
            Instagram account,
            <strong>click the “Follow our page” button below</strong> to go
            directly to our <strong>PlaygroundYUL Instagram page,</strong> then
            click on the blue FOLLOW button at the top of the page, make sure it
            turns grey and says FOLLOWING.
          </p>
          <p>
            Now you just need to <strong>head over to our cashier</strong> and
            show them, either directly on your phone or by printing the page,
            and they will give you a <strong>$10 free play voucher.</strong>
          </p>
          <p>
            And remember, by liking our <strong>PlaygroundYUL</strong> Instagram
            page you get our{" "}
            <strong>latest updates, unique promotions, exclusive offers</strong>{" "}
            and, most importantly,{" "}
            <strong>you help us get to know you better!</strong>
          </p>
          <ContentSectionLink
            href="https://www.instagram.com/playgroundyul/"
            target="_blank"
          >
            <div>
              <span>Follow our page</span>
            </div>
          </ContentSectionLink>
          <button onClick={() => setViewBtn(!viewBtn)}>
            View Terms and Conditions
            <span></span>
          </button>
          <div className={viewBtn === true ? "ul-active" : undefined}>
            <ul>
              <li>
                This promotion is limited to any Playground member with a
                Instagram account.
              </li>
              <li>
                To claim your $10 free-play voucher, you must show, either
                printed or directly on your phone, that you LIKE the
                PlaygroundYUL Instagram page.
              </li>
              <li>
                Limit one (1) “InstaWin” $10 free play voucher per member.
              </li>
              <li>
                $10 free play vouchers are valid on all gaming machines and
                table games.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <p>
            Pour obtenir vos 10$ en crédits-jeu, assurez-vous d'être connecté à
            votre compte Instagram,
            <strong>
              cliquez sur le bouton "Suivez notre page" ci-dessous
            </strong>{" "}
            pour aller directement sur notre{" "}
            <strong>page Instagram PlaygroundYUL,</strong> puis cliquez sur le
            bouton bleu "S'abonner" en haut de la page, assurez-vous qu'il
            devienne gris et qu'il indique ABONNER.
          </p>
          <p>
            Il vous suffit maintenant de{" "}
            <strong>vous rendre à notre caisse</strong> et de leur montrer la
            page, imprimée ou directement sur votre téléphone, et ils vous
            donneront <strong>un bon de 10$ en crédits-jeu.</strong>
          </p>
          <p>
            Et n'oubliez pas qu'en aimant notre page{" "}
            <strong>Instagram PlaygroundYUL,</strong> vous recevez nos{" "}
            <strong>
              dernières mises à jour, des promotions uniques, des offres
              exclusives
            </strong>{" "}
            et, surtout,{" "}
            <strong>vous nous aidez à mieux vous connaître!</strong>
          </p>
          <ContentSectionLink
            href="https://www.instagram.com/playgroundyul/"
            target="_blank"
          >
            <div>
              <span>Suivez notre page</span>
            </div>
          </ContentSectionLink>
          <button onClick={() => setViewBtn(!viewBtn)}>
            Conditions générales
            <span></span>
          </button>
          <div className={viewBtn === true ? "ul-active" : undefined}>
            <ul>
              <li>
                Cette promotion est limitée à tout membre de Playground
                possédant un compte Instagram.
              </li>
              <li>
                Pour réclamer votre bon de 10$ en crédits-jeu, vous devez
                montrer, soit imprimé, soit directement sur votre téléphone, que
                vous êtes abonné à la page Instagram de PlaygroundYUL.
              </li>
              <li>
                Limite d'un (1) bon de 10$ en crédits-jeu par membre pour la
                promotion “InstaCrédits”.
              </li>
              <li>
                Les bons de 10$ en crédits-jeu sont valables sur tous les
                machines de jeu et jeux de table électroniques.
              </li>
            </ul>
          </div>
        </div>
      )}
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  background: #e6e6e6;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 0% 100% fixed no-repeat;
    z-index: 0;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > img:first-child {
      max-width: 800px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: contain;
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      height: fit-content;
      width: 100%;
      padding: 20px;
      text-align: center;
      float: left;
      & > div {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        height: fit-content;
        & > p {
          font-size: 28px;
          line-height: 30px;
          color: #f1f1f1;
          margin: 0 0 10px;
        }
        & > h1 {
          color: var(--white);
          font-weight: 900;
        }
        & > h2 {
          font-size: 32px;
          line-height: 40px;
          color: #fff;
          font-weight: 700;
          margin: 0px;
        }
      }
    }
  }
  & > div:nth-child(2) {
    max-width: 1440px;
    height: fit-content;
    width: 100%;
    margin: 40px auto;
    padding: 40px;
    padding-bottom: 20px;
    background: #fff;
    & > p {
      line-height: 30px;
      font-size: 18px;
      text-align: left;
      color: #555;
      margin: 0 0 10px;
      padding: 0px 15px;
      & > strong {
        font-weight: 700;
      }
    }
    & > button {
      width: 100%;
      max-width: 300px;
      border: none;
      height: 40px;
      background-color: #ddd;
      outline: 0;
      color: var(--black);
      margin: 20px 0;
      border-radius: 0;
      padding: 0;
      & > span {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    & > div:last-child {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      & > ul {
        padding-left: 20px;
        margin: 40px 0 60px 50px;
        list-style-type: disc;
        & > li {
          padding: 5px;
          font-size: 14px;
          color: #555;
        }
      }
      &.ul-active {
        max-height: 1050px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    & > div:first-child {
      background: url(${(props) => props.bgImage}) 50% 50% cover no-repeat;
      max-height: 684px;
      & > div:nth-child(2) {
        padding: 40px 20px;
        & > div {
          & > h2 {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }
    }
    & > div:nth-child(2) {
      padding: 20px;
      text-align: center;
      & > button {
        margin: 20px auto 20px auto;
        margin-bottom: 0px;
      }
      & > div:last-child {
        & > ul {
          padding-left: 20px;
          margin: 20px;
          list-style-type: disc;
          & > li {
            text-align: left;
            padding: 5px;
            font-size: 14px;
            color: #555;
          }
        }
      }
    }
  }
`;
const ContentSectionLink = styled.a`
  line-height: 29px;
  height: 50px;
  text-align: center;
  display: block;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 0.5s;
  position: relative;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 20px auto;
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  &:hover {
    color: #d83692;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.5s;
    color: var(--white);
    background: #d83692;
    border-radius: 5px;
  }
  &:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.5s;
    border: 2px solid #d83692;
    transform: scale(1.2);
    border-radius: 5px;
  }
  &:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
`;
export default InstaWin2024;
