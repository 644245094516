import { useReducer, useMemo, useContext } from "react";
import { promotionsReducer } from "./promotions.reducer";
import { promotionsActionTypes as actions } from "./promotions.actions";
import { PromotionsState } from "./promotions.state";

const PROMOTIONS_STATE = {
  selectPromo: "Select Promotion",
  selectPromoToggle: false,
  isReadMore: true,
  isReadMoreValue: "",
  playgroundLive: false,
  playgroundLiveCartImg: "JAMIE STAPLES",
};

export const PromotionsStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(promotionsReducer, PROMOTIONS_STATE);
  const value = useMemo(
    () => ({
      ...state,
      selectPromoFn: (value) => {
        dispatch({ type: actions.SELECT_PROMO, payload: value });
      },
      selectPromoToggleFn2: (value) => {
        dispatch({ type: actions.SELECT_PROMO_TOGGLE, payload: value });
      },
      selectPromoToggleFn: (value) => {
        // state.selectPromoToggle === false
        //   ? dispatch({ type: actions.SELECT_PROMO_TOGGLE, payload: true })
        dispatch({ type: actions.SELECT_PROMO_TOGGLE, payload: value });
      },
      isReadMoreFn: (value) => {
        dispatch({ type: actions.READ_MORE, payload: value });
      },
      playgroundLiveFn: () => {
        dispatch({ type: actions.PLAYGROUND_LIVE });
      },
      playgroundLiveCardImgFn: (value) => {
        dispatch({ type: actions.PLAYGROUND_LIVE_CART_IMG, payload: value });
      },
    }),
    [state]
  );
  return (
    <PromotionsState.Provider value={value}>
      {children}
    </PromotionsState.Provider>
  );
};
export const usePromotions = () => useContext(PromotionsState);
