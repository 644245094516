import React from "react";
import styled from "styled-components";


import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const JackpotParties = () => {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  return (
    <PromoDetailDiv>
      <ResponsiveImage
        src={`promotions/header_jp_may_2023_${lng}.jpg`}
        alt="header_jp_may_2023_en"
      />
      <ResponsiveImage
        src={`promotions/header_jp_may_2023_mobile_${lng}.jpg`}
        alt="header_jp_may_2023_en"
      />
      <FirstContent>
        <div>
          <div>
            <h2>Tuesday May 30, 2023</h2>
            <p>
              <strong>
                Reception with complimentary food and drinks at 7:00pm. Draws
                start at 8:00pm.
              </strong>
            </p>
            <p>
              From <strong>May 5 to May 30</strong>, when you{" "}
              <strong>hit a jackpot of $1,000 to $1,300</strong> while playing
              on our gaming machines or table games you will get at least 1{" "}
              <strong>invitation to this Jackpot party!</strong>
            </p>
            <p>
              So hit the machines and spin ‘till you win and then… win again!
            </p>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Guaranteed Prizes</th>
                </tr>
                <tr>
                  <th>Amount to be won</th>
                  <th>Prize amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>7</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>$1,000</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>$5,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <ResponsiveImage
            src={`promotions/jp_may_2023_1_${lng}.jpg`}
            alt="jp_may_2023_1_en"
          />
        </div>
        <div>
          <p>
            Each winner that is drawn will present themselves to the promotion
            area , which will contain 10 envelopes, and make their selection in
            the same order in which they were drawn. Then when all 5 winners
            have selected their envelope they will all open their envelopes
            simultaneously. This will then be repeated for winners 6-10.
          </p>
          <a href={`${t("routes.jackpotPartyMay2023")}#terms`}>
            VIEW TERMS AND CONDITIONS
          </a>
        </div>
      </FirstContent>
      <SecondContent>
        <div>
          <ResponsiveImage
            src={`promotions/jp_may_2023_2_${lng}.jpg`}
            alt="jp_may_2023_2_en"
          />
          <div>
            <h2>Wednesday May 31, 2023</h2>
            <p>
              <strong>
                Reception with complimentary food and drinks at 7:00pm. Draws
                start at 8:00pm.
              </strong>
            </p>
            <p>
              From <strong>May 5 to May 30</strong>, when you{" "}
              <strong>hit a jackpot of $1,300.01 or over</strong> while playing
              on our gaming machines or table games you will get at least 1{" "}
              <strong>invitation to this Jackpot party!</strong>
            </p>
            <p>
              So hit the machines and spin ‘till you win and then… win again!
            </p>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Guaranteed Prizes</th>
                </tr>
                <tr>
                  <th>Amount to be won</th>
                  <th>Prize amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>7</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>$1,000</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>$5,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <p>
            Each winner that is drawn will present themselves to the promotion
            area , which will contain 10 envelopes, and make their selection in
            the same order in which they were drawn. Then when all 5 winners
            have selected their envelope they will all open their envelopes
            simultaneously. This will then be repeated for winners 6-10.
          </p>
          <a href={`${t("routes.jackpotPartyMay2023")}#terms`}>
            VIEW TERMS AND CONDITIONS
          </a>
        </div>
      </SecondContent>
      <div id="terms">
        <p>
          <strong>General Rules and Conditions</strong>
        </p>
        <ol>
          <li>
            Players receive one (1) invitation/draw entry to the{" "}
            <strong>Jackpot party</strong> on <strong>Tuesday May 30</strong>{" "}
            for every jackpot of <strong>$1,000 to $1,199.99</strong> and two
            (2) invitations/draw entries for every jackpot of{" "}
            <strong>$1,200 to $1,300</strong> while playing our gaming machines
            or table games between Friday, May 5 at 9:00AM and Tuesday, May 30
            at 8:00PM.
          </li>
          <li>
            Players receive one (1) invitation/draw entry to the{" "}
            <strong>Jackpot party</strong> on <strong>Wednesday, May 31</strong>{" "}
            for every jackpot of <strong>$1,300.01 to $2,999.99</strong> and two
            (2) invitations/draw entries for every jackpot of{" "}
            <strong>$3,000 or more</strong> while playing our gaming machines
            between Friday, May 5 at 9:00AM and Wednesday, May 31 at 8:00PM.
          </li>
          <li>
            After a player is given an invitation by a Playground
            representative, they will then write down their name and ID on the
            raffle portion of the invitation and drop it in the appropriate
            Jackpot Party raffle drum.
          </li>
          <li>You must bring your invitation to enter the Jackpot party.</li>
          <li>
            Starting at 8:00pm, (5) consecutive draws will be held, each winner,
            in the order in which they are drawn, will select 1 of 10 envelopes,
            each containing a prize amount, Then all 5 will open their
            respective envelopes simultaneously to reveal their prize. This will
            then be repeated for winners 6 through 10.
          </li>
          <li>
            Guests are eligible to win once during the Jackpot Party. In the
            event someone has their name called on more than one occasion, by
            way of the random draw, the guest will receive $50 in Free Play.
          </li>
          <li>
            All participants must present a valid government-issued photo
            identification upon request.
          </li>
          <li>
            Playground reserves the right to change or alter this promotion, as
            it deems appropriate, including, but not limited to, making changes
            or alterations to these Rules and Regulations relating to the
            accommodation of participants.
          </li>
          <li>The promotion is open to all Playground members.</li>
          <li>The promotion takes place at Playground.</li>
          <li>
            Employees of Playground shall be ineligible to participate in the
            promotion.
          </li>
          <li>
            Patrons with self-excluded or banned status, as determined by
            Playground, are not eligible for any of the gifts/prizes associated
            with Playground contests, promotions, tournaments or giveaways.
          </li>
          <li>All references to times and dates reflect Eastern Time.</li>
          <li>
            By participating in the promotion, winners consent to the use of
            their names and photographs for promotional purposes without
            additional compensation.
          </li>
        </ol>
        <p>
          <strong>
            Questions relating to this promotion can be answered by visiting the
            Player Rewards desk.
          </strong>
        </p>
      </div>
      
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  background-color: #000;
  padding-bottom: 40px;
  & > img:first-child {
    z-index: 1;
    width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: middle;
  }
  & > img:nth-child(2) {
    z-index: 1;
    width: 100%;
    height: fit-content;
    display: none;
    vertical-align: middle;
  }
  & > div:nth-child(5) {
    margin: 0 auto;
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    background-color: #fff;
    padding: 40px 20px;
    margin-bottom: 40px;
    & > p:first-child {
      font-size: 16px;
      color: #000;
      margin: 0 0 10px;
      & > strong {
        font-weight: 700;
      }
    }
    & > ol:nth-child(2) {
      margin-top: 0;
      margin-bottom: 10px;
      list-style-type: decimal;
      padding-left: 40px;
      & > li {
        font-size: 16px;
        color: #000;
      }
    }
    & > p:last-child {
      font-size: 16px;
      color: #000;
      text-align: center;
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      display: block;
    }
    & > div:nth-child(5) {
      & > ol:nth-child(2) {
        padding-left: 20px;
      }
    }
  }
`;
const FirstContent = styled.div`
  background-color: #fff;
  height: fit-content;
  padding: 60px 0;
  & > div:first-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    & > div {
      height: fit-content;
      width: 100%;
      max-width: 700px;
      & > h2:first-child {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #000;
        margin: 0px;
      }
      & > p:nth-child(2) {
        font-size: 18px;
        color: #000;
        margin: 0 0 10px;
      }
      & > p:nth-child(3) {
        font-size: 18px;
        color: #000;
        margin: 0 0 10px;
        & > strong {
          font-weight: 700;
        }
      }
      & > p:nth-child(4) {
        font-size: 16px;
      }
      & > table:last-child {
        width: 100%;
        margin-bottom: 15px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
        & > thead {
          & > tr:first-child {
            & > th {
              background: radial-gradient(
                circle,
                rgb(249 189 93) 0%,
                rgb(224 126 74) 50%,
                rgb(215 91 49) 100%,
                rgb(187 71 43) 100%
              );
              color: #000;

              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
          & > tr:last-child {
            & > th {
              background: #000;
              color: #fff;

              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              color: #fff;
              background: #000;

              font-weight: 700;
              padding: 15px 5px;
              font-size: 18px;
              border: 2px solid #fff;
            }
          }
        }
      }
    }
    & > img {
      width: 100%;
      max-width: 650px;
      height: fit-content;
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    & > p:first-child {
      font-size: 18px;
      color: #000;
      margin: 0 0 10px;
    }
    & > a:last-child {
      background: radial-gradient(
        circle,
        rgb(249 189 93) 0%,
        rgb(224 126 74) 50%,
        rgb(215 91 49) 100%,
        rgb(187 71 43) 100%
      ) !important;
      color: #000;
      border: none;
      border-radius: 5px;
      min-width: 140px;
      min-height: 40px;

      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      transition: 500ms;
      padding: 3.5px 20px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      &:active {
        outline: 0;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 60px 20px;
    & > div:first-child {
      flex-flow: row wrap-reverse;
      & > div {
        max-width: none;
      }
      & > img {
        max-width: none;
      }
    }
  }
`;
const SecondContent = styled.div`
  background-color: inherit;
  height: fit-content;
  padding: 60px 0;
  & > div:first-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    & > img {
      width: 100%;
      max-width: 650px;
      height: fit-content;
    }
    & > div {
      height: fit-content;
      width: 100%;
      max-width: 700px;
      & > h2:first-child {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #fff;
        margin: 0px;
      }
      & > p:nth-child(2) {
        font-size: 18px;
        color: #fff;
        margin: 0 0 10px;
      }
      & > p:nth-child(3) {
        font-size: 18px;
        color: #fff;
        margin: 0 0 10px;
        & > strong {
          font-weight: 700;
        }
      }
      & > p:nth-child(4) {
        font-size: 16px;
      }
      & > table:last-child {
        width: 100%;
        margin-bottom: 15px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
        & > thead {
          & > tr:first-child {
            & > th {
              background: radial-gradient(
                circle,
                rgb(255 224 107) 0%,
                rgb(250 173 45) 50%,
                rgb(26 86 148) 100%,
                rgb(108 159 250) 100%
              );
              color: #000;

              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
          & > tr:last-child {
            & > th {
              background: #000;
              color: #fff;

              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              background: #fff;
              color: #000;

              font-weight: 700;
              padding: 15px 5px;
              font-size: 18px;
              border: 2px solid #000;
            }
          }
        }
      }
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    & > p:first-child {
      font-size: 18px;
      color: #fff;
      margin: 0 0 10px;
    }
    & > a:last-child {
      background: radial-gradient(
        circle,
        rgb(255 224 107) 0%,
        rgb(250 173 45) 50%,
        rgb(26 86 148) 100%,
        rgb(108 159 250) 100%
      );
      color: #000;
      border: none;
      border-radius: 5px;
      min-width: 140px;
      min-height: 40px;

      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      transition: 500ms;
      padding: 3.5px 20px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      &:active {
        outline: 0;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 60px 20px;
    & > div:first-child {
      flex-flow: row wrap;
      & > img {
        max-width: none;
      }
      & > div {
        max-width: none;
      }
    }
  }
`;
export default JackpotParties;
