import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
// eslint-disable-next-line no-unused-vars
import { FaUser, FaRegNewspaper, FaLock } from "react-icons/fa";
import { BsFillLockFill } from "react-icons/bs";
import { useAccount } from "../context/account/account.provider";
import { StyledForm as GlobalStyledForm } from "../GlobalStyle";
import { change_password } from "../api/auth";
import { useTranslation } from "react-i18next";
import { subscribe as subscribeApi } from "../api/accounts";
import { Line } from "rc-progress";
import Button from "../components/Button/Button";
import NewsletterSection from "../components/NewsletterSection/NewsletterSection";

const MyAccount = () => {
  const { i18n, t } = useTranslation();
  const { accountInfo, access_token } = useAccount();

  const [form, setForm] = useState({
    subscribe: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    errorMessage: "",
    isLoading: false,
    method: "email",
  });

  const {
    subscribe,
    oldPassword,
    newPassword,
    confirmPassword,
    errorMessage,
    isLoading,
    method,
  } = form;

  const setField = (field, value) => {
    setForm((prevForm) => ({ ...prevForm, [field]: value }));
  };

  const resetPasswords = () => {
    setForm((prevForm) => ({
      ...prevForm,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setField("isLoading", true);
    setField("errorMessage", "");

    // Check if the new password matches the confirmation
    if (newPassword !== confirmPassword) {
      setField(
        "errorMessage",
        i18n.language === "en"
          ? "New and confirm password do not match!"
          : "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas!"
      );
      setField("isLoading", false);
      return;
    }

    // Check if a new password is provided before updating
    if (newPassword && oldPassword) {
      // Prevent new password from being the same as the old password
      if (oldPassword === newPassword) {
        setField(
          "errorMessage",
          i18n.language === "en"
            ? "New and old password cannot be the same!"
            : "Le nouveau et l'ancien mot de passe ne peuvent pas être identiques!"
        );
        setField("isLoading", false);
        return;
      }

      const passwordChangeResult = await change_password(
        localStorage.getItem("access_token"),
        oldPassword,
        newPassword,
        confirmPassword
      );

      setField(
        "errorMessage",
        passwordChangeResult.success
          ? i18n.language === "en"
            ? "Password changed successfully!"
            : "Le mot de passe a été modifié avec succès!"
          : i18n.language === "en"
            ? "Password change failed"
            : "Le changement de mot de passe a échoué"
      );
    }

    if (form.subscribe) {
      const newStatus = form.subscribe === "true" ? true : false;
      const newsletterUpdateResult = await subscribeApi(
        access_token,
        newStatus,
        method
      );

      setField(
        "errorMessage",
        newsletterUpdateResult.success
          ? i18n.language === "en"
            ? "Newsletter status will be updated in an hour!"
            : "L'état de la lettre d'information sera mis à jour dans une heure!"
          : i18n.language === "en"
            ? "Error updating newsletter status!"
            : "Erreur dans la mise à jour du statut de la newsletter!"
      );
    }

    setTimeout(() => {
      setField("isLoading", false);
      setField("errorMessage", "");
    }, 3000);

    resetPasswords();
  };

  useEffect(() => {
    setField("errorMessage", "");
  }, [subscribe]);

  const calculateProgressBar = (totalEarned) => {
    if (totalEarned <= 2499) {
      return {
        pointsLeft: 2500 - totalEarned,
        percentage: (totalEarned / 2500) * 100,
        status: 1,
      };
    } else if (totalEarned <= 29999) {
      return {
        pointsLeft: 30000 - totalEarned,
        percentage: ((totalEarned - 2500) / 30000) * 100,
        status: 2,
      };
    } else if (totalEarned <= 299999) {
      return {
        pointsLeft: 300000 - totalEarned,
        percentage: ((totalEarned - 30000) / 300000) * 100,
        status: 3,
      };
    } else {
      return { pointsLeft: 0, percentage: 100, status: 4 };
    }
  };

  const totalEarned = accountInfo?.data.balance_egd || 0;
  const { pointsLeft, percentage, status } = calculateProgressBar(totalEarned);

  return (
    <MyAccountDiv>
      <div>
        <h1>
          {t("myAccount.welcome")}{" "}
          {accountInfo?.data.name && accountInfo.data.name}!
        </h1>
        <div>
          {/* <YourInfoDiv>
            <h3>
              <FaUser />
              Your Info
            </h3>
            <div>
              <p>
                <FaInfoCircle />
                If you’d like to change or update any of this information,
                please send us an email at info@playground.ca or see the Player
                Services desk the next time you visit Playground.
              </p>
              <div>
                <h4>Name</h4>
                <p>Helel Aasdad</p>
              </div>
              <div>
                <h4>Player’s Card Number</h4>
                <p>324242</p>
              </div>
              <div>
                <h4>Email</h4>
                <p>blablabla@gmail.com</p>
              </div>
              <span></span>
              <div>
                <h4>Address</h4>
                <p>7 LakeView Dr</p>
              </div>
              <div>
                <h4>City</h4>
                <p>Las Vegas</p>
              </div>
              <div>
                <h4>Postal Code</h4>
                <p>19232</p>
              </div>
            </div>
          </YourInfoDiv> */}
          <h3>
            <FaUser />
            {t("myAccount.rewardsAndStatus")}
          </h3>
          <YourRewardsDiv status={status} percentage={percentage}>
            {/* <OffersVersionOne>
              <h2>
                {i18n.language === "en"
                  ? "Your Monthly Free PLay Offers"
                  : "Vos offres de jeu gratuites mensuelles"}
              </h2>
              <div>
                <div>
                  {i18n.language === "en"
                    ? "YOUR EXCLUSIVE OFFER!"
                    : "VOTRE OFFRE EXCLUSIVE !"}
                </div>
                <div>
                  {i18n.language === "en"
                    ? "4 MORE REASONS TO VISIT US EVERY WEEK"
                    : "4 RAISONS DE PLUS DE NOUS VENIR CHAQUE SEMAINE"}
                </div>
              </div>
              <div>
                <div>
                  <h3>VALID</h3>
                  <p>August 1 - August 8</p>
                  <h4>$XX</h4>
                  <p>{"<Offer Code>"}</p>
                </div>
                <div>
                  <h3>VALID</h3>
                  <p>August 9 - August 15</p>
                  <h4>$XX</h4>
                  <p>{"<Offer Code>"}</p>
                </div>
                <div>
                  <h3>VALID</h3>
                  <p>August 16 - August 23</p>
                  <h4>$XX</h4>
                  <p>{"<Offer Code>"}</p>
                </div>
                <div>
                  <h3>VALID</h3>
                  <p>August 24 - August 31</p>
                  <h4>$XX</h4>
                  <p>{"<Offer Code>"}</p>
                </div>
              </div>
              <a href="/pdf/August-Mailer.pdf" target="_blank" rel="noreferrer">
                  {i18n.language === "en" ? "Generate PDF" : "Générer un PDF"}
              </a>
            </OffersVersionOne>
            <OffersVersionTwo>
              <h2>
                {i18n.language === "en"
                  ? "Your Monthly Free PLay Offers"
                  : "Vos offres de jeu gratuites mensuelles"}
              </h2>
              <div>
                <div>
                  {i18n.language === "en"
                    ? "YOUR EXCLUSIVE OFFER!"
                    : "VOTRE OFFRE EXCLUSIVE !"}
                </div>
                <div>
                  {i18n.language === "en"
                    ? "4 MORE REASONS TO VISIT US EVERY WEEK"
                    : "4 RAISONS DE PLUS DE NOUS VENIR CHAQUE SEMAINE"}
                </div>
              </div>
              <div>
                <div>
                  <h3>VALID</h3>
                  <p>August 1 - August 8</p>
                  <h4>$XX</h4>
                  <p>{"<Offer Code>"}</p>
                </div>
                <div>
                  <h3>VALID</h3>
                  <p>August 9 - August 15</p>
                  <h4>$XX</h4>
                  <p>{"<Offer Code>"}</p>
                </div>
              </div>
            </OffersVersionTwo> */}
            <h2>{t("myAccount.yourRewards")}</h2>
            <div>
              <div>{t("myAccount.balance")}</div>
              <div>{accountInfo?.data.balance_egd}</div>
              <div>{t("myAccount.points")}</div>
            </div>
            <h2>{t("myAccount.yourStatus")}</h2>
            <Line
              percent={percentage > 0 ? percentage : 0}
              strokeWidth={2}
              strokeLinecap="square"
              trailWidth={2}
              strokeColor={`var(--red)`}
            />
            <ul>
              <li>
                <div></div>
                <div></div>
                {t("myAccount.silver")}
              </li>
              <li>
                <div></div>
                <div></div>
                {t("myAccount.gold")}
              </li>
              <li>
                <div></div>
                <div></div>
                {t("myAccount.red")}
              </li>
              <li>
                <div></div>
                <div></div>
                {t("myAccount.black")}
              </li>
            </ul>
            <p>
              <strong>{pointsLeft}</strong> {t("myAccount.pointsLeft")}
            </p>
            <h2>{t("myAccount.latestRewardsPointsEarned")}</h2>
            <p>{t("myAccount.pointsInPlayNote")}</p>
            <TableDiv>
              <table>
                <thead>
                  <tr>
                    <th>{t("myAccount.date")}</th>
                    <th>{t("myAccount.time")}</th>
                    <th>{t("myAccount.location")}</th>
                    <th>{t("myAccount.game")}</th>
                    <th>{t("myAccount.points")}</th>
                  </tr>
                </thead>
              </table>
            </TableDiv>
            <h2>{t("myAccount.latestRewardsPointsRedeemed")}</h2>
            <TableDiv>
              <table>
                <thead>
                  <tr>
                    <th>{t("myAccount.date")}</th>
                    <th>{t("myAccount.time")}</th>
                    <th>{t("myAccount.kind")}</th>
                    <th>{t("myAccount.name")}</th>
                    <th>{t("myAccount.points")}</th>
                  </tr>
                </thead>
              </table>
            </TableDiv>
          </YourRewardsDiv>
          <StyledForm onSubmit={onSubmit}>
            <div>
              <NewsletterSection setField={setField} />

              <ChangePasswordDiv>
                <h3>
                  <FaLock />
                  {t("myAccount.changeYourPassword")}
                </h3>
                <div>
                  <FormGroup>
                    <FormLabel htmlFor="oldPassword">
                      <BsFillLockFill /> {t("myAccount.oldPassword")}
                    </FormLabel>
                    <FormControl
                      id="oldPassword"
                      type="password"
                      name="oldPassword"
                      value={oldPassword}
                      onChange={(e) => setField("oldPassword", e.target.value)}
                    />
                  </FormGroup>
                  <p>{t("myAccount.useThisFeature")}</p>
                  <p>{t("myAccount.passwordsMustMatch")}</p>
                  <FormGroup>
                    <FormLabel htmlFor="newPassword">
                      <BsFillLockFill /> {t("myAccount.newPassword")}
                    </FormLabel>
                    <FormControl
                      id="newPassword"
                      type="password"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => setField("newPassword", e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="confirmPassword">
                      <BsFillLockFill /> {t("myAccount.repeatNewPassword")}
                    </FormLabel>
                    <FormControl
                      id="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) =>
                        setField("confirmPassword", e.target.value)
                      }
                    />
                  </FormGroup>
                </div>
              </ChangePasswordDiv>
            </div>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Button
              type="submit"
              bgColor="var(--red)"
              color="var(--white)"
              hoverColor="var(--black)"
              hoverBgColor="var(--white)"
              fluid
              label={
                isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  t("myAccount.update")
                )
              }
            />
          </StyledForm>
        </div>
      </div>
      
      {/* <ErrorDiv invalid={errorMessage}>
        <p>{errorMessage}</p>
        <img
          onClick={() => setErrorMessage("")}
          src={cancelIcon}
          alt="cancel-icon"
        />
      </ErrorDiv> */}
    </MyAccountDiv>
  );
};
const MyAccountDiv = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  padding-top: 140px;
  position: relative;
  & > div:first-child {
    width: 100%;
    max-width: 970px;
    height: fit-content;
    & > h1:first-child {
      font-weight: 900;
      font-size: 30px;
      margin-bottom: 20px;
      text-align: center;
      color: var(--white);
      text-transform: capitalize;
    }
    & > div:nth-child(2) {
      padding: 30px;
      border: solid 1px var(--lightblack);
      width: 100%;
      margin-bottom: 80px;
      background: var(--lightblack);
      color: var(--white);
      & > h3 {
        text-shadow: none;
        font-weight: normal;
        line-height: 27px;
        & > svg {
          vertical-align: middle;
          margin-bottom: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    display: block;
    padding-top: 80px;
    & > div:first-child {
      & > h1:first-child {
        font-size: 25px;
      }
      & > div:nth-child(2) {
        padding: 0 20px 20px 20px;
        margin-bottom: 20px;
        & > div:nth-child(2) {
          & > div:nth-child(2) {
            & > div:first-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 150px;
  }
`;
const YourRewardsDiv = styled.div`
  border: solid 1px var(--darkgrey);
  text-align: left;
  width: 100%;
  height: fit-content;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  & > h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    line-height: 33px;
  }
  & > div:nth-child(2) {
    width: 100%;
    background-color: var(--black);
    text-align: center;
    overflow: hidden;
    padding: 15px;
    margin: 10px 0;
    & > div:first-child {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      background: var(--red);
      width: 100%;
      padding: 5px 0px;
    }
    & > div:nth-child(2) {
      font-size: 30px;
      font-weight: 800;
      line-height: normal;
      text-align: center;
    }
    & > div:nth-child(3) {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 3px;
      line-height: 21px;
      color: #a7a7a7;
    }
  }
  & > ul:nth-child(5) {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    width: 100%;
    padding: 0;
    & > li {
      font-weight: 700;
      color: var(--white);
      padding: 10px;
      width: 25%;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    & > li > div {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 100%; /* Set the div width to cover the entire li */
    }
    & > li:nth-child(1) {
      & > div:first-child {
        background: #71706e;
        opacity: 0.2; /* Set initial opacity for all divs */
      }
      & > div:nth-child(2) {
        background: #71706e;
        opacity: 1; /* Set initial opacity for all divs */
      }
    }
    & > li:nth-child(2) {
      & > div:first-child {
        background: #cfa736;
        opacity: 0.2; /* Set initial opacity for all divs */
      }
      & > div:nth-child(2) {
        background: #cfa736;
        opacity: ${({ status }) =>
          status > 1 ? "1" : "0.2"}; /* Set initial opacity for all divs */
      }
    }
    & > li:nth-child(3) {
      & > div:first-child {
        background: var(--red);
        opacity: 0.2; /* Set initial opacity for all divs */
      }
      & > div:nth-child(2) {
        background: var(--red);
        opacity: 0.2; /* Set initial opacity for all divs */
        opacity: ${({ status }) =>
          status > 2 ? "1" : "0.2"}; /* Set initial opacity for all divs */
      }
    }
    & > li:nth-child(4) {
      & > div:first-child {
        background: var(--black);
        opacity: 0.2; /* Set initial opacity for all divs */
      }
      & > div:nth-child(2) {
        background: var(--black);
        opacity: 0.2; /* Set initial opacity for all divs */
      }
    }
    & > li:nth-child(${({ status }) => status + 1}) {
      & > div:first-child {
        width: ${({ percentage }) =>
          `${percentage}%`}; /* Adjust width based on progress */
        opacity: 1; /* Change opacity for the active li */
      }
    }
  }
  & > p:nth-child(6) {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  & > p:nth-child(8) {
    font-size: 15px;
    color: var(--grey);
    margin: 0;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
  }
`;
const OffersVersionOne = styled.div`
  & > h2:first-child {
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    line-height: 33px;
  }
  & > div:nth-child(2) {
    width: 100%;
    background-color: var(--red);
    text-align: center;
    overflow: hidden;
    margin: 10px 0;
    & > div:first-child {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      width: 100%;
      padding: 5px 0px 0px 0px;
      line-height: 20px;
    }
    & > div:last-child {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 2px;
      width: 100%;
      padding: 0px 0px 5px 0px;
      line-height: 20px;
    }
  }
  & > div:nth-child(3) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
    & > div {
      width: 24%;
      height: fit-content;
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      & > h3:first-child {
        font-weight: bold;
        margin: 0;
      }
      & > p:nth-child(2) {
        margin: 0;
        line-height: 16px;
      }
      & > h4:nth-child(3) {
        margin: 0;
        font-size: 65px;
        line-height: 70px;
        font-weight: bold;
      }
      & > p:last-child {
        color: var(--red);
        margin: 0;
        vertical-align: middle;
      }
    }
  }
  & > a {
    text-align: center;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:nth-child(2) {
      & > div:first-child,
      & > div:last-child {
        font-size: 10px;
        line-height: 14px;
      }
    }
    & > div:nth-child(3) {
      gap: 10px;
      & > div {
        width: 48%;
        & > h3:first-child {
          font-size: 18px;
        }
        & > p:nth-child(2) {
          font-size: 12px;
        }
        & > h4:nth-child(3) {
          font-size: 55px;
          line-height: 55px;
        }
        & > p:last-child {
          font-size: 12px;
        }
      }
    }
  }
`;
const OffersVersionTwo = styled.div`
  display: none;
  & > h2:first-child {
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    line-height: 33px;
  }
  & > div:nth-child(2) {
    width: 100%;
    background-color: var(--red);
    text-align: center;
    overflow: hidden;
    margin: 10px 0;
    & > div:first-child {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      width: 100%;
      padding: 5px 0px 0px 0px;
      line-height: 20px;
    }
    & > div:last-child {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 2px;
      width: 100%;
      padding: 0px 0px 5px 0px;
      line-height: 20px;
    }
  }
  & > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    height: fit-content;
    & > div {
      width: 48%;
      height: fit-content;
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      & > h3:first-child {
        font-weight: bold;
        margin: 0;
      }
      & > p:nth-child(2) {
        margin: 0;
        line-height: 16px;
      }
      & > h4:nth-child(3) {
        margin: 0;
        font-size: 65px;
        line-height: 70px;
        font-weight: bold;
      }
      & > p:last-child {
        color: var(--red);
        margin: 0;
        vertical-align: middle;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:nth-child(2) {
      & > div:first-child,
      & > div:last-child {
        font-size: 10px;
        line-height: 14px;
      }
    }
    & > div:last-child {
      gap: 10px;
      & > div {
        & > h3:first-child {
          font-size: 18px;
        }
        & > p:nth-child(2) {
          font-size: 12px;
        }
        & > h4:nth-child(3) {
          font-size: 55px;
          line-height: 55px;
        }
        & > p:last-child {
          font-size: 12px;
        }
      }
    }
  }
`;
// const YourInfoDiv = styled.div`
//   background: rgba(22, 26, 28, 0.75);
//   border: solid 1px var(--darkgrey);
//   text-align: left;
//   width: 100%;
//   height: fit-content;
//   padding: 15px;
//   margin-bottom: 20px;
//   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
//   & > p:first-child {
//     text-align: left;
//     overflow: hidden;
//     padding: 10px;
//     position: relative;
//     border: 1px solid var(--darkgrey);
//     box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
//     background-color: var(--lightgrey);
//     text-align: center;
//     font-size: 12px;
//     color: var(--black);
//     & > svg {
//       vertical-align: middle;
//       margin-bottom: 3px;
//       margin-right: 5px;
//     }
//   }
//   & > div {
//     display: inline-block;
//     height: fit-content;
//     width: 33.33%;
//     position: relative;
//     padding: 0 15px;
//     & > h4 {
//       font-size: 14px;
//       font-weight: 400;
//       margin: 2px 0px;
//       line-height: 25px;
//     }
//     & > p {
//       color: var(--grey);
//       margin-bottom: 15px;
//       font-size: 13px;
//       margin: 0 0 10px;
//     }
//   }
//   & > span {
//     display: flex;
//     width: 100%;
//     max-width: calc(100% - 30px);
//     border-bottom: 1px solid var(--darkgrey);
//     margin: 0 auto;
//     margin-bottom: 10px;
//   }
// `;
const TableDiv = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        border: 1px solid var(--darkgrey);
        & > th {
          background: rgba(255, 255, 255, 0.1);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          border: 1px solid var(--darkgrey);
          color: var(--white);
          font-size: 14px;
          text-align: center;
          padding: 8px 0;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 12px;
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 10px;
            font-weight: 300;
          }
        }
      }
    }
  }
`;
const StyledForm = styled(GlobalStyledForm)`
  height: fit-content;
  max-width: 1200px;
  border-radius: 0;
  & > div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  & > button {
    width: 100%;
    max-width: 320px;
    padding: 10px;
    margin: 0 auto;
    background-color: var(--red);
    &:hover {
      background-color: var(--white);
      color: var(--black);
    }
  }

  @media screen and (max-width: 991px) {
    & > div {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 479px) {
    & > button {
      max-width: unset;
    }
  }
`;

const ChangePasswordDiv = styled.div`
  color: var(--white);
  border-radius: 5px;
  display: inline-block;
  margin: 0;
  width: 100%;
  height: fit-content;
  & > h3 {
    line-height: 27px;
    font-size: 16px;
    color: var(--lightgrey);
    margin: 0;
    & > svg {
      vertical-align: middle;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }
  & > div {
    border: solid 1px var(--darkgrey);
    margin: 10px 0;
    padding: 15px;
    & > p {
      width: 100%;
      font-size: 14px;
      margin: 15px 0;
      color: var(--grey);
    }
    & > label {
      border: 1px solid var(--darkgrey);
      width: 100%;
      font-size: 16px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      border-radius: 5px;
      position: relative;
      & > svg {
        vertical-align: middle;
        margin-right: 10px;
      }
      & > input {
        margin: 0;
        align-self: center;
        width: 100%;
        height: 25px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--white);
      }
    }
    & > label:last-child {
      margin-top: 10px;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of phone styles */
    & > div {
      & > label {
        width: 49%;
      }
      & > label:last-child {
        margin-left: 5px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding: 20px 0;
    & > div {
      margin-bottom: 0;
    }
  }
`;
// const ErrorDiv = styled.div`
//   position: fixed;
//   opacity: ${({ invalid }) => (invalid ? "1" : "0")};
//   transition: all 0.3s linear;
//   transform: ${({ invalid }) =>
//     invalid ? "translateY(0px)" : "translateY(80px)"};
//   bottom: 0;
//   background: var(--red);
//   color: var(--white);
//   z-index: 5;
//   width: 100%;
//   text-align: center;
//   display: flex;
//   height: auto;
//   justify-content: center;
//   align-items: center;
//   padding: 20px;
//   gap: 150px;
//   & > p {
//     font-weight: 300;
//   }
//   & > img {
//     width: 35px;
//     height: auto;
//     cursor: pointer;
//     &:hover {
//       scale: 1.1;
//       transition: 0.3s scale ease-in-out;
//     }
//   }
//   @media screen and (max-width: 991px) {
//     /* start of phone and medium tablet styles */
//     gap: 80px;
//   }
// `;
export default MyAccount;
