import { useState, useEffect } from 'react';
import styled from "styled-components/macro";
import Hero from "../Hero/Hero";
import Button from "../Button/Button";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import { useTranslation } from "react-i18next";
import { formatAmount } from "../../utils/formatters";
import TitleSection from "../TitleSection/TitleSection";

const GameListHero = ({ jackpotData, compact }) => {
  const { i18n, t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 4000); // 4 seconds timeout

    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  useEffect(() => {
    if (jackpotData && Object.keys(jackpotData).length > 0) {
      setIsLoading(false);
    }
  }, [jackpotData]);

  const isEgdDataAvailable = jackpotData && Object.keys(jackpotData).length > 0;

  if (!isEgdDataAvailable && !isLoading) {
    console.log("No Game data");
  }

  const title = compact ? '' : `# ${t("egd.egdGameList.hero.mainText")} \n\n`; // Use h1 title only if not compact

  const heroProps = {
    mainHeroText: isLoading
      ? `${title}${t("egd.egdGameList.loadingData")}`  // Show loading message when loading
      : isEgdDataAvailable && jackpotData.total_amount  // Check if jackpot data is available and valid
      ? `${title}### ${formatAmount(jackpotData.total_amount, i18n.language)}`  // Show formatted amount
      : `${title}${t("egd.egdGameList.errorFetching")}`,  // Show error if no valid data after loading
    bgImage: "egd/game-list/featured-games-hero-bg.jpg",
    descriptionBgColor: "#0000004d",
    overlayVideoSrc: "/videos/bbj-overlay.mp4",
    className: "game-list-hero"
  };


  // const learnMoreButton = {
  //   label: t("egd.egdGameList.buttonText"),
  //   href: t("pseudoRoutes.egdGameList.progressiveJackpots.path"),
  //   size: "medium",
  //   width: "xwide",
  //   specialHover: true
  // }

  return (
    <GamesListHeroWrapper>
      {compact ? (
        <>
          <container-wide>
            <TitleSection as={'h2'} title={t("egd.egdGameList.hero.mainText")} />
          </container-wide>
          <Hero {...heroProps} className="compact-game-list-hero" />
        </>
      ) : (
        <Hero {...heroProps}>
          <container-fluid>
            <TitleSection
              title={t("egd.egdGameList.hero.title")}
              text={t("egd.egdGameList.hero.description")}
            />
          </container-fluid>
        </Hero>
      )}
    </GamesListHeroWrapper>
  )
};

const GamesListHeroWrapper = styled.div`
  .hero_container {
    padding-top: 0;
    position: relative;
    &.compact-game-list-hero {
      margin-top: 0;
      .main-hero-text {
        margin: 2rem 0;
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      filter: brightness(0.7);
      z-index: 1;
    }
  }
  .main-hero-text {
    margin: 9rem 0 3rem;
    text-align: center;
    @media (max-width: 991.8px) {
      margin-top: 3rem;
    }
    h1, h3 {
      margin: 10px 0;
      padding: 0;
    }
    h1 {
      font-size: clamp(0.1rem, 7vw, 4rem);
      font-weight: 700;
      color: var(--white);
      text-shadow: 1px 0px 10px var(--black);
    }
    h3 {
      color: var(--mustard);
      font-weight: 900;
      font-size: clamp(1rem, 14vw, 8rem);
      text-shadow: 1px 0px 10px var(--black);
    }
    p {
      color: var(--white);
      max-width: 800px;
      padding: 0 1rem;
    }
  }
  .hero_text_container {
    p {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
      max-width: 850px;
      strong {
        font-weight: 600;
        color: var(--mustard);
        white-space: nowrap;
        @media (max-width: 450px) {
          display: block;
        }
      }
      &.small {
        font-size: 0.85rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }
    }
    h3 {
      margin-top: 2rem;
      font-weight: 700;;
    }
  }
`;

export default GameListHero;
