/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback } from "react";
import { Form, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaUser, FaCreditCard } from "react-icons/fa";
import { useAccount } from "../context/account/account.provider";
import { payment } from "../api/cart";
import { useTranslation } from "react-i18next";
import Button from "../components/Button/Button";
import ResponsiveImage from "../components/ImageWrapper/ResponsiveImage";
import { formatDate, formatAmount } from "../utils/formatters";

const initialFormState = {
  street_number: "",
  street_name: "",
  postal_code: "",
  cardholder_name: "",
  card_number: "",
  month: "",
  year: "",
  cvv: "",
};

const useYearOptions = () => {
  return useMemo(() => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear + 10;
    const options = [];

    for (let year = currentYear; year <= startYear; year++) {
      options.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }

    return options;
  }, []);
};

const Checkout = () => {
  const [form, setForm] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const { accountCart, accountCartUpdateFn, accountInfo } = useAccount();
  const [isLoading, setIsLoading] = useState(false);
  const { i18n, t } = useTranslation();
  const yearOptions = useYearOptions();
  const currentLocale = i18n.language;

  const handleChange = useCallback(
    (event) => {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    },
    [form]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const paymentData = buildPaymentData(form);
    try {
      setIsLoading(true);
      const response = await payment(paymentData);
      handleResponse(response);
    } catch (error) {
      setErrors({
        submit: error.message,
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  const validateForm = () => {
    const numberPattern = /.+/; // Ensures the field is not empty
    const namePattern = /.+/; // Ensures the field is not empty
    const card_numberPattern = /^\d{13,19}$/; // Allow 13 to 19 digits, including spaces which will be stripped
    const cvvPattern = /^\d{3}$/;
    const monthPattern = /^(0?[1-9]|1[0-2])$/; // Allow numbers from 01 to 12
    const yearPattern = /^\d{4}$/; // Allow two-digit years

    const newErrors = {};

    if (!numberPattern.test(form.street_number)) {
      newErrors.street_number = t("checkout.streetNumberInvalid");
    }
    if (!namePattern.test(form.street_name)) {
      newErrors.street_name = t("checkout.streetNameInvalid");
    }
    if (!isValidPostal_code(form.postal_code)) {
      newErrors.postal_code = t("checkout.postalCodeInvalid");
    }
    if (!namePattern.test(form.cardholder_name)) {
      newErrors.cardholder_name = t("checkout.cardholderNameInvalid");
    }
    if (!card_numberPattern.test(form.card_number?.replace(/\s/g, ""))) {
      newErrors.card_number = t("checkout.cardNumberInvalid");
    }
    if (!cvvPattern.test(form.cvv)) {
      newErrors.cvv = t("checkout.cvvInvalid");
    }
    if (!monthPattern.test(form.month)) {
      newErrors.month = t("checkout.monthInvalid");
    }
    if (!yearPattern.test(form.year)) {
      newErrors.year = t("checkout.yearInvalid");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const isValidPostal_code = (postal_code) => {
    const lengthValid = postal_code?.length > 3 && postal_code?.length <= 7;
    const patternValid = /^[A-Za-z0-9\s-]+$/.test(postal_code); // Allow spaces and dashes
    return lengthValid && patternValid;
  };

  const buildPaymentData = (formData) => {
    return {
      cc_name: formData.cardholder_name,
      cc_number: formData.card_number.replace(/\s/g, ""), // Strip spaces before sending
      cc_month: parseInt(formData.month, 10),
      cc_year: parseInt(formData.year, 10),
      cc_verification_value: formData.cvv,
      cc_street_number: formData.street_number,
      cc_street_name: formData.street_name,
      cc_zip: formData.postal_code,
    };
  };

  const handleResponse = (response) => {
    if (response.success) {
      window.location.href = t("routes.successfulPayment");
    } else {
      setErrors({ submit: response.message });
    }
    clearForm();
  };

  const clearForm = () => {
    setForm(initialFormState);
  };

  return (
    <MainDiv>
      <container-div>
        <CheckoutDiv isLoading={isLoading}>
          {accountCart?.order_items?.length == 0 && (
            <h2>{t("tournament.cart.empty")}</h2>
          )}
          <Button
            color="var(--white)"
            hoverBgColor="var(--white)"
            hoverColor="var(--black)"
            href={t("routes.pokerTournamentCalendar")}
            label={t("tournament.cart.backToCalendar")}
          />
          {accountCart?.order_items?.length > 0 && (
            <>
              <ShoppingCart
                accountCart={accountCart}
                accountCartUpdateFn={accountCartUpdateFn}
                t={t}
                currentLocale={currentLocale}
              />
              <BillingAndPayment
                form={form}
                handleChange={handleChange}
                yearOptions={yearOptions}
                t={t}
                i18n={i18n}
                accountInfo={accountInfo}
                errors={errors}
              />
              <TotalPrice
                accountCart={accountCart}
                t={t}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                currentLocale={currentLocale}
              />
            </>
          )}
        </CheckoutDiv>
        {errors.submit && (
          <ErrorDiv invalid={errors.submit}>
            <p>{errors.submit}</p>
            <ResponsiveImage
              onClick={() => {
                setErrors({ ...errors, submit: "" });
                setIsLoading(false);
              }}
              src="svg/cancel-popup.svg"
              alt="cancel-icon"
            />
          </ErrorDiv>
        )}
      </container-div>
    </MainDiv>
  );
};

const ShoppingCart = ({ accountCart, accountCartUpdateFn, t, currentLocale }) => (
  <ShoppingCartDiv>
    <SectionHeader>
      <div>1</div>
      <div>
        <h2>{t("checkout.shoppingCard")}</h2>
        <p>{t("checkout.reviewPurchase")}</p>
      </div>
    </SectionHeader>
    <div>
      <table>
        <thead>
          <tr>
            <th style={{ width: "60%" }}>{t("checkout.ticket")}</th>
            <th style={{ width: "30%" }}>{t("checkout.optional")}</th>
            <th style={{ width: "5%" }}>{t("price")}</th>
            <th style={{ width: "5%" }}>{t("checkout.remove")}</th>
          </tr>
        </thead>
        <tbody>
          {accountCart?.order_items?.map((ticket) => (
            <TicketRowContainer key={ticket.item_details.id}>
              <td>
                <div>
                  <div>
                    <ResponsiveImage src="checkout/ticket.jpg" alt="ticket" />
                  </div>
                  <div>
                    <h4>
                      {ticket.item_details[`name_${currentLocale}`]}
                    </h4>
                    <p>
                      { formatDate(ticket.item_details.start_date, currentLocale, true) }
                      <span>, {ticket.item_details.start_time}</span>
                    </p>
                  </div>
                </div>
              </td>
              <td data-th="Optional"></td>
              <td>
                { formatAmount(ticket.price, currentLocale, true) }
              </td>
              <td>
                <button
                  onClick={() => accountCartUpdateFn("remove", ticket.gid)}
                >
                  <RiDeleteBin5Line />
                </button>
              </td>
            </TicketRowContainer>
          ))}
        </tbody>
      </table>
    </div>
  </ShoppingCartDiv>
);

const BillingAndPayment = ({
  form,
  handleChange,
  yearOptions,
  i18n,
  t,
  accountInfo,
  errors,
}) => (
  <BillingAndPaymentDiv>
    <SectionHeader>
      <div>2</div>
      <div>
        <h2>{t("checkout.billingPayment")}</h2>
        <p>{t("checkout.paymentMethod")}</p>
      </div>
    </SectionHeader>
    <div>
      <BillingInfo
        form={form}
        handleChange={handleChange}
        t={t}
        accountInfo={accountInfo}
        errors={errors}
      />
      <PaymentInfo
        form={form}
        handleChange={handleChange}
        yearOptions={yearOptions}
        t={t}
        i18n={i18n}
        errors={errors}
      />
    </div>
  </BillingAndPaymentDiv>
);

const BillingInfo = ({ form, handleChange, t, accountInfo, errors }) => (
  <div>
    <SectionSubHeader>
      <FaUser />
      <h3>{t("checkout.billingInfo")}</h3>
    </SectionSubHeader>
    <div>
      <p>
        <strong>Note:</strong> {t("checkout.billingAddressText")}
      </p>
      <div>
        <CustomFormGroup className="d-flex flex-column flex-md-row">
          <div className="w-100 w-md-50 mb-3 mb-md-0">
            <FormLabel>{t("name")}</FormLabel>
            <p>{accountInfo?.data.name}</p>
          </div>
          <div className="w-100 w-md-50">
            <FormLabel>{t("email")}</FormLabel>
            <p>{accountInfo?.data.email}</p>
          </div>
        </CustomFormGroup>
        <CustomFormGroup>
          <FormLabel htmlFor="street-number">{t("streetNumber")}</FormLabel>
          <FormControl
            type="text"
            id="street-number"
            name="street_number"
            value={form.street_number}
            onChange={handleChange}
            isInvalid={!!errors.street_number}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.street_number}
          </Form.Control.Feedback>
        </CustomFormGroup>
        <CustomFormGroup>
          <FormLabel htmlFor="street-name">{t("streetName")}</FormLabel>
          <FormControl
            type="text"
            id="street-name"
            name="street_name"
            value={form.street_name}
            onChange={handleChange}
            isInvalid={!!errors.street_name}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.street_name}
          </Form.Control.Feedback>
        </CustomFormGroup>
        <CustomFormGroup>
          <FormLabel htmlFor="postal-code">{t("postalCode")}</FormLabel>
          <FormControl
            type="text"
            id="postal-code"
            name="postal_code"
            value={form.postal_code}
            onChange={handleChange}
            isInvalid={!!errors.postal_code}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.postal_code}
          </Form.Control.Feedback>
        </CustomFormGroup>
      </div>
    </div>
  </div>
);

const PaymentInfo = ({ form, handleChange, yearOptions, t, i18n, errors }) => (
  <div>
    <SectionSubHeader>
      <FaCreditCard />
      <h3>{t("checkout.paymentMethod")}</h3>
    </SectionSubHeader>
    <div>
      <p>
        <strong>Note:</strong> {t("checkout.creditCardText")}
      </p>
    </div>
    <div>
      <CustomFormGroup className="d-flex justify-content-between mb-3">
        <FormLabel htmlFor="">{t("checkout.paymentTypes")}</FormLabel>
        <ul className="mb-0">
          <li>
            <ResponsiveImage src="checkout/visa.png" alt="visa" />
          </li>
          <li>
            <ResponsiveImage src="checkout/mastercard.png" alt="mastercard" />
          </li>
        </ul>
      </CustomFormGroup>
      <div className="billing-info">
        <CustomFormGroup>
          <FormLabel htmlFor="cardholder-name">
            {t("checkout.cardholderName")}
          </FormLabel>
          <FormControl
            type="text"
            id="cardholder-name"
            name="cardholder_name"
            value={form.cardholder_name}
            onChange={handleChange}
            isInvalid={!!errors.cardholder_name}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.cardholder_name}
          </Form.Control.Feedback>
        </CustomFormGroup>
        <CustomFormGroup>
          <FormLabel htmlFor="card-number">
            {t("checkout.cardNumber")}
          </FormLabel>
          <FormControl
            type="text"
            id="card-number"
            name="card_number"
            value={form.card_number}
            onChange={handleChange}
            isInvalid={!!errors.card_number}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.card_number}
          </Form.Control.Feedback>
        </CustomFormGroup>
        <div className="d-flex">
          <CustomFormGroup>
            <FormLabel htmlFor="month">
              {t("checkout.expirationDate")}
            </FormLabel>
            <FormControl
              type="text"
              name="month"
              id="month"
              maxLength="2"
              value={form.month}
              onChange={(e) => handleChange(e)}
              isInvalid={!!errors.month}
              placeholder="MM"
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.month}
            </Form.Control.Feedback>
          </CustomFormGroup>
          <CustomFormGroup>
            <FormLabel htmlFor="year">&nbsp;</FormLabel>
            <FormControl
              type="text"
              name="year"
              id="year"
              maxLength="4"
              value={form.year}
              onChange={(e) => handleChange(e)}
              isInvalid={!!errors.year}
              placeholder={t("checkout.year")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.year}
            </Form.Control.Feedback>
          </CustomFormGroup>
        </div>
        <CustomFormGroup>
          <FormLabel htmlFor="cvv">CVV</FormLabel>
          <FormControl
            type="text"
            id="cvv"
            name="cvv"
            value={form.cvv}
            onChange={handleChange}
            isInvalid={!!errors.cvv}
            maxLength="3"
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.cvv}
          </Form.Control.Feedback>
        </CustomFormGroup>
      </div>
    </div>
    <p>{t("checkout.paymentInfoText")}</p>
  </div>
);

const TotalPrice = ({
  accountCart,
  t,
  isLoading,
  handleSubmit,
  currentLocale,
}) => (
  <div>
    <div>
      <h4>
        <strong>Total:</strong>
        <span>
          {formatAmount(accountCart?.total_price, currentLocale, true, false)} *
        </span>
      </h4>
      <small>{t("checkout.buyInInfo")}</small>
      <Button
        color="var(--white)"
        hoverBgColor="var(--white)"
        hoverColor="var(--black)"
        label={t("submit")}
        disabled={isLoading}
        onClick={handleSubmit}
      />
    </div>
  </div>
);

const MainDiv = styled.div`
  position: relative;
  z-index: 0;
  height: auto;
  width: 100%;
  background: var(--black);
  & > div:first-child {
    height: auto;
    z-index: 1;
    position: relative;
    width: 100%;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      min-height: unset;
      & > img:first-child {
        display: none;
      }
      & > img:last-child {
        display: block;
        padding-top: 80px;
      }
    }
  }
`;

const CheckoutDiv = styled.div`
  height: auto;
  width: 100%;
  background: var(--lightblack);
  color: var(--white);
  margin: 0 auto;
  padding: 200px 40px 40px 40px;
  & > div:last-child {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      justify-self: flex-end;
      width: 50%;
      & > h4 {
        border-top: 2px solid var(--rewards_grid);
        border-bottom: 2px solid var(--rewards_grid);
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        & > span {
          color: var(--red);
        }
      }
      & > small {
        font-size: 12px;
      }
      & > button {
        background-color: var(--red);
        align-self: flex-start;
        margin-top: 20px;
        pointer-events: ${({ isLoading }) => isLoading && "none"};
        &:hover {
          background: var(--white);
          color: var(--black);
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding: 100px 20px 20px 20px;
    & > div:last-child {
      & > div {
        width: 100%;
      }
    }
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 15px;
  border-left: 4px solid var(--red);
  & > div:first-child {
    font-size: 75px;
    line-height: 70px;
  }
  & > div:last-child {
    & > h2 {
      margin: 0;
    }
    & > p {
      margin: 0;
    }
  }
`;

const SectionSubHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  background: var(--black);
  padding: 0 15px;
  border-right: 4px solid var(--red);
`;

const ShoppingCartDiv = styled.div`
  & > div:last-child {
    padding: 40px;
    & > table {
      border-spacing: 0;
      border-collapse: collapse;
      width: 100%;
      & > thead {
        & > tr {
          & > th {
            font-size: 16px;
            font-weight: 400;
            padding-bottom: 20px;
            text-transform: uppercase;
          }
        }
      }
      & > tbody {
        border: 1px solid #eee;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:last-child {
      padding: 40px 0;
      & > table {
        & > thead {
          & > tr {
            & > th:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

const BillingAndPaymentDiv = styled(Form)`
  width: 100%;
  #month,
  #year {
    cursor: pointer;
  }
  #postal-code {
    grid-column: span 2;
    width: 100%;
  }
  .d-flex {
    column-gap: 20px;
  }
  & > div:last-child {
    gap: 20px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    & > div {
      & > div:nth-child(2) {
        & > p:first-child {
          background: var(--rewards_grid);
          padding: 15px;
          font-weight: 300;
          font-size: 14px;
          flex-grow: 2;
        }
        & > div:nth-child(2) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          gap: 20px;
          & > div:last-child,
          & > div:first-child {
            grid-column: span 2;
          }
          & > div {
            & > h4 {
              font-weight: normal;
              margin: 16px 0;
            }
            & > p {
              font-weight: 300;
              font-size: 14px;
            }
          }
        }
      }
      & > div:nth-child(5) {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
      }
      & > div:nth-child(3) {
        & > div > ul {
          list-style: none;
          display: flex;
          padding: 0;
          gap: 20px;
          & > li {
            & > img {
              object-fit: contain;
              max-width: 50px;
            }
          }
        }
      }
      & > div:nth-child(6) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 15px 0;
        gap: 20px;
        & > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
          & > select,
          & > input {
            & > option {
              padding: 15px;
            }

            ::-webkit-scrollbar {
              width: 4px;
            }

            ::-webkit-scrollbar-thumb {
              background-color: var(--rewards_grid);
              border-radius: 6px;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: #555;
              background-color: var(--lightgrey);
            }
          }
        }
      }
    }
  }
  .billing-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media screen and (max-width: 991px) {
    & > div:last-child {
      grid-template-columns: 1fr;
    }
  }
`;

const TicketRowContainer = styled.tr`
  position: relative;
  z-index: 2;
  pointer-events: all;
  padding: 15px;
  border: 1px solid #eee;
  & > td:first-child {
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      & > div:first-child {
        & > img {
          display: block;
          max-width: 100%;
          height: 72px;
        }
      }
      & > div:last-child {
        flex: 1;
        padding-left: 1rem;
        & > h4 {
          margin: 0;
        }
        & > p {
          margin: 0;
        }
      }
    }
  }
  & > td:nth-child(2) {
  }
  & > td:nth-child(3) {
    text-align: center;
    font-weight: bold;
  }
  & > td:last-child {
    text-align: center;
    & > button {
      background-color: var(--red);
      color: var(--white);
      border: none;
      padding: 0.5rem;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 991px) {
    padding: 0;
    & > td:first-child {
      & > div {
        padding: 15px 5px 15px 15px;
        & > div:first-child {
          display: none;
        }
        & > div:last-child {
          padding-left: 0;
        }
      }
    }
    & > td:nth-child(2) {
      display: none;
    }
  }
`;

const CustomFormGroup = styled(FormGroup)`
  & > select {
    padding-left: 15px;
    line-height: 35px;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;

    & > option {
      padding: 15px;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--rewards_grid);
      border-radius: 6px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #555;
      background-color: var(--lightgrey);
    }
  }
`;

const ErrorDiv = styled.div`
  position: fixed;
  transition: all 0.3s linear;
  transform: ${({ invalid }) =>
    invalid ? "translateY(0px)" : "translateY(80px)"};
  bottom: 0;
  background: var(--red);
  color: var(--white);
  z-index: 2;
  width: 100%;
  text-align: center;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 150px;
  & > p {
    font-weight: 300;
  }
  & > img {
    width: 35px;
    height: auto;
    cursor: pointer;
    &:hover {
      scale: 1.1;
      transition: 0.3s scale ease-in-out;
    }
  }
  @media screen and (max-width: 991px) {
    gap: 80px;
  }
`;

export default Checkout;
