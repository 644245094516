import React from 'react';
import {Row, Col} from 'react-bootstrap';
import ResponsiveImage from 'src/components/ImageWrapper/ResponsiveImage';

const ThreeColumnImages = ({ images }) => {
  return (
    <container-wide>
      <Row xs={1} md={3} className="g-4 mb-4">
        {images.map((image, index) => (
          <Col key={index} className=" margin-bottom-30 mini-event">
            <ResponsiveImage
              src={image.src}
              alt={image.alt}
              className="img-responsive"
            />
          </Col>
        ))}
      </Row>
    </container-wide>
  );
};

export default ThreeColumnImages;
