import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import TournamentHighlightedList from "./TournamentHighlightedList";
import TournamentDropDownText from "./TournamentDropDownText";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import TournamentDynamicTable from "./TournamentDynamicTable";

const TournamentSubtabsContent = ({ tournamentContent }) => {
  return (
    <Div>
      <container-div>
        <ContentText>
          <ReactMarkdown>{tournamentContent?.content?.text}</ReactMarkdown>
        </ContentText>

        {/* Use the HighlightedList component */}
        {tournamentContent?.content?.highlightedList && (
          <TournamentHighlightedList
            highlightedList={tournamentContent.content.highlightedList}
          />
        )}

        {tournamentContent?.content?.textTwo && (
          <ContentTextSmall>
            <ReactMarkdown>{tournamentContent?.content?.textTwo}</ReactMarkdown>
          </ContentTextSmall>
        )}

        {tournamentContent?.content?.centeredText && (
          <ContentCenteredText>
            <ReactMarkdown>
              {tournamentContent?.content?.centeredText}
            </ReactMarkdown>
          </ContentCenteredText>
        )}

        {/* Use the HighlightedListTwo component */}
        {tournamentContent?.content?.highlightedListTwo && (
          <TournamentHighlightedList
            highlightedList={tournamentContent.content.highlightedListTwo}
          />
        )}

        {tournamentContent?.content?.textThree && (
          <ContentTextSmall>
            <ReactMarkdown>
              {tournamentContent?.content?.textThree}
            </ReactMarkdown>
          </ContentTextSmall>
        )}

        {tournamentContent?.content?.smallText && (
          <ReactMarkdown>{tournamentContent?.content?.smallText}</ReactMarkdown>
        )}

        {tournamentContent?.content?.dropdownText && (
          <TournamentDropDownText
            dropdownText={tournamentContent?.content?.dropdownText}
          />
        )}

        {tournamentContent?.imageTextWrapper && (
          <ImageTextWrapper>
            <ResponsiveImage
              src={tournamentContent?.imageTextWrapper?.image}
              alt="text-image"
            />
            <ReactMarkdown>
              {tournamentContent?.imageTextWrapper?.text}
            </ReactMarkdown>
          </ImageTextWrapper>
        )}

        {tournamentContent?.content?.tableDataText && (
          <ReactMarkdown>
            {tournamentContent?.content?.tableDataText}
          </ReactMarkdown>
        )}

        {tournamentContent?.content?.tableData && (
          <TournamentDynamicTable
            tableData={tournamentContent?.content?.tableData}
          />
        )}

        {tournamentContent?.content?.tableDataTextTwo && (
          <ReactMarkdown>
            {tournamentContent?.content?.tableDataTextTwo}
          </ReactMarkdown>
        )}

        {tournamentContent?.content?.tableDataTwo && (
          <TournamentDynamicTable
            tableData={tournamentContent?.content?.tableDataTwo}
          />
        )}

        {tournamentContent?.content?.bottomText && (
          <ReactMarkdown>
            {tournamentContent?.content?.bottomText}
          </ReactMarkdown>
        )}
      </container-div>
    </Div>
  );
};
const Div = styled.div`
  padding-top: 20px;
  text-align: left;
`;
const ContentText = styled.div`
  p:first-child {
    padding: 0 20px 20px 20px;
    font-size: 18px;
    line-height: 26px;
    margin: 0px auto;
    max-width: 960px;
  }
`;
const ContentCenteredText = styled.div`
  p:first-child {
    padding: 0 20px 20px 20px;
    font-size: 18px;
    line-height: 26px;
    margin: 0px auto;
    max-width: 960px;
  }
`;
const ContentTextSmall = styled.div`
  text-align: center;
`;
const ImageTextWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
  margin: 0 auto 40px auto;
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }

  p {
    position: absolute;
    padding: 10px 30px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    font-weight: normal;
    text-align: center;
  }
  @media (max-width: 768px) {
    p {
      padding: 15px 20px;
      font-size: 14px;
    }
  }
`;

export default TournamentSubtabsContent;
