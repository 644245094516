import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../context/account/account.provider";
import { useTranslation } from "react-i18next";

const useCheckTokenExpiration = () => {
  const { logout } = useAccount();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        try {
          const payload = JSON.parse(atob(access_token.split(".")[1]));
          const expirationTime = payload.exp * 1000;
          const currentTime = Date.now();

          if (expirationTime <= currentTime) {
            localStorage.removeItem("access_token");
            logout();
            navigate(t("routes.homepage"));
          }
        } catch (error) {
          console.error("Error decoding token:", error);
          localStorage.removeItem("access_token");
          logout();
          navigate(t("routes.homepage"));
        }
      }
    };

    checkTokenExpiration();

    const tokenCheckInterval = setInterval(checkTokenExpiration, 3600000); // Check every hour

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [logout]);
};

export default useCheckTokenExpiration;
