import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const ContentWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  .row {
    justify-content: center !important;
  }
  .large-text {
    font-size: 1.2rem;
  }
`;

const BoxesWrapper = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
`;

const BoxesHeader = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
  padding: 15px;
  color: var(--white);
  background-color: var(--red);
  span {
    font-size: 0.9rem;
    display: block;
  }
`;

const ImageArrow = styled.div`
  width: 60px;
  margin: 10px auto;
  display: block;
`;

const TournamentEventContent = ({ data }) => {
  return (
    <ContentWrapper>
      {Object.keys(data).map((sectionKey, sectionIndex) => {
        const section = data[sectionKey];
        const { title, description, tournaments, satellites } = section;

        return (
          <div key={sectionIndex}>
            {/* Section Title and Description */}
            <Row>
              <Col md={12}>
                <ContentWrapper>
                  <h3 className="event-title">{title}</h3>
                  <ReactMarkdown className="large-text">
                    {description}
                  </ReactMarkdown>
                </ContentWrapper>
              </Col>
            </Row>

            {/* Tournaments */}
            {tournaments && (
              <Row>
                {tournaments.map((tournament, index) => (
                  <Col key={index} md={index % 2 === 0 ? 5 : 6}>
                    <BoxesWrapper>
                      <BoxesHeader>
                        {tournament.header}
                        <br />
                        <span>{tournament.subheader}</span>
                      </BoxesHeader>
                      <div>
                        <p>Buy-in: {tournament.buyIn}</p>
                        <p>
                          <strong>Prize</strong>: {tournament.prize}
                        </p>
                        {tournament.details && <p>{tournament.details}</p>}
                      </div>
                    </BoxesWrapper>

                    {/* {tournament.arrow && (
                      <Col md={1} className="d-none d-md-block">
                        <ImageArrow>
                          <ResponsiveImage src={tournament.arrow} alt="arrow" />
                        </ImageArrow>
                      </Col>
                    )} */}
                  </Col>
                ))}
              </Row>
            )}

            {/* Satellites */}
            {satellites && (
              <Row>
                {satellites.map((satellite, index) => (
                  <Col key={index} md={index % 2 === 0 ? 5 : 6}>
                    <BoxesWrapper>
                      <BoxesHeader>
                        {satellite.header}
                        <br />
                        <span>{satellite.subheader}</span>
                      </BoxesHeader>
                      <div>
                        <p>Buy-in: {satellite.buyIn}</p>
                        <p>
                          <strong>Prize</strong>: {satellite.prize}
                        </p>
                        {satellite.details && <p>{satellite.details}</p>}
                      </div>
                    </BoxesWrapper>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        );
      })}
    </ContentWrapper>
  );
};

export default TournamentEventContent;
