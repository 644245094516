import React from "react";
import ReactMarkdown from "react-markdown";
import {Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import TitleSection from "../TitleSection/TitleSection";

const TabContent = ({ categoryKey, t }) => {
  // const { t } = useTranslation();
  const translationPath = `eatAndPlay.${categoryKey}.items`;
  const items = t(translationPath, { returnObjects: true });
  const categoryHeading = t(`eatAndPlay.${categoryKey}.heading`);
  const categoryDescription = t(`eatAndPlay.${categoryKey}.description`);

  // Function to replace {{star}} marker with Markdown image syntax
  const formatSide = (side) => {
    if (side.includes("{{star}}")) {
      return side.replace(
        "{{star}}",
        `![star](${"https://assets.playground.ca/pgfe/img/rail/star-cash.png"})`
      );
    }
    return side;
  };

  return (
    <container-fluid>
      <TitleSection
        as={'h2'}
        title={t(categoryHeading)}
        text={t(categoryDescription)}
        className="pt-2"
      />
      <Row className="gx-4 gy-4">
        {items?.map((item, index) => (
          <Col key={index} lg={4} md={6} sm={12}>
            <GridItem>
              <div className="item-header">
                {categoryKey !== "tournamentBreakfast" && categoryKey !== "tournamentMenu" && (
                  <p>
                    <span>
                      <ResponsiveImage
                        src={"rail/star-cash.png"}
                        alt="star-cash"
                      />{" "}
                      {item.availableOnTables
                        ? item.availableOnTables
                        : t(
                            "eatAndPlay.availableOnTables",
                            "Available on tables"
                          )}
                    </span>
                    <span>{item.tablesOfChoice && item.tablesOfChoice}</span>
                  </p>
                )}
                {item.imageUrl && (
                  <ResponsiveImage src={item.imageUrl} alt={item.title} />
                )}
                <h3>{item.title}</h3>
              </div>
              <ReactMarkdown>{item.description}</ReactMarkdown>
              {item.options?.map((option, ind) => (
                <div className="item-row" key={ind}>
                  <h6>
                    <ReactMarkdown
                      components={{
                        p: ({ node, ...props }) => <>{props.children}</>,
                      }}
                    >
                      {formatSide(option.name)}
                    </ReactMarkdown>{" "}
                    <span className="red">
                      {option?.price ?? option?.price}
                    </span>{" "}
                    <br />
                  </h6>
                  <ReactMarkdown>
                    {formatSide(option.description)}
                  </ReactMarkdown>
                </div>
              ))}
              {item?.sides?.description && (
                <RedBox className="item-row">
                  <h6>{item?.sides?.name}</h6>
                  {item?.sides.description.split(", ").map((side, i) => (
                    <ReactMarkdown key={i}>{formatSide(side)}</ReactMarkdown>
                  ))}
                </RedBox>
              )}
            </GridItem>
          </Col>
        ))}
      </Row>
    </container-fluid>
  );
};

// GridItem styled component for each item
const GridItem = styled(Col)`
  break-inside: avoid; /* Prevent item from breaking between columns */
  margin-bottom: 1rem;
  background: var(--lightblack);
  padding: 20px;

  & img {
    width: 24px;
    height: 24px;
  }

  & > p {
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
  }

  div.item-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    & > p {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 20px;
      font-weight: 200;
      font-size: 14px;
      text-align: center;
      margin-bottom: 15px;
      img {
        margin-right: 10px;
        display: inline-block;
        position: relative;
        bottom: 2px;
      }
    }

    & > img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 230px;
    }

    & > h3 {
      margin: 0;
      text-align: center;
      background: var(--red);
      padding: 10px;
      text-transform: uppercase;
      font-size: 18px;
    }
  }

  & > h3 {
    margin: 0;
    margin-top: 20px;
  }

  .item-row {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid var(--rewards_grid);
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    h6 {
      margin: 3px 0 5px;
      text-transform: uppercase;
      line-height: normal;
      img {
        position: relative;
        bottom: 2px;
      }
    }
    p {
      margin-top: 5px;
      font-size: 14px;
      color: var(--mutedwhite);
      img {
        height: 20px;
        width: 20px;
        vertical-align: text-top;
      }
    }

    & > span {
      color: var(--red);
    }
  }

  @media screen and (max-width: 991px) {
    padding: 10px;
    margin-top: 20px;
  }
`;

const RedBox = styled.div`
  position: relative;
  padding: 20px 40px 30px !important;
  background: var(--mutedred);
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
  & > h6 {
    margin: 0;
    font-size: 18px;
    text-transform: uppercase;
  }
  & > p {
    margin: 5px 0 0 0;
    font-size: 14px;
    font-weight: normal !important;
  }
`;

export default TabContent;
