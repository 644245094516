import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const PlaygroundCaresFirst = () => {
  const { t } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl("playground_cares/mack_bg2.jpg");
  return (
    <FirstPart bgImage={responsiveBgImage}>
      <div>
        <ResponsiveImage
          src="promotions/mack_bg_mobile.jpg"
          alt="mack_bg_mobile"
        />
        <h1>
          {t("pg-cares.title-pg")}
          <br />
          {t("pg-cares.title-cares")}
        </h1>
        <h2>{t("pg-cares.header-text")}</h2>
      </div>
    </FirstPart>
  );
};
const FirstPart = styled.div`
  z-index: 0;
  height: fit-content;
  width: 100%;
  transition: 0.5s all;
  padding: 200px 100px;
  background: url(${({ bgImage }) => bgImage}) 0% 30% fixed no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  & > div {
    width: 100%;
    margin: 0 auto;
    & > img {
      display: none;
    }
    & > h1 {
      font-size: 125px;
      font-weight: 900;
      text-transform: uppercase;
      color: #fff;
      line-height: 110px;
      margin: 0px;
      margin-bottom: 10px;
    }
    & > h2 {
      max-width: 800px;
      color: #fff;
      font-weight: 400;
      font-size: 22px;
      margin: 0px;
      line-height: 33px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    padding: 0;
    background: none;
    & > div {
      background: var(--black);
      text-align: center;
      padding: 40px 0;
      margin: 0;
      position: relative;
      & > img {
        display: block;
        max-width: 100%;
        height: auto;
      }
      & > h1 {
        font-size: 40px;
        line-height: 42px;
        padding: 0 20px;
      }
      & > h2 {
        font-size: 19px;
        line-height: 30px;
        padding: 0 20px;
      }
    }
  }
`;
export default PlaygroundCaresFirst;
