import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap';
import Button from "../Button/Button";
import styled from 'styled-components';
import { ScrollPositionContext } from 'src/context/scrollPosition/scrollPosition.provider';

const ScrollToTabs = ({tabs, isActive, scrollFix }) => {
  const { setScrollPosition } = useContext(ScrollPositionContext);
  return (
    <TabsRow>
        {Object.entries(tabs).map(([category, tab], index) => (
          <Col key={`col_${index}`}>
            <Button
              href={tab?.url}
              className={isActive === category ? "active" : undefined}
              label={tab?.label}
              hoverBgColor="var(--red)"
              hoverColor="var(--white)"
              bgColor="var(--mutedwhite)"
              color="var(--black)"
              onClick={() => setScrollPosition(false)}
              fluid={true}
            />
          </Col>
        ))}
      </TabsRow>
  )
}
const TabsRow = styled(Row)`
  white-space: nowrap;
  a {
    margin: 5px 0;
  }
  button {
    margin: 0;
    transition: ease-in-out 0.4s;
    &:hover {
      background: var(--red);
      color: var(--white);
    }
    &.active {
      background: var(--red);
      color: var(--white);
    }
  }
`;
export default ScrollToTabs