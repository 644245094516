import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { IoSearch } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";

import { leaderboards } from "src/api/poker";
import ResponsiveImage  from "src/components/ImageWrapper/ResponsiveImage";
import Hero             from "src/components/Hero/Hero";
import SubTitleSection  from "src/components/SubTitleSection/SubTitleSection";
import { formatAmount } from "src/utils/formatters";

const TournamentLeaderboard = () => {
  const { t, i18n } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [jsonData, setJsonData] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result;
      const parsedData = Papa.parse(csvData, { header: true }).data;
      setJsonData(parsedData);
    };

    reader.readAsText(file);
  };
  const handleInputValue = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const data = await leaderboards("tlb");
        setLeaderboardData(data);
      } catch (error) {
        console.error("Failed to fetch leaderboard data:", error);
      }
    };

    fetchLeaderboard();
  }, []);
  return (
    <>
      <MainDiv>
        <Hero
          bgColor=""
          bgImage="promotions/tournament-leaderboad-bg-2023.jpg"
          buttonLink=""
          buttonText=""
          description={t("poker.tlb.dateRange")}
          descriptionBgColor=""
          image=""
          imageAlt=""
          mainHeroText={t("poker.tlb.tournamentLeaderboard_h1")}
          title=""
        />
        <container-div>

          <SubTitleSection subTitle={t("poker.tlb.prizeAnnouncement")} />

          <ReactMarkdown>{t("poker.tlb.prizeDescription")}</ReactMarkdown>
          <ReactMarkdown>{t("poker.tlb.climbLeaderboard")}</ReactMarkdown>

          <TableDiv>
            <div>
              <ResponsiveImage
                src="promotions/tlb_header_v2.png"
                alt="tlb_header_v2"
              />
              <div>
                <input
                  onChange={handleInputValue}
                  type="text"
                  placeholder={t("poker.tlb.searchPlaceholder")}
                />
                <IoSearch />
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  {leaderboardData?.headers?.map((el, ind) => (
                    <th key={el}>{t(`poker.tlb.${el}`)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {leaderboardData?.rows?.map((el) =>
                  searchQuery === "" ||
                  (el.player_name &&
                    el.player_name.toLowerCase().includes(searchQuery.toLowerCase())) ? (
                    <tr key={el.player_name}>
                      <td>{el.rank}</td>
                      <td>{el.player_name}</td>
                      <td>{formatAmount(el.tlb_points, i18n.language, false)}</td>
                      <td>{el.prize}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </TableDiv>
          <div className="tlb_rules">
            <ReactMarkdown>{t("poker.tlb.prizeDisclaimer1")}</ReactMarkdown>
            <ReactMarkdown>{t("poker.tlb.prizeDisclaimer2")}</ReactMarkdown>
          </div>
          <SubTitleSection subTitle={t("poker.tlb.scoringInfoTitle")} />

          <div className="tlb_rules">
            <ReactMarkdown>{t("poker.tlb.scoringInfoDescription")}</ReactMarkdown>
            <ReactMarkdown>{t("poker.tlb.contactInfo")}</ReactMarkdown>
          </div>
        </container-div>
      </MainDiv>
    </>
  );
};
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  background: var(--black);
  .main-hero-text h1 {
    font-size: clamp(1rem, 4vw + 1rem, 4rem);
    padding: clamp(1rem, 4vw + 1rem, 4rem) 1rem;
  }
  p {
    color: var(--white);
  }
  .tlb_rules p {
    color: var(--grey);
  }
`;

const TableDiv = styled.div`
  margin: 40px auto;
  border: 1px solid var(--lightblack);
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  & > div:first-child {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    justify-content: stretch;
    align-items: center;
    & > img {
      width: 100%;
    }
    & > div:last-child {
      width: 100%;
      position: relative;
      height: auto;
      & > input {
        --webkit-appearance: none;
        border: 1px solid #ccc;
        border-radius: 20px;
        color: #000;
        height: 36px;
        padding: 0 32px 0 12px;
        margin: 0;
        width: 100%;
      }
      & > svg {
        position: absolute;
        width: 20px;
        height: 100%;
        top: 0;
        right: 10px;
        text-align: center;
        cursor: pointer;
        color: var(--grey);
      }
    }
  }
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        border-top: 1px solid var(--lightblack);
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        & > td {
          border-right: 1px solid var(--lightblack);
          border-top: none;
          text-align: center;
          color: var(--white);
          font-size: 15px;
          padding: 8px;
          line-height: 1.42857143;
          & > strong {
            color: var(--red);
          }
          &.million {
            background-color: var(--red);
            & > strong {
              color: var(--white);
            }
          }
          &.seats {
            background-color: rgba(255, 255, 255, 0.1);
            & > strong {
              color: var(--white);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px 10px 40px 10px;
    & > div:first-child {
      grid-template-columns: 1fr;
      gap: 0;
      & > div:last-child {
        width: 100%;
        margin: 20px auto;
      }
    }
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 10px;
            padding: 5px;
            &.hide-col {
              display: none;
            }
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 10px;
            padding: 5px;
            font-weight: 300;
            &.hide-col {
              display: none;
            }
          }
        }
      }
    }
  }
`;
export default TournamentLeaderboard;
