import React from "react";
import { Helmet } from "react-helmet-async";
const imgUrl = process.env.REACT_APP_PGFE_IMG_ASSETS_PATH;

const LdJson = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "Playground",
            "image": "${imgUrl}/header/playground.png",
            "url": "https://www.playground.ca",
            "telephone": "+1-450-635-7653",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1500 Unit C Route 138",
              "addressLocality": "Kahnawake",
              "addressRegion": "QC",
              "postalCode": "J0L 1B0",
              "addressCountry": "CA"
            },
            "location": {
              "@type": "Place",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "45.22615",
                "longitude": "-73.42381"
              }
            }
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "closes": "23:59:59",
            "opens": "00:00:00",
            "name": "Playground Hours"
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "url": "https://www.playground.ca",
            "logo": "${imgUrl}/header/playground.png",
            "contactPoint": [{
                "@type": "ContactPoint",
                "telephone": "+1-855-685-7653",
                "contactType": "customer service"
            }],
            "sameAs": [
                "https://www.facebook.com/playgroundYUL/",
                "https://twitter.com/PlaygroundYUL",
                "https://www.instagram.com/playgroundYUL/"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default LdJson;
