// components/Auth/TopLogo.jsx
import React from "react";
import styled from "styled-components";
import ImageLink from "../ImageWrapper/ImageLink";
import { t } from "i18next"

const CenteredImageLink = styled(ImageLink)`
  & > img {
    max-width: 300px;
  }
`;

const TopLogo = () => (
  <CenteredImageLink
    to={t("routes.homepage")}
    imgSrc={"svg/PLAYGROUND-LOGO-HORIZONTAL_WHITE_TEXT.svg"}
    alt="PG logo"
    className={`mt-3 mx-auto `}
  />
);

export default TopLogo;
