// scrollPosition.actions.js
export const scrollPositionActionTypes = {
  SET_SCROLL_POSITION: 'SET_SCROLL_POSITION',
};

// Action creator
export const setScrollPosition = (position) => ({
  type: scrollPositionActionTypes.SET_SCROLL_POSITION,
  payload: position,
});
