import React from "react";
import styled from "styled-components";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const BlogHeader = ({ title }) => {
  return (
    <BlogHeaderContainer
      bgImage={useResponsiveImageUrl(
        "playground_cares/generic_playground_cares_story_header.jpg"
      )}
    >
      <container-div>
        <h1 className="blog-title">{title}</h1>
      </container-div>
    </BlogHeaderContainer>
  );
};
const BlogHeaderContainer = styled.div`
  background: url(${(props) => props.bgImage}) no-repeat;
  background-position: 50% 50%;
  padding: 150px 0px 110px;
  background-size: cover;
  z-index: 1;
  position: relative;
  border-bottom: 1px solid #222;
  width: 100%;
  height: fit-content;
  &::after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
  .blog-title {
    text-align: center;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #fff;
  }
  @media screen and (max-width: 991px) {
    padding: 130px 0 90px 0;
    .blog-title {
      font-size: 40px;
      line-height: 40px;
      padding: 0 15px;
    }
  }
`;
export default BlogHeader;
