import React, { useEffect } from 'react';
import styled from 'styled-components';
import SubTitleSection from "../SubTitleSection/SubTitleSection";

const InstagramWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <WidgetContainer>
      <SubTitleSection subTitle="Instagram" as={'h4'} dark="false" />
      <iframe
        src="https://cdn.lightwidget.com/widgets/6779989c92d550b29bdd437eab880725.html"
        scrolling="no"
        allowtransparency="true"
        className="lightwidget-widget"
        style={{ width: "100%", border: "0", overflow: "hidden", height: "65px" }}
        title="Instagram Widget"
      />
    </WidgetContainer>
  );
};

const WidgetContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0;
  padding: 8px 20px 20px 20px;
  @media (min-width: 992px) {
    margin-left: 60%;
    margin-top: -6rem;
    width: 40%;
  }
  .subtitle-section {
    padding-top: 0;
    h4 {
      padding: 0;
      margin: 0;
      font-weight: 300;
      font-size: 20px;
      color: var(--white);
      text-transform: uppercase;
      border: none;
    }
  }
  iframe {
    width: 100%;
  }
`;

export default InstagramWidget;
