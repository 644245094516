import React from "react";
import { useHomepage } from "src/context/homepage/homepage.provider";
import styled from "styled-components";

import BbjHero from "src/components/Poker/BbjHero";
import TitleSection from "src/components/TitleSection/TitleSection";
import Row from "react-bootstrap/Row";
import PokerJackpotRulesCard from "src/components/Poker/PokerJackpotRulesCard";

import { useTranslation } from "react-i18next";
import { formatAmount, formatDate } from "src/utils/formatters";
import { toUrlFriendlyString } from "src/utils/formatters";

const BadBeatJackpots = () => {
  const { bbjData, omahaData, highstakesData } = useHomepage();
  const { i18n, t } = useTranslation();
  const omahaRulesButton = {
    label: t("poker.badBeatJackpot.readRules"),
    href: t("routes.badBeatJackpotRules") + '#' + toUrlFriendlyString(t("pokerRules.omahaJackpot")),
    hoverBgColor: "black",
    hoverColor: "white",
    size: "medium",
    width: "wide"
  }
  const highStakesRulesButton = {
    label: t("poker.badBeatJackpot.readRules"),
    href: t("routes.badBeatJackpotRules") + '#' + toUrlFriendlyString(t("pokerRules.highStakesJackpot")),
    hoverBgColor: "black",
    hoverColor: "white",
    size: "medium",
    width: "wide"
  }
  return (
    <>
      <JackpotPage>
        <BbjHero bbjData={bbjData} />
        <container-fluid>
          <TitleSection as={'h2'} title={t("poker.badBeatJackpot.moreInfoTitle")} dark className="pb-3" />
          <Row xs={1} md={2} className="g-4 mt-2 mb-3">
            <PokerJackpotRulesCard
              className="omaha-card"
              amount={formatAmount(omahaData?.amount, i18n.language)}
              title={t("poker.badBeatJackpot.omahaJackpot")}
              text={`${t("poker.badBeatJackpot.minimumQualifyingHandAlways")} **${omahaData?.min_qualifying_hand}** \n\n ${t("poker.badBeatJackpot.lastJackpotHit")} **${formatDate(omahaData?.last_hit_at, i18n.language)}**`}
              buttonSettings={omahaRulesButton} />
            <PokerJackpotRulesCard
              className="high-stakes-card"
              amount={formatAmount(highstakesData?.amount, i18n.language)}
              title={t("poker.badBeatJackpot.highStakesJackpot")}
              text={`${t("poker.badBeatJackpot.minimumQualifyingHand")} **${t(`poker.bbj.${highstakesData?.min_qualifying_hand || "loading"}`)}**`}
              buttonSettings={highStakesRulesButton} />
          </Row>
        </container-fluid>
        
      </JackpotPage>
      
    </>
  );
};
const JackpotPage = styled.div`
  background: #e6e6e6;
`;

export default BadBeatJackpots;
