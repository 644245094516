import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Cookies } from 'react-cookie-consent';
import ReactMarkdown from 'react-markdown';
import Button from "src/components/Button/Button";
import styled from "styled-components";
import { t } from 'i18next';

// Shared cookie settings
const cookieSettings = {
  expires: 30,  // 1 month
  sameSite: "Strict",
  secure: true,
};

const cookieName = "fraudModalPopup";

const FraudWarningModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Check if the fraudModalPopup cookie is not set
    const fraudPopupCookie = Cookies.get(cookieName);
    if (!fraudPopupCookie) {
      setShowModal(true);
    }
  }, []);

  const handleClose = () => {
    // Set the cookie with 1 month expiration
    Cookies.set(cookieName, "true", cookieSettings);
    setShowModal(false);
  };

  return (
    <StyledModal show={showModal} onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="fraud-warning-modal"
      >
      <Modal.Header>
        <Modal.Title>{t("fraudWarningModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactMarkdown>
          {t("fraudWarningModal.message")}
        </ReactMarkdown>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}
          bgColor="var(--mustard)"
          color="var(--lightblack)"
          hoverColor="var(--white)"
          hoverBgColor="var(--red)"
          label={t("fraudWarningModal.button")}
        />
      </Modal.Footer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-header {
    background-color: var(--mustard);
    .modal-title {
      color: var(--lightblack);
      font-weight: 700;
      text-align: center;
    }
  }
  .modal-content, .modal-header {
    border-radius: 0;
  }
  .modal-footer {
    button {
      margin: 0;
    }
  }
`;

export default FraudWarningModal;
