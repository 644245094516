import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { useErs } from "src/context/ers/ers.provider";
import { formatAmount } from "src/utils/formatters";
import ReactMarkdown from "react-markdown";

const MegaMysteryHighHand = () => {
  const { i18n, t } = useTranslation();
  const { data, fetchData } = useErs();
  const lng = i18n.language;

  useEffect(() => {
    fetchData("mystery-high-hand");
  }, [fetchData, i18n.language]);

  // Function to add a dollar sign to the amount
  const addDollarSign = (amount) => formatAmount(amount, lng);

  const mysteryHighHands = data["mystery-high-hand"] || [];

  return (
    <MysteryHighHandDiv>
      <Hero
        bgImage="promotions/mega_mystery_high_hand_bg_lp.jpg"
        descriptionBgColor="#6e06064d"
        image={`promotions/mystery_high_hand_logo_lp_${i18n.language}.png`}
        imageAlt="Mystery High Hand logo"
      >
        <h1>{t("poker.mega-mystery-high-hand.title")}</h1>
        <p className="hero-content">{t("poker.mega-mystery-high-hand.content")}</p>
        <h2>{t("poker.mega-mystery-high-hand.dates")}</h2>
        <div className="hero-box">
          <p className="bonus">
            {t("poker.mega-mystery-high-hand.bonus")}
          </p>
          <p className="draws">
            {t("poker.mega-mystery-high-hand.hotSeatDraws")}
          </p>
          <p className="mega-prizes">
            {t("poker.mega-mystery-high-hand.megaPrizes")}
          </p>
        </div>
        <ReactMarkdown>
          {t("poker.mega-mystery-high-hand.intro")}
        </ReactMarkdown>
      </Hero>
      <div className="need-to-know">
        <h2>{t("poker.mega-mystery-high-hand.needToKnow")}</h2>
        <ReactMarkdown>
          {t("poker.mega-mystery-high-hand.eligibility")}
        </ReactMarkdown>
        <ReactMarkdown>
          {t("poker.mega-mystery-high-hand.continuousExcitement")}
        </ReactMarkdown>
        <ReactMarkdown>
          {t("poker.mega-mystery-high-hand.exclusiveSeptember")}
        </ReactMarkdown>
      </div>
      <div className="cash-prizes">
        <h2>{t("poker.mega-mystery-high-hand.cash-prize-available")}</h2>
        <table>
          <thead>
            <tr>
              <th>{t("poker.mega-mystery-high-hand.remaining")}</th>
              <th>{t("poker.mega-mystery-high-hand.amount")}</th>
            </tr>
          </thead>
          <tbody>
            {mysteryHighHands?.map((highHand, index) => (
              <tr key={index}>
                <td>{highHand.remaining}</td>
                <td>{addDollarSign(highHand.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="hours">
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.newHours")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.period-one")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.winning-one")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.period-two")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.winning-two")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.period-three")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.mega-mystery-high-hand.winning-three")}</ReactMarkdown>
        <container-div>
          <div className="info-box">
            <ReactMarkdown>
              {t("poker.mega-mystery-high-hand.info-box")}
            </ReactMarkdown>
            <ReactMarkdown>
            {t("poker.mega-mystery-high-hand.info-box-link").replace("{url}", t("routes.cashGames"))}
            </ReactMarkdown>

          </div>
        </container-div>
      </div>

      <div className="rules">
        <h2>{t("poker.mega-mystery-high-hand.general-rules")}</h2>
        <ol>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-1")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-2")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-3")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-4")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-5")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-6")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-7")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-8")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-9")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-10")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-11")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-12")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-13")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-14")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-15")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-16")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-17")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-18")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-19")}</ReactMarkdown></li>
          <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-20")}</ReactMarkdown></li>
          <li>
            <ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22")}</ReactMarkdown>
            <ol type="a">
              <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-1")}</ReactMarkdown>
                <ol type="i">
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-1-i")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-1-ii")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-1-iii")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-1-iv")}</ReactMarkdown></li>
                </ol>
              </li>
              <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-2")}</ReactMarkdown>
                <ol type="i">
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-2-i")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-2-ii")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-2-iii")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-2-iv")}</ReactMarkdown></li>
                </ol>
              </li>
              <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-3")}</ReactMarkdown>
                <ol type="i">
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-3-i")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-3-ii")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-3-iii")}</ReactMarkdown></li>
                  <li><ReactMarkdown>{t("poker.mega-mystery-high-hand.general-rules-ol-22-a-3-iv")}</ReactMarkdown></li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </MysteryHighHandDiv>
  );
};
const MysteryHighHandDiv = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;
  .hero_text_container {
    h1 {
      font-weight: 900;
    }
    .hero-content {
      margin-top: 0.5rem;
      font-size: 32px;
    }
    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-top: 0;
    }
    .hero-box {
      background-color: #FC400D;
      max-width: 420px;
      margin: 0 auto 20px;
      padding: 15px;
      width: 100%;
      color: #fff;
      font-weight: 700;
      text-align: center;
      .bonus {
        margin-top: 0px;
        padding-bottom: 0px;
        line-height: 45px;
        font-size: 45px;
      }
      .draws {
        font-size: 18px;
        margin-top: -14px;
      }
      .mega-prizes {
        font-family: 'Poppins';
        background-color: #000;
        padding: 5px;
        font-size: 20px;
        font-weight: 700;
        display: block;
      }
    }
  }

  div.need-to-know {
    padding: 60px 20px 0;
    width: 100%;
    max-width: 1440px;
    height: auto;
    margin: 0 auto;
    color: var(--white);
    h2 {
      font-weight: 700;
      font-size: 20px;
      margin-top: 0px;
      padding-bottom: 20px;
      text-transform: uppercase;
    }
  }
  div.cash-prizes {
    h2 {

      background: rgb(170, 11, 11);
      background: linear-gradient(
        45deg,
        rgba(170, 11, 11, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(170, 11, 11, 1) 100%
      );
      color: var(--white);
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      padding: 15px;
      max-width: 500px;
      font-size: 30px;
      margin: 3rem auto 1rem;
      border-radius: 5px;
      line-height: 25px;
    }
    table {
      width: 100%;
      max-width: 100%;
      margin: 20px 0px 40px 0px;
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--lightblack);
      border-spacing: 0;
      border-collapse: collapse;
      & > thead {
        & > tr {
          & > th {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            width: 50%;
            background-color: #333;
            border: 1px solid #222;
            padding: 8px;
            line-height: 1.42857143;
          }
        }
      }
      & > tbody {
        & > tr {
          & > td:first-child {
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            width: 50%;
            color: var(--white);
            background-color: #230202;
            border: 1px solid #222;
            padding: 8px;
            line-height: 1.42857143;
          }
          & > td:last-child {
            background-color: #aa0b0b;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            width: 50%;
            color: #fff;
            border: 1px solid #222;
            padding: 8px;
            line-height: 1.42857143;
          }
        }
      }
    }
  }
  div.hours {
    padding-bottom: 1rem;
    h2 {
      font-weight: 700;
      color: #aaa;
      text-transform: uppercase;
      text-align: center;
    }
    p {
      margin: 0 0 10px;
      font-size: 18px;
      color: #aaa;
      text-align: center;
    }
    div.info-box {
      margin-top: 2rem;
      background-color: #333;
      padding: 20px;
      p {
        color: #aaa;
      }
    }
  }
  div.rules {
    padding: 40px 20px;
    background-color: #111;
    margin-top: 40px;
    h2 {
      font-weight: 700;
      color: #999;
      text-transform: uppercase;
      text-align: center;
      padding: 15px;
      font-size: 30px;
      margin: 5px 0 10px 0;
    }
    & > ol {
      margin-top: 0;
      margin-bottom: 10px;
      & > li {
        font-size: 16px;
        color: #999;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      & > img {
        width: 100%;
      }
      & > div {
        & > h2 {
          padding: 10px;
          font-size: 20px;
          line-height: 1.35em;
        }
      }
    }
  }
`;
export default MegaMysteryHighHand;
