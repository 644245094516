import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

// Styled components for the layout
const Wrapper = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 24px;
`;

const Description = styled.div`
  margin-bottom: 20px;
  font-size: 16px;

  p {
    margin: 0 0 10px 0;
  }
`;

const ImageWrapper = styled.div`
  flex: 0 0 40%;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const DetailsWrapper = styled.div`
  flex: 0 0 58%;
`;

const DetailsList = styled.div`
  margin-bottom: 20px;
  text-align: left;
  p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  a {
    color: blue;
    text-decoration: none;
  }
`;

const MapIframe = styled.iframe`
  width: 100%;
  height: 140px;
  border: 0;
`;

const TournamentHotelCard = ({
  title,
  description,
  imageUrl,
  details,
  mapSrc,
}) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <div style={{ flex: "1 1 100%" }}>
          <Title>
            <ReactMarkdown>{title}</ReactMarkdown>
          </Title>
          <Description>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Description>
        </div>

        <ImageWrapper>
          <ResponsiveImage src={imageUrl} alt={title} />
        </ImageWrapper>

        <DetailsWrapper>
          <DetailsList>
            <ReactMarkdown>{details}</ReactMarkdown>
          </DetailsList>

          <MapIframe src={mapSrc} allowFullScreen loading="lazy"></MapIframe>
        </DetailsWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default TournamentHotelCard;
