import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const BigGameBash2024 = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv>
      <div>
        <ResponsiveImage
          src={`promotions/bgb-header-${i18n.language}.jpg`}
          alt="bgb-header-en"
        />
      </div>
      <div>
        <h1 style={{textAlign: 'center'}}>
          {i18n.language === "en" ? (
            <>
              Big Game Bash 2024
            </>
          ) : (
            <>
              Grand Match 2024
            </>
          )}
        </h1>
        {i18n.language === "en" ? (
          <div>
            <p>
              Enjoy the ultimate <strong>Big Game Bash</strong> experience at
              Playground. Catch all the action on our{" "}
              <strong>giant screen located on our 2nd floor</strong>{" "}
              Entertainment area or on any of our{" "}
              <strong>over 100 4K TV’s</strong> located throughout the
              Playground complex.
            </p>
            <p>
              Our <strong>Big Game viewing party</strong> offers a variety of
              seating options with an{" "}
              <strong>all-you-can-eat tailgate buffet</strong> and beer
              specials!
            </p>
            <p>
              We will also be turning up the heat during half-time with{" "}
              <strong>Hot Seat draws every 5 minutes</strong> with a chance to{" "}
              <strong>win up to $250 in Free Play!</strong>
            </p>
          </div>
        ) : (
          <div>
            <p>
              Profitez de l'expérience ultime du <strong>Grand Match</strong> au
              Playground. Regardez toute l'action sur notre{" "}
              <strong>écran géant situé au 2e étage</strong> de notre zone de
              divertissement ou sur l'une de{" "}
              <strong>nos 100 télévisions 4K</strong> réparties dans tout le
              complexe Playground.
            </p>
            <p>
              Notre <strong>fête de visionnement du Grand Match</strong> offre
              une variété de places assises avec un{" "}
              <strong>buffet à volonté</strong> et des bières en promotion.
            </p>
            <p>
              Nous ferons également monter la température pendant la mi-temps
              avec des{" "}
              <strong>tirages par sièges chanceux toutes les 5 minutes</strong>{" "}
              avec une chance de{" "}
              <strong>gagner jusqu'à 250$ en Crédits-jeu!</strong>
            </p>
          </div>
        )}
      </div>
      <div>
        <div>
          {i18n.language === "en" ? (
            <div>
              <h2>Big Game viewing party</h2>
              <p>
                <strong>$40 per person includes $10 Free Play</strong>
              </p>
              <h4>Buffet</h4>
              <p>
                Supreme Nachos Station | Curly Fries | Pulled Pork Sandwiches |
                Poutine Station | BBQ Chicken Slices | Loaded Potato Skins |
                Mini Muffuletta Sandwiches | Chicken Tenders Fried Pickles |
                Mini Pogos | Mozzarella Sticks.
              </p>
              <h4>Beer Specials</h4>
              <p>
                Canadian Beer Bucket - <strong>6 for $20</strong>
              </p>
              <p>
                Imported Beer Bucket - <strong>6 for $25</strong>
              </p>
            </div>
          ) : (
            <div>
              <h2>Fête de visionnement du Grand Match</h2>
              <p>
                <strong>40$ par personne, inclut 10$ en crédits-jeu</strong>
              </p>
              <h4>Buffet</h4>
              <p>
                Station de Nachos Suprême | Frites bouclées | Sandwichs au Porc
                Effiloché | Station de Poutine | Tranches de poulet BBQ |
                Pelures De Pommes De Terre Chargées | Mini-sandwichs Muffuletta
                Filets de Poulet | Cornichons Frits | Mini Pogos | Bâtonnets de
                Mozzarella.
              </p>
              <h4>Bières en promotion</h4>
              <p>
                Seau à bière canadienne - <strong>6 pour 20$</strong>
              </p>
              <p>
                Seau à bière importée - <strong>6 pour 25$</strong>
              </p>
            </div>
          )}
          <ResponsiveImage src="promotions/bgb-image1.jpg" alt="bgb-image1" />
        </div>
      </div>
      <div>
        <div>
          <ResponsiveImage src="promotions/bgb-image2.jpg" alt="bgb-image2" />
          {i18n.language === "en" ? (
            <div>
              <h2>Half-Time Hot Seats</h2>
              <p>
                As soon as the game clock hits 00:00 we will usher in Hot Seat
                Draws every 5 minutes until the 2nd half kick off! Winners will
                collect tier specific prizes. So make sure you are on your
                favorite machine.
              </p>
              <h4>
                <strong>Silver: $25 Free in Play</strong>
              </h4>
              <h4>
                <strong>Gold: $50 Free in Play</strong>
              </h4>
              <h4>
                <strong>Red: $100 Free in Play</strong>
              </h4>
              <h4>
                <strong>Black: $250 Free in Play</strong>
              </h4>
            </div>
          ) : (
            <div>
              <h2>La mi-temps des sièges chanceux</h2>
              <p>
                Dès que l'horloge du match affichera 00:00, nous procéderons à
                des tirages par sièges chanceux toutes les 5 minutes jusqu'au
                coup d'envoi de la seconde mi-temps! Les gagnants recevront des
                prix en fonction de leur niveau de joueur. Assurez-vous donc
                d'être sur votre machine préférée.
              </p>
              <h4>
                <strong>Argent : 25$ en Crédits-jeu</strong>
              </h4>
              <h4>
                <strong>Or : 50$ en Crédits-jeu</strong>
              </h4>
              <h4>
                <strong>Rouge : 100$ en Crédits-jeu</strong>
              </h4>
              <h4>
                <strong>Noir : 250$ en Crédits-jeu</strong>
              </h4>
            </div>
          )}
        </div>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  /* position: relative; */
  background: var(--black);
  color: var(--white);
  & > div:first-child {
    height: auto;
    width: 100%;
    position: relative;
    z-index: 1;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }
  & > div:nth-child(2) {
    height: auto;
    width: 100%;
    & > div {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      clear: both;
      text-align: center;
      padding-bottom: 40px;
      & > p {
        padding: 0 20px 20px 20px;
        font-size: 18px;
        line-height: 26px;
        margin: 0px auto;
        max-width: 960px;
      }
    }
  }
  & > div:nth-child(3) {
    height: auto;
    width: 100%;
    background: var(--lightblack);
    padding: 40px 0;
    overflow-x: hidden;
    & > div {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      gap: 20px;
      & > div {
        height: auto;
        width: 100%;
        max-width: 600px;
        & > h2 {
          font-size: 30px;
          font-weight: 700;
          margin: 0 0 10px 0;
        }
        & > p {
          margin: 0 0 10px 0;
        }
        & > p:nth-child(2) {
          font-size: 16px;
          & > strong {
            background-color: #573e99;
            padding: 5px 10px;
          }
        }
        & > h4 {
          font-size: 18px;
          line-height: 25px;
          font-weight: 700;
          margin: 5px 0 10px 0;
        }
        & > p:nth-child(4) {
          font-size: 16px;
          border-bottom: 1px solid var(--red);
          padding-bottom: 20px;
        }
      }
      & > img {
        height: auto;
        width: 100%;
        max-width: 570px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  & > div:nth-child(4) {
    height: auto;
    width: 100%;
    padding: 40px 0;
    overflow-x: hidden;
    & > div {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      gap: 20px;
      & > img {
        height: auto;
        width: 100%;
        max-width: 570px;
        object-fit: cover;
        object-position: center;
      }
      & > div {
        height: auto;
        width: 100%;
        max-width: 600px;
        & > h2 {
          font-size: 30px;
          font-weight: 700;
          margin: 0;
        }
        & > p:nth-child(2) {
          font-size: 16px;
          border-bottom: 1px solid var(--red);
          padding-bottom: 20px;
        }
        & > h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 25px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      padding-top: 80px;
    }
    & > div:nth-child(3) {
      & > div {
        grid-template-columns: 1fr;
        padding: 0 20px;
      }
    }
    & > div:nth-child(4) {
      & > div {
        grid-template-columns: 1fr;
        padding: 20px;
      }
    }
  }
`;
export default BigGameBash2024;
