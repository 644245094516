import React from "react";
import styled from "styled-components";


import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const HollywoodNights = () => {
  const { i18n } = useTranslation();
  const lng = i18n.language;
  return (
    <PromoDetailDiv bgImage={useResponsiveImageUrl("promotions/lp_hollywood_nights_bg.jpg")}>
      <div>
        <ResponsiveImage
          src={`promotions/lp_hollywood_nights_logo_${lng}.png`}
          alt="Hollywood Nights"
        />
        <div>
          <div>
            <h2>
              <span>
                Rub shoulders with some of
                <br />
                Tinseltown's most beloved stars!
              </span>
              Every Friday night | As of 8:00pm
            </h2>
            <p>
              Our guests will have a chance to mingle with some of Hollywood's
              biggest stars, both past and present, as talented impersonators
              bring the icons to life.
            </p>
          </div>
        </div>
      </div>
      
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  background: #e6e6e6;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
    z-index: 0;
    height: fit-content;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    margin-bottom: 0px;
    & > img:first-child {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      height: fit-content;
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      padding: 60px 0px 40px;
      & > div {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        height: 100%;
        & > h2 {
          font-size: 32px;
          line-height: 40px;
          color: #fff;
          margin: 0;
          & > span {
            background-color: #fff;
            padding: 5px 30px;
            display: block;
            color: #000;
            margin-bottom: 15px;
          }
        }
        & > p {
          padding: 10px;
          margin: 0;
          font-size: 18px;
          line-height: 22px;
          color: #f1f1f1;
        }
      }
    }
  }
  @media screen and (min-width: 1992px) {
    /* start of desktop styles */
    & > div:first-child {
      & > img:first-child {
        max-width: 1440px;
      }
      & > div:nth-child(2) {
        & > div {
          max-width: 1440px;
        }
      }
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div:first-child {
      & > div:nth-child(2) {
        padding: 60px 20px 40px;
        & > div {
          & > h2 {
            font-size: 26px;
            line-height: 38px;
            padding: 0 10px;
          }
          & > p {
            padding: 10px 0;
          }
        }
      }
    }
  }
`;
export default HollywoodNights;
