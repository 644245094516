import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Button from "../Button/Button";
import ImageLink from "../ImageWrapper/ImageLink";
import Image from "../ImageWrapper/Image";

const PgCard = ({
  image,
  imageAlt,
  title,
  titleAs: TitleTag = "h3", // Default to h3 if not provided
  subtitle,
  subTitleAs: SubTitleTag = "h4", // Default to h4 if not provided
  text,
  textTitle,
  textTitleAs: TextTitleTag = "h4", // Default to h4 if not provided
  buttonSettings,
  className,
  variant='default',
  highlightTextColor,
  highlightColor
}) => {
  return (
    <StyledCard className={`pg-card ${variant} ${className}`}>
      {
        buttonSettings?.href ? (
          <ImageLink to={buttonSettings.href} imgSrc={image} alt={imageAlt} className="card-image-top"/>
        ) : (
          <Image imgSrc={image} alt={imageAlt} className="card-image-top"/>
        )
      }

      <CardTextWrapper className={`card-text-wrapper ${variant}`} highlightColor={highlightColor} highlightTextColor={highlightTextColor}>
        <Card.Header>
          <Card.Title>
            <TitleTag>{title}</TitleTag>
          </Card.Title>
            {subtitle &&
              <Card.Subtitle className="mb-2 text-muted">
                <SubTitleTag>{subtitle}</SubTitleTag>
              </Card.Subtitle>
            }
        </Card.Header>

        {(text || textTitle || buttonSettings) &&  (
          <Card.Body>
            {(text || textTitle) &&  (
              <Card.Text as={'div'}>
                {textTitle && <TextTitleTag>{textTitle}</TextTitleTag>}
                <p>
                  {text && text}
                </p>
              </Card.Text>
            )}
            {/* If buttonSettings is an array, iterate and create buttons in a row */}
            {Array.isArray(buttonSettings) ? (
              // If buttonSettings is an array, iterate and create buttons in a row
              <Row className="button-row gx-2">
                {buttonSettings.map((button, index) => (
                  <Col key={index} className={`${button.className} button-col`}>
                    <Button fluid width="fit" {...button} className={`${button.className} card-button`} />
                  </Col>
                ))}
              </Row>
            ) : (
              // If it's a single button, render one button
              buttonSettings && <Button fluid {...buttonSettings}  className={`${buttonSettings.className} card-button`} />
            )}
          </Card.Body>
        )}
      </CardTextWrapper>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  border-radius: 0;
  background: none;
  position: relative;
  height: 100%;
  .card-image-top {
    aspect-ratio: 16 / 9;
    border-radius: 0;
    overflow: hidden;
    img {
      opacity: 0;
      transform: scale(0.95); /* Start slightly zoomed out */
      transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
      &.loaded {
        opacity: 1;  /* Fade in */
        transform: scale(1.0001);  /* Zoom in */
      }
    }
  }
  &.portrait {
    .card-image-top {
      aspect-ratio: 3 / 5;
    }
  }
  a.card-image-top:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

const CardTextWrapper = styled.div`
  position: relative;
  top: -10px;
  margin: 0 auto;
  width: 90%;
  &.portrait {
    text-align: center;
    position: absolute;
    top: auto;
    bottom: 35px;
    margin: 0;
    left: 0;
    .card-header, .card-body {
      color: var(--white);
      background-color: rgba(0, 0, 0, 0.7);
    }
    .card-subtitle {
      h1, h2, h3, h4, > span {
        color: ${props => props.highlightTextColor || 'var(--red)'};
      }
    }
  }
  .card-header {
    padding-top: 1rem;
    border-bottom: 4px solid var(--red);
    border-bottom-color: ${props => props.highlightColor || 'var(--red)'};
    background-color: var(--white);
    flex-grow: 1;
    align-content: center;
    border-radius: 0;
  }
  .card-header, .card-body {
    color: var(--black);
  }
  .card-body {
    background: var(--lightblack);
    color: var(--white);
    flex-grow: 0;
    p {
      padding: 0.7rem 0;
    }
  }
  .card-button {
    margin: 0;
  }
  .card-title {
    h1, h2, h3, > span {
      font-size: 1.2rem;
      margin: 0;
      font-weight: 700;
    }
  }
  .card-subtitle {
    h1, h2, h3, h4, > span {
      font-size: 0.9rem;
      margin-top: 0.5rem;
      color: var(--red);
    }
  }
  .card-text {
    h4 {
      font-size: 1rem;
      margin: 0 0 1rem;
      padding: 0;
      font-weight: 700;
    }
    p {
      font-size: 0.9rem;
      margin: 0 0 1rem;
      padding: 0;
    }
  }
`;


export default PgCard;


