import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoSearch } from "react-icons/io5";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { getYearsArray, getAllPastEventsData } from "src/utils/tournamentUtils";
import { toUrlFriendlyString } from "src/utils/formatters";
import Hero from "src/components/Hero/Hero";
import PgCard from "src/components/Card/PgCard";

const PastEvents = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [allYearsData, setAllYearsData] = useState({});
  const { pastEventsNav, pastEventsNavFn } = useHomepage();
  const { i18n, t } = useTranslation();
  const yearsArray = getYearsArray();
  const yearsNavData = ["ALL", ...yearsArray];

  // Fetch all past events data once on component mount
  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllPastEventsData(i18n);
      setAllYearsData(data);
    };
    fetchData();
  }, [i18n.language]);

  const handleInputValue = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Filter events based on selected year and search query
  const filteredEvents = pastEventsNav === "ALL"
    ? Object.keys(allYearsData)
        .sort((a, b) => b - a) // Sort the years in descending order; use (a - b) for ascending order
        .flatMap(year => Object.entries(allYearsData[year])) // Flatten the events maintaining year order
    : Object.entries(allYearsData[pastEventsNav] || {}); // Convert the specific year's data to entries

  return (
    <PastEventsDiv>
      <Hero
        bgImage="games/header_bg.jpg"
        mainHeroText={t("pastEvents.hero.header")}
        description={t("pastEvents.hero.searchPastEvents")}
      >
        <div className="hero-search">
          <input
            onChange={handleInputValue}
            type="text"
            placeholder={t("pastEvents.hero.search")}
          />
          <IoSearch />
        </div>
      </Hero>

      <container-wide>
        <NavYearsDiv>
          <Row className="g-1 gx-3">
            {yearsNavData.map((year) => (
              <Col key={year}>
                <h2
                  className={year === pastEventsNav ? "active" : undefined}
                  onClick={() => pastEventsNavFn(year)}
                >
                  {year}
                </h2>
              </Col>
            ))}
          </Row>
        </NavYearsDiv>
      </container-wide>

      <container-wide>
        <Row className="g-5">
          {filteredEvents
            .filter(([key, event]) => {
              const containsTitle = searchQuery === "" || (event.title && event.title.toLowerCase().includes(searchQuery));
              return containsTitle;
            })
            .map(([key, event]) => (
              <Col xs={12} sm={6} md={4} lg={3} key={event.cart_image}>
                <PgCard
                  key={key}
                  className={toUrlFriendlyString(event.title)}
                  // href={`${t(`routes.${key}.main`)}/winners`}
                  href={event.url}
                  image={event.cart_image}
                  imageAlt={event.title}
                  title={event.title}
                  subtitle={event.date}
                  buttonSettings={
                    event.url
                      ? {
                          label: t("button.event-info"),
                          href: event.url,
                          color: "var(--white)",
                          hoverBgColor: "var(--white)",
                          hoverColor: "var(--black)",
                          size: "xsmall",
                        }
                      : null
                  }
                />
              </Col>
            ))}
        </Row>
      </container-wide>
    </PastEventsDiv>
  );
};

const PastEventsDiv = styled.div`
  position: relative;
  z-index: 0;
  height: fit-content;
  width: 100%;
  background: var(--black);
  .hero-search {
    padding: 0.7rem 0 1rem;
    position: relative;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    input {
      border: 1px solid var(--white);
      border-radius: 4px;
      color: var(--black);
      font-size: 1.2rem;
      padding: 0.7rem;
      margin: 0;
      width: 100%;
      outline: none;
    }
    svg {
      position: absolute;
      width: 25px;
      height: 100%;
      top: 0;
      right: 0.7rem;
      padding-bottom: 0.2rem;
      text-align: center;
      cursor: pointer;
      pointer-events: none;
      color: var(--darkgrey);
    }
  }
`;

const NavYearsDiv = styled.div`
  width: 100%;
  height: fit-content;
  padding: 40px 0;
  margin: 0 auto;
  .row {
    justify-content: center;
  }
  .col {
    flex-grow: 0;
  }
  h2 {
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    transition: 0.3s;
    display: inline-block;
    padding: 5px 30px;
    height: 30px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--lightblack);
    border-radius: 5px;
    &:hover,
    &.active {
      background: var(--red);
    }
  }
`;

export default PastEvents;
