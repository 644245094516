// src/utils/useScrollToHash.js
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = (scrollOffset = 100) => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1); // Get the ID from the hash (remove #)
      const section = document.getElementById(id); // Find the element with that ID
      if (section) {
        const elementTop = section.getBoundingClientRect().top; // Get the element's position relative to the viewport
        const offsetPosition = elementTop + window.scrollY - scrollOffset; // Calculate the scroll position with the offset
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }
  }, [location.hash, scrollOffset]); // Run this effect when the hash or scrollOffset changes
};

export default useScrollToHash;