import styled from "styled-components";
import { useHomepage } from "src/context/homepage/homepage.provider";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import ImageLink from "../ImageWrapper/ImageLink";
import { useIsLgScreenUp } from "src/utils/windowUtils";

const sliderConfig = [
  {
    key: "poker",
    image: "home/poker-slider.jpg",
  },
  {
    key: "egd",
    image: "home/egd-slider.jpg",
  },
  {
    key: "nye2025",
    image: "entertainment/variety/new-years-eve-2025/NYE-Carousel-1369x1200.jpg",
  },
  {
    key: "surfAndTurf2024",
    image: "promotions/dining/surf-and-turf/surf-and-turf-main.jpg",
  },
  {
    key: "wcc2025",
    getImage: (language) =>
      `events/wcc_2025/WCC-homepage-carousel-${language}.jpg`,
  },
  {
    key: "restaurants",
    image: "home/restaurants-slider.jpg",
  },
  // {
  //   key: "loungeBars",
  //   image: "home/lounge-slider.jpg",
  // },
  // {
  //   key: "entertainment",
  //   image: "home/entertainment-slider.jpg",
  // },
];

const MoreThanShows = () => {
  const { t, i18n } = useTranslation();
  const isLgScreenUp = useIsLgScreenUp();
  const { homeOfPlaygroundCardImg, homeOfPlaygroundCardImgFn } = useHomepage();

  const settings = {
    className: "",
    centerMode: true,
    centerPadding: 20,
    dots: false,
    infinite: true,
    lazyload: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: 2,
  };

  return (
    <SliderContainer>
      {isLgScreenUp ? (
        <SecondPartMain>
          {sliderConfig.map((el) => {
            const image =
              typeof el.getImage === "function"
                ? el.getImage(i18n.language)
                : el.image;

            return (
            <div
              key={el.key}
              onMouseOver={() => homeOfPlaygroundCardImgFn(el.key)}
              className={
                el.key === homeOfPlaygroundCardImg ? "active-card" : undefined
              }
            >
              <ImageLink
                to={t(`moreThanShows.${el.key}.url`)}
                  imgSrc={image}
                alt={t(`moreThanShows.${el.key}.image_alt`)}
                className="slide-header-image"
              />
              <section toggle={el.key}>
                <h3>{t(`moreThanShows.${el.key}.title`)}</h3>
                {el.key === homeOfPlaygroundCardImg ? (
                  <>
                    <p>{t(`moreThanShows.${el.key}.content`)}</p>
                    <Button
                      bgColor="var(--red)"
                      color="var(--white)"
                      hoverColor="var(--white)"
                      hoverBgColor="var(--black)"
                      size="small"
                      fluid
                      href={t(`moreThanShows.${el.key}.url`)}
                      label={t("button.check-it-out")}
                    />
                  </>
                ) : null}
              </section>
            </div>
            );
          })}
        </SecondPartMain>
      ) : (
        <SecondPartMainMobile>
          <Slider {...settings}>
            {sliderConfig.map((el) => {
              const image =
                typeof el.getImage === "function"
                  ? el.getImage(i18n.language)
                  : el.image;

              return (
              <div key={el.key}>
                <ImageLink
                  to={t(`moreThanShows.${el.key}.url`)}
                    imgSrc={image}
                  alt={t(`moreThanShows.${el.key}.image_alt`)}
                  className="slide-header-image"
                />
                <section toggle={el.key}>
                  <h3>{t(`moreThanShows.${el.key}.title`)}</h3>
                  <p>{t(`moreThanShows.${el.key}.content`)}</p>
                  <Button
                    bgColor="var(--red)"
                    color="var(--white)"
                    hoverColor="var(--white)"
                    hoverBgColor="var(--black)"
                    fluid
                    href={t(`moreThanShows.${el.key}.url`)}
                    label={t("button.check-it-out")}
                    tabIndex="-1"
                  />
                </section>
              </div>
              );
            })}
          </Slider>
        </SecondPartMainMobile>
      )}
    </SliderContainer>
  );
};

const SliderContainer = styled.section`
  height: auto;
  width: 100%;
  a[type="button"] {
    margin-bottom: 0;
  }
`;

const SecondPartMain = styled.main`
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  padding: 2% 1%;
  box-sizing: border-box;
  letter-spacing: 0;
  height: auto;
  min-height: 820px;
  & > div {
    background: #fff;
    flex: 1;
    overflow: hidden;
    transition: 0.3s;
    margin: 0 5px;
    padding: 0;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
    color: var(--black);
    border-radius: 15px;
    height: 100%;
    &.active-card {
      flex: 1 1 15%;
      margin: 0 12px;
      scale: 1;
      border-radius: 15px;
      a.slide-header-image img {
        height: auto;
        width: 100%;
        object-fit: cover;
        object-position: center;
        transition: height 0.4s;
      }
      & > section {
        justify-content: flex-start;
        & > h3 {
          text-align: left;
        }
      }
    }
    a.slide-header-image img {
      width: 100%;
      height: auto;
      min-height: 350px;
      object-fit: cover;
      object-position: center;
    }
    & > section {
      padding: 0 20px 10px;
      height: 100%;
      min-height: 80px;
      display: flex;
      flex-flow: column nowrap;
      gap: 0;
      justify-content: center;
      & > h3 {
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
      }
      @media screen and (max-width: 1200px) {
        & > h3 {
          font-size: 16px;
        }
      }
      & > p {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        width: auto;
        text-align: left;
        margin: 0;
        padding: 0;
      }
    }
  }
`;
const SecondPartMainMobile = styled.div`
  @media screen and (max-width: 991px) {
    width: calc(100% - 20px);
    overflow-x: hidden;
    padding: 0 20px;
    margin: 0 auto;
    height: 100% !important;

    .slick-slider {
      height: 100% !important;
      .slick-list {
        height: 100% !important;
      }
      .slick-track {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100% !important;
      }
      .slick-slide {
        margin: 0 5px;
        height: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }

    div:nth-child(1) {
      div {
        margin: 0 auto;
        padding: 0 !important;
        outline: none !important;
        height: 100% !important;

        img {
          height: 60%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
        }

        section {
          height: 100%;
          min-height: 200px;
          width: 100%;
          z-index: 100;
          background: var(--white);
          color: var(--black);
          padding: 10px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;

          h3 {
            margin-top: 0;
          }
          p {
            font-weight: 400;
            text-align: left;
            padding: 0;
            margin: 0;
            font-size: 14px;
            width: 100%;
            letter-spacing: 0;
          }
        }
      }

      .slick-arrow {
        display: none !important;
      }
      .slick-dots {
        display: none !important;
      }
    }
  }
  @media screen and (min-width: 715px) and (max-width: 991px) {
    div:nth-child(1) {
      div {
        section {
          min-height: 210px;
        }
      }
    }
  }
  @media (max-width: 714px) {
    div:nth-child(1) {
      div {
        section {
          min-height: initial;
        }
      }
    }
  }
`;
export default MoreThanShows;
