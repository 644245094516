import { useState } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const EGDBenefits = () => {
  const [benefits, setBenefits] = useState(2);
  const { t } = useTranslation();
  return (
    <MainDiv benefits={benefits}>
      <div>
        <h2
          onClick={() => setBenefits(2)}
          className={benefits === 2 ? "active" : undefined}
        >
          {t("rewards.section-2-status-silver")}
        </h2>
        <h2
          onClick={() => setBenefits(3)}
          className={benefits === 3 ? "active" : undefined}
        >
          {t("rewards.section-2-status-gold")}
        </h2>
        <h2
          onClick={() => setBenefits(4)}
          className={benefits === 4 ? "active" : undefined}
        >
          {t("rewards.section-2-status-red")}
        </h2>
        <h2
          onClick={() => setBenefits(5)}
          className={benefits === 5 ? "active" : undefined}
        >
          {t("rewards.section-2-status-black")}
        </h2>
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan={2}></th>
            <th>{t("rewards.section-2-status-silver")}</th>
            <th>{t("rewards.section-2-status-gold")}</th>
            <th>{t("rewards.section-2-status-red")}</th>
            <th>{t("rewards.section-2-status-black")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}></td>
            <td>{t("rewards.section-2-points-1")}</td>
            <td>{t("rewards.section-2-points-2")}</td>
            <td>{t("rewards.section-2-points-3")}</td>
            <td>{t("rewards.section-2-points-4")}</td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-1")}</td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-2")}</td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-3")}</td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-4")}</td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-5")}</td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-6")}</td>
            <td></td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-7")}</td>
            <td></td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-8")}</td>
            <td></td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-9")}</td>
            <td></td>
            <td></td>
            <td>15%</td>
            <td>25%</td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-10")}</td>
            <td></td>
            <td></td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-11")}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{t("rewards.section-2-egd-li-12")}</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <ResponsiveImage src={"svg/Check.svg"} alt="check" />
            </td>
          </tr>
        </tbody>
      </table>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  & > div {
    display: none;
  }
  & > table {
    margin-top: 30px;
    width: 100%;
    border-collapse: collapse;
    color: white;
    th,
    td {
      border: 1px solid var(--rewards_grid);
      padding: 10px;
    }
    & > thead {
      tr {
        th {
          font-weight: 500;
        }
        th:nth-child(1) {
          border: none;
        }
        th:nth-child(2) {
          background: #71706e;
        }
        th:nth-child(3) {
          background: #cfa736;
        }
        th:nth-child(4) {
          background: var(--red);
        }
        th:nth-child(5) {
          background: #101010;
        }
      }
    }
    & > tbody {
      tr {
        td:nth-child(1) {
          text-align: left;
        }
        td {
          font-weight: 300;
          text-align: center;
          img {
            display: unset;
            width: 20px;
            height: 20px;
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px 60px 20px;
    & > div {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      & > h2 {
        text-align: center;
        width: 100%;
        padding: 8px;
        font-size: 18px;
        background: var(--white);
        color: var(--black);
      }
      .active {
        background: ${(props) => props.benefits === 2 && "#71706e"};
        background: ${(props) => props.benefits === 3 && "#cfa736"};
        background: ${(props) => props.benefits === 4 && "var(--red)"};
        background: ${(props) => props.benefits === 5 && "#101010"};
        color: var(--white);
      }
    }
    & > table {
      & > thead {
        display: none;
        font-size: 14px;
      }
      & > tbody {
        tr {
          display: flex;
          font-size: 14px;
          td {
            display: none;
          }
          td:nth-child(1) {
            display: flex;
            flex-basis: 50%;
          }
          ${(props) => css`
            td:nth-child(${props.benefits}) {
              display: flex;
              flex-basis: 50%;
              align-items: center;
              justify-content: center;
            }
          `}
        }
      }
    }
  }
`;
export default EGDBenefits;
