import React from "react";
import { Helmet } from "react-helmet-async";
import { t } from "i18next";
import { useLocation, useParams } from "react-router-dom";
import { normalizePathname } from "./utils/formatters";
const imgUrl = process.env.REACT_APP_PGFE_IMG_ASSETS_PATH;

const SEO = () => {
  const location = useLocation();
  const params = useParams(); // Retrieve dynamic parameters
  const baseUrl = "https://www.playground.ca";
  const pathname = normalizePathname(location.pathname);

  // Retrieve the SEO data key based on the current route
  const routes = t("routes", { returnObjects: true });
  const tournamentRoutes = t("tournaments", { returnObjects: true });
  let routeKey = Object.keys(routes).find(
    (key) => normalizePathname(routes[key]) === pathname
  );
  const tournamentRouteKey = findKeyNamesByPathname(tournamentRoutes, pathname);
  // Function to find key names based on a specific pathname
  function findKeyNamesByPathname(tournaments, targetPath) {
    let foundKeys = "";

    Object.keys(tournaments).forEach((tournamentName) => {
      const tournamentDetails = tournaments[tournamentName];
      // Check each detail in the tournament for the exact target path
      Object.values(tournamentDetails).forEach((value) => {
        if (value === targetPath) {
          foundKeys = tournamentName;
        }
      });
    });

    return foundKeys;
  }
  if (tournamentRouteKey) {
    routeKey = tournamentRouteKey;
  }
  let seoData = null;
  // category name for pseudoRoutes
  let category = "";
  // key for pseudoRoutes
  let dynamicSegment = "";

  if (routeKey) {
    seoData = t(`seo.${routeKey}`, { returnObjects: true });
  } else {
    // Attempt to find the SEO data in pseudoRoutes.json if not found in the main routes
    const pseudoRoutes = t("pseudoRoutes", { returnObjects: true });

    // Helper function to find a route by path (recursively)
    const findRouteByPath = (() => {
      // Cache to store previously found routes
      const routeCache = new Map();

      // Helper function to find a route by path (recursively)
      const findRoute = (obj, path) => {
        // Check cache first
        if (routeCache.has(path)) {
          return routeCache.get(path);
        }

        for (let key in obj) {
          if (typeof obj[key] === "object") {
            const result = findRoute(obj[key], path);
            if (result) {
              const cachedResult = { key, routeData: result };
              routeCache.set(path, cachedResult); // Store result in cache
              return cachedResult;
            }
          }
          if (key === "path" && obj[key] === path) {
            routeCache.set(path, obj); // Store result in cache
            return obj; // Return the matched route object
          }
        }
        return null;
      };

      // Return the memoized function
      return findRoute;
    })();

    // Search for matching path
    const matchedRoute = findRouteByPath(pseudoRoutes, pathname);

    if (matchedRoute) {
      const { key, routeData } = matchedRoute;
      category = routeData.key;
      seoData = routeData.routeData.seo;
      dynamicSegment = key; // Store the key for URL translation
    } else {
      // Directly search for a top-level match in pseudoRoutes
      const categoryKeyname = Object.keys(pseudoRoutes).find(
        (key) => pseudoRoutes[key].path === pathname
      );

      if (categoryKeyname) {
        const categoryData = pseudoRoutes[categoryKeyname];
        seoData = categoryData.seo;
        dynamicSegment = categoryKeyname; // Store the key for URL translation
      }
    }
  }
  if (!seoData || !seoData.title) {
    seoData = t("seo.default", { returnObjects: true });
  }
  // Destructure SEO data properties
  const { title, description, image, keywords } = seoData;

  // Handle dynamic segments replacement
  const replaceDynamicSegments = (path, lang) => {
    let translatedPath = t(path, { lng: lang });

    if (translatedPath) {
      Object.entries(params).forEach(([param, value]) => {
        translatedPath = translatedPath.replace(`:${param}`, value);
      });
    }
    return translatedPath;
  };

  // Map dynamic segment key to pseudoRoute path
  const getTranslatedUrl = (lang) => {
    let translatedPath = "";

    if (routeKey) {
      translatedPath = replaceDynamicSegments(`routes.${routeKey}`, lang);
    } else {
      // Use pseudoRoutes data if no routeKey
      const pseudoRoutes = t("pseudoRoutes", {
        returnObjects: true,
        lng: lang,
      });
      const pseudoRouteData = {
        ...pseudoRoutes[dynamicSegment],
        key: category,
      };
      const data = { ...pseudoRouteData[category], key: category };

      if (pseudoRouteData) {
        translatedPath = data.path;
      } else {
        translatedPath = pathname;
      }
    }

    return `${baseUrl}${normalizePathname(translatedPath)}`;
  };

  const urlEn = getTranslatedUrl("en");
  const urlFr = getTranslatedUrl("fr");
  const defaultOgImage = "og/logo1.jpg";
  if (!routeKey && !dynamicSegment) {
    seoData = {
      title: t("error.error404"),
    };
  }
  const getImageType = (imageUrl) => {
    // Extract the file type from the image URL
    const extension = imageUrl.split(".").pop().toLowerCase();

    // Image types
    const imageTypes = {
      jpg: "image/jpg",
      jpeg: "image/jpg",
      png: "image/png",
      gif: "image/gif",
      svg: "image/svg+xml",
      webp: "image/webp",
    };

    return imageTypes[extension] || "image/png"; // Default to default playground image
  };
  const imageUrl = image ? `${imgUrl}${image}` : `${imgUrl}og/default.png`; // Fallback image
  const imageType = getImageType(imageUrl); // Get image type dynamically
  return (
    <Helmet>
      <title>{`${title} | Playground`}</title>
      {process.env.REACT_APP_INDEX_PAGES !== "true" && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <meta name="index_pages" content={process.env.REACT_APP_INDEX_PAGES} />
      {!routeKey && !dynamicSegment && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <meta name="environment" content={`${process.env.NODE_ENV}`} />
      <meta name="description" content={description} />
      <meta property="og:site_name" content="Playground" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${baseUrl}${pathname}`} />
      <meta property="og:title" content={title} />
      <meta
        property="og:image"
        content={image ? `${imgUrl}${image}` : `${imgUrl}${defaultOgImage}`}
      />
      <meta property="og:description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:image:type" content={imageType} />
      <meta property="og:image" content={imgUrl + image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@PlaygroundPoker" />
      <meta name="twitter:image" content={imgUrl + image} />
      <link rel="canonical" href={urlEn} />
      <link rel="alternate" hreflang="en" href={urlEn} />
      <link rel="alternate" hreflang="fr" href={urlFr} />
    </Helmet>
  );
};

export default SEO;
