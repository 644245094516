import React, { useState } from "react";
import styled from "styled-components";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import ImageLink from "../ImageWrapper/ImageLink";

const PlaygroundCaresSecond = () => {
  const [isActive, setIsActive] = useState("recent");
  const { i18n, t } = useTranslation();

  // Import playgroundCares and routes data
  const playgroundCaresData = require(
    `../../locales/${i18n.language}/playgroundCares.json`
  );
  const routesData = require(`../../locales/${i18n.language}/routes.json`);

  const playgroundCaresDataArr = Object.entries(
    playgroundCaresData.playgroundCares.data || {}
  );

  // Function to get the route URL based on the keyName
  const getRouteUrl = (keyName) => {
    var routeUrl = routesData.routes[keyName];
    if (!routeUrl) {
      console.warn(`No route found for key: ${keyName}`);
      return "#"; // Fallback to "#" if the route doesn't exist
    }

    return routeUrl;
  };

  const getButtonClassName = (nav) => (isActive === nav ? "active" : undefined);

  const renderInitiative = (key, el, ind) => (
    <SecondMainCart key={key}>
      <ImageLink imgSrc={el.image_small} alt={el.title} to={getRouteUrl(key)} />
      <div>
        <div>
          <h2>{el.title}</h2>
        </div>
        <p>
          {el.text.substring(0, 125)}
          ...
        </p>
        <Button
          color="var(--white)"
          hoverBgColor="var(--white)"
          hoverColor="var(--black)"
          label={t("button.read-more")}
          href={getRouteUrl(key)}
        />
      </div>
    </SecondMainCart>
  );

  return (
    <SecondPart>
      <div>
        <button
          className={getButtonClassName("recent")}
          onClick={() => setIsActive("recent")}
        >
          {i18n.language === "en"
            ? "Recent Initiatives"
            : "Initiatives récentes"}
        </button>
        <button
          className={getButtonClassName("all")}
          onClick={() => setIsActive("all")}
        >
          {i18n.language === "en" ? "All" : "Tous"}
        </button>
      </div>
      <SecondMain>
        {playgroundCaresDataArr?.map(([key, el], ind) =>
          isActive === "recent"
            ? ind < 8 && renderInitiative(key, el, ind)
            : renderInitiative(key, el, ind)
        )}
      </SecondMain>
      
    </SecondPart>
  );
};

const SecondPart = styled.div`
  height: fit-content;
  width: 100%;
  padding: 60px 0;
  background: var(--black);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  overflow-x: hidden;

  & > div:first-child {
    text-align: center;
    display: flex;
    gap: 20px;

    & > button {
      text-transform: uppercase;
      background: var(--white);
      color: var(--black);
      transition: all 0.3s;

      &.active {
        background: var(--red);
        color: var(--white);
      }

      &:hover {
        background: var(--red);
        color: var(--white);
      }
    }
  }

  @media screen and (max-width: 991px) {
    & > div:first-child {
      & > button {
        font-size: 14px;
        padding: 8px 25px;
      }
    }

    padding: 0;
  }
`;

const SecondMain = styled.main`
  width: 100%;
  max-width: 1440px;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @media screen and (max-width: 991px) {
    padding: 0 20px;
    grid-template-columns: 1fr;
    margin-top: 60px;
    gap: 5px;
  }
`;

const SecondMainCart = styled.div`
  width: 100%;
  position: relative;

  & > img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  & > div {
    position: relative;
    top: -10%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 90%;
    background: var(--lightblack);

    & > div {
      width: 100%;
      background: var(--white);
      color: var(--black);
      border-bottom: 4px solid var(--red);
      display: flex;
      align-items: center;
      min-height: 111px;

      & > h2 {
        margin: 0;
        padding: 15px;
        font-size: 18px;
      }
    }

    & > p {
      margin: 0;
      padding: 15px 15px 0 15px;
      text-align: left;
      font-size: 14px;
    }

    & > a {
      margin: 15px;
      max-width: 200px;
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 991px) {
    & > div {
      & > div {
        min-height: unset;
      }
    }
  }
`;

export default PlaygroundCaresSecond;
