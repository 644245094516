// src/components/Navbar/StyledBurger.jsx
import React from "react";
import styled from "styled-components";
import { t } from "i18next";

const StyledBurger = ({ isOpen, setOpen, closeOffcanvas }) => {
  const handleClick = () => {
    if (isOpen) {
      closeOffcanvas();
    } else {
      setOpen(true);
    }
  };

  return (
    <StyledBurgerButton
      className="burger-button"
      aria-label="hamburger"
      open={isOpen}
      onClick={handleClick}
    >
      <div className="firstLine" />
      <div className="secondLine" />
      <div className="thirdLine" />
      <p>{isOpen ? t('nav.close') : t('nav.menu')}</p>
    </StyledBurgerButton>
  );
};

const StyledBurgerButton = styled.button`
  z-index: 1040;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  height: 42px;
  background: transparent;
  border: none;
  font-size: unset;
  padding: 0;
  padding-top: 5px;
  border-radius: unset;
  letter-spacing: unset;
  transition: 0.4s all;
  cursor: pointer;
  &:focus, &:focus-visible {
    outline: solid 1px #0060cc;
    outline-offset: 5px;
  }
  div {
    width: 40px;
    height: 1px;
    background: var(--white);
    border-radius: 10px;
    transition: all 0.4s linear;
    transform-origin: 4.5px;
    padding-bottom: 2px;
    margin-bottom: 9px;
    position: relative;
    left: ${({ open }) => (open ? "4px" : "0")};
    &.firstLine {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &.secondLine {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(-20px)" : "translateX(0)")};
    }
    &.thirdLine {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
  p {
    z-index: 11 !important;
    color: var(--white);
    transition: all 0.4s;
    margin: 0;
    padding: 0;
    font-size: 9px;
    font-weight: 700;
    color: lightgrey;
    width: 100%;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export default StyledBurger;
