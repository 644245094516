import React from "react";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const BlogBackButton = () => {
  const { t } = useTranslation();
  return (
    <ButtonContainer>
      <Button
        className="no-spacing"
        color="var(--white) !important"
        hoverBgColor="var(--black)"
        hoverColor="var(--white)"
        href={t("routes.blog")}
        label={t("button.go-back")}
        size="small"
      />
    </ButtonContainer>
  );
};
const ButtonContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
`;
export default BlogBackButton;
