const BASE_API = process.env.REACT_APP_BASE_URL;

export async function primary_bbj() {
  const url = `${BASE_API}/jackpots/primary_bbj`;
  const accessToken = localStorage.getItem("access_token");

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    });
    if (!response.ok) {
      const { message } = await response.json();
      console.log(message);
      throw new Error(message);
    }
    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching jackpot data:", error);
    throw error;
  }
}

export async function omaha_bbj() {
  const url = `${BASE_API}/jackpots/omaha_bbj`;
  const accessToken = localStorage.getItem("access_token");

  try {
    const headers = {};
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    const response = await fetch(url, { method: "GET", headers });
    if (!response.ok) {
      const { message } = await response.json();
      console.log(message);
      throw new Error(message);
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching jackpot data:", error);
    throw error;
  }
}

export async function high_stakes_bbj() {
  const url = `${BASE_API}/jackpots/high_stakes_bbj`;
  const accessToken = localStorage.getItem("access_token");

  try {
    const headers = {};
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    const response = await fetch(url, { method: "GET", headers });
    if (!response.ok) {
      const errorBody = await response.json();
      console.log(errorBody.message);
      throw new Error(errorBody.message);
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching high stakes jackpot data:", error);
    throw error;
  }
}

export async function gaming_machine_jackpots() {
  const url = `${BASE_API}/jackpots/gaming_machines`;
  const accessToken = localStorage.getItem("access_token");

  try {
    const headers = {};
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    const response = await fetch(url, { method: "GET", headers });
    if (!response.ok) {
      const errorBody = await response.json();
      console.log(errorBody.message);
      throw new Error(errorBody.message);
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching gaming machines jackpot data:", error);
    throw error;
  }
}
