import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const CashGameType = ({ gameName, desc, tableTypes, imgSrc, imgAlt }) => {
  return (
    <GameType className="g-4 mb-5 pb-4 mt-1 pt-2">
      <Col xs={12} md={6} lg={8} className="mt-0">
        <SubTitleSection subTitle={gameName} as="h3" />
        <ReactMarkdown>{desc}</ReactMarkdown>
        {tableTypes && <ReactMarkdown>{tableTypes}</ReactMarkdown>}
      </Col>
      <Col xs={12} md={6} lg={4}>
        <ResponsiveImage src={imgSrc} alt={imgAlt} />
      </Col>
    </GameType>
  );
};

const GameType = styled(Row)`
  width: 100%;
  /* /* height: fit-content;
  display: flex;
  padding: 20px; */
  /* margin-bottom: 40px; */
  background: var(--lightblack);
  color: var(--white);
  ul {
    border-top: 1px solid var(--darkgrey);
    list-style-type: none;
    padding: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
    & > li {
      display: inline-block;
      background: var(--lightgrey);
      color: var(--lightblack);
      padding: 10px;
      border-radius: 5px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
  img {
    height: 100%;
    object-fit: cover;
  }
`;

export default CashGameType;
