import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { t } from "i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const HockeyNightAtTheRail2024 = () => {
  return (
    <>
      <Div>
        <Hero
          arrowBounce="true"
          bgImage={t("entertainment.hockeyNightAtTheRail2024.hero.image")}
          buttonLink=""
          buttonText=""
          descriptionBgColor="none"
        />
        <container-div>
          <TitleSection
            as={"h2"}
            title={t("entertainment.hockeyNightAtTheRail2024.content.part1.title")}
            dark={true}
          />
          <ShowBody className="shadow">
            <ReactMarkdown>
              {t("entertainment.hockeyNightAtTheRail2024.content.part1.description")}
            </ReactMarkdown>
          </ShowBody>
        </container-div>
      </Div>
    </>
  );
};

const Div = styled.div`
  background: #e8ebef;
  text-align: center;
  display: flex;
  flex-direction: column;
  .hero_container {
    min-height: 500px;
  }
  .hero_text_container {
    display: none;
  }
`;

const ShowBody = styled.div`
  margin: -10px 40px 40px;
  @media (max-width: 768px) {
    margin: -10px 10px 10px;
  }
  padding: 40px;
  background: #fff;
  .title-section {
    padding-bottom: 10px;
  }
  p {
    &:first-child {
      margin-top: 0;
      font-size: 1.2rem;
      font-weight: bold;
      strong {
        color: var(--red);
      }
    }
  }
  .examples {
    background-color: var(--red);
    padding: 15px;
    color: #fff;
    margin-bottom: 1.5rem;
    height: 100%;
  }
`;

export default HockeyNightAtTheRail2024;
