import React from "react";
import styled, { keyframes } from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import ReactMarkdown from "react-markdown";
import { shouldPublish } from "src/utils/publishUtils";

const FeteJackpotHebdomadaire = () => {
  const { i18n, t } = useTranslation();
  return (
    <PromoDetailDiv>
      <PartOne>
        <ResponsiveImage
          src={`promotions/header_jp_june_2024_${i18n.language}.jpg`}
          alt="walk_of_fame_2023_header_desktop"
        />
        <ResponsiveImage
          src={`promotions/header_mobile_jp_june_2024_${i18n.language}.jpg`}
          alt="walk_of_fame_2023_header_mobile"
        />
      </PartOne>
      <container-div>
        <PartTwo>
          {
            // Check if the card should render based on the publish/unpublish logic
            shouldPublish({
              publish_at: "2024-12-7",
              unpublish_at: "2024-12-31",
            }) ? (
              <React.Fragment>
                <h1>{t("egdWelcomeJackpotParty2024.introSection.title")}</h1>
                <h2>
                  {t(
                    "egdWelcomeJackpotParty2024.introSection.specialDecember.subtitle"
                  )}
                </h2>
                <p>
                  <strong>
                    {t("egdWelcomeJackpotParty2024.introSection.hours")}
                  </strong>
                </p>
                <p className="special">
                  {t(
                    "egdWelcomeJackpotParty2024.introSection.specialDecember.specialNote"
                  )}
                </p>
                <ReactMarkdown>
                  {t("egdWelcomeJackpotParty2024.introSection.text")}
                </ReactMarkdown>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h1>{t("egdWelcomeJackpotParty2024.introSection.title")}</h1>
                <h2>{t("egdWelcomeJackpotParty2024.introSection.subtitle")}</h2>
                <p>
                  <strong>
                    {t("egdWelcomeJackpotParty2024.introSection.hours")}
                  </strong>
                </p>
                <ReactMarkdown>
                  {t("egdWelcomeJackpotParty2024.introSection.text")}
                </ReactMarkdown>
              </React.Fragment>
            )
          }
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th colSpan="2">
                    {i18n.language === "en"
                      ? "Guaranteed Prizes"
                      : "Prix garantis"}
                  </th>
                </tr>
                <tr>
                  <th>
                    {i18n.language === "en"
                      ? "Draw Time"
                      : "Heures des tirages"}
                  </th>
                  <th>
                    {i18n.language === "en"
                      ? "Prize amount"
                      : "Montant du prix"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}>
                    {i18n.language === "en" ? "7:30pm" : "19h30"}
                  </td>
                  <td>
                    {i18n.language === "en"
                      ? "$500 Free Play"
                      : "500$ en crédits-jeu"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.language === "en"
                      ? "$500 Free Play"
                      : "500$ en crédits-jeu"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.language === "en"
                      ? "$500 Free Play"
                      : "500$ en crédits-jeu"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.language === "en"
                      ? "$500 Free Play"
                      : "500$ en crédits-jeu"}
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3}>
                    {i18n.language === "en" ? "8:30pm" : "20h30"}
                  </td>
                  <td>
                    {i18n.language === "en"
                      ? "$1,000 Cash"
                      : "1 000$ en argent"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.language === "en"
                      ? "$1,000 Cash"
                      : "1 000$ en argent"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.language === "en"
                      ? "$1,000 Cash"
                      : "1 000$ en argent"}
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    {i18n.language === "en" ? "9:30pm" : "21h30"}
                  </td>
                  <td>
                    {i18n.language === "en"
                      ? "$1,500 Cash + $1,000 Free Play"
                      : "1 500$ en argent + 1 000$ en crédits-jeu"}
                  </td>
                </tr>
                <tr>
                  <td>
                    {i18n.language === "en"
                      ? "$1,500 Cash + $1,000 Free Play"
                      : "1 500$ en argent + 1 000$ en crédits-jeu"}
                  </td>
                </tr>
                <tr>
                  <td>{i18n.language === "en" ? "10:30pm" : "22h30"}</td>
                  <td>
                    {i18n.language === "en"
                      ? "$5,000 Cash - Grand Prize"
                      : "5 000$ Grand Prix en argent"}
                  </td>
                </tr>
              </tbody>
            </table>
          </TableDiv>
          <p>{t("egdWelcomeJackpotParty2024.draws")}</p>
        </PartTwo>
      </container-div>
      <PartThree>
        <div>
          <div>
            <h2>
              {i18n.language === "en"
                ? "General Rules and Conditions"
                : "Règles et conditions générales"}
            </h2>
            <ol>
              {i18n.language === "en" ? (
                <li>
                  Players receive one (1) invitation/draw entry to each of{" "}
                  <strong>the following 2 Weekly Jackpot Parties,</strong> held
                  every Tuesday, for every jackpot of{" "}
                  <strong>$500 to $1,199.99</strong> and two (2)
                  invitations/draw entries for every jackpot of{" "}
                  <strong>$1,200 to $2999.99</strong> and three (3)
                  invitations/draw entries for every jackpot of{" "}
                  <strong>$3000 or more</strong> while playing our gaming
                  machines or table games between Tuesday at 7:30PM to the
                  following Tuesday at 7:29PM.
                </li>
              ) : (
                <li>
                  Les joueurs reçoivent une (1) invitation/participation au
                  tirage à{" "}
                  <strong>
                    chacune des 2 Fêtes Jackpot hebdomadaires suivantes,
                  </strong>{" "}
                  organisées tous les mardis, pour chaque jackpot de{" "}
                  <strong>500$ à 1 199,99$</strong> et deux (2)
                  invitations/participations au tirage pour chaque jackpot de{" "}
                  <strong>1 200$ à 2 999,99$</strong> et trois (3)
                  invitations/participations au tirage pour chaque jackpot de{" "}
                  <strong>3 000$ ou plus</strong> en jouant à nos machine de
                  jeux ou table de jeux entre le mardi à 19h30 et le mardi
                  suivant à 19h29.
                </li>
              )}
              <li>
                {i18n.language === "en"
                  ? "Players that win a Jackpot on a Tuesday prior to 7:30PM will get an invitation for the Jackpot Party that is scheduled for that evening and the following Tuesday."
                  : "Les joueurs qui gagnent un Jackpot le mardi avant 19h30 recevront une invitation pour la Fête Jackpot prévue ce soir-là et le mardi suivant."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "Players that win a Jackpot on a Tuesday after 7:30PM will get an invitation for the Jackpot Party that is scheduled for the following Tuesday and the Tuesday after that."
                  : "Les joueurs qui remportent un Jackpot un mardi après 19h30 recevront une invitation pour la Fête Jackpot prévue le mardi suivant et le mardi d'après."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "After a player is given an invitation by a Playground representative, they will then write down their name and ID on the raffle portion of the invitation and drop it in the appropriate Jackpot Party raffle drum."
                  : "Après qu'un représentant de Playground ait remis à un joueur une invitation, il inscrit ensuite son nom et numéro de membre sur la partie tirage de l'invitation et le dépose dans le tambour de tirage au sort approprié de la Fête du Jackpot."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "At 7:30PM the first 4 draws will be held, followed by 3 draws at 8:30PM, 2 draws at 9:30PM, and the final Grand Prize draw at 10:30PM."
                  : "Les 4 premiers tirages ont lieu à 19h30, suivis de 3 tirages à 20h30, 2 tirages à 21h30, et du tirage final du Grand Prix à 22h30."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "Guests are eligible to win once during the Jackpot Party. In the event someone has their name called on more than one occasion, by way of the random draw, the guest will get to keep the greater of the 2 amounts won and an additional draw will be held for the lesser amount. If the lesser amount was Free Play, the guest will get to keep it and we will hold an additional draw for the same Free Play amount. If the amount is the same as their first prize the guest will receive $50 in Free Play."
                  : "Les invités ne peuvent gagner qu'une seule fois au cours de la soirée Jackpot. Si le nom d'un invité est appelé plus d'une fois, par le biais du tirage au sort, l'invité conservera le plus élevé des deux montants gagnés et un tirage supplémentaire sera effectué pour le montant le moins élevé. Si le montant le moins élevé était en crédits-jeu, l'invité le gardera et nous organiserons un tirage supplémentaire pour le même montant en crédits-jeu. Si le montant est identique au premier prix, l'invité recevra 50$ en crédits-jeu."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "Draw winners will be given five (5) minutes to present themselves either to the draw area on the 2nd floor or the desk on the 1st floor to confirm their presence before making their way to the 2nd floor."
                  : "Les gagnants du tirage disposent de cinq (5) minutes pour se présenter soit à la zone de tirage du 2e étage, soit au comptoir du 1er étage pour confirmer leur présence avant de se rendre au 2e étage."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "All participants must present a valid government-issued photo identification upon request."
                  : "Tous les participants doivent présenter une pièce d'identité valide avec photo émise par le gouvernement sur demande."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "Playground reserves the right to change or alter this promotion, as it deems appropriate, including, but not limited to, making changes or alterations to these Rules and Regulations relating to the accommodation of participants."
                  : "Playground se réserve le droit de modifier ou d'altérer cette promotion, comme elle le juge approprié, y compris, mais sans s'y limiter, en apportant des modifications ou des altérations à ces règles et règlements relatifs à la participation des membres."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "The promotion is open to all Playground members."
                  : "La promotion est ouverte à tous les membres de Playground."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "The promotion takes place at Playground."
                  : "La promotion a lieu au Playground."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "Employees of Playground shall be ineligible to participate in the promotion."
                  : "Les employés de Playground ne sont pas autorisés à participer à la promotion."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "Patrons with self-excluded or banned status, as determined by Playground, are not eligible for any of the gifts/prizes associated with Playground contests, promotions, tournaments orgiveaways."
                  : "Les clients ayant un statut d'auto-exclusion ou de bannissement, tel que déterminé par Playground, ne sont pas admissibles aux cadeaux/prix associés aux concours, aux promotions, aux tournois ou aux cadeaux promotionnels de Playground."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "All references to times and dates reflect Eastern Time."
                  : "Toutes les heures et dates mentionnées sont en heure de l'Est."}
              </li>
              <li>
                {i18n.language === "en"
                  ? "By participating in the promotion, winners consent to the use of their names and photographs for promotional purposes without additional compensation."
                  : "En participant à la promotion, les gagnants consentent à l'utilisation de leur nom et de leur photo à des fins promotionnelles sans compensation supplémentaire."}
              </li>
            </ol>
            <p>
              <strong>
                {i18n.language === "en"
                  ? "Questions relating to this promotion can be answered by visiting the Player Rewards desk."
                  : "Les questions relatives à cette promotion peuvent être posées au bureau des récompenses aux joueurs."}
              </strong>
            </p>
          </div>
        </div>
      </PartThree>
    </PromoDetailDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: var(--white);
  font-family: "Poppins", sans-serif;
  padding-bottom: 40px;
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
const PartOne = styled.div`
  height: 100%;
  width: 100%;
  &::after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
  & > img {
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > img:first-child {
      display: block;
    }
    & > img:nth-child(2) {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      display: block;
      padding-top: 80px;
    }
  }
`;
const PartTwo = styled.div`
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 60px auto;
  height: auto;
  & > h1:first-child {
    font-size: 45px;
    font-weight: 800;
    line-height: 1.15em;
    margin-bottom: 0;
  }
  & > p {
    font-size: 18px;
    text-align: center;
    margin: 5px 0;
  }
  .special {
    color: var(--red);
    margin: 20px auto;
    font-size: 24px;
    font-weight: 700;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    margin: 0;
    padding: 40px 20px;
    & > h1:first-child {
      font-size: 30px;
    }
    & > p {
      font-size: 16px;
    }
  }
`;
const PartThree = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 40px 0;
  background: var(--lightblack);
  & > ul:first-child {
    margin-bottom: 20px;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    & > li {
      width: 100%;
      text-align: center;
      padding: 10px 0;
      background-color: #232426;
      color: #fff;
      transition: 500ms;
      font-weight: 700;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        background-color: #c39236;
      }
      &.active {
        background-color: #c39236;
      }
    }
  }
  & > div:last-child {
    height: auto;
    max-width: 1440px;
    margin: 40px auto;
    width: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    & > div {
      background: var(--white);
      animation: ${fadeIn} 0.3s linear;
      width: 100%;
      height: fit-content;
      padding: 15px;
      & > h2 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
      }
      & > ol {
        font-size: 15px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 10px;
        & > li {
        }
      }
      & > h3 {
        color: #555;
        margin: 20px 0 0;
        font-weight: 600;
        font-size: 19px;
        line-height: 27px;
      }
      & > p:last-child {
        font-size: 16px;
        padding: 0;
        margin: 0;
        line-height: 28px;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px;
    & > ul:first-child {
      flex-flow: wrap;
      justify-content: space-evenly;
      & > li {
        font-size: 14px;
        max-width: 45%;
      }
    }
    & > div:last-child {
      & > div {
        & > ol {
          font-size: 15px;
          padding-left: 20px;
        }
      }
    }
  }
`;
const TableDiv = styled.div`
  height: fit-content;
  width: 100%;
  height: fit-content;
  margin: 20px 0;
  & > table:first-child {
    width: 100%;
    margin-bottom: 15px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: center;
    & > thead {
      & > tr:first-child {
        & > th {
          background: radial-gradient(
            circle,
            rgb(220 220 220) 0%,
            rgb(145 145 145) 50%,
            rgb(204 204 204) 100%,
            rgb(209 209 209) 100%
          );
          color: #000;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          padding: 10px 5px;
          font-size: 18px;
        }
      }
      & > tr:last-child {
        & > th {
          background: #000;
          color: #fff;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          padding: 10px 5px;
          font-size: 18px;
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          color: #fff;
          background: #000;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          padding: 15px 5px;
          font-size: 18px;
          border: 2px solid #fff;
          width: 50%;
        }
      }
    }
  }
`;
export default FeteJackpotHebdomadaire;
