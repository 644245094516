import React from 'react';
import styled from 'styled-components';
import Button from '../../Button/Button';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../context/account/account.provider';
import { isItemInCart } from '../../../utils/tournamentUtils';

const TournamentDetailsCardButtons = ({ tournamentDetails, ...props }) => {
  const { t } = useTranslation();
  const { access_token, accountCart, accountInfo, accountCartUpdateFn } = useAccount();

  const isLoggedIn = props.isLoggedIn || !!access_token;
  const isEmailVerified = props.isEmailVerified || accountInfo?.meta?.verified_email;
  const isInCart = props.isInCart || isItemInCart(accountCart, tournamentDetails.id);
  // Can also use tournamentDetails?.availability === "in_cart" to check if item is in cart
  const availability = props.availability || tournamentDetails?.availability;
  const alreadyPurchased = props.alreadyPurchased || tournamentDetails?.availability === 'purchased' && !isInCart;

  let buttonDisabled = true;
  let onClickHandler = null;
  let infoText = "";
  let buttonText = t("tournament.cart.addToCart");
  let buttonHref = null;
  let alertVariant = "warning";

  if (!isLoggedIn) {
    if (availability === "closed") {
      infoText = t("tournament.card.registrationClosed");
    } else if (availability === "in_person") {
      infoText = t("tournament.card.inPersonPurchase");
    } else {
      alertVariant = "info";
      infoText = t("tournament.card.logInToPurchase");
    }
  } else if (!isEmailVerified && availability === "online") {
    alertVariant = "warning";
    infoText = t("tournament.card.emailVerificationNeeded");
  } else if (alreadyPurchased) {
    alertVariant = "success";
    infoText = t("tournament.card.alreadyPurchased");
  } else if (isInCart) {
    buttonDisabled = false;
    buttonText = t("tournament.card.checkout");
    onClickHandler = null;
    buttonHref = t("routes.checkout");
    alertVariant = "success";
    infoText = (
      <>
        {t("tournament.cart.isInCart")}{" "}
        {/* <a
          href="#"
          onClick={() => accountCartUpdateFn("remove", tournamentDetails.gid)}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {t("tournament.cart.removeFromCart")}
        </a> */}
      </>
    );
  } else {
    switch (availability) {
      case "closed":
        infoText = t("tournament.card.registrationClosed");
        break;
      case "in_person":
        infoText = t("tournament.card.inPersonPurchase");
        break;
      case "online":
        buttonDisabled = false;
        buttonText = t("tournament.cart.addToCart");
        onClickHandler = () => {
          accountCartUpdateFn("add", tournamentDetails.gid);
        };
        break;
    }
  }

  return (
    <ButtonsContainer>
      {infoText && <Alert key={tournamentDetails.gid} variant={alertVariant}>{infoText}</Alert>}
      <Button
        href={buttonHref}
        disabled={buttonDisabled}
        fluid
        onClick={onClickHandler}
        label={buttonText}
      />
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white);
  .alert {
    width: 100%;
    text-align: center;
  }
`;

export default TournamentDetailsCardButtons;
