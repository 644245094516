import React from "react";
import { Accordion, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";

const SignUpExistingFAQs = () => {
  const { t } = useTranslation();

  return (
    <ContainerDiv>
      <h3>
        {t("auth.signUpExistingFaq.title")}
      </h3>
      <Accordion defaultActiveKey="0" className="panel-group panel-ctn">
        {t("auth.signUpExistingFaq.questions", { returnObjects: true }).map((item, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>
              {item.question}
            </Accordion.Header>
            <Accordion.Body>
              <p>{item.answer1}</p>
              {item.answer2 && <p>{item.answer2}</p>}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </ContainerDiv>
  );
};

const ContainerDiv = styled(Container)`
  .accordion-item {
    h2 {
      margin: 0;
    }
  }
  .accordion-button:not(.collapsed) {
    background-color: var(--bluegrey);
    color: var(--lightblack);
    box-shadow: none;
  }
`;

export default SignUpExistingFAQs;
