import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

const NavTabs = ({
  links,
  bgColor,
  activeBgColor,
  textColor,
  activeTextColor,
  borderColor,
  dropDownBgColor,
  dropdownActiveTextColor,
}) => {
  const renderTabs = (links) => {
    return links?.map((el, index) => {
      if (el.type === "dropdown" && el.options) {
        return (
          <Dropdown as={Nav.Item} key={index}>
            <Dropdown.Toggle
              as={Nav.Link}
              className={`dropdown-toggle ${el.isActive ? "active" : ""}`}
            >
              {el.label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {el.options?.map((option, idx) => (
                <Dropdown.Item as="button" key={idx} onClick={option.onClick}>
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        return (
          <Nav.Item key={index}>
            <Nav.Link href={el.path}
              onClick={(e) => {
                e.preventDefault();
                el.onClick();
              }} active={el.isActive}>
              {el.title}
            </Nav.Link>
          </Nav.Item>
        );
      }
    });
  };

  return (
    <StyledNav
      fill
      variant="pills"
      className="nav-fill gap-2"
      bgColor={bgColor}
      activeBgColor={activeBgColor}
      textColor={textColor}
      activeTextColor={activeTextColor}
      borderColor={borderColor}
      dropDownBgColor={dropDownBgColor}
      dropdownActiveTextColor={dropdownActiveTextColor}
    >
      {renderTabs(links)}
    </StyledNav>
  );
};

NavTabs.propTypes = {
  links: PropTypes.array.isRequired,
  bgColor: PropTypes.string,
  activeBgColor: PropTypes.string,
  textColor: PropTypes.string,
  activeTextColor: PropTypes.string,
  borderColor: PropTypes.string,
  dropDownBgColor: PropTypes.string,
  dropdownActiveTextColor: PropTypes.string,
};

const StyledNav = styled(Nav).withConfig({
  shouldForwardProp: (prop) =>
    ![
      "bgColor",
      "activeBgColor",
      "activeTextColor",
      "textColor",
      "borderColor",
      "dropDownBgColor",
      "dropdownActiveTextColor",
    ].includes(prop),
})`
  height: auto;
  width: 100%;
  .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 0.8rem 2rem;
    background-color: ${(props) => props.bgColor || "var(--mutedred)"}!important;
    color: ${(props) => props.textColor || "var(--white)"};
    /* border-radius: 0; */
    /* border-right: 1px solid ${(props) => props.borderColor || "var(--lightblack)"}; */
    &:hover,
    &.active,
    &:focus {
      color: ${(props) => props.activeTextColor || "var(--red)"};
      background-color: ${(props) =>
        props.activeBgColor || "var(--white)"}!important;
    }
  }

  .nav-item:last-child .nav-link,
  .nav-pills .nav-item:last-child .nav-link.active,
  .nav-pills .show > .nav-item:last-child .nav-link {
    border-right: none;
  }

  .nav-item.dropdown .dropdown-menu {
    background-color: ${(props) => props.dropDownBgColor || "var(--red)"};
    .dropdown-item {
      color: ${(props) => props.textColor || "var(--white)"};
      &:hover,
      &.active-hash,
      &:focus {
        background-color: ${(props) => props.activeBgColor || "var(--white)"};
        color: ${(props) => props.dropdownActiveTextColor || "var(--red)"};
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .nav-link,
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      /* border-bottom: 1px solid ${(props) => props.borderColor || "var(--white)"}; */
      border-right: none;
    }

    .nav-item:last-child .nav-link,
    .nav-pills .nav-item:last-child .nav-link.active,
    .nav-pills .show > .nav-item:last-child .nav-link {
      border-bottom: none;
    }
  }
`;

export default NavTabs;
