import React from "react";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import TitleSection from "../TitleSection/TitleSection";
import SubTitleSection from "../SubTitleSection/SubTitleSection";

const Roulette = () => {
  const { t, i18n } = useTranslation();

  return (
    <RouletteDiv>
      <container-div>
        <TitleSection title={t("etg.roulette.title")} />
        <ResponsiveImage
          src={`promotions/roulette_header_${i18n.language}.jpg`}
          alt={t("etg.roulette.headerImageAlt")}
        />
        <SubTitleSection subTitle={t("etg.roulette.subtitle")} />
        <ReactMarkdown>{t("etg.roulette.description1")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.roulette.description2")}</ReactMarkdown>
        <ResponsiveImage
          src={`promotions/roulette_wheel.png`}
          alt="roulette_wheel"
        />
        <ReactMarkdown>{t("etg.roulette.bettingInstructions")}</ReactMarkdown>
        <ResponsiveImage className="mt-4"
          src={`promotions/roulette_table.jpg`}
          alt="roulette_table"
        />
        <BettingOptions className="mb-4">
          <div>
            <ul>
              <li>{t("etg.roulette.betOptions.straightUp")}</li>
              <li>{t("etg.roulette.betOptions.split")}</li>
              <li>{t("etg.roulette.betOptions.street")}</li>
              <li>{t("etg.roulette.betOptions.corner")}</li>
              <li>{t("etg.roulette.betOptions.basket")}</li>
              <li>{t("etg.roulette.betOptions.line")}</li>
              <li>{t("etg.roulette.betOptions.column")}</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>{t("etg.roulette.betOptions.dozen")}</li>
              <li>{t("etg.roulette.betOptions.lowNumber")}</li>
              <li>{t("etg.roulette.betOptions.highNumber")}</li>
              <li>{t("etg.roulette.betOptions.redBlack")}</li>
              <li>{t("etg.roulette.betOptions.oddEven")}</li>
              <li>{t("etg.roulette.betOptions.zeroSplit")}</li>
            </ul>
          </div>
        </BettingOptions>
        <SubTitleSection subTitle={t("etg.roulette.progressiveJackpotTitle")} />
        <ReactMarkdown>{t("etg.roulette.progressiveJackpotDescription")}</ReactMarkdown>
        <ol>
          <li>{t("etg.roulette.progressiveJackpotRule1")}</li>
          <li>{t("etg.roulette.progressiveJackpotRule2")}</li>
          <li>{t("etg.roulette.progressiveJackpotRule3")}</li>
          <li>{t("etg.roulette.progressiveJackpotRule4")}</li>
        </ol>
        <SubTitleSection subTitle={t("etg.roulette.randomPayModeTitle")} />
        <ReactMarkdown>{t("etg.roulette.randomPayModeDescription1")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.roulette.randomPayModeDescription2")}</ReactMarkdown>
        <ul>
          <li>{t("etg.roulette.randomPayNormal")}</li>
          <li>{t("etg.roulette.randomPayGold")}</li>
          <li>{t("etg.roulette.randomPayDiamond")}</li>
        </ul>
        <SubTitleSection as={'h3'} subTitle={t("etg.roulette.randomPayRulesTitle")} />
        <ul>
          <li>{t("etg.roulette.randomPayRule1")}</li>
          <li>{t("etg.roulette.randomPayRule2")}</li>
          <li>{t("etg.roulette.randomPayRule3")}</li>
          <li>{t("etg.roulette.randomPayRule4")}</li>
          <li>{t("etg.roulette.randomPayRule5")}</li>
        </ul>
        <p>
          <strong>{t("etg.roulette.helpNotice")}</strong>
        </p>
      </container-div>
    </RouletteDiv>
  );
};

const RouletteDiv = styled.div`
  background: #101010;
  color: var(--white);
  height: fit-content;
  width: 100%;
  font-family: "Poppins", sans-serif;
  & > img:first-child {
    width: 100%;
    height: fit-content;
    vertical-align: middle;
  }
`;

const BettingOptions = styled.div`
  background-color: #006600;
  padding: 15px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  & > div {
    width: 50%;
    height: fit-content;
    padding: 0 30px;
    & > ul {
      list-style-type: none;
      padding-left: 0px;
      margin-top: 0;
      margin-bottom: 10px;
      & > li {
        font-size: 14px;
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    & > div {
      width: 100%;
      padding: 0;
    }
  }
`;

export default Roulette;