import React from "react";
import styled from "styled-components";

const BlogContainer = ({ children }) => {
  return <BlogContainerDiv>{children}</BlogContainerDiv>;
};
const BlogContainerDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  background: var(--lightgrey);
  padding: 0 20px;
`;
export default BlogContainer;
