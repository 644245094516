// components/MarkdownWithRouter.jsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

// Custom Link Renderer
const LinkRenderer = ({ href, children }) => {
  // Check if the link is an internal link (starts with "/")
  if (href.startsWith('/')) {
    return <RouterLink to={href}>{children}</RouterLink>;
  }
  // For external links, use the default anchor element
  return <a href={href}>{children}</a>;
};

const MarkdownWithRouter = ({ children, className }) => {
  return (
    <ReactMarkdown
      className={className}
      components={{
        a: LinkRenderer,
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownWithRouter;
