import styled from "styled-components";
// import pgRewardsImage1 from "../../images/playground/pg-rewards-page-1.jpg";
// import pgRewardsImage2 from "../../images/playground/pg-rewards-page-2.jpg";
// import pgRewardsImage3 from "../../images/playground/pg-rewards-page-3.jpg";
// import pgRewardsImage4 from "../../images/playground/pg-rewards-page-4.jpg";
import { useAccount } from "src/context/account/account.provider";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import TitleSection from "../TitleSection/TitleSection";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const JoinTheClub = () => {
  const { access_token, accountInfo } = useAccount();
  const { t } = useTranslation();
  return (
    <FourthPart status={accountInfo?.meta?.current_tier || 1}>
      {!access_token ? (
        <div>
          <div>
            <TitleSection
              as={"h2"}
              title={t("homepage-4.title")}
              text={t("homepage-4.text-1")}
            />
            <Button
              bgColor="var(--white)"
              color="var(--black)"
              hoverBgColor="var(--red)"
              hoverColor="var(--white)"
              href={t("routes.register")}
              label={t("homepage-4.btn")}
              width="wide"
              size="large"
            />
          </div>
          <div>
            <ResponsiveImage
              src={"home/pg-rewards-page-1.jpg"}
              alt="become a member bg"
            />
          </div>
        </div>
      ) : (
        <div>
          <div>
            <TitleSection
              title={t("homepage-4.rewards-title")}
              text={t("homepage-4.rewards-text-1", {
                name: accountInfo?.data?.name,
              })}
            />
            <Button
              bgColor="var(--white)"
              color="var(--black)"
              hoverBgColor="var(--red)"
              hoverColor="var(--white)"
              href={t("routes.myAccount")}
              label={t("homepage-4.rewards-btn")}
              width="wide"
              size="large"
            />
          </div>
          <div>
            <ResponsiveImage
              src={"home/pg-rewards-page-1.jpg"}
              alt="become a member bg"
            />
          </div>
        </div>
      )}
    </FourthPart>
  );
};
// const pgRewardsImages = {
//   1: pgRewardsImage1,
//   2: pgRewardsImage2,
//   3: pgRewardsImage3,
//   4: pgRewardsImage4,
// };
const FourthPart = styled.div`
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--white);
  & > div {
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    & > div:first-child {
      height: 100%;
      width: 50%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      letter-spacing: 1px;
      overflow-x: hidden;
      padding: 100px 20px;
      background: rgba(0, 0, 0, 0.2);
      & > a {
        margin: 15px auto;
      }
    }
    & > div:last-child {
      width: 50%;
      height: 100%;
      position: relative;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0;
    & > div {
      flex-direction: column-reverse;
      padding: 20px;
      gap: 0;
      & > div:first-child {
        padding: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.7);
      }
      & > div:last-child {
        width: 100%;
      }
    }
  }
`;
export default JoinTheClub;
