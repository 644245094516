import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const RequestPassword = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          <h3>
            {i18n.language === "en" ? "Request Successful" : "Requête réussie"}
          </h3>
          <div>
            <div>
              <ResponsiveImage
                src="svg/Check.svg"
                alt="check"
              />
              <h4>
                {i18n.language === "en"
                  ? "Thank you. Your request has been submitted"
                  : "Merci. Votre demande a été envoyée"}
              </h4>
              <h4>
                {i18n.language === "en"
                  ? "What's next ?"
                  : "Quelle est la suite?"}
              </h4>
              <p>
                {i18n.language === "en"
                  ? "You will receive an email in your inbox with instructions about how to create a new password for your My Playground account."
                  : "Vous allez recevoir un courriel avec des instructions sur comment créer un nouveau mot de passe pour votre compte Mon Playground."}
              </p>
              <p>
                {i18n.language === "en"
                  ? "If this email does not arrive within 10 minutes, please check your junk mail folder."
                  : "Si après dix minutes, vous ne le voyez toujours pas, vérifiez votre courrier indésirable."}
              </p>
              <p>
                {i18n.language === "en" ? (
                  <strong>
                    If you still have not received an email please phone Player
                    Services at <a href="tel:14506357653">450-635-7653</a> or
                    email{" "}
                    <a href="mailto:info@playground.ca">info@playground.ca</a>{" "}
                    for further assistance.
                  </strong>
                ) : (
                  <strong>
                    Si vous ne le voyez toujours pas, envoyez un message à{" "}
                    <a href="tel:14506357653">450-635-7653</a> au email{" "}
                    <a href="mailto:info@playground.ca">info@playground.ca</a>{" "}
                    pour obtenir de l’aide.
                  </strong>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div:first-child {
    height: auto;
    min-height: 800px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 570px;
      height: auto;
      & > h3 {
        font-size: 22px;
        font-weight: 700;
        margin: 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--red);
        margin-bottom: 30px;
        color: var(--white);
      }
      & > div {
        background: rgba(0, 0, 0, 0.5);
        padding: 30px;
        text-align: center;
        color: var(--white);
        & > div {
          padding: 20px;
          background: rgba(0, 0, 0, 0.7);
          & > img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin: 10px auto;
          }
          & > h4:nth-child(2) {
            font-weight: normal;
            margin: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--rewards_grid);
          }
          & > p {
            & > strong {
              & > a {
                color: var(--red) !important;
                &:hover {
                  border-bottom: 1px solid var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          padding: 20px;
        }
      }
    }
  }
`;
export default RequestPassword;
