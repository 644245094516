// src/utils/rewardsImageUtil.js
import { useTranslation } from "react-i18next";

// Utility to build the rewards image path
export const getImageSrc = (imageName) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const suffix = lang === "fr" ? "-fr" : "";
    const path = `rewards/redeem/${lang}/${imageName}${suffix}.jpg`;
    return path;
};


