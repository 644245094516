import styled from "styled-components";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from 'react-markdown';
import TitleSection from "src/components/TitleSection/TitleSection"

const Legal = () => {
  const { t, i18n } = useTranslation();
  const policy = Array.from({ length: 8 }, (_, index) => index + 1);
  return (
    <LegalDiv>
      <container-div>
        <TitleSection title={t("legal.title")} />
        <ReactMarkdown>
          {t("legal.content")}
        </ReactMarkdown>
        <p>{t("legal.more-info")} <a href="https://gamingcommission.ca/faqs/" target="_blank">{t("legal.click-here")}</a>.</p>

      </container-div>
    </LegalDiv>
  );
};

const LegalDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: var(--lightblack);
  padding: 140px 0;
  color: var(--mutedwhite);
  p:last-child {
    padding-top: 2rem;
  }
`;

export default Legal;
