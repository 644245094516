import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { t } from "i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const MusicalMonday = () => {

  return (
    <>
      <Div>
        <Hero
          arrowBounce="true"
          bgImage="entertainment/variety/game-day-2024/football_game_day_hero.jpg"
          buttonLink=""
          buttonText=""
          title={t("entertainment.gameDay2024.hero.title")}
          description={t("entertainment.gameDay2024.hero.description")}
          descriptionBgColor="#5a0b0b4d"
          image={t("entertainment.gameDay2024.hero.image")}
          imageAlt={t("entertainment.gameDay2024.hero.imageAlt")}
          showArrow="true"
        />
        <container-div>
          <TitleSection as={'h2'} title={t("entertainment.gameDay2024.content.part1.title")} dark={true}/>
          <ShowBody className="shadow">
            <ReactMarkdown>
              {t("entertainment.gameDay2024.content.part1.description")}
            </ReactMarkdown>
            <TitleSection as={'h3'} title={t("entertainment.gameDay2024.content.part2.title")} dark={true}/>
            <Row xs={1} md={2} className="g-3">
              <Col>
                <p className="examples">{t("entertainment.gameDay2024.content.part2.li-1")}</p>
              </Col>
              <Col>
                <p className="examples">{t("entertainment.gameDay2024.content.part2.li-2")}</p>
              </Col>
            </Row>
            <TitleSection as={'h3'} title={t("entertainment.gameDay2024.content.part3.title")} dark={true}/>
            <p>{t("entertainment.gameDay2024.content.part3.description")}</p>
          </ShowBody>
        </container-div>
      </Div>
      
    </>
  );
};

const Div = styled.div`
  background: #e8ebef;
  text-align: center;
  display: flex;
  flex-direction: column;
  .hero_image_container {
    img {
      max-height: 500px;
    }
  }
  .hero_text_container p {
    background-color: var(--red);
    color: white;
    text-transform: uppercase;
    padding: 1rem;
    font-weight: 700;
    margin-top: 1rem;
  }
`;

const ShowBody = styled.div`
  margin: -10px 40px 40px;
  @media (max-width: 768px) {
    margin: -10px 10px 10px;
  }
  padding: 40px;
  background: #fff;
  .title-section {
    padding-bottom: 10px;
  }
  p {
    &:first-child {
      margin-top: 0;
      font-size: 1.2rem;
      font-weight: bold;
      strong {
        color: var(--red);
      }
    }
  }
  .examples {
    background-color: var(--red);
    padding: 15px;
    color: #fff;
    margin-bottom: 1.5rem;
    height: 100%;
  }
`;

export default MusicalMonday;
