import React from "react";
import styled from "styled-components";
import TitleSection     from "../components/TitleSection/TitleSection";
import SubTitleSection     from "../components/SubTitleSection/SubTitleSection";
import HospitalityUnit  from "../components/DiningAndLounges/HospitalityUnit";
import DiningCarousel   from "../components/Restaurants/DiningCarousel";
import useScrollToHash  from '../utils/useScrollToHash';
import { t }            from "i18next"

const DiningAndLounges = () => {
  // Scrolls to url hash anchor, if element is found with matching ID
  // take yOffset, default 100, for navBar
  useScrollToHash()
  return (
    <MainDiv>
      <DiningCarousel />
      <container-div>
        <div id={t("diningAndLounges.restaurantsAnchor")}></div>
        <TitleSection title={t("diningAndLounges.title")} />
        <SubTitleSection subTitleAs={'h2'} subTitle={t("diningAndLounges.dining.header-title")} />
        <HospitalityUnit
          key={t("diningAndLounges.dining.drunkenDragon.name")}
          name={t("diningAndLounges.dining.drunkenDragon.name")}
          imgSrc={t("diningAndLounges.dining.drunkenDragon.imgSrc")}
          imgAlt={t("diningAndLounges.dining.drunkenDragon.imgAlt")}
          logoSrc={t("diningAndLounges.dining.drunkenDragon.logoSrc")}
          logoAlt={t("diningAndLounges.dining.drunkenDragon.logoAlt")}
          title={t("diningAndLounges.dining.drunkenDragon.title")}
          text={t("diningAndLounges.dining.drunkenDragon.text")}
          link={t("diningAndLounges.dining.drunkenDragon.link")}
          buttons={t("diningAndLounges.dining.drunkenDragon.buttons", { returnObjects: true })}
          imageSide="left"
        />
        <HospitalityUnit
          key={t("diningAndLounges.dining.etc.name")}
          name={t("diningAndLounges.dining.etc.name")}
          imgSrc={t("diningAndLounges.dining.etc.imgSrc")}
          imgAlt={t("diningAndLounges.dining.etc.imgAlt")}
          logoSrc={t("diningAndLounges.dining.etc.logoSrc")}
          logoAlt={t("diningAndLounges.dining.etc.logoAlt")}
          title={t("diningAndLounges.dining.etc.title")}
          text={t("diningAndLounges.dining.etc.text")}
          link={t("diningAndLounges.dining.etc.link")}
          buttons={t("diningAndLounges.dining.etc.buttons", { returnObjects: true })}
          imageSide="right"
        />
        <HospitalityUnit
          key={t("diningAndLounges.dining.theRail.name")}
          name={t("diningAndLounges.dining.theRail.name")}
          imgSrc={t("diningAndLounges.dining.theRail.imgSrc")}
          imgAlt={t("diningAndLounges.dining.theRail.imgAlt")}
          logoSrc={t("diningAndLounges.dining.theRail.logoSrc")}
          logoAlt={t("diningAndLounges.dining.theRail.logoAlt")}
          title={t("diningAndLounges.dining.theRail.title")}
          text={t("diningAndLounges.dining.theRail.text")}
          link={t("diningAndLounges.dining.theRail.link")}
          logoLink={t("diningAndLounges.dining.theRail.logoLink")}
          buttons={t("diningAndLounges.dining.theRail.buttons", { returnObjects: true })}
          imageSide="left"
        />

        <div id={t("diningAndLounges.loungesAnchor")}></div>
        <SubTitleSection subTitleAs={'h2'} subTitle={t("diningAndLounges.lounges.header-title")} />
        <HospitalityUnit
          key={t("diningAndLounges.lounges.babel.name")}
          name={t("diningAndLounges.lounges.babel.name")}
          imgSrc={t("diningAndLounges.lounges.babel.imgSrc")}
          imgAlt={t("diningAndLounges.lounges.babel.imgAlt")}
          logoSrc={t("diningAndLounges.lounges.babel.logoSrc")}
          logoAlt={t("diningAndLounges.lounges.babel.logoAlt")}
          title={t("diningAndLounges.lounges.babel.title")}
          text={t("diningAndLounges.lounges.babel.text")}
          link={t("diningAndLounges.lounges.babel.link")}
          buttons={t("diningAndLounges.lounges.babel.buttons", { returnObjects: true })}
          imageSide="right"
        />
        <HospitalityUnit
          key={t("diningAndLounges.lounges.esperanto.name")}
          name={t("diningAndLounges.lounges.esperanto.name")}
          imgSrc={t("diningAndLounges.lounges.esperanto.imgSrc")}
          imgAlt={t("diningAndLounges.lounges.esperanto.imgAlt")}
          logoSrc={t("diningAndLounges.lounges.esperanto.logoSrc")}
          logoAlt={t("diningAndLounges.lounges.esperanto.logoAlt")}
          title={t("diningAndLounges.lounges.esperanto.title")}
          text={t("diningAndLounges.lounges.esperanto.text")}
          link={t("diningAndLounges.lounges.esperanto.link")}
          buttons={t("diningAndLounges.lounges.esperanto.buttons", { returnObjects: true })}
          imageSide="left"
        />
      </container-div>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  width: 100%;
`;

export default DiningAndLounges;
