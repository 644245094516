import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const PokerPlayer = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const updateData = async () => {
      try {
        // Dynamically import JSON files based on the current language
        const pokerPlayersData = await import(
          `../../locales/${i18n.language}/pokerPlayers.json`
        );
        const routesData = await import(
          `../../locales/${i18n.language}/routes.json`
        );

        // Find the key that matches the current pathname in the routes
        const currentKey = Object.keys(routesData.routes).find(
          (key) => routesData.routes[key] === location.pathname
        );


        if (currentKey) {
          const currentItem = pokerPlayersData.pokerPlayers[currentKey];
          if (currentItem) {
            setItem(currentItem);
          } else {
            console.warn(`No data found for key: ${currentKey}`);
            navigate(t("routes.error"), { replace: true });
          }
        } else {
          console.warn(`No route matching pathname: ${location.pathname}`);
          navigate(t("routes.error"), { replace: true });
        }
      } catch (error) {
        console.error(`Error loading data: ${error.message}`);
        navigate(t("routes.error"), { replace: true });
      }
    };

    // Update data when language or location changes
    updateData();
  }, [i18n.language, location.pathname, navigate, t]);

  return (
    <Div bgImage={useResponsiveImageUrl("players_club/player_bg.jpg")}>
      <div>
        <div>
          <h1>{item?.name}</h1>
        </div>
      </div>
      <div>
        <ResponsiveImage src={item?.image} alt={item?.name} />
        <div>
          <p>{item?.text}</p>
        </div>
      </div>
      
    </Div>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Div = styled.div`
  height: auto;
  width: 100%;
  background: var(--lightgrey);

  & > div:first-child {
    background: url(${(props) => props.bgImage}) no-repeat;
    background-position: 50% 50%;
    padding: 150px 0px 110px;
    background-size: cover;
    z-index: 1;
    position: relative;
    border-bottom: 1px solid #222;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > div {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      height: fit-content;
      padding: 30px;
      text-align: center;
      & > h1 {
        font-size: 52px;
        line-height: 52px;
        font-weight: 700;
        margin: 0px;
        margin-bottom: 10px;
        font-weight: 700;
        color: #fff;
      }
    }
  }

  & > div:nth-child(2) {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
    & > img {
      animation: ${fadeIn} 0.3s linear;
      height: auto;
      object-fit: cover;
      max-width: 40%;
      border: 4px solid #333;
    }
    & > div {
      background: var(--white);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      height: auto;
      width: 100%;
      max-width: 555px;
      padding: 20px;
      & > a {
        color: var(--red);
        font-size: 14px;
        position: relative;
        margin: 0 0 10px;
        display: block;
      }
      & > p {
        margin: 0 0 10px;
        color: #555;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    & > div:first-child {
      border-bottom: 1px solid #222;
      padding: 130px 0 90px 0;
      & > div {
        & > h1 {
          font-size: 40px;
          line-height: 40px;
          padding: 0 15px;
        }
      }
    }
    & > div:nth-child(2) {
      padding: 0 20px;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export default PokerPlayer;