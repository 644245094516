import React from "react";
import { t } from "i18next";
import {
  Row,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

export const IdSection = () => {
  const renderIdTypes = (props) => (
    <Tooltip id="id-tooltip-1" {...props}>
      {t("auth.idSection.uploadTypes")}
    </Tooltip>
  );
  const renderIdReason = (props) => (
    <Tooltip id="id-tooltip-2" {...props}>
      {t("auth.idSection.uploadReason")}
    </Tooltip>
  );

  return (
    <>
      <SubTitleSection subTitle={t("auth.idSection.uploadYourId")} />
      <p>{t("auth.idSection.uploadInstructions")}</p>
      <Row xs={1} md={2} className="g-3">
        <Col>
          <ResponsiveImage
            src={"c0f72d719744dab44f7d5582d75511ae.png"}
            alt="ID Example"
            style={{ cursor: "pointer" }}
          />
        </Col>
        <Col>
          <OverlayTrigger placement="top" overlay={renderIdTypes}>
            <p className="overlay-trigger">
              {t("auth.idSection.uploadTypesQuestion")}
            </p>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={renderIdReason}>
            <p className="overlay-trigger">
              {t("auth.idSection.uploadReasonQuestion")}
            </p>
          </OverlayTrigger>
        </Col>
      </Row>
      <FormGroup className="mb-4">
        <FormLabel htmlFor="fileUpload">
          {t("auth.form.uploadImage.label")}
        </FormLabel>
        <FormControl
          type="file"
          id="fileUpload"
          name="id_image"
          placeholder={t("auth.form.uploadImage.placeholder")}
          required
        />
        <small>jpg, jpeg, png</small>
        <Form.Control.Feedback type="invalid">
          {t("auth.form.uploadImage.requiredMessage")}
        </Form.Control.Feedback>
      </FormGroup>
    </>
  );
};

export default IdSection;
