import React, { forwardRef } from "react";
import styled from 'styled-components';
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

/**
 * DiningPromo component displays a promotional section with an image and accompanying content.
 * It adapts the image layout based on screensize. For mobile, it is always on top.
 * For larger screens it can be staggered left and right using the imageSide
 */
const DiningPromo = forwardRef(({ image, imageAlt, time, title, description, ...props }, ref) => (
// const DiningPromo = ({ image, imageAlt, time, title, description, ref, ...props }) => (
  <>
    <PromoContainer ref={ref} imageSide={props.imageSide} className={`promo_container ${props.containerClassName}`}>
        <PromoImage src={image} alt={imageAlt} imagePosition={props.imagePosition} className="dining_promo_image" />
        <PromoContent className="promo_content_container">
          <div>
            {time && <PromoTime>{time}</PromoTime>}
            {title && <h2>{title}</h2>}
            <p>{description}</p>
          </div>
        </PromoContent>
    </PromoContainer>
  </>
));

const PromoContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
  @media (min-width: 992px) {
    flex-direction: ${({ imageSide }) => (imageSide === 'right' ? 'row-reverse' : 'row')};
  }
`;

const PromoImage = styled(ResponsiveImage)`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: ${(props) => props.imagePosition || 'center'};
  @media (min-width: 992px) {
    width: 50%;
    height: auto;
  }
`;

const PromoTime = styled.p`
  width: 100%;
  height: auto;
  background-color: #151f29;
  color: #fff;
  padding: 5px;
  margin-top: 0;
`;

const PromoContent = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    width: 50%;
  }
`;

export default DiningPromo;
