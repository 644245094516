import React, { useState } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const FollowForFreePlay = () => {
  const [viewBtn, setViewBtn] = useState(false);
  const { i18n } = useTranslation();
  return (
    <PromoDetailDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          <h1>
            {i18n.language === "en"
              ? "FOLLOW FOR FREE-PLAY"
              : "SUIVEZ-NOUS POUR JOUER"}
          </h1>
        </div>
        <div>
          <h2>
            {i18n.language === "en"
              ? "Like our Facebook page and get $10 in Free Play!"
              : "Aimez notre page Facebook et recevez 10$ en crédits-jeu!"}
          </h2>
        </div>
      </div>
      <div>
        <div>
          {i18n.language === "en" ? (
            <p>
              To get your $10 in Free Play, make sure you are logged-in to your
              Facebook account,{" "}
              <strong>click the “Like our page” button below</strong> to go
              directly to our <strong>PlaygroundYUL Facebook page</strong>, then
              click on the black Like button at the top of the page, make sure
              it turns blue and that’s it.
            </p>
          ) : (
            <p>
              Pour obtenir vos 10$ en crédits-jeu, assurez-vous d'être connecté
              à votre compte Facebook,{" "}
              <strong>cliquez sur le bouton "Aimez-nous" ci-dessous</strong>{" "}
              pour aller directement sur notre{" "}
              <strong>page Facebook PlaygroundYUL</strong>, puis cliquez sur le
              bouton noir "J'aime" en haut de la page, assurez-vous qu'il
              devienne bleu et c'est tout.
            </p>
          )}
          {i18n.language === " en" ? (
            <p>
              Now you just need to <strong>head over to our cashier</strong> and
              show them, either directly on your phone or by printing the page,
              and they will give you a <strong>$10 free play voucher.</strong>
            </p>
          ) : (
            <p>
              Il vous suffit maintenant de{" "}
              <strong>vous rendre à notre caisse</strong> et de leur montrer la
              page, imprimée ou directement sur votre téléphone, et ils vous
              donneront <strong>un bon de 10$ en crédits-jeu.</strong>
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              And remember, by liking our <strong>PlaygroundYUL</strong>{" "}
              Facebook page you get our{" "}
              <strong>
                latest updates, unique promotions, exclusive offers
              </strong>{" "}
              and, most importantly,{" "}
              <strong>you help us get to know you better!</strong>
            </p>
          ) : (
            <p>
              Et n'oubliez pas qu'en aimant notre page{" "}
              <strong>Facebook PlaygroundYUL,</strong> vous recevez nos{" "}
              <strong>
                dernières mises à jour, des promotions uniques, des offres
                exclusives
              </strong>{" "}
              et, surtout,{" "}
              <strong>vous nous aidez à mieux vous connaître!</strong>
            </p>
          )}
          <ContentSectionLink
            href="https://www.facebook.com/PlaygroundYUL"
            target="_blank"
            rel="noreferrer"
          >
            {i18n.language === "en" ? "Like our page" : "Aimez notre page"}
          </ContentSectionLink>
          <button onClick={() => setViewBtn(!viewBtn)}>
            {i18n.language === "en"
              ? "View Terms and Conditions"
              : "Conditions générales"}
            <span></span>
          </button>
          <div className={viewBtn === true ? "ul-active" : undefined}>
            {i18n.language === "en" ? (
              <ul>
                <li>
                  This promotion is limited to any Playground member with a
                  Facebook account.
                </li>
                <li>
                  To claim your $10 free-play voucher, you must show, either
                  printed or directly on your phone, that you LIKE the
                  PlaygroundYUL Facebook page.
                </li>
                <li>
                  Limit one (1) “Follow for Freeplay” $10 free play voucher per
                  member.
                </li>
                <li>
                  $10 free play vouchers are valid on all gaming machines that
                  accept free play.
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  Cette promotion est limitée à tout membre de Playground
                  possédant un compte Facebook.
                </li>
                <li>
                  Pour réclamer votre bon de 10$ en crédits-jeu, vous devez
                  montrer, soit imprimé, soit directement sur votre téléphone,
                  que vous AIMEZ la page Facebook de PlaygroundYUL.
                </li>
                <li>
                  Limite d'un (1) bon de 10$ en crédits-jeu par membre pour la
                  promotion “Suivez-nous pour jouer”.
                </li>
                <li>
                  Les bons de 10$ en crédits-jeu sont valables sur tous les
                  machines de jeu qui acceptent les crédits-jeu.
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  z-index: 0;
  background: #e8ebef;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 50% 0% fixed no-repeat;
    z-index: 0;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > div:first-child {
      max-width: 1000px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      & > h1 {
        font-size: 130px;
        line-height: 140px;
        padding: 100px 20px;
        font-weight: 700;
        color: #fff;
        font-family: "Oswald", sans-serif;
        text-shadow: 1px 3px 1px #000;
        margin: 0px;
        margin-bottom: 10px;
      }
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 40px 0px;
      & > h2 {
        max-width: 800px;
        width: 100%;
        margin: auto auto;
        height: 100%;
        font-size: 32px;
        line-height: 40px;
        color: #fff;
      }
    }
  }
  & > div:nth-child(2) {
    max-width: 1440px;
    width: 100%;
    margin: 40px auto;
    height: 100%;
    & > div {
      padding: 40px;
      background: var(--white);
      width: 100%;
      height: fit-content;
      & > p {
        font-size: 16px;
        text-align: left;
        padding: 0px 15px;
        color: #555;
      }
      & > button:nth-child(5) {
        width: 100%;
        max-width: 300px;
        border: none;
        height: 40px;
        background-color: #ddd;
        outline: 0;
        color: var(--black);
        margin: 0 auto;
        border-radius: 0;
        padding: 0;
        & > span {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 5px;
          vertical-align: middle;
          border-top: 4px dashed;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
      & > div:nth-child(6) {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        & > ul {
          padding: 20px;
          margin: 20px 0 10px 40px;
          list-style-type: disc;
          & > li {
            padding: 3px;
            font-size: 14px;
            color: #555;
          }
        }
        &.ul-active {
          max-height: 350px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of medium tablet styles */
    & > div:first-child {
      & > div:first-child {
        & > h1 {
          font-size: 80px;
          line-height: 90px;
          padding: 20px;
        }
      }
      & > div:nth-child(2) {
        & > h2 {
          font-size: 26px;
          line-height: 38px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    & > div:nth-child(2) {
      & > div {
        & > div:nth-child(6) {
          & > ul {
            margin: 20px;
          }
        }
        & > button:nth-child(5) {
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div:first-child {
      background: url(${(props) => props.bgImage}) 0% 100% fixed no-repeat;
      & > div:first-child {
        & > h1 {
          font-size: 80px;
          line-height: 90px;
          padding: 20px;
        }
      }
    }
    & > div:nth-child(2) {
      & > div {
        padding: 20px;
        & > button:nth-child(5) {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }
`;
const ContentSectionLink = styled.a`
  line-height: 29px;
  height: 50px;
  text-align: center;
  display: block;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 3;
  transition: all 0.5s;
  position: relative;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 20px auto;
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  &:hover {
    color: rgb(38, 89, 154);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.5s;
    color: var(--white);
    background: rgb(38, 89, 154);
    border-radius: 5px;
  }
  &:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.5s;
    border: 2px solid rgb(38, 89, 154);
    transform: scale(1.2);
    border-radius: 5px;
  }
  &:hover::after {
    opacity: 1;
    transform: scale(1, 1);
  }
`;
export default FollowForFreePlay;
