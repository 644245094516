import { entertainmentActionTypes as actions } from "./entertainment.actions";

export const entertainmentReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SELECT_EVENT:
      return { ...state, selectEvent: payload, selectEventToggle: false };
    case actions.SELECT_EVENT_TOGGLE:
      return { ...state, selectEventToggle: payload };
    case actions.READ_MORE:
      return {
        ...state,
        isReadMore: !state.isReadMore,
        isReadMoreValue: payload,
      };
    default:
      return state;
  }
};
