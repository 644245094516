import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CashGamesInfo = () => {
  const { t } = useTranslation();
  return (
    <Div>
      <p>
        {t("poker.mystery-high-hand.small-box-info-one")}
        <strong>
          {" "}
          {t("poker.mystery-high-hand.small-box-info-two")}
        </strong>, {t("poker.mystery-high-hand.small-box-info-three")}{" "}
        <strong>{t("poker.mystery-high-hand.small-box-info-four")} </strong>
        {t("poker.mystery-high-hand.small-box-info-five")}{" "}
        <Link to={t("routes.cashGames")}>
          {t("poker.mystery-high-hand.small-box-info-six")}
        </Link>
        .
      </p>
    </Div>
  );
};
const Div = styled.div`
  background-color: #333;
  margin: 30px auto;
  & > p {
    padding: 20px;
    font-size: 16px;
    color: #aaa;
    margin: 0 0 10px;
  }
`;
export default CashGamesInfo;
