import { useEffect } from "react";
import { useHomepage } from "../context/homepage/homepage.provider";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import RewardsHero from "../components/Rewards/Hero";
import RewardsDetails from "../components/Rewards/RewardsDetails";
import JoinTheClub from "../components/HomePage/JoinTheClub";

const Rewards = () => {
  const { darkThemeFn } = useHomepage();
  const { pathname } = useLocation();
  useEffect(() => {
    darkThemeFn(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <RewardsDiv>
      <RewardsHero />
      <RewardsDetails />
      <JoinTheClub />
    </RewardsDiv>
  );
};
const RewardsDiv = styled.div`
  width: 100%;
  min-height: 100vh;
`;

export default Rewards;
