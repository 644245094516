import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const IrishOpen2024 = () => {
  const { t } = useTranslation();
  return (
    <MainDiv>
      <div>
        <ResponsiveImage
          src={t("poker.irishOpen2024.headerImgSrc")}
          alt={t("poker.irishOpen2024.headerImgAlt")}
        />
        <ResponsiveImage
          src={t("poker.irishOpen2024.headerMobileImgSrc")}
          alt={t("poker.irishOpen2024.headerMobileImgAlt")}
        />
      </div>
      <div>
        <div>
          <h2>
            <strong>{t("poker.irishOpen2024.title")}</strong>
          </h2>
          <h3>{t("poker.irishOpen2024.dateRange")}</h3>
          <h4>{t("poker.irishOpen2024.qualifyDescription")}</h4>
          <ReactMarkdown>{t("poker.irishOpen2024.description1")}</ReactMarkdown>
          <ReactMarkdown>{t("poker.irishOpen2024.description2")}</ReactMarkdown>
        </div>
        <h4>{t("poker.irishOpen2024.playgroundQualifyHeader")}</h4>
        <div>
          <div>
            <p>
              <strong>{t("poker.irishOpen2024.step1.title")}</strong>
            </p>
            <ReactMarkdown>
              {t("poker.irishOpen2024.step1.details")}
            </ReactMarkdown>
            <p>{t("poker.irishOpen2024.step1.advance")}</p>
          </div>
          <div>
            <p>
              <strong>{t("poker.irishOpen2024.step2.title")}</strong>
            </p>
            <ReactMarkdown>
              {t("poker.irishOpen2024.step2.details")}
            </ReactMarkdown>
            <p>{t("poker.irishOpen2024.step2.win")}</p>
          </div>
        </div>
        <ReactMarkdown>{t("poker.irishOpen2024.packageDetails")}</ReactMarkdown>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  position: relative;
  z-index: 0;
  height: auto;
  width: 100%;
  background: var(--white);
  & > div:first-child {
    z-index: 1;
    position: relative;
    width: 100%;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img:first-child {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: auto;
    }
    & > img:nth-child(2) {
      display: none;
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: auto;
    }
  }
  & > div:nth-child(2) {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    height: auto;
    color: var(--black);
    padding: 40px 0;
    & > div:first-child {
      text-align: center;
      & > h2 {
        font-size: 36px;
        line-height: 34px;
        max-width: 900px;
        margin: 0 auto;
        font-weight: 400;
      }
      & > h3 {
        font-size: 22px;
        font-weight: normal;
      }
      & > h4 {
        font-size: 20px;
        font-weight: 700;
      }
      & > p {
      }
    }
    & > h4:nth-child(2) {
      font-size: 20px;
      font-weight: 700;
    }
    & > div:nth-child(3) {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 20px;
      & > div {
        width: 50%;
        background-color: #006601;
        border-radius: 15px;
        padding: 15px;
        color: var(--white);
        text-align: center;
        & > p {
        }
      }
    }
    & > p:last-child {
      font-size: 13px;
      text-align: center;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      padding-top: 80px;
      & > img:first-child {
        display: none;
      }
      & > img:nth-child(2) {
        display: block;
      }
    }
    & > div:nth-child(2) {
      padding: 40px 20px;
      & > div:nth-child(3) {
        flex-direction: column;
        & > div {
          width: 100%;
        }
      }
    }
  }
`;
export default IrishOpen2024;
