import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledNav = styled(Nav).withConfig({
  shouldForwardProp: (prop) =>
    ![
      "bgColor",
      "activeBgColor",
      "textColor",
      "dropDownBgColor",
      "dropdownActiveTextColor",
      "active",
    ].includes(prop),
})`
  .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 1rem;
    background-color: ${(props) =>
      props.bgColor || "var(--lightblack)"} !important;
    color: ${(props) => props.textColor || "white"};
    border-radius: 0;
    border-right: 1px solid ${(props) => props.borderColor || "var(--white)"};
    &:hover,
    &.active,
    &:focus {
      //temporarily changed to active
      /* background-color: ${(props) =>
        props.activeBgColor && "var(--red)"} !important; */
    }
    &:focus,
    &:hover {
      background-color: ${(props) => props.active || "var(--red)"} !important;
    }
  }

  .nav-item.dropdown .dropdown-menu {
    background-color: ${(props) =>
      props.dropDownBgColor || "var(--lightblack)"};
      top: 0;
    .dropdown-item {
      color: ${(props) => props.textColor || "#000"};
      &.active {
        background-color: ${(props) => props.dropDownBgColor || "#000"};
        color: ${(props) => props.textColor || "#fff"};
      }
      &:hover,
      &:focus {
        background-color: ${(props) => props.activeBgColor || "var(--red)"};
        color: ${(props) => props.dropdownActiveTextColor || "#fff"};
      }
    }
  }
`;

const NavTabs = ({ tabLinks, ...props }) => {
  const location = useLocation();

  // Function to remove the last slug from the current URL for comparison
  const getBasePath = (pathname) => {
    const segments = pathname?.split("/").filter(Boolean); // Remove empty segments
    return segments?.length > 4
      ? `/${segments.slice(0, -1).join("/")}`
      : pathname;
  };

  // Function to check if a tab's link matches the base URL of the current location
  const isActive = (link) => {
    const basePath = getBasePath(location.pathname);
    const baseLinkPath = getBasePath(link);
    return basePath === baseLinkPath || location.pathname === link;
  };

  const renderTabs = (links) => {
    return links.map((tab, index) => {
      if (tab.type === "dropdown" && tab.options) {
        const isDropdownActive = tab.options.some((option) =>
          isActive(option.link)
        );

        return (
          <Dropdown as={Nav.Item} key={index}>
            <Dropdown.Toggle
              as={Nav.Link}
              className={`dropdown-toggle ${isDropdownActive ? "active" : ""}`}
            >
              {tab.label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {tab.options.map((option, idx) => (
                <Dropdown.Item
                  as={NavLink}
                  to={option.link}
                  key={idx}
                  className={isActive(option.link) ? "active-hash" : ""}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        return (
          <Nav.Item key={index} className="nav-item">
            <NavLink
              className={`nav-link ${isActive(tab.link) ? "active" : ""}`}
              to={tab.link}
            >
              {tab.label}
            </NavLink>
          </Nav.Item>
        );
      }
    });
  };

  return (
    <StyledNav fill variant="pills" className="nav-fill" {...props}>
      {renderTabs(tabLinks)}
    </StyledNav>
  );
};

export default NavTabs;
