import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { t } from "i18next";
import ReactMarkdown from "react-markdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RestaurantCarousel from "./RestaurantCarousel";
import RestaurantDishesCard from "./RestaurantDishesCard";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const TheRail = () => {
  const heroProps = {
    arrowBounce: "true",
    bgImage: "restaurants/rail-wallpaper.jpg",
    title: t("restaurants.theRail.hero.title"),
    description: t("restaurants.theRail.hero.description"),
    buttonSettings: {
      label: t("restaurants.theRail.hero.button.label"),
      href: t("restaurants.theRail.hero.button.href"),
      href: t("link.the-rail"),
      size: "large",
      width: "wide",
      color: "black",
      bgColor: "white",
      specialHover: "true",
    },
    descriptionBgColor: "#0000004d",
    image: "svg/rail_logo.svg",
    imageAlt: "The Rail Logo",
  };

  const theRailSlide = [
    {
      id: 111,
      title: "slide1",
      image: "restaurants/carousel-3.png",
      url: "",
    },
    {
      id: 221,
      title: "slide2",
      image: "restaurants/hamburger-steak-new.jpg",
      url: "",
    },
    {
      id: 331,
      title: "slide3",
      image: "restaurants/carousel-4.png",
      url: "",
    },
    {
      id: 441,
      title: "slide4",
      image: "restaurants/rail-pizza-new.jpg",
      url: "",
    },
    {
      id: 551,
      title: "slide5",
      image: "restaurants/carousel-7.png",
      url: "",
    },
    {
      id: 661,
      title: "slide6",
      image: "restaurants/cheesecake-new.jpg",
      url: "",
    },
  ];

  return (
    <Div>

      <Hero {...heroProps} />
      <RestaurantCarousel slides={theRailSlide} />

      <container-div>
        <div className="shadow bg-blue-grey p-3 p-md-5 my-4">
          <h2 className="text-center mb-3 mb-md-5 mt-1 mt-md-0">
            {t("restaurants.theRail.pastToPresent.title")}
          </h2>
          <Row xs={1} lg={2} className="g-4">
            <Col>
              <ReactMarkdown>
                {t("restaurants.theRail.pastToPresent.text")}
              </ReactMarkdown>
            </Col>
            <Col>
              <ImageSwap>
                <ResponsiveImage
                  className="base-image"
                  src="restaurants/about.jpg"
                  alt="The Rail, today"
                />
                <ResponsiveImage
                  className="hover-image mt-2 mt-md-0"
                  src="restaurants/about_hover.jpg"
                  alt="The Rail, in 2021"
                />
              </ImageSwap>
            </Col>
          </Row>
        </div>
      </container-div>

      <container-div>
        <h2 className="text-center mb-4 mt-4">
          {t("restaurants.dishesToTry")}
        </h2>
        <Row xs={1} md={2} lg={4} className="g-4 mb-3">
          <RestaurantDishesCard
            image={t("restaurants.theRail.dishes.pizza.image")}
            imageAlt={t("restaurants.theRail.dishes.pizza.imageAlt")}
            title={t("restaurants.theRail.dishes.pizza.title")}
            text={t("restaurants.theRail.dishes.pizza.text")}
          />
          <RestaurantDishesCard
            image={t("restaurants.theRail.dishes.wings.image")}
            imageAlt={t("restaurants.theRail.dishes.wings.imageAlt")}
            title={t("restaurants.theRail.dishes.wings.title")}
            text={t("restaurants.theRail.dishes.wings.text")}
          />
          <RestaurantDishesCard
            image={t("restaurants.theRail.dishes.taco.image")}
            imageAlt={t("restaurants.theRail.dishes.taco.imageAlt")}
            title={t("restaurants.theRail.dishes.taco.title")}
            text={t("restaurants.theRail.dishes.taco.text")}
          />
          <RestaurantDishesCard
            image={t("restaurants.theRail.dishes.breakfastSkillet.image")}
            imageAlt={t("restaurants.theRail.dishes.breakfastSkillet.imageAlt")}
            title={t("restaurants.theRail.dishes.breakfastSkillet.title")}
            text={t("restaurants.theRail.dishes.breakfastSkillet.text")}
          />
        </Row>
      </container-div>

      <div className="bg-blue-grey p-3 pt-4 p-md-5 mt-4 mb-0">
        <container-div>
          <Row xs={1} lg={2} className="g-4">
            <Col>
              <h2 className="mb-3 mb-md-4">
                {t("restaurants.theRail.theMoreYouKnow.title")}
              </h2>
              <ReactMarkdown>
                {t("restaurants.theRail.theMoreYouKnow.list")}
              </ReactMarkdown>
            </Col>
            <Col>
              <ResponsiveImage
                className="img-fluid"
                src="restaurants/logo.png"
                alt="The Rail logo"
              />
            </Col>
          </Row>
        </container-div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  background: white;
  img.hero_image {
    max-height: 33vh;
  }
`;

const ImageSwap = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @media screen and (min-width: 575.98px) and (max-width: 991.98px) {
    display: flex;
    img {
      width: 50%;
    }
  }
  @media screen and (min-width: 992px) {
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease-in-out;
      &.hover-image {
        opacity: 0;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

export default TheRail;
