import React from "react";
import PropTypes from "prop-types";
import DynamicRoutingNavTabs from "./DynamicRoutingNavTabs";
import NavTabs from "./NavTabs";
import styled from "styled-components";

const SectionalTabs = ({ routeData, isDynamic = false, className, ...props }) => {
  // console.log(routeData)
  if (isDynamic) {
    return (
      <Div className={`sectional-tabs ${className ? className : ""}`}>
        <DynamicRoutingNavTabs routeData={routeData} {...props} />
      </Div>
    );
  } else {
    return (
      <Div className={`sectional-tabs ${className ? className : ""}`}>
        <NavTabs links={props.links} {...props} />
      </Div>
    );
  }
};

SectionalTabs.propTypes = {
  routeData: PropTypes.shape({
    route: PropTypes.string.isRequired,
    routeCategory: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    selectCategory: PropTypes.string,
    selectAll: PropTypes.string,
  }),
  className: PropTypes.string,
  isDynamic: PropTypes.bool,
  links: PropTypes.array,
  bgColor: PropTypes.string,
  activeBgColor: PropTypes.string,
  textColor: PropTypes.string,
  dropDownBgColor: PropTypes.string,
  dropdownActiveTextColor: PropTypes.string,
};
const Div = styled.div`
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 9;

  @media (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
    flex-flow: row wrap;
  }
`;
export default SectionalTabs;
// example usage
{/* <SectionalTabs
  isDynamic={false}
  links={links}
  bgColor="green"
  activeBgColor="darkgreen"
  textColor="white"
  borderColor="gray"
/>; */}
