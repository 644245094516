import { promotionsActionTypes as actions } from "./promotions.actions";

export const promotionsReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SELECT_PROMO:
      return { ...state, selectPromo: payload, selectPromoToggle: false };
    case actions.SELECT_PROMO_TOGGLE:
      return { ...state, selectPromoToggle: payload };
    case actions.READ_MORE:
      return {
        ...state,
        isReadMore: !state.isReadMore,
        isReadMoreValue: payload,
      };
    case actions.PLAYGROUND_LIVE:
      return { ...state, playgroundLive: !state.playgroundLive };
    case actions.PLAYGROUND_LIVE_CART_IMG:
      return { ...state, playgroundLiveCartImg: payload };
    default:
      return state;
  }
};
