import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button/Button";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const careerFairBody = [
  {
    title: "Food & Beverage",
    image: "careers/image-1.jpg",
  },
  {
    title: "Gaming",
    image: "careers/image-2.jpg",
  },
  {
    title: "Marketing",
    image: "careers/image-3.jpg",
  },
  {
    title: "Security",
    image: "careers/image-4.jpg",
  },
  {
    title: "Surveillance",
    image: "careers/image-5.jpg",
  },
  {
    title: "Administration",
    image: "careers/image-9.jpg",
  },
  {
    title: "Maintenance",
    image: "careers/image-7.jpg",
  },
  {
    title: "Plus more!",
    image: "careers/image-10.jpg",
  },
];

const CareersFair = () => {
  const { t } = useTranslation();
  return (
    <>
      <CareersFairDiv>
        <div>
          <div>
            <HeaderContentDiv>
              <div>
                <h1>
                  <span>{t("careerFair.header-one")}</span>
                  <span>{t("careerFair.header-two")}</span>
                </h1>
                <p>{t("careerFair.header-text")}</p>
                <div>
                  <h3>{t("careerFair.header-hours-info")}</h3>
                  <ResponsiveImage
                    src="careers/holiday-inn_lsc_lkp_d_r_rgb_pos-web.png"
                    alt="holiday-inn"
                  />
                  <span>
                    1390 BLVD. RENÉ-LÉVESQUE OUEST, MONTREAL, QC H3G 0E3
                  </span>
                </div>
              </div>
              <div>
                <iframe
                  title="pg-past-present-future"
                  width="1920"
                  height="1080"
                  src="https://www.youtube.com/embed/F5f9PBYPkf4?rel=0"
                  style={{ border: "0" }}
                  allowFullScreen=""
                ></iframe>
              </div>
            </HeaderContentDiv>
          </div>
        </div>
        <div>
          <section>
            {careerFairBody.map((el) => (
              <div key={Math.random() * 1400}>
                <ResponsiveImage src={el.image} alt={el.title} />
              </div>
            ))}
          </section>
        </div>
        <div>
          <div>
            <h3>{t("careerFair.weOffer")}</h3>
            <p>{t("careerFair.dynamicWork")}</p>
            <p>{t("careerFair.compensation")}</p>
            <p>{t("careerFair.careerAdvancement")}</p>
            <p>{t("careerFair.discountedFood")}</p>
            <p>{t("careerFair.freeParking")}</p>
            <p>{t("careerFair.andMore")}</p>
          </div>
        </div>
        <div>
          <h3>{t("careerFair.moreInfoText")}</h3>
          <Button
            color="var(--white)"
            hoverBgColor="var(--white)"
            hoverColor="var(--black)"
            label={t("button.click-here")}
            href={t("routes.careers")}
          />
        </div>
      </CareersFairDiv>
    </>
  );
};
const CareersFairDiv = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  color: var(--white);
  background: var(--lightblack);
  & > div:first-child {
    background: url("careers/careerFair-bg.jpg");
    background-position: 50% 0%;
    padding: 140px 0px 40px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: fit-content;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > div {
      width: 100%;
      max-width: 1440px;
      height: fit-content;
      margin: 0 auto;
    }
  }
  & > div:nth-child(2) {
    padding: 90px 0;
    height: fit-content;
    width: 100%;
    & > section {
      margin: 0 auto;
      width: 100%;
      max-width: 1440px;
      height: fit-content;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 15px;
      & > div {
        height: 100%;
        min-height: 240px;
        position: relative;
        & > p {
          text-orientation: sideways;
          text-transform: uppercase;
          writing-mode: vertical-rl;
          transform: scale(-1);
          position: absolute;
          right: 0;
          bottom: 0;
          color: var(--white);
          font-size: 1.2vw;
          text-shadow: 2px 2px 4px var(--black);
          font-weight: 800;
          margin: 0 0 10px;
        }
        & > img {
          height: 100%;
          width: 100%;
          vertical-align: middle;
          object-fit: cover;
        }
      }
    }
  }
  & > div:nth-child(3) {
    width: 100%;
    background: url("careers/careerFair-bg.jpg") 50% 0% no-repeat;
    height: auto;
    padding: 100px 0 50px;
    overflow: hidden;
    text-align: center;
    position: relative;
    background-size: cover;
    & > div {
      width: 100%;
      max-width: 1440px;
      height: fit-content;
      margin: 0 auto;
      & > h3 {
        color: var(--white);
        font-size: 36px;
        font-weight: 900;
        margin-bottom: 20px;
      }
      & > p {
        color: var(--white);
        font-size: 24px;
        margin: 0 0 10px;
      }
    }
  }
  & > div:nth-child(4) {
    width: 100%;
    height: fit-content;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h3 {
      color: var(--lightgrey);
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:nth-child(2) {
      padding: 20px;
      & > section {
        grid-template-columns: 1fr;
        & > div {
          & > p {
            font-size: 20px;
          }
        }
      }
    }
    & > div:nth-child(3) {
      & > div {
        padding: 0 20px;
        & > h3 {
          font-size: 33px;
          margin-top: 0;
        }
        & > p {
          font-size: 20px;
        }
      }
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    & > div:nth-child(2) {
      padding: 40px 20px;
      & > section {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;
const HeaderContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;
  & > div:first-child {
    width: 100%;
    max-width: 69%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h1 {
      font-weight: 700;
      line-height: 70px;
      font-size: 70px;
      margin: 0;
      & > span {
        display: block;
      }
    }
    & > p {
      width: 100%;
      max-width: 700px;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      color: var(--lightgrey);
    }
    & > div {
      text-align: center;
      width: 100%;
      max-width: 800px;
      height: fit-content;
      background-color: var(--white);
      display: inline-block;
      margin: 0 auto;
      padding: 15px 20px;
      & > h3 {
        font-size: 26px;
        font-weight: 400;
        line-height: 26px;
        margin: 0;
        color: var(--darkgrey);
      }
      & > img {
        max-width: 300px;
        padding-top: 10px;
        width: 100%;
        margin: 0 auto;
        height: auto;
      }
      & > span {
        display: block;
        color: #363573;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 26px;
        line-height: 26px;
        padding: 10px 30px;
      }
    }
  }
  & > div:last-child {
    width: 100%;
    max-width: 30%;
    height: fit-content;
    background: rgba(255, 255, 255, 0.7);
    position: relative;
    padding: 55px 15px 480px;
    & > iframe {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    & > div:first-child {
      max-width: unset;
      & > h1 {
        line-height: 50px;
        font-size: 50px;
        text-align: center;
      }
      & > div {
        & > h3 {
          font-size: 22px;
          line-height: 22px;
        }
        & > span {
          padding: 10px 10px 0 10px;
          font-weight: 700;
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
    & > div:last-child {
      max-width: unset;
      margin: 20px;
      padding: 35px 15px 420px;
      & > iframe {
        top: 10px;
        right: 10px;
      }
    }
  }
`;
export default CareersFair;