import styled from "styled-components";
import { t } from "i18next";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";

const NewMemberSection = () =>  {
  return (
  <>
    <SubTitleSection subTitle={t("auth.newMember.newToPlayground")} className="pb-0" />
    <Button
      className="button"
      bgColor="var(--white)"
      color="var(--black)"
      hoverColor="var(--white)"
      hoverBgColor="var(--red)"
      fluid
      href={t("routes.register")}
      label={t("auth.newMember.createAccount")}
    />
    <Link to={t("routes.homepage")} className="link">
      {t("button.return-back-home")}
    </Link>
  </>
  );
};

export default NewMemberSection;