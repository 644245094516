// src/utils/timeUtils.js
import { t } from "i18next";

/**
 * Calculates the remaining time until a specified target date and time.
 *
 * @function calculateTimeRemaining
 * @param {string | Date} targetDateTime - The target date and time. Can be a date string or a Date object.
 * @param {boolean} [formatted=false] - If true, returns the remaining time as a formatted string. Otherwise, returns total seconds.
 * @returns {number | string | null} - The remaining time in total seconds, a formatted string, or null if the input is invalid.
 *
 * @description
 * This function calculates the remaining time from the current moment until the specified target date and time.
 * It can return the remaining time either as the total number of seconds or as a human-readable formatted string,
 * depending on the `formatted` parameter.
 *
 * @example
 * // Returns total seconds remaining until the target date
 * const seconds = calculateTimeRemaining('2024-12-31T23:59:59Z');
 *
 * // Returns a formatted string like "2 days, 3 hours, 15 minutes, 20 seconds"
 * const formattedTime = calculateTimeRemaining('2024-12-31T23:59:59Z', true);
 *
 * @throws {TypeError} Will throw an error if `targetDateTime` is neither a string nor a Date object.
 */
export function calculateTimeRemaining(targetDateTime, formatted = false) {

  const targetTime = new Date(targetDateTime).getTime();

  if (isNaN(targetTime)) {
    console.error("Invalid Date: targetDateTime cannot be parsed into a valid date.");
    return;
  }

  const now = new Date().getTime();
  const difference = targetTime - now;
  // console.log('now', now);
  // console.log('targetTime', targetTime);
  // console.log('difference', difference);

  if (difference <= 0) {
    return formatted ? "00:00:00" : 0; // No time remaining
  }

  const totalSeconds = Math.floor(difference / 1000);

  if (formatted) {
    // If formatted is true, return the formatted string (hh:mm:ss)
    return formatRemainingTime(convertSecondsToTime(totalSeconds));
  }

  // Return total seconds if formatted is false
  return totalSeconds;
}

// Converts total seconds into hours, minutes, and seconds
export function convertSecondsToTime(totalSeconds) {
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600); // Convert days to hours
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

// Formats hours, minutes, and seconds into hh:mm:ss format
// export function formatRemainingTime({ hours, minutes, seconds }) {
//   return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}:${formatTimeUnit(seconds)}`;
// }
export function formatRemainingTime({ days, hours, minutes, seconds }) {
  return `
    ${days > 0 ? `${formatTimeUnit(days)}${t("tournament.card.days")}` : ""}
    ${formatTimeUnit(hours)}${t("tournament.card.hours")}
    ${formatTimeUnit(minutes)}${t("tournament.card.minutes")}
    ${formatTimeUnit(seconds)}${t("tournament.card.seconds")}
  `;
}

// Formats a time unit (hours, minutes, seconds) to ensure two digits
export function formatTimeUnit(unit) {
  return String(unit).padStart(2, "0");
}

// Determines the current status of the countdown based on the current time
export function determineStatus(startAt, endEntryTime) {
  const timeUntilStart = calculateTimeRemaining(startAt);
  const timeUntilEndOfreg = calculateTimeRemaining(endEntryTime);

  if (timeUntilStart > 0) {
    return "starting"; // Event has not started yet
  } else if (timeUntilEndOfreg > 0) {
    return "lateReg"; // Event started, but late registration is still open
  } else {
    return "closed"; // Late registration is closed
  }
}

export const formatTimeHHMM = (dateTime) => {
  const dateObj = new Date(dateTime); // Convert string to Date object
  if (isNaN(dateObj.getTime())) {
    throw new Error("Invalid Date");
  }

  return new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Use 24-hour format
  }).format(dateObj);
};

// Utility to format date as YYYY-MM-DD
export const formatDateYYYYMMDD = (dateTime) => {
  const dateObj = new Date(dateTime); // Convert string to Date object
  if (isNaN(dateObj.getTime())) {
    throw new Error("Invalid Date");
  }
  return new Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(dateObj);
};

// Today's date in 'YYYY-MM-DD' format
export const getTodayInEasternTime = () => {
  // Get the current date in the Eastern Time Zone (EST/EDT)
  const today = new Date();
  const easternTime = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/New_York",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(today);

  // Convert the formatted date into 'YYYY-MM-DD' format for the date input
  const [month, day, year] = easternTime.split("/");
  return `${year}-${month}-${day}`;
};

/**
 * Formats a time from a Date object or a date string based on the provided language code.
 *
 * @function formatTimeString
 * @param {Date|string} date - A Date object or a date string in ISO format.
 * @param {string} [lng='en'] - The language code for localization (e.g., 'en' for English, 'fr' for French).
 * @returns {string} - The formatted time string based on the locale.
 *
 * @example
 * formatTimeString(new Date(1970, 0, 1, 12, 0), 'en'); // Returns '12:00 PM'
 * formatTimeString("2024-12-05T12:00:00.000-05:00", 'en'); // Returns '12:00 PM'
 * formatTimeString("2024-12-05T07:00:00.000Z", 'fr'); // Returns '07h00'
 *
 * @throws {TypeError} Will throw an error if `date` is not a valid Date object or string.
 */
export const formatTimeString = (date, lng = "en") => {
  // If 'date' is a string, attempt to convert it to a Date object
  if (typeof date === 'string') {
    date = new Date(date);
  }

  // Input validation: Ensure 'date' is a valid Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new TypeError(`Expected 'date' to be a valid Date object or ISO date string, but received: ${date}`);
  }

  // Define formatting options based on the language code
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: lng === 'en', // Use 12-hour format for English, 24-hour for others
  };

  // Create a formatter for the specified locale
  const formatter = new Intl.DateTimeFormat(lng, options);

  // Format the time
  let formattedTime = formatter.format(date);

  // Customize formatting for specific locales if necessary
  if (lng === 'fr') {
    // Replace ':' with 'h' to conform to French time notation (e.g., '19h00')
    formattedTime = formattedTime.replace(':', 'h');

    // Ensure leading zeros for single-digit hours
    const [formattedHour, formattedMinute] = formattedTime.split('h');
    const paddedHour = formattedHour.padStart(2, '0');
    formattedTime = `${paddedHour}h${formattedMinute}`;
  }

  return formattedTime;
};

