import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useErs } from "src/context/ers/ers.provider";
import { useHomepage } from "src/context/homepage/homepage.provider";
import styled from "styled-components/macro";
import GameListHero from "src/components/ElectronicGamingDevices/GameListHero";
import TitleSection from "../components/TitleSection/TitleSection";
import HeroCarousel from "../components/Carousel/HeroCarousel";
import Hero from "../components/Hero/Hero";
import Jackpots from "../components/ElectronicGamingDevices/Jackpots";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import {Tabs, Tab, Row, Col} from 'react-bootstrap';
import Button from "../components/Button/Button";

const Egd = () => {
  const { t, i18n } = useTranslation();
  const { data, fetchData } = useErs();
  const { gamingMachineJackpotData } = useHomepage();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const bgImage = "egd/promo_bg.jpg";
  const seeTheGamesButton = {
    label: t("egd.egdGameList.buttonText"),
    href: t("routes.egdGameList"),
    size: "medium",
    width: "xwide",
    specialHover: true,
    className: "mb-5 mt-0",
  }

  // Fetch the carousel data when the component mounts or language changes
  useEffect(() => {
    fetchData(`carousel-egd-${i18n.language}`)
    .then(() => {
      setError(null); // Clear error if successful
    })
    .catch((err) => {
      setError(true); // Set error to true on failure
      console.error("Error fetching carousel data:", err);
    });
  }, [fetchData, i18n.language]);

  return (
    <EgdDiv>
      <switcheroo-holder>
        <TitleSection title={t("egd.section-title")} text={t("egd.section-title-text")} />
        <HeroCarousel
          carouselData={data[`carousel-egd-${i18n.language}`] && data[`carousel-egd-${i18n.language}`].length > 0
          ? data[`carousel-egd-${i18n.language}`]
          : [t('defaultHeroCarousel.loading', { returnObjects: true })]}
        />
      </switcheroo-holder>

      <center-content>
        <Button {...seeTheGamesButton} />
      </center-content>

      <GameListHero jackpotData={gamingMachineJackpotData} compact />

      <RecentJackpotSection bgImage={useResponsiveImageUrl(bgImage)}  className="pb-5">
        <container-div>
          <TitleSection className="pb-0" title={t("egd.30-day-jackpots")} text={t("egd.30-day-jackpots-text")} />
          <JackpotTabs defaultActiveKey="recent-jackpots" id="egd-jackpot-tabs" fill >
            {/* Tab 1: Recent jackpots */}
            <Tab eventKey="recent-jackpots" title={t("egd.recentJackpots.tab")}>
              <TabContent>
                <Jackpots jackpotType={"recentJackpots"} jackpotData={gamingMachineJackpotData} />
              </TabContent>
            </Tab>
            <Tab eventKey="top-jackpots" title={t("egd.topJackpots.tab")}>
              <TabContent>
                <Jackpots jackpotType={"topJackpots"} jackpotData={gamingMachineJackpotData} />
              </TabContent>
            </Tab>
          </JackpotTabs>
        {/* <Row xs={1} md={2}>
          <Col>
            <Button label={t("egd.recentJackpots.tab")} size="medium" fluid />
          </Col>
          <Col>
            <Button label={t("egd.topJackpots.tab")} size="medium" fluid />
          </Col>
        </Row>
          <Jackpots jackpotType={"topJackpots"} jackpotData={gamingMachineJackpotData} /> */}
        </container-div>
      </RecentJackpotSection>

      <Hero
        className="inline-hero mt-5 promo-hero"
        mainHeroText={t("egd.promo-hero.main-hero-text")}
        darkenBg={0.4}
        bgImage="home/offers-header.jpg"
        descriptionBgColor="#0000004d"
        overlayVideoSrc="/videos/bbj-overlay.mp4"
        buttonSettings={{
          label: t("egd.promo-hero.buttonSettings.label"),
          href: t("routes.egdPromotions"),
          size: "large",
          width: "xwide",
          specialHover: true,
        }}
      />
    </EgdDiv>
  );
};

const EgdDiv = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--black);
  .inline-hero {
    // TODO: Move this to variant="inline" on the component
    padding-top: 0;
  }
  .promo-hero {
    @media (max-width: 768px) {
      h2 {
        font-size: 2rem;
      }
    }
  }
  .game-list-container {
    .hero_text_container {
      display: none;
    }
  }
`;

const RecentJackpotSection = styled.div`
  background-color: var(--black);
  padding: 20px;
  position: relative;
  container-div {
    padding: 0;
    z-index: 2;
    position: relative;
    > .title-section {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  .title-section, .sectional-tabs {
    z-index: 2;
    position: relative;
  }
`;

const JackpotTabs = styled(Tabs)`
  position: relative;
  z-index: 2;
  margin-bottom: 0!important;
  border-bottom-color: #ab7c1d;
  .nav-link {
    cursor: pointer!important;
    color: var(--mustard);
    border: 0px;
    &:hover, &.active {
      color: var(--black) !important;
      cursor: default;
      background-color: #d79c23;
    }
  }
  background-color: var(--lightblack);
  font-weight: 700;
  /* .tab-content {
    padding: 2rem;
    background: var(--white);
    color: var(--lightblack);
  } */
`;

const TabContent = styled.div`
  background-color: var(--lightblack);
  /* background-color: rgba(255, 255, 255, 0.5); */
`;

export default Egd;
