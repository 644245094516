import styled from "styled-components";
import PgCard from "../Card/PgCard";
import { formatAmount } from "../../utils/formatters";
import { useTranslation } from "react-i18next";
import { Col } from 'react-bootstrap';


const GameListCard = ({ game, jackpot }) => {
  const { i18n, t } = useTranslation();
  const imgDir = 'egd/game-list/';

  return (
    <CardHolder
      key={game.game_name}
      minBetLabel={t("egd.egdGameList.minbet")}
      jackpotMinBetLabel={t("egd.egdGameList.jackpotMinbet")}
      minBetValue={formatAmount(game.min_bet, i18n.language, true, false)}
      jackpotMinBetValue={formatAmount(game.jackpot_min_bet, i18n.language, true, false)}
      className="position-relative"
      >
      {jackpot && (
        <ProgressiveAmount>
          <span className="prog-label">{`${t("egd.egdGameList.progressive")}*`}</span>
          <span className="prog-amount">
            {formatAmount(jackpot.amount, i18n.language)}
          </span>
        </ProgressiveAmount>
      )}
      <PgCard
        className={jackpot ? "has-jackpot" : ""}
        image={game.image ? `${imgDir}${game.image}` : 'egd/promo_bg.jpg'}
        imageAlt={game.game_name}
        title={game.game_name}
        subtitle={
          game[`title_${i18n.language}`]
            ? game[`title_${i18n.language}`]
            : game.title_en
        }
        text={game[`text_${i18n.language}`]}
      />
    </CardHolder>
  );
};

const CardHolder = styled(Col).withConfig({
  shouldForwardProp: (prop) =>
    !["minBetValue", "jackpotMinBetValue", "minBetLabel", "jackpotMinBetLabel"].includes(prop),
  })`
  .card-header {
    border-bottom-color: var(--pinklinkdark);
    flex-grow: 0;
  }
  .pg-card.has-jackpot {
    .card-header {
      border-bottom-color: var(--mustard);
    }
  }
  .card-subtitle h4 {
    color: var(--black);
  }
  .card-body {
    flex-grow: 1;
  }
  .card-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    p {
      width: 100%;
      order: 3;
      margin-top: 3px;
    }
  }

  .card-text:before,
  .card-text:after {
    color: var(--lightblack);
    border-radius: 1rem;
    padding: 0 14px;
    font-weight: 600;
    font-size: 14px;
    margin-left: -2px;
    white-space: nowrap;
  }

  .card-text:before {
    content: ${(props) => (props.minBetValue ? `"${props.minBetLabel} ${props.minBetValue}"` : "")};
    display: ${(props) => (props.minBetValue ? "inline-block" : "none")};
    background-color: var(--pinklinkdark);
    order: 1;
  }

  .card-text:after {
    content: ${(props) => (props.jackpotMinBetValue ? `"${props.jackpotMinBetLabel} ${props.jackpotMinBetValue}"` : "")};
    display: ${(props) => (props.jackpotMinBetValue ? "inline-block" : "none")};
    background-color: var(--mustard);
    order: 2;
  }


`;


const ProgressiveAmount = styled.div`
  position: absolute;
  top: -8px;
  right: 20px;
  z-index: 5;
  background: var(--mustard);
  padding: 0.1rem 0.7rem 0.2rem ;
  font-weight: 900;
  span {
    color: #3c1c19;
    display: block;
    text-align: center;
    font-size: 0.8rem;
    &.prog-label {
      padding-left: 5px;
    }
    &.prog-amount {
      line-height: 2rem;
      font-size: 2rem;
      letter-spacing: -1px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 9px 9px;
    border-color: transparent transparent #8d5e12 transparent;
  }
`;

export default GameListCard;