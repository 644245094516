import ContextProviderComposer from "./ContextProviderComposer";
import { EntertainmentStateProvider } from "./entertainment/entertainment.provider";
import { HomepageStateProvider } from "./homepage/homepage.provider";
import { PromotionsStateProvider } from "./promotions/promotions.provider";
import { AccountStateProvider } from "./account/account.provider";
import { ToastStateProvider } from "./toast/toast.provider";
import { ScrollPositionProvider } from "./scrollPosition/scrollPosition.provider";
import { ErsStateProvider } from "./ers/ers.provider";

const CombinedContextProviders = ({ children }) => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <ScrollPositionProvider key={"scroll_position_provider"} />,
        <ToastStateProvider key={"toast_state_provider"} />,
        <HomepageStateProvider key={"homepage_state_provider"} />,
        <PromotionsStateProvider key={"promotions_state_provider"} />,
        <EntertainmentStateProvider key={"entertainment_state_provider"} />,
        <AccountStateProvider key={"account_state_provider"} />,
        <ErsStateProvider key={"ers_state_provider"} />
      ]}
    >
      {children}
    </ContextProviderComposer>
  );
};

export default CombinedContextProviders;
