import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { useLocation } from "react-router-dom";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
// import { promotionsSecondPart } from "./PromotionsSecond";

const PeaceLoveAndMoney = () => {
  const { i18n } = useTranslation();
  const lng = i18n.language;
  const [isActive, setIsActive] = React.useState("Psychedelic Ticket");
  const handleisActive = (value) => {
    setIsActive(value);
  };
  const { pathname } = useLocation();
  const { darkThemeFn } = useHomepage();
  useEffect(() => {
    darkThemeFn(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  return (
    <PromoDetailDiv>
      <PartOne
        bgImage={useResponsiveImageUrl("promotions/peace-love-money-bg.jpg")}
      >
        <ResponsiveImage
          src={`promotions/peace-love-money-logo-${lng}.png`}
          alt="peace-love-money-logo"
        />
      </PartOne>
      <PartTwo bgImage={useResponsiveImageUrl("promotions/flower-bg-plm.jpg")}>
        <h1>March 27 - May 7</h1>
        <p>
          Earn entries daily to our latest promotion and return{" "}
          <strong>every Sunday from March 27 to May 7</strong>, for a chance to
          be one of the <strong>15 winners</strong> that will get{" "}
          <strong>a shot at winning $250,000!</strong> Now that’s groovy!
        </p>
        <h2>2:00pm | 3:30pm | 5:00pm | 6:30pm | 8:00pm</h2>
        <p>15 WINNERS PER DRAW DAY GET A SHOT AT $250,000</p>
      </PartTwo>
      <PartThree
        bgImage={useResponsiveImageUrl(
          "promotions/peace-love-money-finale-bg.jpg"
        )}
      >
        <div>
          <ResponsiveImage
            src={`promotions/PLMF-${lng.toUpperCase()}.png`}
            alt="PLMF"
          />
          <p>
            COLLECT{" "}
            <strong>
              PEACE, LOVE & MONEY PSYCHEDELIC TICKETS FOR A CHANCE TO WIN
              $500,000!
            </strong>
          </p>
          <div>
            <div>
              <p>APRIL 30 | 9:00PM</p>
              <p>
                <strong>+3 CHANCES AT $500,000</strong>
              </p>
            </div>
            <div>
              <p>MAY 7 | 9:00PM</p>
              <p>
                <strong>+5 CHANCES AT $500,000</strong>
              </p>
            </div>
          </div>
          <div>
            <h2>
              <strong>HOW DO YOU GET A PSYCHEDELIC TICKET?</strong>
            </h2>
            <div>
              <p>
                <strong>Hot Seat Draws</strong>
              </p>
              <p>Every Thursday</p>
              <p>From March 30 to April 27</p>
              <p>2 per hour | 11:00am to 11:00pm</p>
              <p>Winners are also awarded $50 in Free Play</p>
            </div>
            <div>
              <p>
                <strong>Daily Hot Seat Draws</strong>
              </p>
              <p>From May 1 to 5</p>
              <p>2 per hour | 11:00am to 11:00pm</p>
            </div>
            <p>
              And all weekly <strong>Peace, Love & Money contestants</strong>{" "}
              will receive a <strong>Psychedelic Ticket</strong> to the{" "}
              <strong>Finales on April 30 and May 7</strong>.
            </p>
          </div>
        </div>
      </PartThree>
      <PartFour>
        <div>
          <a
            href="###"
            onClick={() => handleisActive("Psychedelic Ticket")}
            className={isActive === "Psychedelic Ticket" ? "active" : undefined}
          >
            Psychedelic Ticket
          </a>
          <a
            href="###"
            onClick={() => handleisActive("How to Qualify")}
            className={isActive === "How to Qualify" ? "active" : undefined}
          >
            How to Qualify
          </a>
          <a
            href="###"
            onClick={() => handleisActive("Draw Process")}
            className={isActive === "Draw Process" ? "active" : undefined}
          >
            Draw Process
          </a>
          <a
            href="###"
            onClick={() => handleisActive("Game Description")}
            className={isActive === "Game Description" ? "active" : undefined}
          >
            Game Description
          </a>
          <a
            href="###"
            onClick={() => handleisActive("Eligibility")}
            className={isActive === "Eligibility" ? "active" : undefined}
          >
            Eligibility
          </a>
          <a
            href="###"
            onClick={() => handleisActive("General Rules")}
            className={isActive === "General Rules" ? "active" : undefined}
          >
            General Rules
          </a>
        </div>
        {isActive === "Psychedelic Ticket" && (
          <PsychedelicTicket
            selected={isActive}
            bgImage={useResponsiveImageUrl(
              "promotions/peace-love-money-finale-bg.jpg"
            )}
          >
            <ResponsiveImage
              src={`promotions/PSYCHEDELIC_LOGO_${lng.toUpperCase()}.png`}
              alt="PLMPSYCHEDELIC_LOGOF"
            />
            <h2>
              Psychedelic Tickets are your key to playing the 2 Peace, Love &
              Money Finales for a chance to win $500,000!
            </h2>
            <div>
              <ol>
                <li>
                  Only players that have collected Peace, Love & Money
                  Psychedelic Tickets between March 27 and May 7, 2023, are
                  eligible for the Peace, Love & Money Final draws on April 30
                  (3 draws) and May 7 (5 draws).
                </li>
                <li>
                  Players collect Psychedelic Tickets the following ways:{" "}
                  <ol type="a">
                    <li>
                      Psychedelic Tickets are awarded to all players who get
                      called to play Peace, Love & Money on any of the draw
                      days. Players can only play Peace, Love & Money once per
                      draw day, but still receive a Psychedelic Ticket for every
                      time they are called up.
                    </li>
                    <li>
                      Psychedelic Tickets are awarded, along with $50 in Free
                      Play via{" "}
                      <strong>
                        Hot Seat draw, every Thursday between March 30 and April
                        27
                      </strong>
                      , at a rate of 2 per hour from 11:00am to 11:00pm.
                    </li>
                    <li>
                      Psychedelic Tickets are awarded via{" "}
                      <strong>
                        Hot Seat draw, every day between May 1 to May 5
                      </strong>
                      , at a rate of 2 per hour from 11:00am to 11:00pm.
                    </li>
                    <li>
                      Psychedelic Tickets can also be randomly awarded by floor
                      staff.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </PsychedelicTicket>
        )}
        {isActive === "How to Qualify" && (
          <HowToQuilify selected={isActive}>
            <h2>How to Qualify</h2>
            <p>
              There are TWO (2) ways to participate in this promotion, as
              detailed in rules 1, 2 and 4
            </p>
            <ol>
              <li>
                Players receive ONE (1) draw entry for every TWO HUNDRED AND
                FIFTY (250) Rewards Points earned while playing Electronic
                Gaming Devices (“EGD”) or Electronic Table Games (“ETG”).
              </li>
              <li>
                After every gaming session, players must present themselves to
                one of the cashiers, and request to have their entries printed.
                Players then need to place their entries inside the Peace, Love
                & Money raffle drum located on the gaming machines gaming floor.
              </li>
              <li>
                Red tier and Black tier members also received one (1) electronic
                draw entry for every two hundred and fifty (250) Rewards Points
                earned while playing gaming machines.
              </li>
              <li>
                Players may also purchase entries for TWO HUNDRED AND FIFTY
                (250) points.
              </li>
              <li>
                Players may redeem a maximum of TWENTY-FIVE (25) draw entries
                per day.
              </li>
              <li>
                Draw entries are only available for collection on the day they
                were earned.
              </li>
              <li>
                For every draw day, players have up to ONE (1) week to earn
                raffle entries, as per the schedule below
              </li>
            </ol>
            <table>
              <thead>
                <tr>
                  <th>Earning Period</th>
                  <th>Draw Day</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>March 27 at 12:00am - April 2 at 7:55pm</td>
                  <td>April 2 (15 draws)</td>
                </tr>
                <tr>
                  <td>April 2 at 7:56pm - April 9 at 7:55pm</td>
                  <td>April 9 (15 draws)</td>
                </tr>
                <tr>
                  <td>April 9 at 7:56pm - April 16 at 7:55pm</td>
                  <td>April 16 (15 draws)</td>
                </tr>
                <tr>
                  <td>April 16 at 7:56pm - April 23 at 7:55pm</td>
                  <td>April 23 (15 draws)</td>
                </tr>
                <tr>
                  <td>April 23 at 7:56pm - April 30 at 7:55pm</td>
                  <td>April 30 (15 draws)</td>
                </tr>
                <tr>
                  <td>April 30 at 7:56pm - May 7 at 7:55pm</td>
                  <td>May 7 (15 draws)</td>
                </tr>
              </tbody>
            </table>
            <ol start="8">
              <li>
                To be part of a draw, a ballot must be deposited into the
                designated draw drum by the contestant before the corresponding
                earning period ends.
              </li>
            </ol>
            <h3>Peace, Love & Money Finales</h3>
            <ol start="1">
              <li>
                Only players that have collected Psychedelic Tickets between
                March 27 and May 7, 2023, are eligible to play.
              </li>
              <li>
                Players collect Psychedelic Tickets the following ways:
                <ol type="a">
                  <li>
                    Psychedelic Tickets are awarded to all players who get
                    called to play Peace, Love & Money on any of the draw days.
                    Players can only play Peace, Love & Money once per draw day,
                    but still receive a Psychedelic Ticket for every time they
                    are called up.
                  </li>
                  <li>
                    Psychedelic Tickets are awarded, along with $50 in Free Play
                    via{" "}
                    <strong>
                      Hot Seat draw, every Thursday between March 30 and April
                      27
                    </strong>
                    , at a rate of 2 per hour from 11:00am to 11:00pm.
                  </li>
                  <li>
                    Psychedelic Tickets are awarded via{" "}
                    <strong>
                      Hot Seat draw, every day between May 1 to May 5
                    </strong>
                    , at a rate of 2 per hour from 11:00am to 11:00pm.
                  </li>
                  <li>
                    Psychedelic Tickets can also be randomly awarded by floor
                    staff.
                  </li>
                </ol>
              </li>
            </ol>
          </HowToQuilify>
        )}
        {isActive === "Draw Process" && (
          <DrawProcess selected={isActive}>
            <h2>Draw Process</h2>
            <ol>
              <li>
                There are a total of NINETY (90) prizes available to be won via
                this promotion. THIRTY (30) with a top prize of TWO HUNDRED
                FIFTY THOUSAND DOLLARS ($250,000 CAD) and EIGHT (8) with a top
                prize of FIVE HUNDRED THOUSAND DOLLARS ($500,000 CAD). Plus
                FIFTY-TWO (52) Free Play prizes ranging from $100 to $500 in
                value.
              </li>
              <li>
                Draws are conducted five times per draw day, at 2:00pm, 3:30pm,
                5:00pm, 6:30pm and 8:00pm, on the following days:
                <ol type="a">
                  <li>Sunday April 2, 2023</li>
                  <li>Sunday April 9, 2023</li>
                  <li>Sunday April 16, 2023</li>
                  <li>Sunday April 23, 2023</li>
                  <li>Sunday April 30, 2023</li>
                  <li>Sunday May 7, 2023</li>
                </ol>
              </li>
              <li>
                Three (3) contestants are picked at each draw time, for a total
                of fifteen (15) contestants per draw day, via the following
                methods:
                <ol type="a">
                  <li>
                    Two (2) contestants will be selected from the raffle drum,
                    as per rules 1-4 in the How to Qualify section.
                  </li>
                  <li>
                    For the draws held at 2:00pm, 5:00pm and 8:00pm, one (1)
                    contestant will be selected by electronic raffle among{" "}
                    <strong>Black tier</strong> members.
                  </li>
                  <li>
                    For the draws held at 3:30pm and 6:30pm, one (1) contestant
                    will be selected by electronic raffle among{" "}
                    <strong>Red tier</strong> members.
                  </li>
                  <li>
                    To be eligible for their tier specific electronic draws,{" "}
                    <strong>Black and Red tier</strong> members are required to
                    check-in prior to the scheduled draw time. Check-in is done
                    by earning at least twenty five (25) Rewards Points points,
                    on any EGD, within the 30 minute window prior to the draw.
                    The periods are as follows: 1:30pm to 2:00pm, 3:00pm to 3:30
                    pm, 4:30pm to 5:00pm, 6:00pm to 6:30pm and 7:30pm to 8:00pm.
                  </li>
                </ol>
              </li>
              <li>
                Psychedelic Ticket draws for the Finales are conducted after the
                2:00pm, 5:00pm and 8:00pm games on Sunday, April 30 and after
                2:00pm, 3:30pm, 5:00pm, 6:30pm and 8:00pm games on Sunday, May
                7.
              </li>
            </ol>
          </DrawProcess>
        )}
        {isActive === "Game Description" && (
          <GameDescription selected={isActive}>
            <h2>Game Description</h2>
            <ol>
              <li>
                The <strong>Peace, Love & Money</strong> game board contains
                FIFTEEN (15) envelopes plus THREE (3) red envelopes for the side
                game.
              </li>
              <li>
                THREE (3) contestants are drawn during each draw time. (See Draw
                Process)
              </li>
              <li>
                Each of the THREE (3) contestants chooses a red envelope, ONE
                (1) will contain a card with the Peace, Love & Money symbols,
                the other two (2) will have a card that says “SPIN TO WIN”. The
                contestant with the Peace, Love & Money symbols, plays the game.
                <ol type="a">
                  <li>
                    The TWO (2) contestant , whose envelope contained “SPIN TO
                    WIN” will get to spin the bonus wheel, which will determine
                    the amount of their prize in free play. The bonus wheel
                    contains the following options:
                    <ol>
                      <li>10 x $100 in Free Play</li>
                      <li>6 x $250 in Free Play</li>
                      <li>4 x $500 in Free Play</li>
                    </ol>
                  </li>
                  <li>
                    All THREE (3) contestants also win a{" "}
                    <strong>Psychedelic Ticket</strong>. (more details below).
                  </li>
                </ol>
              </li>
              <li>
                There are FIVE (5) draw times throughout the day, for a total of
                FIFTEEN (15) contestants, per draw day.
              </li>
              <li>
                A total of FIVE (5) contestants will move on to play the Peace,
                Love & Money game, each draw day.
              </li>
              <li>
                On the gameboard, we will place a total of FIFTEEN (15)
                envelopes.
                <ol>
                  <li>
                    Each winner will play from their own unique set of FIFTEEN
                    (15) envelopes.
                  </li>
                  <li>
                    Each envelope holds a predetermined dollar value on a sheet
                    of paper.
                  </li>
                </ol>
              </li>
              <li>
                To win:
                <ol>
                  <li>
                    If the total sum of the FIVE (5) selections{" "}
                    <strong>
                      totals exactly THREE THOUSAND DOLLARS ($3,000 CAD)
                    </strong>
                    , the contestant will be awarded the grand prize of TWO
                    HUNDRED FIFTY THOUSAND DOLLARS ($250,000 CAD).
                  </li>
                  <li>
                    If the total sum of the FIVE (5) selections is{" "}
                    <strong>
                      less than THREE THOUSAND DOLLARS ($3,000 CAD)
                    </strong>
                    , the contestant will keep the total value as a consolation
                    prize.
                    <ol>
                      <li>
                        In the event, the winner does not select the correct
                        FIVE (5) envelopes totalling THREE THOUSAND DOLLARS
                        ($3,000 CAD), the GFM may open the Master Envelope to
                        show where the winning combination of FIVE (5) are
                        located within each set.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                On the days of the Finales, April 30 and May 7, at selected draw
                times, once the winner has completed the Peace, Love & Money
                game, we will draw ONE (1) Psychedelic Ticket winner, to return
                for the finale game at 9:00pm.
                <ol>
                  <li>
                    <strong>April 30</strong> - Psychedelic Ticket draws will be
                    conducted immediately following the game for the 2:00pm,
                    5:00pm, and 8:00pm draws, for a total of THREE (3) players.
                  </li>
                  <li>
                    <strong>May 7</strong> - Psychedelic Ticket draws will be
                    conducted immediately following the game for the 2:00pm,
                    3:30pm, 5:00pm, 6:30pm and 8:00pm for a total of FIVE (5)
                    players.
                  </li>
                </ol>
              </li>
            </ol>
            <h3>
              <em>Peace, Love & Money Finales</em>
            </h3>
            <p>
              The <strong>Peace, Love & Money Finale</strong> versions of the
              game will be played on <strong>Sunday, April 30 and May 7</strong>
              , 2023 at 9:00pm. Winners will be pulled from the game specific
              Psychedelic Ticket raffle drum. The game will be played following
              the same rules and regulations as prior weeks, with the only
              difference being the amount of money that’s available to win.
              During the finale, contestants have a chance to win a cash prize
              of <strong>FIVE HUNDRED THOUSAND DOLLARS ($500,000 CAD)</strong>.
            </p>
            <ol>
              <li>
                Everyone that collected a <strong>Psychedelic Ticket</strong>{" "}
                since March 27 is invited back to play the finales{" "}
                <strong>Sunday, April 30 and May 7, 2023</strong> at 9:00pm.
              </li>
              <li>
                In the finale, we’ll draw the below number of contestants from
                the list of Psychedelic Ticket holders. Contestants will not
                need to select an envelope to play the game, they will
                automatically move forward to play the game.
                <ol>
                  <li>
                    <strong>Sunday, April 30 - THREE(3) contestants</strong>{" "}
                    will play for a chance at FIVE HUNDRED THOUSAND DOLLARS
                    ($500,000 CAD)
                  </li>
                  <li>
                    <strong>Sunday, April 30 - THREE(3) contestants</strong>{" "}
                    will play for a chance at FIVE HUNDRED THOUSAND DOLLARS
                    ($500,000 CAD)
                  </li>
                  <li>
                    Sunday, May 7 - FIVE (5) contestants will play for a chance
                    at FIVE HUNDRED THOUSAND DOLLARS ($500,000 CAD).
                  </li>
                  <li>Contestants will play in the order they were drawn.</li>
                </ol>
              </li>
              <li>
                On the gameboard, we will place a total of FIFTEEN (15)
                envelopes.
                <ol>
                  <li>
                    Each winner will play from their own unique set of FIFTEEN
                    (15) envelopes.
                  </li>
                  <li>
                    Each envelope holds a predetermined dollar value on a sheet
                    of paper.
                  </li>
                </ol>
              </li>
              <li>
                To win:
                <ol>
                  <li>
                    If the total sum of the FIVE (5) selections{" "}
                    <strong>
                      totals exactly FIVE THOUSAND DOLLARS ($5,000 CAD)
                    </strong>
                    , the contestant will be awarded the grand prize of FIVE
                    HUNDRED THOUSAND DOLLARS ($500,000 CAD)
                  </li>
                  <li>
                    If the total sum of the FIVE (5) selections is{" "}
                    <strong>
                      less than FIVE THOUSAND DOLLARS ($5,000 CAD)
                    </strong>
                    , the contestant will keep the total value as a consolation
                    prize.
                    <ol>
                      <li>
                        In the event, the winner does not select the correct
                        FIVE (5) envelopes totalling{" "}
                        <strong>FIVE THOUSAND DOLLARS ($5,000 CAD)</strong>, the
                        GFM may open the Master Envelope to show where the
                        winning combination of FIVE (5) are located within each
                        set.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </GameDescription>
        )}
        {isActive === "Eligibility" && (
          <Eligibility selected={isActive}>
            <h2>Eligibility</h2>
            <ol>
              <li>The promotion is open to all Playground members.</li>
              <li>The promotion takes place at Playground.</li>
              <li>
                Employees, agents, independent contractors performing services
                for Playground and assignees of Sponsor, its advertising
                agencies and promotional companies involved in this Promotion,
                as well as immediate family members (spouse, children, siblings
                and parents), shall be ineligible to participate in the
                Promotion and shall be ineligible to win any prize.
              </li>
            </ol>
          </Eligibility>
        )}
        {isActive === "General Rules" && (
          <GeneralRules selected={isActive}>
            <h2>General Rules</h2>
            <h3>$250,000 Top Prize</h3>
            <ol>
              <li>
                In the event that more than one person wins the top prize of TWO
                HUNDRED FIFTY THOUSAND DOLLARS ($250,000 CAD), the prize amount
                will be split evenly among all winners.
              </li>
              <li>
                In the event that the top prize of $250,000 is won, the winning
                player(s) acknowledge that payment will be available for
                collection as of May 8, 2023 - not on the day of the actual win.
              </li>
              <li>
                Guests are eligible to play once per draw day but still receive
                a Psychedelic Ticket for every time they are called up.
              </li>
              <li>
                When receiving their printed entries, it is the participating
                participant’s responsibility to verify that the correct number
                of ballots is issued.
              </li>
              <li>
                It is the participating patron’s responsibility to make sure
                they insert their ballot into the correct raffle drum. Failure
                to do so could result in disqualification if their name is
                selected.
              </li>
            </ol>
            <h3>$500,000 Top Prize</h3>
            <ol start="6">
              <li>
                In the event that more than one person wins the top prize of
                FIVE HUNDRED THOUSAND DOLLARS ($500,000 CAD), the prize amount
                will be split evenly among all winners.
              </li>
              <li>
                In the event that the top prize of FIVE HUNDRED THOUSAND DOLLARS
                ($500,000 CAD) is won, the winning player(s) acknowledge that
                payment will be available for collection as of May 8, 2023 - not
                on the day of the actual win.
              </li>
              <li>
                At the <strong>April 30 and May 7 Finales</strong>, all
                Psychedelic Ticket draw winners will be given five (5) minutes
                to present themselves to the game area after having their name
                called. If they have not shown up within this period, they shall
                be ineligible to play the Finale and we will select TWO (2)
                winners at the following draw time, and so on throughout the
                day.
              </li>
              <li>
                In the event that a draw winner fails to present themselves to
                the game area at the designated game time, for the Peace, Love &
                Money Finale, a FIVE (5) minute window will be issued. If the
                draw winner still cannot be found, a new name will be selected
                via the Psychedelic Tickets.
              </li>
            </ol>
            <h3>General</h3>
            <ol start="9">
              <li>
                Winners must be in attendance at the time of the draw to win.
                The selected names are announced over Playground’s internal
                public address system on the gaming floor. The selected names
                have FIVE (5) minutes from the time of the announcement to
                present themselves at the game area to participate.
              </li>
              <li>
                If, at the end of the above-mentioned time interval, an eligible
                selectee has not presented themselves at the designated area, a
                site representative draws ONE (1) additional ballot from the
                same raffle drum. This process continues until an eligible
                selectee is present.
              </li>
              <li>
                Guests are eligible to win once per Finale Draw Day. In the
                event someone has their name called on more than one occasion,
                by way of Psychedelic Ticket Draw, on the same day, the guest
                will receive ONE HUNDRED DOLLARS ($100 CAD) in Free Play for
                each additional draw, and a new Psychedelic Ticket will be
                selected.
              </li>
              <li>
                The chance of being selected in any draw is dependent on the
                number of entries received for that draw.
              </li>
              <li>
                All participants must present a valid government-issued photo
                identification upon request.
              </li>
              <li>
                Playground reserves the right to change or alter this promotion,
                as it deems appropriate, including, but not limited to, making
                changes or alterations to these Rules and Regulations relating
                to the accommodation of participants.
              </li>
              <li>
                Patrons with self-excluded or banned status, as determined by
                Playground, are not eligible for any of the gifts/prizes
                associated with Playground contests, promotions, tournaments or
                giveaways.
              </li>
              <li>All references to times and dates reflect Eastern Time.</li>
              <li>
                By participating in the promotion, contestants consent to the
                use of their names and photographs and likeness for promotional
                purposes without additional compensation.
              </li>
              <li>
                By participating in the promotion, winners hereby irrevocably
                permit, authorize, grant, and license Playground and their
                affiliates and partners the rights to display, publicly perform,
                exhibit, transmit, broadcast, reproduce, record, photograph,
                digitize, modify, alter, edit, adapt, exploit, sell, rent,
                license, otherwise use, and permit others to use, your name,
                image, likeness, and appearance, voice and other personal
                characteristics and private information in perpetuity throughout
                the universe in any medium or format whatsoever now existing or
                hereafter created, including but not limited to, in and on any
                print, publication, social media, video, media, display,
                point-of-sale, and other advertising and promotional materials,
                press releases, the internet and other digital transmission or
                delivery methods,mobile applications, on any platform and for
                any purpose, including but not limited to advertising, public
                relations, publicity, packaging, and promotion of Playground and
                its affiliates and their products, and services, without further
                consent from or royalty, payment, or other compensation to you.
              </li>
            </ol>
            <p>
              <strong>
                Questions relating to this promotion can be answered by visiting
                the Player Rewards desk
              </strong>
            </p>
          </GeneralRules>
        )}
      </PartFour>
      
    </PromoDetailDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: #e8ebef;
`;
const PartOne = styled.div`
  background: url(${(props) => props.bgImage}) 50% 100%;
  padding: 130px 0px 60px;
  background-size: cover;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > img:first-child {
    width: 100%;
    max-width: 800px;
    padding: 0 15px;
  }
  @media screen and (max-width: 479px) {
    & > div:last-child {
      display: none;
    }
  }
`;
const PartTwo = styled.div`
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto 60px auto;
  height: fit-content;
  clear: both;
  & > h1:first-child {
    font-size: 40px;
    font-weight: 800;
    line-height: 1.15em;
  }
  & > p:nth-child(2) {
    font-size: 18px;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 10px;
    color: #555;
  }
  & > h2:nth-child(3) {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 24px;
    color: #555;
  }
  & > p:last-child {
    background: url(${(props) => props.bgImage}) 50% 50%;
    background-size: contain;
    color: #316275;
    text-shadow: 2px 2px 0px #fff;
    border-radius: 5px;
    border: 2px solid #316275;
    max-width: 650px;
    width: 100%;
    font-size: 40px;
    line-height: 46px;
    font-weight: 900;
    margin: 20px auto;
    padding: 20px;
    text-transform: uppercase;
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding: 20px;
    margin: 20px 0;
    & > p:nth-child(2) {
      font-size: 16px;
    }
    & > p:last-child {
      margin-bottom: 0;
    }
  }
`;
const PartThree = styled.div`
  background: url(${(props) => props.bgImage}) 50% 50%;
  padding: 30px 0px 10px 0px;
  background-size: cover;
  padding: 40px;
  text-align: center;

  & > div:first-child {
    max-width: 1440px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    clear: both;
    & > img:first-child {
      margin: 0 auto;
      max-width: 800px;
      padding-bottom: 10px;
      width: 100%;
    }
    & > p:nth-child(2) {
      font-size: 26px;
      padding-bottom: 20px;
      text-align: center;
      color: #fff;
      font-weight: 400;
    }
    & > div:nth-child(3) {
      display: flex;
      flex: row;
      column-gap: 40px;
      justify-content: space-between;
      & > div {
        width: 100%;
        max-width: 700px;
        background: url("https://assets.playground.ca/pgfe/img/promotions/flower-bg-plm.jpg")
          50% 50%;
        background-size: contain;
        color: #316275;
        border-radius: 5px;
        padding: 20px;
        border: 2px solid #316275;
        & > p {
          font-size: 40px;
          text-align: center;
          font-weight: 700;
          color: #316275;
          text-shadow: 2px 2px 0px #fff;
          text-transform: uppercase;
          margin: 15px;
          & > strong {
            color: #31706e;
          }
        }
      }
    }
    & > div:nth-child(4) {
      padding: 30px 20px 20px 20px;
      background: var(--white);
      margin: 40px 0;
      & > h2:first-child {
        font-size: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #000;
        margin: 0;
        padding-bottom: 10px;
        line-height: 33px;
        & > strong {
          font-weight: 700;
        }
      }
      & > div {
        background-color: #316275;
        border-radius: 5px;
        border: 2px solid #316275;
        padding: 20px 10px;
        margin: 0 0 20px 0;
        & > p {
          font-size: 26px;
          padding-bottom: 20px;
          text-align: center;
          color: #fff;
          font-weight: 400;
          margin: 0;
          padding: 2px 0;
        }
      }
      & > p:last-child {
        color: #555;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding: 20px;
    & > div:first-child {
      & > p:nth-child(2) {
        margin: 0 0 10px;
      }
      & > div:nth-child(3) {
        row-gap: 40px;
        flex-direction: column;
        margin-bottom: 10px;
        & > div {
          & > p {
            line-height: 60px;
            margin: 0 10px;
          }
        }
      }
      & > div:nth-child(4) {
        margin: 20px 0;
        & > h2:first-child {
          line-height: 30px;
        }
        & > p:last-child {
          font-size: 14px;
        }
      }
    }
  }
`;
const PartFour = styled.div`
  margin: 40px auto;
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  padding-bottom: 40px;
  & > div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;
    margin-bottom: 40px;
    font-weight: 600;
    & > a {
      text-align: center;
      background-color: #232426;
      color: #fff;
      padding: 10px 15px;
      transition: 0.3s all;
      &:hover {
        background-color: #316275;
      }
    }
    .active {
      background-color: #316275;
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    padding: 20px;
    & > div:first-child {
      grid-template-columns: auto auto;
      & > a {
        font-size: 15px;
        padding: 10px 5px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding: 20px;
    margin: 20px auto;
    & > div:first-child {
      grid-template-columns: auto auto;
      margin-bottom: 20px;
      & > a {
        font-size: 15px;
        padding: 10px 5px;
      }
    }
  }
`;
const PsychedelicTicket = styled.div`
  display: ${({ selected }) =>
    selected === "Psychedelic Ticket" ? "block" : "none"};
  animation: ${fadeIn} 0.3s linear;
  background: url(${(props) => props.bgImage}) 50% 50%;
  padding: 30px 10px 20px 10px;
  background-size: cover;
  width: 100%;
  & > img {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  & > h2 {
    width: 100%;
    max-width: 1150px;
    color: #fff;
    margin: auto;
    text-align: center;
  }
  & > div:nth-child(3) {
    background-color: #fff;
    margin: 15px;
    padding: 25px;
    padding-left: 50px;
    & > ol {
      font-size: 15px;
      line-height: 28px;
      list-style: decimal;
      margin-top: 0;
      margin-bottom: 10px;
      & > li {
        color: #555;
        & > ol {
          list-style: lower-latin;
          padding-bottom: 0px;
          font-size: 15px;
          line-height: 28px;
          padding-left: 40px;
          & > li {
            color: #555;
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding: 30px 2px 20px 2px;
    & > div:nth-child(3) {
      margin: 10px;
      padding: 15px;
      & > ol {
        padding-left: 20px;
      }
    }
  }
`;
const HowToQuilify = styled.div`
  display: ${({ selected }) =>
    selected === "How to Qualify" ? "block" : "none"};
  animation: ${fadeIn} 0.3s linear;
  padding: 15px;
  background: var(--white);
  & > h2:first-child {
    color: #555;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    margin: 10px;
  }
  & > p:nth-child(2) {
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-style: italic;
  }
  & > ol:nth-child(3) {
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      margin: 5px 0;
      color: #555;
      font-size: 15px;
      line-height: 28px;
    }
  }
  & > table:nth-child(4) {
    margin: 20px 0px 20px 0px;
    width: 100%;
    background: var(--white);
    border-spacing: 0;
    border-collapse: collapse;
    & > thead {
      & > tr {
        text-align: center;
        background: #131619;
        color: #fff;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        & > th {
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          border: 1px solid #ddd;
          border-bottom: none;
        }
      }
    }
    & > tbody {
      & > tr {
        height: 40px;
        &:hover {
          background: #e8ebef;
        }
        & > td {
          border: 1px solid #ddd;
          font-weight: 300;
          text-align: center;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          font-size: 14px;
        }
      }
    }
  }
  & > ol:nth-child(5) {
    font-size: 15px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      color: #555;
    }
  }
  & > h3:nth-child(6) {
    margin: 20px 0 0;
    font-weight: 600;
    font-size: 19px;
    color: #555;
  }
  & > ol:nth-child(7) {
    font-size: 15px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      color: #555;
      & > ol {
        list-style-type: lower-alpha;
        margin-left: 40px;
      }
    }
  }
`;
const DrawProcess = styled.div`
  display: ${({ selected }) =>
    selected === "Draw Process" ? "block" : "none"};
  animation: ${fadeIn} 0.3s linear;
  padding: 15px;
  background: var(--white);
  & > h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: #555;
    line-height: 33px;
  }
  & > ol {
    font-size: 15px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      font-size: 15px;
      line-height: 28px;
      color: #555;
      & > ol {
        font-size: 15px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 10px;
        list-style-type: lower-latin;
        margin-left: 40px;
        & > li {
          font-size: 15px;
          line-height: 28px;
          color: #555;
        }
      }
    }
  }
`;
const GameDescription = styled.div`
  display: ${({ selected }) =>
    selected === "Game Description" ? "block" : "none"};
  animation: ${fadeIn} 0.3s linear;
  padding: 15px;
  background: var(--white);
  & > h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: #555;
    line-height: 33px;
  }
  & > ol {
    font-size: 15px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      font-size: 15px;
      line-height: 28px;
      color: #555;
      & > ol {
        font-size: 15px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 10px;
        list-style-type: lower-latin;
        margin-left: 40px;
        & > li {
          font-size: 15px;
          line-height: 28px;
          color: #555;
          & > ol {
            font-size: 15px;
            line-height: 28px;
            margin-top: 0;
            margin-bottom: 10px;
            list-style-type: lower-roman;
            margin-left: 40px;
            & > li {
              font-size: 15px;
              line-height: 28px;
              color: #555;
            }
          }
        }
      }
    }
  }
  & > h3 {
    margin: 20px 0 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #555;
    text-shadow: none;
    font-weight: normal;
  }
  & > p {
    font-size: 15px;
    line-height: 28px;
    padding-top: 10px;
    color: #555;
    margin: 0;
  }
`;
const Eligibility = styled.div`
  display: ${({ selected }) => (selected === "Eligibility" ? "block" : "none")};
  animation: ${fadeIn} 0.3s linear;
  padding: 15px;
  background: #fff;
  & > h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: #555;
    line-height: 33px;
  }
  & > ol {
    font-size: 15px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      font-size: 15px;
      line-height: 28px;
      color: #555;
    }
  }
`;
const GeneralRules = styled.div`
  display: ${({ selected }) =>
    selected === "General Rules" ? "block" : "none"};
  animation: ${fadeIn} 0.3s linear;
  padding: 15px;
  background: #fff;
  & > h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0px;
    text-align: center;
    color: #555;
    line-height: 33px;
  }
  & > ol {
    font-size: 15px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    list-style-type: decimal;
    margin-left: 40px;
    & > li {
      font-size: 15px;
      line-height: 28px;
      color: #555;
    }
  }
  & > h3 {
    margin: 20px 0 0;
    font-weight: 600;
    font-size: 19px;
    color: #555;
    text-shadow: none;
    line-height: 27px;
  }
  & > p {
    font-size: 16px;
    padding: 0;
    margin: 0;
    font-style: italic;
    line-height: 28px;
    text-align: center;
    color: #555;
  }
`;
export default PeaceLoveAndMoney;
