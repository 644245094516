import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { t } from "i18next";

const ContainerWrapper = styled.div`
  padding: 20px;
  background-color: #f8f8f8;
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

const Image = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
`;

const Description = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  background-color: #333;
  color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  @media (max-width: 768px) {
    padding: 5px;
    font-size: 12px;
  }
`;

const Td = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
  @media (max-width: 768px) {
    padding: 5px;
    font-size: 14px;
  }
`;

const TournamentTravelDiscount = ({ flight }) => {
  return (
    <ContainerWrapper>
      <Title>
        <ReactMarkdown>{flight?.title}</ReactMarkdown>
        <Image>
          <ResponsiveImage src={flight?.image} alt="Air Canada Promo" />
        </Image>
      </Title>
      <ContentWrapper>
        <Description>
          <ReactMarkdown>{flight?.description}</ReactMarkdown>
          <ReactMarkdown>{flight?.promoCode}</ReactMarkdown>
          <ReactMarkdown>{flight?.dates}</ReactMarkdown>
          <ReactMarkdown>{flight?.note}</ReactMarkdown>
        </Description>
      </ContentWrapper>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>{t("poker.tournaments.flight.travelZone")}</Th>
              <Th>{t("poker.tournaments.flight.fare")}</Th>
              <Th>{t("poker.tournaments.flight.discount")}</Th>
            </tr>
          </thead>
          <tbody>
            {flight?.table.map((zone, index) => (
              <React.Fragment key={index}>
                <tr>
                  <Td rowSpan={zone.fares.length}>
                    <ReactMarkdown>{zone.zone}</ReactMarkdown>
                  </Td>
                  <Td>{zone.fares[0].fare}</Td>
                  <Td>{zone.fares[0].discount}</Td>
                </tr>
                {zone.fares.slice(1).map((fare, fareIndex) => (
                  <tr key={fareIndex}>
                    <Td>{fare.fare}</Td>
                    <Td>{fare.discount}</Td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </ContainerWrapper>
  );
};

export default TournamentTravelDiscount;
