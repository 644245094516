export const scrollToElementById = (id) => {
  const element = document.getElementById(id);
  const navElement = document.getElementById('header-navbar');

  if (element && navElement) {
    const offset = navElement.offsetHeight;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset + 20;
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  } else if (element) {
    // Fall back to scrollIntoView if no navbar is present
    element.scrollIntoView({ behavior: 'smooth' });
  }
};