import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAccount } from "src/context/account/account.provider";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Offcanvas from 'react-bootstrap/Offcanvas';
import BsButton from 'react-bootstrap/Button';
import { tournamentTypeToString } from '../../utils/tournamentUtils';
import { formatDate, formatAmount } from "src/utils/formatters";
import { FaArrowLeft, FaShoppingCart, FaTimes, FaTrash } from 'react-icons/fa';

const HoverCart = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { access_token, accountCart, accountCartUpdateFn } = useAccount();
  const { t, i18n } = useTranslation();

  const currentLocale = i18n.language;
  const isLoggedIn = !!access_token;
  const itemsInCartCount = accountCart?.order_items?.length;
  const showCart = isLoggedIn && accountCart && itemsInCartCount > 0;

  return (
    <>
      {showCart && (
        <HoverCartContainer className={showCart ? "bounce" : ""}>
          <HoverCartButton onClick={handleShow}>
            <FaShoppingCart className="main-icon" />
            <div className="floating-icon">{itemsInCartCount || 0}</div>
          </HoverCartButton>

          <HoverCartOffcanvas show={show} onHide={handleClose} placement={'end'} className="dark">
            <Offcanvas.Header>
              <Offcanvas.Title>
                <span>
                  <h5><FaShoppingCart/> {t("tournament.cart.cart")}</h5>
                  <Link to={t("routes.pokerTournamentCalendar")} onClick={handleClose}><FaArrowLeft/> {t("tournament.cart.backToCalendar")}</Link>
                </span>
                <HoverCartButton onClick={handleClose}>
                  <FaTimes className="main-icon" />
                  <div className="floating-icon">{itemsInCartCount || 0}</div>
                </HoverCartButton>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

              {/* {console.log(accountCart)} */}
              {accountCart?.order_items?.length > 0 ? (
                accountCart.order_items.map((item) => (
                  <HoverCartItem key={item.gid} className={`hover-cart-item tournament-type-${tournamentTypeToString(item.item_details.type)} id-${item.item_details.id}`}>
                    <h4>{item.item_details[`name_${currentLocale}`] || item.item_details.name_en }</h4>
                    <p>{item.item_details.start_time}, {formatDate(item.item_details.start_date, currentLocale, true)}</p>
                    <p className="d-flex justify-content-between">
                      <span className="price">{formatAmount(item.price, currentLocale, true)}</span>
                      <span className="remove-from-cart"
                          onClick={() => {
                            accountCartUpdateFn("remove", item.gid);
                          }}>
                        <small>
                          {t("button.remove-cart")}
                        </small>
                        <FaTrash/>
                      </span>
                    </p>
                  </HoverCartItem>
                ))
              ) : (
                <div>{t("tournament.cart.empty")}</div>
              )}
              </Offcanvas.Body>
              <OffcanvasFooter>
                {/* <p className="total mb-0 mt-1">
                  {t("tournament.cart.total")}
                </p> */}
                <h5 className="mb-0 mt-3">
                  {formatAmount(
                    accountCart.total_price,
                    currentLocale,
                    true,
                    false
                  )}<span className="grey">*</span>
                </h5>
                <Button
                  href={t("routes.checkout")}
                  label={t("tournament.card.checkout")}
                  fluid
                  size="large"
                  onClick={handleClose} // Close the overlay when the button is clicked
                />
                <p className="fees mt-0 grey"><small>*{t("tournament.cart.feesAdded")}</small></p>
              </OffcanvasFooter>
          </HoverCartOffcanvas>
        </HoverCartContainer>
      )}
    </>
  )
};

const HoverCartOffcanvas = styled(Offcanvas)`
  border: solid 4px var(--lightblack)!important;
  box-shadow: -10px 0 20px rgba(0,0,0,0.5);
  z-index: 2000;
  .offcanvas-body {
    // for footer contents
    margin-bottom: calc(9rem + 10px);
    background-color: var(--black);
  }
  .offcanvas-header {
    background-color: var(--lightblack);
  }
  .offcanvas-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    button {
      transform: scale(0.8);
    }
    h5 {
      font-size: 1.8rem;
      color: var(--white);
    }
    a {
      color: var(--bluelinkdark);
      font-weight: normal;
      font-size: 0.9rem;
      display: block;
    }
  }
`;

const OffcanvasFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: var(--bs-offcanvas-width);
  max-width: 100%;
  @media (max-width: 543.98px) {
    width: 100vw;
  }
  background-color: var(--lightblack);
  padding: 0 1.2rem;
  text-align: center;
  h5 {
    font-size: 1.8rem;
    font-weight: bolder;
    color: var(--white);
  }
`;

const HoverCartItem = styled.div`
  border-width: 0;
  border-left: solid 4px transparent;
  color: var(--white);
  padding: 0.1rem 1rem;
  margin-bottom: 1rem;
  h4 {
    font-size: 1.1rem;
    font-weight: bold;
  }
  &.tournament-type-festival {
    border-left-color: var(--fc-tournament-festival);
    background-color: var(--fc-tournament-festival-muted);
  }
  &.tournament-type-online {
    border-left-color: var(--fc-tournament-online);
    background-color: var(--fc-tournament-online-muted);
  }
  &.tournament-type-satellite {
    border-left-color: var(--fc-tournament-satellite);
    background-color: var(--fc-tournament-satellite-muted);
  }
  &.tournament-type-daily {
    border-color: var(--fc-tournament-daily);
    background: var(--fc-tournament-daily-muted);
  }
  .price {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .remove-from-cart {
    cursor: pointer;
    color: var(--pinklinkdark);
    &:hover {
      color: var(--white);
    }
    svg {
      margin-left: 0.5rem;
      font-size: 1rem;
      position: relative;
      bottom: 2px;
    }

  }
`;

const HoverCartContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  width: 3rem;
  height: 3rem;
  @media (min-width: 768px) {
    width: 4rem;
    height: 4rem;
    right: 70px;
    bottom: 70px;
  }
`;

const HoverCartButton = styled(BsButton)`
  width: 60px;
  height: 60px;
  position: relative;
  background-color: var(--red);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0,0,0,1);
  :hover {
    background-color: var(--red);
    transform: scale(1.1);
  }
  .main-icon {
    color: white;
    font-size: 2rem;
    padding-right: 3px;
  }
  .floating-icon {
    box-shadow: -2px 2px 5px rgba(0,0,0,0.6);
    background-color: white;
    color: var(--red);
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -12px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-weight: bold;
  }
  cursor: pointer;
  @media (min-width: 768px) {
    width: 70px;
    height: 70px;
    .main-icon {
      font-size: 2.5rem;
    }
    .floating-icon {
      font-size: 20px;
      width: 35px;
      height: 35px;
    }
  }
`;

export default HoverCart;
