import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TypesOfPoints = () => {
  const { t, i18n } = useTranslation();
  return (
    <Main>
      <p>{t("rewards.section-2-top-text")}</p>
      <h2>{t("rewards.section-2-top-content-1-title")}</h2>
      <p>{t("rewards.section-2-top-content-1-text-1")}</p>
      <p>
        <strong>{t("rewards.section-2-top-content-1-text-2-part-1")} </strong>
        {t("rewards.section-2-top-content-1-text-2-part-2")}
      </p>
      <p>{t("rewards.section-2-top-content-1-text-3")}</p>
      <h2>{t("rewards.section-2-top-content-2-title")}</h2>
      <p>{t("rewards.section-2-top-content-2-text")}</p>
      <h2>{t("rewards.section-2-top-content-3-title")}</h2>
      <Table>
        <tbody>
          <tr>
            <th>{t("rewards.section-2-top-content-3-li-1")}</th>
            {i18n.language === "en" ? (
              <td>
                One (1) point for every <strong>$1 wagered</strong>
              </td>
            ) : (
              <td>
                1 point pour chaque <strong>1$ misé</strong>
              </td>
            )}
          </tr>
          <tr>
            <th>{t("rewards.section-2-top-content-3-li-2")}</th>
            {i18n.language === "en" ? (
              <td>
                One (1) point for every $1.50 wagered{" "}
                <strong>(Roulette)</strong>, $2 wagered{" "}
                <strong>(Blackjack)</strong>, or $4 wagered{" "}
                <strong>(Baccarat)</strong>
              </td>
            ) : (
              <td>
                Un (1) point pour chaque 1,50$ misés <strong>(Roulette)</strong>
                , 2$ misés <strong>(Blackjack)</strong>, ou 4$ misés{" "}
                <strong>(Baccarat)</strong>
              </td>
            )}
          </tr>
          <tr>
            <th rowSpan={4}>
              {t("rewards.section-2-top-content-3-li-3-part-1")} <br />
              {t("rewards.section-2-top-content-3-li-3-part-2")}
            </th>
          </tr>
          <tr>
            {i18n.language === "en" ? (
              <td>
                Stakes from <strong>$1/$1</strong> to <strong>$1/$3</strong>{" "}
                earn <strong>300 points/hour</strong>
              </td>
            ) : (
              <td>
                Les limites entre <strong>1$/1$</strong> et{" "}
                <strong>1$/3$</strong> accumulent{" "}
                <strong>300 points/heure</strong>
              </td>
            )}
          </tr>
          <tr>
            {i18n.language === "en" ? (
              <td>
                Stakes from <strong>$2/$2</strong> to <strong>$5/$5</strong>,
                and <strong>$5/$10 NLH</strong> earn{" "}
                <strong>500 points/hour</strong>
              </td>
            ) : (
              <td>
                Les limites entre <strong>2$/2$</strong> et{" "}
                <strong>5$/5$</strong>, et <strong>5$/10$ NLH</strong>{" "}
                accumulent <strong>500 points/heure</strong>
              </td>
            )}
          </tr>
          <tr>
            {i18n.language === "en" ? (
              <td>
                Stakes from <strong>$10/$20</strong>, and{" "}
                <strong>$5/$10 PLO</strong> earn{" "}
                <strong>700 points/hour</strong>
              </td>
            ) : (
              <td>
                Les limites à partir de <strong>10$/20$</strong>, et{" "}
                <strong>5$/10$ PLO</strong> accumulent{" "}
                <strong>700 points/heure</strong>
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </Main>
  );
};
const Main = styled.main`
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  padding: 40px;
  background: var(--white);
  color: var(--black);
  & > h1 {
    font-weight: 700;
    text-align: center;
  }
  & > p:nth-child(2) {
    background: rgba(125, 125, 125, 0.2);
    padding: 25px 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  & > p {
    text-align: unset;
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 400;
    padding: 0;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px;
    & > h1 {
      font-size: 30px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin: 20px 0px 40px 0px;
  background: #fff;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
  & > tbody {
    & > tr {
      & > td,
      th {
        text-align: center;
        border: 1px solid #ddd;
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: middle;
        font-size: 16px;
        color: var(--black);
      }
      & > th {
        background-color: rgba(0, 0, 0, 0.1);
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > tbody {
      & > tr {
        & > td,
        th {
          font-size: 14px;
        }
      }
    }
  }
`;
export default TypesOfPoints;
