import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { ScrollPositionContext } from "src/context/scrollPosition/scrollPosition.provider"; // Adjust import path

const FAQ = () => {
  const { t } = useTranslation();
  const [accordion, setAccordion] = useState(null);
  const { setScrollPosition } = useContext(ScrollPositionContext); // Use context for scroll position

  const handleClick = () => {
    setScrollPosition(false); // Disable scrolling on link click
  };

  return (
    <MainDiv accordion={accordion}>
      <div>
        <h2
          className={accordion === 2 ? "active" : undefined}
          onClick={() => (accordion === 2 ? setAccordion(0) : setAccordion(2))}
        >
          {t("rewards.faq-li-2")}
        </h2>
        <section className={accordion === 2 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>
            {t("rewards.faq-li-2-text")}
            <Link
              to={t("pseudoRoutes.rewards.playerBenefits.path")}
              className="light"
              onClick={handleClick} // Set scroll to false on click
            >
              &nbsp;{t("rewards.faq-li-2-link")}
            </Link>
          </p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 3 ? "active" : undefined}
          onClick={() => (accordion === 3 ? setAccordion(0) : setAccordion(3))}
        >
          {t("rewards.faq-li-3")}
        </h2>
        <section className={accordion === 3 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-3-text")}</p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 4 ? "active" : undefined}
          onClick={() => (accordion === 4 ? setAccordion(0) : setAccordion(4))}
        >
          {t("rewards.faq-li-4")}
        </h2>
        <section className={accordion === 4 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-4-text")}</p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 5 ? "active" : undefined}
          onClick={() => (accordion === 5 ? setAccordion(0) : setAccordion(5))}
        >
          {t("rewards.faq-li-5")}
        </h2>
        <section className={accordion === 5 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-5-text-1")}</p>
          <p>{t("rewards.faq-li-5-text-2")}</p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 6 ? "active" : undefined}
          onClick={() => (accordion === 6 ? setAccordion(0) : setAccordion(6))}
        >
          {t("rewards.faq-li-6")}
        </h2>
        <section className={accordion === 6 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-6-text-1")}</p>
          <p>{t("rewards.faq-li-6-text-2")}</p>
          <ul>
            <li>{t("rewards.faq-li-6-list-1")}</li>
            <li>{t("rewards.faq-li-6-list-2")}</li>
            <li>{t("rewards.faq-li-6-list-3")}</li>
          </ul>
          <p>{t("rewards.faq-li-6-text-3")}</p>
          <p>{t("rewards.faq-li-6-text-4")}</p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 7 ? "active" : undefined}
          onClick={() => (accordion === 7 ? setAccordion(0) : setAccordion(7))}
        >
          {t("rewards.faq-li-7")}
        </h2>
        <section className={accordion === 7 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-7-text-1")}</p>
          <p>{t("rewards.faq-li-7-text-2")}</p>
          <p>{t("rewards.faq-li-7-text-3")}</p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 8 ? "active" : undefined}
          onClick={() => (accordion === 8 ? setAccordion(0) : setAccordion(8))}
        >
          {t("rewards.faq-li-8")}
        </h2>
        <section className={accordion === 8 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-8-text")}</p>
        </section>
      </div>
      <div>
        <h2
          className={accordion === 9 ? "active" : undefined}
          onClick={() => (accordion === 9 ? setAccordion(0) : setAccordion(9))}
        >
          {t("rewards.faq-li-9")}
        </h2>
        <section className={accordion === 9 ? "show" : undefined}>
          <p>{t("rewards.faq-answer")}:</p>
          <p>{t("rewards.faq-li-9-text")}</p>
        </section>
      </div>
    </MainDiv>
  );
};
const fadeIn = keyframes`
  from {
    transform: scale(.15);
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    transform: scale(1);
    transform: translateX(0);
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  padding: 40px;
  background: var(--white);
  color: var(--black);
  & > h1 {
    text-align: center;
    font-weight: 700;
  }
  & > div {
    transition: ease-in-out 0.3s;
    & > h2 {
      cursor: pointer;
      margin: 0;
      font-weight: 400;
      font-size: 18px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 20px;
      margin: 10px 0;
    }
    .active {
      background: var(--red);
      color: var(--white);
    }
    & > section {
      display: none;
      padding: 20px;
      animation: ${fadeIn} 0.3s ease-in-out;
      & > p:nth-child(1) {
        font-weight: 700;
      }
      & > p {
        margin: 0;
        padding: 2px 0;
        font-weight: 400;
      }
      & > p:nth-child(3) {
        font-weight: 400;
        text-align: left;
        padding: 0;
      }
    }
    .show {
      display: block;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px;
    & > div {
      & > h2 {
        padding: 10px;
      }
      & > section {
        padding: 0;
      }
    }
  }
`;
export default FAQ;
