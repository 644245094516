import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHomepage } from "../context/homepage/homepage.provider";

export const useDynamicRouting = (routeData, categoryArr) => {
  const { route = "", selectCategory = "" } = routeData || {};
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { sectionCategoryFn, sectionDescriptionFn } = useHomepage();

  const [state, setState] = useState({
    categoryName: selectCategory,
    selectEventToggle: false,
  });

  useEffect(() => {
    // Update the URL and category name when the language changes
    handleSelectedEvent(selectCategory);
  }, [i18n.language]);

  const updateURL = (categoryName) => {
    if (categoryName) {
      const url = categoryArr.find(
        (el) => el.title === categoryName || el.title_fr === categoryName
      );
      navigate(url?.path || route, { replace: true });
    } else {
      navigate(route, { replace: true });
    }
  };

  useEffect(() => {
    const categoryName = categoryArr.find(
      (el) => el.path === location.pathname || el.path_fr === location.pathname
    );
    const pathname = location.pathname;
    const segments = pathname.split("/");
    const lastSegment = segments.pop() || "/";

    if (categoryName && categoryName.title !== "all") {
      handleSelectedEvent(categoryName.title);
      sectionCategoryFn(categoryName.title);
      sectionDescriptionFn(categoryName.description);
    } else if (params.category && lastSegment !== ":category") {
      navigate("/404", { replace: true });
    }
  }, [location.pathname]);

  const handleSelectedEvent = (category) => {

    // Logic for selecting the event and updating the state
    const eventName = category !== selectCategory ? category : null;
    const eventDescription = categoryArr.find(
      (el) => el.title === eventName || el.title_fr === eventName
    )?.description;

    setState((prevState) => ({
      ...prevState,
      categoryName: eventName || "",
    }));

    sectionCategoryFn(eventName);
    sectionDescriptionFn(eventDescription);
    updateURL(eventName || "");
  };

  return {
    state,
    setState,
    handleSelectedEvent,
  };
};
