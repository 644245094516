import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import pastEvents from "src/data/pastEvents.json";
import worldCupOfCards from "src/data/worldCupOfCards.json";
import PartyPoker from "./PartyPoker";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const WorldCupOfCards2021 = () => {
  const [isActive, setIsActive] = useState("schedule");
  const [toggleNav, setToggleNav] = useState(false);
  const [selectedPartypoker, setSelectedPartyPoker] = useState("");
  const navRef = useRef();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pastEvent = pastEvents.find(
    (el) => el.url === location.pathname || el.url_fr === location.pathname
  );

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split("poker.worldCupOfCards2021. ");
    let [hours, minutes] = time.split("poker.worldCupOfCards2021.:");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  const moveDollarSigns = (str) => str.replace(/\$(\d+(?:\.\d+)?)/g, "$1$");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setToggleNav(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleNav]);

  const handleisActive = (action) => {
    if (action === "schedule") {
      setIsActive(action);
      setSelectedPartyPoker("");
    } else {
      setIsActive("partypoker");
      setSelectedPartyPoker(action);
    }
  };

  return (
    <WorldCupOfCardsDiv schedule={isActive}>
      <div>
        <ResponsiveImage
          src={i18n.language === "en" ? pastEvent.image : pastEvent.image_fr}
          alt={pastEvent.url}
        />
        <ResponsiveImage
          src={
            i18n.language === "en"
              ? pastEvent.image_mobile
              : pastEvent.image_mobile_fr
          }
          alt={pastEvent.url}
        />
      </div>
      <ul>
        <li
          className={isActive === "schedule" ? "active" : undefined}
          onClick={() => handleisActive("schedule")}
        >
          {t("poker.worldCupOfCards2021.schedule")}
        </li>
        <li
          className={isActive === "partypoker" ? "active" : undefined}
          ref={navRef}
          onClick={() => setToggleNav((prev) => !prev)}
        >
          {t("poker.worldCupOfCards2021.qualifyOnPartyPoker")} <span></span>
          {toggleNav && (
            <ul>
              <li onClick={() => handleisActive("main-event")}>
                <a href={`${t('routes.worldCupOfCards2021')}#`}>
                  {t("poker.worldCupOfCards2021.mainEventSatellites")}
                </a>
              </li>
              <li onClick={() => handleisActive("malibu-big-game")}>
                <a href={`${t('routes.worldCupOfCards2021')}#malibu-big-game`}>
                  {t("poker.worldCupOfCards2021.malibuBigGameSatellites")}
                </a>
              </li>
            </ul>
          )}
        </li>
      </ul>
      {isActive === "schedule" && selectedPartypoker === "" ? (
        <div>
          <h1>{t("poker.worldCupOfCards2021.content.header1")}</h1>
          <h2>{t("poker.worldCupOfCards2021.content.header2")}</h2>
          <div>
            {t("poker.worldCupOfCards2021.content.paragraphs", {
              returnObjects: true,
            }).map((paragraph, index) => (
              <ReactMarkdown key={index}>{paragraph}</ReactMarkdown>
            ))}
          </div>
          <TableDiv>
            <table>
              <thead>
                <tr>
                  <th>{t("poker.worldCupOfCards2021.tableHeaders.date")}</th>
                  <th>{t("poker.worldCupOfCards2021.tableHeaders.time")}</th>
                  <th className="hide-col">
                    {t("poker.worldCupOfCards2021.tableHeaders.eventNumber")}
                  </th>
                  <th>{t("poker.worldCupOfCards2021.tableHeaders.buyIn")}</th>
                  <th>
                    {t("poker.worldCupOfCards2021.tableHeaders.tournament")}
                  </th>
                  <th className="hide-col">
                    {t("poker.worldCupOfCards2021.tableHeaders.info")}
                  </th>
                  <th>
                    {t("poker.worldCupOfCards2021.tableHeaders.guarantee")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {worldCupOfCards.map((event, index) => {
                  const isFirstRow =
                    index === 0 ||
                    event.date !== worldCupOfCards[index - 1].date;
                  const rowSpan = worldCupOfCards.filter(
                    (e) => e.date === event.date
                  ).length;
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        {isFirstRow && (
                          <td rowSpan={rowSpan}>
                            {i18n.language === "en" ? event.date : event.dateFr}
                          </td>
                        )}
                        <td
                          className={
                            (event.guarantee?.substring(0, 1) !== "$"
                              ? "seats"
                              : undefined) ||
                            (event?.guarantee === "$1,000,000"
                              ? "million"
                              : undefined)
                          }
                        >
                          {i18n.language === "en"
                            ? event.time
                            : convertTime12to24(event.time)}
                        </td>
                        <td
                          className={
                            ((event.guarantee?.substring(0, 1) !== "$"
                              ? "seats"
                              : undefined) ||
                              (event?.guarantee === "$1,000,000"
                                ? "million"
                                : undefined)) + " hide-col"
                          }
                        >
                          {i18n.language === "en"
                            ? event.event
                            : "No. " + event?.event.substring(1)}
                        </td>
                        <td
                          className={
                            (event.guarantee?.substring(0, 1) !== "$"
                              ? "seats"
                              : undefined) ||
                            (event?.guarantee === "$1,000,000"
                              ? "million"
                              : undefined)
                          }
                        >
                          {i18n.language === "en"
                            ? event.buyIn
                            : moveDollarSigns(event.buyIn)}
                        </td>
                        <td
                          className={
                            (event.guarantee?.substring(0, 1) !== "$"
                              ? "seats"
                              : undefined) ||
                            (event?.guarantee === "$1,000,000"
                              ? "million"
                              : undefined)
                          }
                        >
                          {i18n.language === "en"
                            ? event.tournament
                            : event.tournamentFr}
                        </td>
                        <td
                          className={
                            ((event.guarantee?.substring(0, 1) !== "$"
                              ? "seats"
                              : undefined) ||
                              (event?.guarantee === "$1,000,000"
                                ? "million"
                                : undefined)) + " hide-col"
                          }
                        >
                          {i18n.language === "en" ? event.info : event.infoFr}
                        </td>
                        <td
                          className={
                            (event.guarantee?.substring(0, 1) !== "$"
                              ? "seats"
                              : undefined) ||
                            (event?.guarantee === "$1,000,000"
                              ? "million"
                              : undefined)
                          }
                        >
                          <strong>
                            {event.guarantee.length === 2
                              ? event.guarantee +
                                (i18n.language === "en" ? " Seats" : " Sièges")
                              : i18n.language === "en"
                                ? event.guarantee
                                : event?.guarantee.substring(1) + "$"}
                          </strong>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </TableDiv>
        </div>
      ) : (
        <PartyPoker />
      )}
      
      
    </WorldCupOfCardsDiv>
  );
};

const WorldCupOfCardsDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  background: var(--black);
  & > div:first-child {
    height: fit-content;
    width: 100%;
    z-index: 1;
    position: relative;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img {
      width: 100%;
      height: fit-content;
      object-fit: cover;
      object-position: center;
      vertical-align: middle;
    }
    & > img:last-child {
      display: none;
    }
  }
  & > ul:nth-child(2) {
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 2px;
    & > li {
      background: var(--white);
      color: var(--black);
      height: 100%;
      width: 50%;
      text-align: center;
      line-height: 50px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: var(--white);
        background: var(--red);
      }
      & > span {
        margin-left: 10px;
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
      &.active {
        color: var(--white);
        background: var(--red);
      }
    }
    & > li:last-child {
      position: relative;
      & > ul {
        z-index: 2;
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        & > li {
          height: 100%;
          color: var(--black);
          background: var(--white);
          text-align: center;
          line-height: 50px;
          font-size: 14px;
          cursor: pointer;
          border-top: 1px solid var(--lightblack);
          &:hover {
            background: var(--red);
            color: var(--white);
          }
          & > span {
            margin-left: 10px;
            display: inline-block;
            width: 0;
            height: 0;
            vertical-align: middle;
            border-top: 4px dashed;
            border-top: 4px solid;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
          }
        }
      }
    }
  }
  & > div:nth-child(3) {
    width: 100%;
    max-width: ${({ schedule }) =>
      schedule === "schedule" ? "1440px" : "unset"};
    height: fit-content;
    text-align: center;
    color: var(--white);
    margin: 0 auto;
    & > h1:first-child {
      margin: 40px 0 10px 0;
      font-weight: 700;
      font-size: 30px;
    }
    & > h2:nth-child(2) {
      font-weight: 700;
      font-size: 22px;
      color: var(--red);
      margin: 0;
    }
    & > div:nth-child(3) {
      & > p {
        & > a {
          color: var(--red);
          &:hover {
            border-bottom: 1px solid var(--red);
          }
        }
      }
      & > p:last-child {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      padding: 80px 0px 0px;
      & > img:first-child {
        display: none;
      }
      & > img:last-child {
        display: block;
      }
    }
    & > ul:nth-child(2) {
      & > li {
        font-size: 12px;
      }
      & > li:last-child {
        & > ul {
          & > li {
            font-size: 12px;
          }
        }
      }
    }
    & > div:nth-child(3) {
      & > div:nth-child(3) {
        padding: 0 20px;
        & > p {
          font-size: 14px;
        }
      }
    }
  }
`;
const TableDiv = styled.div`
  margin: 40px auto;
  border: 1px solid var(--lightblack);
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        border-top: 1px solid var(--lightblack);
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        & > td {
          border-right: 1px solid var(--lightblack);
          border-top: none;
          text-align: center;
          color: var(--white);
          font-size: 15px;
          padding: 8px;
          line-height: 1.42857143;
          & > strong {
            color: var(--red);
          }
          &.million {
            background-color: var(--red);
            & > strong {
              color: var(--white);
            }
          }
          &.seats {
            background-color: rgba(255, 255, 255, 0.1);
            & > strong {
              color: var(--white);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 10px;
            padding: 5px;
            &.hide-col {
              display: none;
            }
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 10px;
            padding: 5px;
            font-weight: 300;
            &.hide-col {
              display: none;
            }
          }
        }
      }
    }
  }
`;
export default WorldCupOfCards2021;
