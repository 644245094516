import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import ResponsiveImage from "./ResponsiveImage";

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: 991px) {
    & > img {
      max-width: 100%;
    }
  }
`;

const StyledAnchor = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;

  & > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
  }

  @media screen and (max-width: 991px) {
    & > img {
      max-width: 100%;
    }
  }
`;

const ImageLink = ({ to, imgSrc, alt, className, ...props }) => {
  const isExternalLink = to && to.startsWith("http");
  return isExternalLink ? (
    <StyledAnchor
      href={to}
      className={className}
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ResponsiveImage src={imgSrc} alt={alt} />
    </StyledAnchor>
  ) : (
    <StyledLink to={to} className={className} {...props}>
      <ResponsiveImage src={imgSrc} alt={alt} />
    </StyledLink>
  );
};

ImageLink.propTypes = {
  to: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ImageLink.defaultProps = {
  className: "",
};

export default ImageLink;
