import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { useErs } from "src/context/ers/ers.provider";
import { formatAmount } from "src/utils/formatters";
import ReactMarkdown from "react-markdown";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";

const MysteryHighHandOctober2024 = () => {
  const { i18n, t } = useTranslation();
  const { data, fetchData } = useErs();
  const lng = i18n.language;

  useEffect(() => {
    fetchData("mystery-high-hand");
  }, [fetchData, i18n.language]);

  // Function to add a dollar sign to the amount
  const addDollarSign = (amount) => formatAmount(amount, lng);

  const mysteryHighHands = data["mystery-high-hand"] || [];

  return (
    <MysteryHighHandDiv>
      <Hero
        bgImage="promotions/poker/mystery-high-hand/mystery_high_hand_bg_lp.jpg"
        descriptionBgColor="#aa0b0b4d"
        image={`promotions/mystery_high_hand_logo_lp_${i18n.language}.png`}
        imageAlt="Mystery High Hand logo"
      >
        <ReactMarkdown>
          {t("poker.mystery-high-hand-october-2024.headerText")}
        </ReactMarkdown>
      </Hero>
      <div>
        <ReactMarkdown>
          {t("poker.mystery-high-hand-october-2024.text")}
        </ReactMarkdown>
        <div>
          <h4>{t("poker.mystery-high-hand.cash-prize-available")}</h4>
          <table>
            <thead>
              <tr>
                <th>{t("poker.mystery-high-hand.remaining")}</th>
                <th>{t("poker.mystery-high-hand.amount")}</th>
              </tr>
            </thead>
            <tbody>
              {mysteryHighHands?.map((highHand, index) => (
                <tr key={index}>
                  <td>{highHand.remaining}</td>
                  <td>{addDollarSign(highHand.amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactMarkdown>
            {t("poker.mystery-high-hand-october-2024.smallInfo")}
          </ReactMarkdown>
          <div>
            <p>
              {t("poker.mystery-high-hand.small-box-info-one")}
              <strong>
                {" "}
                {t("poker.mystery-high-hand.small-box-info-two")}
              </strong>
              , {t("poker.mystery-high-hand.small-box-info-three")}{" "}
              <strong>
                {t("poker.mystery-high-hand.small-box-info-four")}{" "}
              </strong>
              {t("poker.mystery-high-hand.small-box-info-five")}{" "}
              <a href={t("routes.cashGames")}>
                {t("poker.mystery-high-hand.small-box-info-six")}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div>
        <h4>{t("poker.mystery-high-hand-october-2024.generalRules.title")}</h4>
        <ReactMarkdown>
          {t("poker.mystery-high-hand-october-2024.generalRules.list", {
            returnObjects: false,
          })}
        </ReactMarkdown>
      </div>
    </MysteryHighHandDiv>
  );
};
const MysteryHighHandDiv = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;
  .hero_text_container {
    max-width: 1200px;
    margin: 20px auto;
    backdrop-filter: blur(0px) brightness(80%) grayscale(30%);
    p {
      font-size: 16px;
    }
    @media screen and (max-width: 776px) {
      h2 {
        font-size: 24px;
      }
      h3 {
        font-size: 20px;
        margin: 0;
      }
      h4 {
        font-size: 18px;
      }
    }
  }

  & > div:nth-child(2) {
    padding: 60px 20px 0;
    width: 100%;
    max-width: 1440px;
    height: auto;
    margin: 0 auto;
    color: var(--white);
    & > h3 {
      font-weight: 700;
      font-size: 20px;
      margin-top: 0px;
      padding-bottom: 20px;
      text-transform: uppercase;
    }
    & > div {
      width: 100%;
      & > h4 {
        background: rgb(170, 11, 11);
        background: linear-gradient(
          45deg,
          rgba(170, 11, 11, 1) 0%,
          rgba(0, 0, 0, 1) 50%,
          rgba(170, 11, 11, 1) 100%
        );
        color: var(--white);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        max-width: 500px;
        font-size: 30px;
        margin: 20px auto;
        border-radius: 5px;
        line-height: 25px;
      }
      & > table {
        width: 100%;
        max-width: 100%;
        margin: 20px 0px 40px 0px;
        background: var(--black);
        color: var(--white);
        border: 1px solid var(--lightblack);
        border-spacing: 0;
        border-collapse: collapse;
        & > thead {
          & > tr {
            & > th {
              text-align: center;
              font-size: 20px;
              font-weight: 400;
              width: 50%;
              background-color: #333;
              border: 1px solid #222;
              padding: 8px;
              line-height: 1.42857143;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td:first-child {
              text-align: center;
              font-size: 30px;
              font-weight: 700;
              width: 50%;
              color: var(--white);
              background-color: #230202;
              border: 1px solid #222;
              padding: 8px;
              line-height: 1.42857143;
            }
            & > td:last-child {
              background-color: #aa0b0b;
              text-align: center;
              font-size: 30px;
              font-weight: 700;
              width: 50%;
              color: #fff;
              border: 1px solid #222;
              padding: 8px;
              line-height: 1.42857143;
            }
          }
        }
      }
      & > p {
        margin: 0 0 10px;
        font-size: 18px;
        color: #aaa;
        text-align: center;
      }
      & > div {
        background-color: #333;
        & > p {
          padding: 20px;
          font-size: 16px;
          color: #aaa;
          margin: 0 0 10px;
          & > a {
            color: var(--red);
          }
        }
      }
    }
  }
  & > div:nth-child(3) {
    padding: 40px 20px;
    background-color: #111;
    margin-top: 40px;
    & > h4 {
      font-weight: 700;
      color: #999;
      text-transform: uppercase;
      text-align: center;
      padding: 15px;
      font-size: 30px;
      margin: 5px 0 10px 0;
    }
    & > ul {
      margin-top: 0;
      margin-bottom: 10px;
      list-style: decimal;
      & > li {
        font-size: 16px;
        color: #999;
        ul {
          list-style: lower-alpha;
          ul {
            list-style: lower-roman;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      & > img {
        width: 100%;
      }
      & > div {
        & > h2 {
          padding: 10px;
          font-size: 20px;
          line-height: 1.35em;
        }
      }
    }
  }
`;
export default MysteryHighHandOctober2024;
