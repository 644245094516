import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import BlackJack from "../components/ElectronicTableGames/BlackJack";
import Roulette from "../components/ElectronicTableGames/Roulette";
import Baccarat from "../components/ElectronicTableGames/Baccarat";
import { useTranslation } from "react-i18next";
import SectionalTabs from "../components/SectionalTabs/SectionalTabs";
import Hero from "../components/Hero/Hero";
import { useLocation, useNavigate } from "react-router-dom";

const ElectronicTableGames = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const routeCategory = "electronicTableGames";
  const categories = require(`../locales/${i18n.language}/pseudoRoutes.json`);
  const categoryArr = useMemo(() => Object.values(categories.pseudoRoutes[routeCategory] || {}), [i18n.language]);

  const [selectedCategory, setSelectedCategory] = useState({
    title: categoryArr[0]?.title || "",
    path: categoryArr[0]?.path || ""
  });

  useEffect(() => {
    const currentTab = categoryArr.find((el) => el.path === location.pathname);

    if (location.pathname === t(`routes.${routeCategory}`) && categoryArr[0] && selectedCategory.path !== categoryArr[0].path) {
      setSelectedCategory({
        title: categoryArr[0].title,
        path: categoryArr[0].path
      });
    }

    if (currentTab && currentTab.path !== selectedCategory.path) {
      setSelectedCategory({
        title: currentTab.title,
        path: currentTab.path
      });
    }
  }, [location.pathname, categoryArr, selectedCategory.path, t, routeCategory]);

  useEffect(() => {
    const currentTab = categoryArr.find((el) => el.path === location.pathname);

    if (!currentTab) {
      navigate(t(`routes.${routeCategory}`), { replace: true });
    }
  }, [i18n.language, location.pathname, navigate, t, routeCategory]);

  return (
    <ETGDiv>
      <Hero
        bgImage="home/etg-header.jpg"
        altText="Table games header"
        mainHeroText={t("etg.title")}
        darkenBg={0.3}
      />
      <container-wide>
        {/* Sectional Tabs */}
        <SectionalTabs
          className="mt-4 mb-4"
          routeData={{
            route: t(`routes.${routeCategory}`),
            routeCategory,
            sectionTitle: categoryArr[0]?.title || "",
            selectCategory: location.pathname === t(`routes.${routeCategory}`) ? categoryArr[0]?.title : selectedCategory.title,
          }}
          isDynamic={true}
          selectedCategory={selectedCategory.title}
          bgColor="var(--white)"
          textColor="var(--black)"
          activeBgColor="var(--red)"
          activeTextColor="var(--white)"
        />

        {/* Dynamic Content */}
        <TabContent>
          {selectedCategory.title === t("pseudoRoutes.electronicTableGames.blackJack.title") && <BlackJack />}
          {selectedCategory.title === t("pseudoRoutes.electronicTableGames.roulette.title") && <Roulette />}
          {selectedCategory.title === t("pseudoRoutes.electronicTableGames.baccarat.title") && <Baccarat />}
        </TabContent>
      </container-wide>
    </ETGDiv>
  );
};

const ETGDiv = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  z-index: 0;
  background-color: var(--black);
`;

const TabContent = styled.div`
  background: var(--lightblack);
  padding: 0 1rem 1rem;
  margin-bottom: 2rem;
`;

export default ElectronicTableGames;