import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";
import EventFinished from "src/components/EventFinished";

import { t } from "i18next";

const MusicalMonday = () => {

  return (
    <>
      <Div>
        <Hero
          arrowBounce="true"
          bgImage="entertainment/musical_monday_background.jpg"
          buttonLink=""
          buttonText=""
          title={t("entertainment.musicalMonday.hero.title")}
          description={t("entertainment.musicalMonday.hero.description")}
          descriptionBgColor="#0000004d"
          image={t("entertainment.musicalMonday.hero.image")}
          imageAlt={t("entertainment.musicalMonday.hero.imageAlt")}
          showArrow="true"
        />
        <EventFinished date="2021-09-06" />
        <container-div>
          <ShowBody className="shadow">
            <p>{t("entertainment.musicalMonday.showBody.details.part1")}</p>
            <p>{t("entertainment.musicalMonday.showBody.details.part2")}</p>
            <p className="footer_intro">
              {t("entertainment.musicalMonday.showBody.footer.heading")}
            </p>
            <p className="examples">
              {t("entertainment.musicalMonday.showBody.footer.details")}
            </p>
          </ShowBody>
        </container-div>
      </Div>
      
    </>
  );
};

const Div = styled.div`
  background: #e8ebef;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const ShowBody = styled.div`
  margin: 40px;
  padding: 40px;
  background: #fff;
  p {
    margin-bottom: 2.5rem;
    &:first-child {
      margin-top: 0;
    }
    &.footer_intro {
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
  .examples {
    background-color: #002e60;
    padding: 15px;
    color: #fff;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 991px) {
    margin: 20px 0;
    padding: 20px;
  }
`;

export default MusicalMonday;
