import React from "react";

import BlogHeader from "src/components/Blog/BlogHeader";
import BlogContainer from "src/components/Blog/BlogContainer";
import BlogContent from "src/components/Blog/BlogContent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const DiscoverReasonsWsop = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <BlogHeader title={t("blog.wsop-pg-2024.header-title")} />
      <BlogContainer>
        <BlogContent>
          {i18n.language === "en" ? (
            <p>
              As the World Series of Poker Circuit (WSOP-C) rapidly approaches,
              poker enthusiasts from around the globe are gearing up for what
              promises to be an unforgettable experience. The{" "}
              <a
                href="https://www.playground.ca/poker/wsopc-playground-2024"
                target="_blank"
                rel="noopener noreferrer"
              >
                WSOP-C Playground
              </a>
              , from April 8–22, is not just an event; it's a beacon for those
              who live and breathe poker. Here are the top 8 reasons why you
              should consider making your way to this prestigious event.
            </p>
          ) : (
            <p>
              Alors que le World Series of Poker Circuit (WSOP-C) approche à
              grands pas. Les passionnés de poker du monde entier se préparent
              pour ce qui promet d'être une expérience inoubliable. Du 8 au 22
              avril, vivez au rythme du{" "}
              <a
                href="https://www.playground.ca/poker/wsopc-playground-2024"
                target="_blank"
                rel="noopener noreferrer"
              >
                WSOP-C à Playground
              </a>
              . Cet événement phare fera vibrer tous les passionnés et joueurs
              de Poker.
            </p>
          )}
          <ResponsiveImage src="blog/wsop-blog-1.jpg" alt="wsop-blog-1" />
          <h3>{t("blog.wsop-pg-2024.list-8")}</h3>
          <p>{t("blog.wsop-pg-2024.list-8-text")}</p>
          <ResponsiveImage src="blog/wsop-blog-2.jpg" alt="wsop-blog-2" />
          <h3>{t("blog.wsop-pg-2024.list-7")}</h3>
          <p>{t("blog.wsop-pg-2024.list-7-text")}</p>
          <ResponsiveImage src="blog/wsop-blog-3.jpg" alt="wsop-blog-3" />
          <h3>{t("blog.wsop-pg-2024.list-6")}</h3>
          <p>
            {t("blog.wsop-pg-2024.list-6-text-1")}{" "}
            <a
              href="https://playgroundetc.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              steakhouse, etc.
            </a>
            , {t("blog.wsop-pg-2024.list-6-text-2")}{" "}
            <a
              href="https://drunkendragon.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Drunken Dragon
            </a>
            , {t("blog.wsop-pg-2024.list-6-text-3")}
          </p>
          <ResponsiveImage src="blog/wsop-blog-4.jpg" alt="wsop-blog-4" />
          <h3>{t("blog.wsop-pg-2024.list-5")}</h3>
          <p>
            {t("blog.wsop-pg-2024.list-5-text-1-1")},{" "}
            <a
              href="https://esperantolounge.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Esperanto
            </a>{" "}
            {t("blog.wsop-pg-2024.list-5-text-1-2")}{" "}
            <a href="" target="_blank" rel="noopener noreferrer">
              Babel
            </a>
            , {t("blog.wsop-pg-2024.list-5-text-1-3")}
          </p>
          <p>{t("blog.wsop-pg-2024.list-5-text-2")}</p>
          <p>{t("blog.wsop-pg-2024.list-5-text-3")}</p>
          <p>{t("blog.wsop-pg-2024.list-5-text-4")}</p>
          <ResponsiveImage src="blog/wsop-blog-5.jpg" alt="wsop-blog-5" />
          <h3>{t("blog.wsop-pg-2024.list-4")}</h3>
          <p>{t("blog.wsop-pg-2024.list-4-text-1")}</p>
          <p>
            {t("blog.wsop-pg-2024.list-4-text-2-1")}{" "}
            <a href="">{t("blog.wsop-pg-2024.list-4-text-2-2")}</a>{" "}
            {t("blog.wsop-pg-2024.list-4-text-2-3")}{" "}
            <a href="">{t("blog.wsop-pg-2024.list-4-text-2-4")}</a>,{" "}
            {t("blog.wsop-pg-2024.list-4-text-2-5")}
          </p>
          <p>{t("blog.wsop-pg-2024.list-4-text-3")}</p>
          <ResponsiveImage src="blog/wsop-blog-6.jpg" alt="wsop-blog-6" />
          <h3>{t("blog.wsop-pg-2024.list-3")}</h3>
          <p>{t("blog.wsop-pg-2024.list-3-text-1")}</p>
          <p>{t("blog.wsop-pg-2024.list-3-text-2")}</p>
          <ol>
            <li>
              <strong>Old Montreal (Vieux-Montréal):</strong>{" "}
              {t("blog.wsop-pg-2024.list-3-text-2-ol-1")}
            </li>
            <li>
              <strong>Mount Royal (Mont Royal):</strong>{" "}
              {t("blog.wsop-pg-2024.list-3-text-2-ol-2")}
            </li>
            <li>
              <strong>
                Montreal Museum of Fine Arts (Musée des beaux-arts de Montréal):
              </strong>{" "}
              {t("blog.wsop-pg-2024.list-3-text-2-ol-3")}
            </li>
            <li>
              <strong>The Underground City (La Ville Souterraine):</strong>{" "}
              {t("blog.wsop-pg-2024.list-3-text-2-ol-4")}
            </li>
          </ol>
          <p>{t("blog.wsop-pg-2024.list-3-text-3")}</p>
          <ResponsiveImage src="blog/wsop-blog-7.jpg" alt="wsop-blog-7" />
          <h3>{t("blog.wsop-pg-2024.list-2")}</h3>
          <p>{t("blog.wsop-pg-2024.list-2-text-1")}</p>
          <p>{t("blog.wsop-pg-2024.list-2-text-2")}</p>
          <p>
            {t("blog.wsop-pg-2024.list-2-text-3-1")}{" "}
            <Link to={t("routes.games")}>
              {t("blog.wsop-pg-2024.list-2-text-3-2")}
            </Link>
            , {t("blog.wsop-pg-2024.list-2-text-3-3")}{" "}
            <Link href={t("routes.monteCarloJackpots2024")}>
              {t("blog.wsop-pg-2024.list-2-text-3-4")}
            </Link>{" "}
            {t("blog.wsop-pg-2024.list-2-text-3-5")}{" "}
            <Link href={t("routes.badBeatJackpot")}>
              {t("blog.wsop-pg-2024.list-2-text-3-6")}
            </Link>
            , {t("blog.wsop-pg-2024.list-2-text-3-7")}
          </p>
          <p>{t("blog.wsop-pg-2024.list-2-text-4")}</p>
          <ResponsiveImage src="blog/wsop-blog-8.jpg" alt="wsop-blog-8" />
          <h3>{t("blog.wsop-pg-2024.list-1")}</h3>
          <p>{t("blog.wsop-pg-2024.list-1-text-1")}</p>
          <p>{t("blog.wsop-pg-2024.list-1-text-2")}</p>
          <p>
            {t("blog.wsop-pg-2024.list-1-text-3-1")}{" "}
            <Link to={"/poker/wsopc-playground-2024"}>WSOP-C Playground</Link>{" "}
            {t("blog.wsop-pg-2024.list-1-text-3-2")}
          </p>
        </BlogContent>
      </BlogContainer>
      
    </>
  );
};
export default DiscoverReasonsWsop;
