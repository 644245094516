import { ersActionTypes as actions } from "./ers.actions";

export const ersReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.ERS_DATA:
      return {
        ...state,
        data: { ...state.data, [payload.slug]: payload.data },
      };
    default:
      return state;
  }
};
