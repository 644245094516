import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { email_verification } from "../../api/auth";
import { useAccount } from "src/context/account/account.provider";
import { useNavigate } from "react-router-dom";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [isProcessingConfirmation, setIsProcessingConfirmation] =
    useState(false);
  const { access_token } = useAccount();
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [message, setMessage] = useState(false);

  // Extract 'gid' from URL query parameter outside useEffect
  const urlParams = new URLSearchParams(window.location.search);
  const gid = urlParams.get("gid");

  useEffect(() => {
    // Extract 'gid' from URL query parameter only once (on component mount)
    const gid = new URLSearchParams(window.location.search).get("gid");
    if (!gid) {
      console.error("No gid found in the URL parameters.");
      return;
    }

    setIsProcessingConfirmation(true); // Start processing

    if (access_token && gid) {
      // Function to process email verification asynchronously
      async function verifyEmail(access_token, gid) {
        try {
          const response = await email_verification(access_token, gid);

          if (response.success) {
            setConfirmEmail(true); // Show success message or state
            // console.log("Email confirmation successful!");
            setMessage(true);
            setTimeout(() => {
              navigate(t("routes.homepage"), { replace: true });
              window.location.reload();
            }, 3000);
          } else {
            console.error(
              "Failed to send email verification gid:",
              response.message
            );
          }
        } catch (error) {
          console.error("An error occurred during email confirmation:", error);
        } finally {
          setIsProcessingConfirmation(false); // End processing in any case
        }
      }

      verifyEmail(access_token, gid); // Execute with the provided 'gid'
    }
  }, []);
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setConfirmEmail(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [message]);
  return (
    <MainDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          {setConfirmEmail && message ? (
            <h3>{i18n.language === "en" ? "Success!" : "Vous avez réussi!"}</h3>
          ) : (
            <h3>
              {i18n.language === "en"
                ? "An error occurred during email confirmation!"
                : "Une erreur s'est produite lors de la confirmation du courrier électronique!"}
            </h3>
          )}
          {setConfirmEmail &&
            (message ? (
              <div>
                <div>
                  <ResponsiveImage src="svg/Check.svg" alt="check" />
                  <h4>
                    {i18n.language === "en"
                      ? "Welcome to Playground"
                      : "Bienvenue au Playground"}
                  </h4>
                  <h4>
                    {i18n.language === "en"
                      ? "Your email was verified!"
                      : "Votre courriel a été vérifié!"}
                  </h4>
                  <p>{i18n.language === "en" ? "Thank you!" : "Merci!"}</p>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <h4>
                    {i18n.language === "en"
                      ? "Your email could not be verified!"
                      : "Votre email n'a pas pu être vérifié !"}
                  </h4>
                  <p>
                    {i18n.language === "en"
                      ? "Please try again!"
                      : "Veuillez réessayer!"}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div:first-child {
    height: auto;
    min-height: 900px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 570px;
      height: auto;
      & > h3 {
        font-size: 22px;
        font-weight: 700;
        margin: 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--red);
        margin-bottom: 30px;
        color: var(--white);
      }
      & > div {
        background: rgba(0, 0, 0, 0.5);
        padding: 30px;
        text-align: center;
        color: var(--white);
        & > div {
          padding: 20px;
          background: rgba(0, 0, 0, 0.7);
          & > img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin: 10px auto;
          }
          & > h4:nth-child(2) {
            font-weight: normal;
            margin: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--rewards_grid);
          }
          & > p {
            & > strong {
              & > a {
                color: var(--red) !important;
                &:hover {
                  border-bottom: 1px solid var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          padding: 20px;
        }
      }
    }
  }
`;
export default EmailConfirmation;
