import React from "react";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import TitleSection from "../TitleSection/TitleSection";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import { Row, Col } from "react-bootstrap";

const Baccarat = () => {
  const { t, i18n } = useTranslation();

  return (
    <BaccaratDiv>
      <container-div>
        <TitleSection title={t("etg.baccarat.title")} />
        <ResponsiveImage
          src={`promotions/baccarat_header_${i18n.language}.jpg`}
          alt={t("etg.baccarat.headerImageAlt")}
        />
        <SubTitleSection subTitle={t("etg.baccarat.subtitle")} />

        <ReactMarkdown>{t("etg.baccarat.description1")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.baccarat.description2")}</ReactMarkdown>

        <SubTitleSection subTitle={t("etg.baccarat.playerCardsTitle")} />
        <Table>
          <thead>
            <tr>
              {t("etg.baccarat.playerCardsTable.headers", { returnObjects: true }).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {t("etg.baccarat.playerCardsTable.rows", { returnObjects: true }).map((row, index) => (
              <tr key={index}>
                <td>{row.pointValue}</td>
                <td>{row.decision}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <SubTitleSection subTitle={t("etg.baccarat.bankerCardsTitle")} />
        <Table>
          <thead>
            <tr>
              {t("etg.baccarat.bankerCardsTable.headers", { returnObjects: true }).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {t("etg.baccarat.bankerCardsTable.rows", { returnObjects: true }).map((row, index) => (
              <tr key={index}>
                <td>{row.pointValue}</td>
                <td>{row.draws}</td>
                <td>{row.doesNotDraw}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ReactMarkdown>{t("etg.baccarat.payoutRulesDescription")}</ReactMarkdown>

        <SubTitleSection subTitle={t("etg.baccarat.payTableTitle")} />
        <Table>
          <thead>
            <tr>
              {t("etg.baccarat.payTable.headers", { returnObjects: true }).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {t("etg.baccarat.payTable.rows", { returnObjects: true }).map((row, index) => (
              <tr key={index}>
                <td>{row.betType}</td>
                <td>{row.payout}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <SubTitleSection subTitle={t("etg.baccarat.sideBetsTitle")} />
        <SubTitleSection as={'h3'} subTitle={t("etg.baccarat.tieBetTitle")} />
        <ReactMarkdown>{t("etg.baccarat.tieBetDescription")}</ReactMarkdown>

        <SubTitleSection as={'h3'} subTitle={t("etg.baccarat.playerBankerPairBetTitle")} />
        <ReactMarkdown>{t("etg.baccarat.playerBankerPairBetDescription")}</ReactMarkdown>

        <SubTitleSection as={'h3'} subTitle={t("etg.baccarat.bankerSixBetTitle")} />
        <ReactMarkdown>{t("etg.baccarat.bankerSixBetDescription")}</ReactMarkdown>

        <p className="mt-4">
          <strong>{t("etg.baccarat.helpNotice")}</strong>
        </p>
      </container-div>
    </BaccaratDiv>
  );
};

const BaccaratDiv = styled.div`
  background: #101010;
  color: var(--white);
  height: fit-content;
  width: 100%;
  & > img:first-child {
    width: 100%;
    height: fit-content;
    vertical-align: middle;
  }
  & > div:last-child {
    padding: 25px 60px;
    width: 100%;
    height: fit-content;
    & > p {
      font-size: 14px;
      color: var(--white);
      margin: 0 0 10px;
    }
    & > h2 {
      font-size: 30px;
      font-weight: 400;
      color: var(--white);
      text-transform: uppercase;
      text-decoration: underline;
      padding: 15px 0;
      line-height: 33px;
      margin: 0;
    }
    & > h3 {
      font-size: 20px;
      font-weight: 700;
      color: var(--white);
      text-transform: uppercase;
      margin: 5px 0px 10px;
    }
    & > p:last-child {
      text-align: center;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    & > div:last-child {
      padding: 20px 15px;
      & > h2 {
        font-size: 25px;
      }
    }
  }
`;

const Table = styled.table`
  background-color: #353535;
  margin: 0;
  border: none;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  & > thead {
    & > tr {
      & > th {
        background: var(--red);
        vertical-align: middle;
        color: var(--white);
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        padding: 8px;
        line-height: 1.42857143;
      }
    }
  }
  & > tbody {
    & > tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      & > td {
        color: var(--white);
        border: 1px solid #111;
        width: 33.33%;
        font-size: 16px;
        padding: 8px;
        line-height: 1.42857143;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > thead {
      & > tr {
        & > th {
          font-size: 14px;
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          font-size: 14px;
        }
      }
    }
  }
`;

export default Baccarat;