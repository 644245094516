import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalStyle from "./GlobalStyle";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import CombinedContextProviders from "./context/CombinedContextProviders";
import "./middleware/i18n";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SEO from "./SEO";
import Tracking from "./Tracking";
import LdJson from "./LdJson";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

// Conditionally load New Relic script in production
if (process.env.NODE_ENV !== 'development') {
  const script = document.createElement('script');
  script.src = `${process.env.PUBLIC_URL}/js/newRelic.js`; // Path to your script in public folder
  script.async = true;
  document.head.appendChild(script);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    <CombinedContextProviders>
      <GlobalStyle />
      <HelmetProvider>
        <Router>
          <App />
          <SEO />
          <Tracking />
          <LdJson />
        </Router>
      </HelmetProvider>
    </CombinedContextProviders>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
