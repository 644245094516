import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHomepage } from "src/context/homepage/homepage.provider";

const TournamentSectionalTabs = ({ subTabs, bgColor, ...props }) => {
  const { tournamentsSubtabsFn, tournamentsSubtabs } = useHomepage();
  return (
    <Div className="sectional-tournament-tabs" bgColor={bgColor}>
      {Object.keys(subTabs).map((key, index) => (
        <Button
          key={key}
          className={
            tournamentsSubtabs === index + 1
              ? "active nav-fill"
              : "nav-fill gap-2"
          }
          onClick={() => tournamentsSubtabsFn(index + 1)}
          fill
          variant="pills"
        >
          {subTabs[key].label}
        </Button>
      ))}
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin: 20px auto;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  z-index: 9;

  button {
    background-color: ${(props) => props.bgColor || "var(--mutedred)"};
    color: var(--white);
    border: none;
    padding: 20px;
    height: auto;
    width: 100%;
    max-width: 48%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &.active {
      background-color: var(--black);
      color: var(--white);
    }
  }

  @media (max-width: 768px) {
    button {
      max-width: 100%;
    }
  }
`;

export default TournamentSectionalTabs;
