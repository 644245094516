import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDynamicRouting } from "src/utils/useDynamicRouting";
import NavTabs from "./NavTabs";
import { ScrollPositionContext } from "src/context/scrollPosition/scrollPosition.provider"; // Import context


const DynamicRoutingNavTabs = ({ routeData, selectedCategory, ...props }) => {
  const { i18n } = useTranslation();
  const { routeCategory = "" } = routeData || {};
  const { setScrollPosition } = useContext(ScrollPositionContext);

  const categories = require(
    `../../locales/${i18n.language}/pseudoRoutes.json`
  );
  const categoryArr = Object.values(
    categories.pseudoRoutes[routeCategory] || {}
  );

  const location = useLocation();
  const { handleSelectedEvent } = useDynamicRouting(routeData, categoryArr);

  const handleClick = (title) => {
    setScrollPosition(false); // Prevent scrolling when a nav tab is clicked
    handleSelectedEvent(title);
  };

  const links = categoryArr.map((el) => ({
    ...el,
    isActive: el.title === selectedCategory || el.path === location.pathname,
    onClick: () => handleClick(el.title)
  }));

  return <NavTabs links={links} {...props} />;
};

export default DynamicRoutingNavTabs;
