import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { Row, Col } from "react-bootstrap";

const CashBack = () => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="my-4">{t("rewards.redeem-nav-5")}</h3>
      <Row xs={1} sm={2} className="g-3 gx-3">
        <Col>
          <ResponsiveImage
            src={"home/cash-back-egd.jpg"}
            alt="Cash Back, gaming machines"
          />
        </Col>
        <Col>
            <p>{t("rewards.cash-back-text-1")}</p>
            <p>{t("rewards.cash-back-text-2")}</p>
        </Col>
      </Row>
    </>
  );
};

export default CashBack;
