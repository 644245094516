import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

const PokerJackpotCard = ({ title, text, amount }) => {

  return (
    <Col key={title}>
      <JackpotCard>
        <Card.Body>
          <Card.Title><h3>{title}</h3></Card.Title>
        </Card.Body>
        <Card.Body className="text-holder">
          <Card.Text>
            {text}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <h6>{amount}</h6>
        </Card.Footer>
      </JackpotCard>
    </Col>
  );
};

const JackpotCard = styled(Card)`
  height: 100%;
  text-align: center;
  border-radius: 0;
  background: rgba(255, 255, 255, 0.3);
  .card-title h3 {
    color: #fff;
    font-weight: 700;
    background: var(--black);
    padding: 20px 10px;
    font-size: 1.25rem;
    margin: 0!important;
  }
  .card-body {
    color: white;
    padding-bottom: 0;
    &.text-holder {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .card-footer {
    border-top: 0;
    padding-top: 0;
    h6 {
      color: var(--mustard);
      font-weight: 900;
      font-size: clamp(1rem, 26vw, 2.2rem);
      text-shadow: 1px 0px 10px #00000099;
      white-space: nowrap;
      @media (min-width: 1200px) {
        font-size: clamp(1rem, 26vw, 3rem);
      }
    }
  }
`;

PokerJackpotCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  amount: PropTypes.string
};

export default PokerJackpotCard;
