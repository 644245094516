import styled from "styled-components";
import { useState } from "react";
import { reset_password } from "../../api/auth";
import { useTranslation } from "react-i18next";
import { StyledForm } from "../../GlobalStyle";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "src/components/Button/Button";
import AuthServiceInfo from "src/components/AuthServiceInfo";
import TopLogo from "src/components/Auth/TopLogo";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";

const ResetPassword = () => {
  const { i18n, t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const payload = {
      new_password: formData.get("new_password"),
      new_password_confirmation: formData.get("new_password_confirmation"),
    };

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await reset_password(payload, i18n.language);

      if (response.success) {
        window.location.href = t("routes.login");
      } else {
        if (Array.isArray(response.messages) && response.messages.length > 0) {
          setErrorMessage(response.messages.join(" "));
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setErrorMessage(
        i18n.language === "en"
          ? "Error resetting password"
          : "Erreur de réinitialisation du mot de passe"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <auth-page>
      <TopLogo />
      <Row xs={1} md={2} className="g-4">
        <Col className="px-2">
          <StyledForm onSubmit={handleSubmit}>
          <SubTitleSection as={'h1'} subTitle={t("auth.resetPassword.title")} text={t("auth.resetPassword.description")} className="py-0" />
            <FormGroup controlId="new_password">
              <FormLabel>{t("auth.form.newPassword.label")}</FormLabel>
              <FormControl
                type="password"
                id="new_password"
                name="new_password"
                placeholder={t("auth.form.newPassword.placeholder")}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("auth.form.newPassword.requiredMessage")}
              </Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="new_password_confirmation">
              <FormLabel>{t("auth.form.confirmNewPassword.label")}</FormLabel>
              <FormControl
                type="password"
                id="new_password_confirmation"
                name="new_password_confirmation"
                placeholder={t("auth.form.confirmNewPassword.placeholder")}
                required
              />
            </FormGroup>
            <Form.Control.Feedback type="invalid">
              {t("auth.form.confirmNewPassword.requiredMessage")}
            </Form.Control.Feedback>
            <Button
              type="submit"
              disabled={isLoading}
              bgColor="var(--red)"
              color="var(--white)"
              hoverColor="var(--black)"
              hoverBgColor="var(--white)"
              fluid
              label={
                isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  t("submit")
                )
              }
            />
            <Link to={t("routes.homepage")} className="link">
              {t("button.return-back-home")}
            </Link>
          </StyledForm>
        </Col>
        <Col className="d-flex align-items-center justify-content-center px-0">
          <AuthServiceInfo />
        </Col>
      </Row>
    </auth-page>
  );
};

export default ResetPassword;
