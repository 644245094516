import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const LeapAndLoot2024 = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv>
      <div>
        <ResponsiveImage
          src={`promotions/header_leap_and_loot_${i18n.language}.jpg`}
          alt="header_leap_and_loot"
        />
        <ResponsiveImage
          src={`promotions/header_mobile_leap_and_loot_${i18n.language}.jpg`}
          alt="header_mobile_leap_and_loot"
        />
      </div>
      <div>
        <div>
          <h2>
            {i18n.language === "en"
              ? "OVER $10,000 IN CASH PRIZES"
              : "PLUS DE 10 000$ EN PRIX EN ARGENT"}
          </h2>
          <h3>
            {i18n.language === "en"
              ? "$5,000 GRAND PRIZE"
              : "GRAND PRIX DE 5 000$"}
          </h3>
          {i18n.language === "en" ? (
            <>
              <p>
                If you win during select Playground gaming machines promotions
                from <strong>February 1 to February 29,</strong> you will also
                receive 1 Golden Ticket for the Leap & Loot draws!
              </p>
              <p>
                So don’t miss Gōng Xǐ Fā Cái every Sunday, Booming Wednesday,
                Hot Seat Thursday, Free Play Friday and the Jackpot Parties on
                February 6 and 7, because if you win, you could win again!
              </p>
              <p>
                Draws will be held every 5 minutes starting at 7:30PM, if the
                person drawn is not present the prize rolls over into the next
                draw, thus increasing the following prize. If a prize rolls over
                4 times, the <span>5th draw will be held via Hot Seat</span>{" "}
                from all active gaming machines and table games players.
              </p>
              <p>
                Players can only win once during the Leap & Loot promotion but
                will be allowed to keep the largest prize. Returned prizes will
                be redrawn via Hot Seat prior to the Grand Prize draw.
              </p>
            </>
          ) : (
            <>
              <p>
                Si vous remportez des prix lors de certaines promotions de
                Playground machine de jeux du{" "}
                <strong>1er au 29 février,</strong> vous recevrez également{" "}
                <strong>
                  un (1) ticket d'or pour les tirages Sautez et Gagnez!
                </strong>
              </p>
              <p>
                Alors ne manquez pas Gōng Xǐ Fā Cái tous les dimanches, Mercredi
                des Boomers, Sièges Chanceux en fureurs, Crédits-jeu en folie et
                les Fêtes du Jackpot les 6 et 7 février, car si vous gagnez,
                vous pourriez gagner à nouveau!
              </p>
              <p>
                Les tirages ont lieu toutes les 5 minutes à partir de 19H30. Si
                la personne tirée n'est pas présente, le prix est reporté au
                tirage suivant, augmentant ainsi le prix suivant. Si un prix est
                remporté 4 fois, le{" "}
                <span>5e tirage aura lieu via des sièges chanceux</span> avec
                tous les joueurs actifs de machine de jeux et table de jeux.
              </p>
              <p>
                Les joueurs ne peuvent gagner qu'une fois pendant la promotion
                Sautez et Gagnez, mais ils seront autorisés à conserver le plus
                gros prix. Les prix non réclamés seront à nouveau tirés au sort
                via des sièges chanceux avant le tirage du Grand Prix.
              </p>
            </>
          )}
          {i18n.language === "en" ? (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Guaranteed Prizes</th>
                </tr>
                <tr>
                  <th>Draw Time</th>
                  <th>Prize amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>7:30pm</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>7:35pm</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>7:40pm</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>7:45pm</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>7:50pm</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>7:55pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:00pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:05pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:10pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:15pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:20pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:25pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:30pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:35pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:40pm</td>
                  <td>$100</td>
                </tr>
                <tr>
                  <td>8:45pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>8:50pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>8:55pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>9:00pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>9:05pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>9:10pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>9:15pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>9:20pm</td>
                  <td>$250</td>
                </tr>
                <tr>
                  <td>9:25pm</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>9:30pm</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>9:35pm</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>9:40pm</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>9:45pm</td>
                  <td>$500</td>
                </tr>
                <tr>
                  <td>9:50pm</td>
                  <td>$5,000 Grand Prize</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Prix garantis</th>
                </tr>
                <tr>
                  <th>Heures des tirages</th>
                  <th>Montant du prix</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>19h30</td>
                  <td>50$</td>
                </tr>
                <tr>
                  <td>19h35</td>
                  <td>50$</td>
                </tr>
                <tr>
                  <td>19h40</td>
                  <td>50$</td>
                </tr>
                <tr>
                  <td>19h45</td>
                  <td>50$</td>
                </tr>
                <tr>
                  <td>19h50</td>
                  <td>50$</td>
                </tr>
                <tr>
                  <td>19h55</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h00</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h05</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h10</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h15</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h20</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h25</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h30</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h35</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h40</td>
                  <td>100$</td>
                </tr>
                <tr>
                  <td>20h45</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>20h50</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>20h55</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>21h00</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>21h05</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>21h10</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>21h15</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>21h20</td>
                  <td>250$</td>
                </tr>
                <tr>
                  <td>21h25</td>
                  <td>500$</td>
                </tr>
                <tr>
                  <td>21h30</td>
                  <td>500$</td>
                </tr>
                <tr>
                  <td>21h35</td>
                  <td>500$</td>
                </tr>
                <tr>
                  <td>21h40</td>
                  <td>500$</td>
                </tr>
                <tr>
                  <td>21h45</td>
                  <td>500$</td>
                </tr>
                <tr>
                  <td>21h50</td>
                  <td>Grand Prix de 5 000$</td>
                </tr>
              </tbody>
            </table>
          )}
          <p>
            Chaque gagnant tiré devra se présenter dans la zone de promotion
            pour récupérer son prix.
          </p>
        </div>
      </div>
      <div>
        <div>
          <p>
            <strong>
              {i18n.language === "en"
                ? "General Rules and Conditions"
                : "Règles générales et conditions"}
            </strong>
          </p>
          {i18n.language === "en" ? (
            <ol>
              <li>
                Every winner during the following promotions in February; Gōng
                Xǐ Fā Cái, Booming Wednesday, Hot Seat Thursday, Free Play
                Friday and Jackpot Parties on February 6 and 7, will receive one
                (1) Golden Ticket to the <strong>Leap & Loot promotion</strong>{" "}
                on <strong>Thursday, February 29, 2024.</strong>
              </li>
              <li>
                After a player is given a{" "}
                <strong>Leap & Loot Golden Ticket</strong> by a Playground
                representative, they will then write down their name and ID on
                the raffle portion of the invitation and drop it in the{" "}
                <strong>Leap & Loot</strong> raffle drum.
              </li>
              <li>
                Winning a Jackpot does not qualify for a{" "}
                <strong>Leap & Loot Golden Ticket.</strong>
              </li>
              <li>
                Starting at 7:30PM, the first draw will be held and will
                continue every 5 minutes until the grand prize draw of $5,000 at
                9:50PM. If a person drawn is not present the prize rolls over
                into the next draw, thus increasing the following prize. If a
                prize rolls over 4 times, the 5th draw will be held via Hot Seat
                from all active gaming machines and table games players.
              </li>
              <li>
                Guests are eligible to win once during the Leap & Loot
                promotion. In the event someone has their name called on more
                than one occasion, by way of the random or hot seat draws, the
                guest will get to keep the greater of the 2 amounts won and the
                difference, which will be the equivalent of the 1st prize won,
                will roll over into the next prize. If the amount is the same as
                their first prize the guest will receive $50 in Free Play.
              </li>
              <li>
                Draw winners will be given five (5) minutes to present
                themselves either to the draw area on the 2nd floor or the desk
                on the 1st floor to confirm their presence before making their
                way to the 2nd floor.
              </li>
              <li>
                All participants must present a valid government-issued photo
                identification upon request.
              </li>
              <li>
                Playground reserves the right to change or alter this promotion,
                as it deems appropriate, including, but not limited to, making
                changes or alterations to these Rules and Regulations relating
                to the accommodation of participants.
              </li>
              <li>The promotion is open to all Playground members.</li>
              <li>The promotion takes place at Playground.</li>
              <li>
                Employees of Playground shall be ineligible to participate in
                the promotion.
              </li>
              <li>
                Patrons with self-excluded or banned status, as determined by
                Playground, are not eligible for any of the gifts/prizes
                associated with Playground contests, promotions, tournaments or
                giveaways.
              </li>
              <li>All references to times and dates reflect Eastern Time.</li>
              <li>
                By participating in the promotion, winners consent to the use of
                their names and photographs for promotional purposes without
                additional compensation.
              </li>
            </ol>
          ) : (
            <ol>
              <li>
                Chaque gagnant lors des promotions suivantes en février; Gōng Xǐ
                Fā Cái, Mercredi des Boomers, Sièges Chanceux en fureurs,
                Crédits-jeu en folie et fêtes du Jackpot les 6 et 7 février,
                recevra un (1) ticket d'or pour la{" "}
                <strong>
                  promotion Sautez et Gagnez le jeudi 29 février 2024.
                </strong>
              </li>
              <li>
                Après qu'un joueur a reçu un{" "}
                <strong>ticket d'or Sautez et Gagnez</strong> d'un représentant
                de Playground, il inscrit ensuite son nom et son ID sur la
                partie tombola de l'invitation et la déposera dans le tambour de
                tombola <strong>Sautez et Gagnez.</strong>
              </li>
              <li>
                Remporter un jackpot ne qualifie pas pour un{" "}
                <strong>ticket d'or Sautez et Gagnez.</strong>
              </li>
              <li>
                À partir de 19H30, le premier tirage aura lieu et se poursuivra
                toutes les 5 minutes jusqu'au tirage du grand prix de 5 000$ à
                21H50. Si une personne tirée n'est pas présente, le prix est
                reporté au tirage suivant, augmentant ainsi le prix suivant. Si
                un prix est remporté 4 fois, le 5e tirage aura lieu via siège
                chanceux avec tous les joueurs actifs sur un machine de jeux et
                table de jeux.
              </li>
              <li>
                Les invités sont éligibles pour gagner une fois pendant la
                promotion Sautez et Gagnez. Dans le cas où quelqu'un a son nom
                appelé à plus d'une occasion, par le biais de tirages au sort
                aléatoires ou de sièges chanceux, l'invité pourra conserver le
                montant le plus élevé des 2 gagnés, et la différence,
                équivalente au 1er prix remporté, sera reportée sur le prix
                suivant. Si le montant est le même que leur premier prix,
                l'invité recevra 50 $ en Crédits-jeu.
              </li>
              <li>
                Les gagnants des tirages auront cinq (5) minutes pour se
                présenter soit à la zone de tirage au 2e étage, soit au bureau
                au 1er étage pour confirmer leur présence avant de se rendre au
                2e étage.
              </li>
              <li>
                Tous les participants doivent présenter une pièce d'identité
                avec photo valide délivrée par le gouvernement sur demande.
              </li>
              <li>
                Playground se réserve le droit de modifier ou de modifier cette
                promotion, comme elle le juge approprié, y compris, mais sans
                s'y limiter, apporter des modifications ou des altérations à ces
                règles et règlements liés à l'accueil des participants.
              </li>
              <li>
                La promotion est ouverte à tous les membres de Playground.
              </li>
              <li>La promotion a lieu à Playground.</li>
              <li>
                Les employés de Playground sont inéligibles pour participer à la
                promotion.
              </li>
              <li>
                Les clients avec un statut d'auto-exclusion ou d'interdiction,
                tel que déterminé par Playground, ne sont pas éligible pour
                aucun des cadeaux/prix associés aux concours, promotions,
                tournois ou cadeaux de Playground.
              </li>
              <li>
                Toutes les références aux heures et dates reflètent l'heure de
                l'Est.
              </li>
              <li>
                En participant à la promotion, les gagnants consentent à
                l'utilisation de leurs noms et photographies à des fins
                promotionnelles sans compensation supplémentaire.
              </li>
            </ol>
          )}
          <p>
            <strong>
              {i18n.language === "en"
                ? "Questions relating to this promotion can be answered by visiting the Player Rewards desk."
                : "Les questions relatives à cette promotion peuvent être répondues en visitant le bureau des récompenses pour les joueurs."}
            </strong>
          </p>
        </div>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  background-color: var(--black);
  color: var(--white);
  position: relative;
  z-index: 0;
  & > div:first-child {
    z-index: 1;
    position: relative;
    width: 100%;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img:first-child {
      height: auto;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    & > img:nth-child(2) {
      height: auto;
      width: 100%;
      object-fit: cover;
      object-position: center;
      display: none;
    }
  }
  & > div:nth-child(2) {
    width: 100%;
    height: auto;
    padding: 40px 0;
    & > div {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      & > h2 {
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #12311d;
        margin: 0px;
        text-align: center;
      }
      & > h3 {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #12311d;
        margin: 5px 0 10px 0;
        line-height: 27px;
        text-align: center;
      }
      & > p {
        font-size: 18px;
      }
      & > table:nth-child(7) {
        width: 100%;
        margin-bottom: 15px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
        background: var(--lightblack);
        & > thead {
          & > tr:first-child {
            & > th {
              background: radial-gradient(
                circle,
                rgb(220 220 220) 0%,
                rgb(145 145 145) 50%,
                rgb(204 204 204) 100%,
                rgb(209 209 209) 100%
              ) !important;
              color: #000;
              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
          & > tr:last-child {
            & > th {
              background: #000;
              color: #fff;
              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              color: #fff;
              background: #000;
              font-weight: 700;
              padding: 15px 5px;
              font-size: 18px;
              border: 2px solid #fff;
            }
          }
        }
        & > div {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
      & > p {
        font-size: 18px;
        text-align: center;
      }
    }
  }
  & > div:nth-child(3) {
    height: auto;
    width: 100%;
    & > div {
      padding: 40px 20px;
      height: auto;
      width: 100%;
      max-width: 1440px;
      background: var(--lightblack);
      margin: 0 auto;
      & > p:last-child {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      padding-top: 80px;
      & > img:first-child {
        display: none;
      }
      & > img:nth-child(2) {
        display: block;
      }
    }
    & > div:nth-child(2) {
      padding: 20px;
    }
  }
`;
export default LeapAndLoot2024;
