import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const NewUser = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv  bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          <h3>{i18n.language === "en" ? "Success!" : "Vous avez réussi!"}</h3>
          <div>
            <div>
              <ResponsiveImage src="svg/Check.svg" alt="check" />
              <h4>
                {i18n.language === "en"
                  ? "Welcome to Playground"
                  : "Bienvenue au Playground"}
              </h4>
              <h4>
                {i18n.language === "en"
                  ? "Please read the instructions below"
                  : "Veuillez lire les instructions ci-dessous"}
              </h4>
              <p>
                {i18n.language === "en"
                  ? "Thank you for signing up for a Playground Player’s Card!"
                  : "Merci d’avoir demandé une Carte de Joueur Playground Poker!"}
              </p>
              <p>
                {i18n.language === "en"
                  ? "Your request has been sent to Playground staff, who will process your request to ensure that the information provided is accurate and complete."
                  : "Votre demande a été envoyée au personnel du Playground, qui la traitera et s’assurera que les informations fournies sont exactes et complètes."}
              </p>
              <p>
                {i18n.language === "en"
                  ? "Once this has been done, your Player’s Card will be created and you will receive an official Welcome Email including your Player’s Card number and a link you can follow to create a password to log in to the Playground website."
                  : "Une fois ce traitement terminé, votre Carte de Joueur sera créée et vous recevrez un courriel officiel de bienvenue incluant votre numéro de Carte de Joueur ainsi qu’une invitation à créer un mot de passe pour vous connecter au site Web du Playground."}
              </p>
              <p>
                {i18n.language === "en"
                  ? "Your Player’s Card number will be valid, and you can pick up the physical Player’s Card the next time you visit the Club."
                  : "Votre numéro de membre sera alors valide et vous pourrez prendre possession de votre carte à votre prochaine visite au Club."}
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div:first-child {
    height: auto;
    min-height: 900px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 570px;
      height: auto;
      & > h3 {
        font-size: 22px;
        font-weight: 700;
        margin: 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--red);
        margin-bottom: 30px;
        color: var(--white);
      }
      & > div {
        background: rgba(0, 0, 0, 0.5);
        padding: 30px;
        text-align: center;
        color: var(--white);
        & > div {
          padding: 20px;
          background: rgba(0, 0, 0, 0.7);
          & > img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin: 10px auto;
          }
          & > h4:nth-child(2) {
            font-weight: normal;
            margin: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--rewards_grid);
          }
          & > p {
            & > strong {
              & > a {
                color: var(--red) !important;
                &:hover {
                  border-bottom: 1px solid var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          padding: 20px;
        }
      }
    }
  }
`;
export default NewUser;
