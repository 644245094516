import React from "react";

import BlogContainer from "src/components/Blog/BlogContainer";
import BlogContent from "src/components/Blog/BlogContent";
import BlogHeader from "src/components/Blog/BlogHeader";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const BbjHits = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <BlogHeader title={t("blog.bad-beat-jackpot.title")} />
      <BlogContainer>
        <BlogContent>
          <p>{t("blog.bad-beat-jackpot.text")}</p>
          <h3>{t("blog.bad-beat-jackpot.content-1-title")}</h3>
          <p>{t("blog.bad-beat-jackpot.content-1-text")}</p>
          <ResponsiveImage
            src="blog/bad-beat-2023-blog-1.jpg"
            alt="bad-beat-2023-blog-1"
          />
          <h3>{t("blog.bad-beat-jackpot.content-2-title")}</h3>
          <p>{t("blog.bad-beat-jackpot.content-2-text-1")}</p>
          <ResponsiveImage
            src="blog/bad-beat-2023-blog-2.jpg"
            alt="bad-beat-2023-blog-2"
          />
          <p>{t("blog.bad-beat-jackpot.content-2-text-2")}</p>
          <ResponsiveImage
            src="blog/bad-beat-2023-blog-3.jpg"
            alt="bad-beat-2023-blog-3"
          />
          <h3>{t("blog.bad-beat-jackpot.content-3-title")}</h3>
          <p>{t("blog.bad-beat-jackpot.content-3-text")}</p>
          <ResponsiveImage
            src="blog/bad-beat-2023-blog-4.jpg"
            alt="bad-beat-2023-blog-4"
          />
          <h3>{t("blog.bad-beat-jackpot.content-4-title")}</h3>
          <p>{t("blog.bad-beat-jackpot.content-4-text")}</p>
          <ResponsiveImage
            src="blog/bad-beat-2023-blog-5.jpg"
            alt="bad-beat-2023-blog-5"
          />
          <h3>{t("blog.bad-beat-jackpot.content-5-title")}</h3>
          <p>{t("blog.bad-beat-jackpot.content-5-text")}</p>
          <ResponsiveImage
            src="blog/bad-beat-2023-blog-6.jpg"
            alt="bad-beat-2023-blog-6"
          />
          <h3>{t("blog.bad-beat-jackpot.conclusion-title")}</h3>
          <p>{t("blog.bad-beat-jackpot.conclusion-text-1")}</p>
          <p>{t("blog.bad-beat-jackpot.conclusion-text-2")}</p>
          <p>
            {t("blog.bad-beat-jackpot.conclusion-text-3")}{" "}
            <a
              href="https://www.pokernews.com/news/2023/08/largest-poker-bad-beat-jackpot-44240.htm"
              target="_blank"
              rel="noreferrer"
            >
              Poker News
            </a>
            ,{" "}
            <a
              href="https://ggpoker.com/blog/daily-scoop/new-world-record-for-largest-bad-beat-jackpot/"
              target="_blank"
              rel="noreferrer"
            >
              GGPoker
            </a>
            ,{" "}
            <a
              href="https://www.cardplayer.com/poker-news/28088-playground-poker-breaks-own-record-for-largest-bad-beat-jackpot-with-cad-2-6-million-payout"
              target="_blank"
              rel="noreferrer"
            >
              Card Player
            </a>
            ,{" "}
            <a
              href="https://www.cardschat.com/news/record-breaking-bad-beat-poker-jackpot-of-1-9-million-hit-in-canada-118740/"
              target="_blank"
              rel="noreferrer"
            >
              CardsChat
            </a>
            ,{" "}
            <a
              href="https://brobible.com/sports/article/biggest-poker-bad-beat-jackpot-ever-hits-quads-straight-flush/"
              target="_blank"
              rel="noreferrer"
            >
              Brobile
            </a>
            ,{" "}
            <a
              href="https://www.poker.org/new-world-record-bad-beat-jackpot-hit-at-canadas-playground-poker-club/"
              target="_blank"
              rel="noreferrer"
            >
              Poker.org
            </a>
            ,{" "}
            <a
              href="https://ftw.usatoday.com/2023/08/bad-beat-jackpot-poker-largest-canada"
              target="_blank"
              rel="noreferrer"
            >
              Usa Today
            </a>
            ,{" "}
            <a
              href="https://www.spadepoker.tv/en/news/detail/a-world-record-bad-beat-jackpot-for-almost-2-million-dollars-in-canada"
              target="_blank"
              rel="noreferrer"
            >
              Spade Poker
            </a>
            ,{" "}
            <a
              href="https://pokerguru.in/poker-news/gossip-column-bad-beat-jackpot-playground-poker-club-04082023"
              target="_blank"
              rel="noreferrer"
            >
              Poker Guru
            </a>
            , {i18n.language === "en" ? "and" : "et"}{" "}
            <a
              href="https://gutshotmagazine.com/bad-beat-jackpot-sets-a-new-record-at-2-6-million/"
              target="_blank"
              rel="noreferrer"
            >
              Gutshot Magazine
            </a>
            .
          </p>
        </BlogContent>
      </BlogContainer>
      
    </>
  );
};
export default BbjHits;
