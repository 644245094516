import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const SuccesfullPayment = () => {
  const { i18n } = useTranslation();
  return (
    <MainDiv bgImage={useResponsiveImageUrl("reg/bg-login.png")}>
      <div>
        <div>
          <h3>
            {i18n.language === "en" ? "Payment received!" : "Paiement reçu!"}
          </h3>
          <div>
            <div>
              <ResponsiveImage
                src="svg/Check.svg"
                alt="check"
              />
              <h4>
                {i18n.language === "en"
                  ? "Thank you! Your purchase is confirmed."
                  : "Merci! Votre achat est confirmé."}
              </h4>
              <p>
                {i18n.language === "en"
                  ? "Your payment has been accepted and a detailed receipt has been sent to your email address."
                  : "Votre paiement a été accepté et un reçu détaillé vous a été envoyé par courriel."}
              </p>
              <p>
                {i18n.language === "en" ? (
                  <strong>
                    Your confirmation email might be delayed, if you have not
                    received it within 12 hours please call{" "}
                    <a href="tel:14506357653">450-635-7653</a>.
                  </strong>
                ) : (
                  <strong>
                    Votre courriel de confirmation peut être retardé, si vous ne
                    l'avez pas reçu dans un délai de 12 heures, veuillez appeler
                    le <a href="tel:14506357653">450-635-7653</a>.
                  </strong>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  width: 100%;
  position: relative;
  z-index: 0;
  & > div:first-child {
    height: auto;
    min-height: 640px;
    width: 100%;
    background-image: url(${(props) => props.bgImage});
    padding: 100px 0 20px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 570px;
      height: auto;
      & > h3 {
        font-size: 22px;
        font-weight: 700;
        margin: 0px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--red);
        margin-bottom: 30px;
        color: var(--white);
      }
      & > div {
        background: rgba(0, 0, 0, 0.5);
        padding: 30px;
        text-align: center;
        color: var(--white);
        & > div {
          padding: 20px;
          background: rgba(0, 0, 0, 0.7);
          & > img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin: 10px auto;
          }
          & > h4 {
            font-weight: normal;
            margin: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--rewards_grid);
          }
          & > p {
            & > strong {
              & > a {
                color: var(--red) !important;
                &:hover {
                  border-bottom: 1px solid var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    & > div:first-child {
      min-height: 80vh;
      padding: 100px 20px 40px 20px;
      & > div {
        & > div {
          padding: 20px;
        }
      }
    }
  }
`;
export default SuccesfullPayment;
