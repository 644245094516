import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "src/components/Button/Button";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { useTranslation } from "react-i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { toUrlFriendlyString } from "src/utils/formatters";

const BadBeatJackpotRules = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const firstTabKey = toUrlFriendlyString(t("pokerRules.badBeatJackpot"));
  const [activeKey, setActiveKey] = useState("badBeatJackpotRules");

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      setActiveKey(hash);
    } else {
      setActiveKey(firstTabKey); // Set the first tab as active by default
    }
  }, [location, firstTabKey]);

  const handleSelect = (key) => {
    setActiveKey(key);
    window.location.hash = key;
  };

  const rules = {
    badBeatJackpotRules: t("pokerRules.badBeatJackpotRules", {
      returnObjects: true,
    }),
    omahaRules: t("pokerRules.omahaRules", { returnObjects: true }),
    highStakesRules: t("pokerRules.highStakesRules", { returnObjects: true }),
    tableHeaders: t("pokerRules.tableHeaders", { returnObjects: true }),
    tableData: t("pokerRules.tableData", { returnObjects: true }),
  };

  return (
    <JackpotRulesDiv>
      <container-div>
        <TitleSection title={t("pokerRules.rulesTitle")} className="mt-5" />
        <Button
          className="ms-0"
          href={t("routes.badBeatJackpot")}
          label={t("button.go-back")}
          size="medium"
          width="wide"
        />
        <Tabs fill activeKey={activeKey} onSelect={handleSelect}>
          <Tab
            eventKey={toUrlFriendlyString(t("pokerRules.badBeatJackpot"))}
            title={t("pokerRules.badBeatJackpot")}
          >
            <SubTitleSection subTitle={t("pokerRules.badBeatJackpot")} />
            <ol>
              {rules.badBeatJackpotRules.map((rule, index) => {
                return (
                  <React.Fragment key={index}>
                    <li>
                      <ReactMarkdown>{rule}</ReactMarkdown>
                      {index === 18 && (
                        <ol type="a">
                          <li>{t("pokerRules.badBeatJackpotPayoutA")}</li>
                          <li>{t("pokerRules.badBeatJackpotPayoutB")}</li>
                          <li>{t("pokerRules.badBeatJackpotPayoutC")}</li>
                          <li>
                            {t("pokerRules.badBeatJackpotPayoutD")}
                            <ol type="i">
                              <li>
                                {t("pokerRules.badBeatJackpotPayoutD1")}
                              </li>
                            </ol>
                          </li>
                        </ol>
                      )}
                      {index === 3 && (
                        <Table striped bordered hover variant="dark" className="my-4">
                          <thead>
                            <tr>
                              <th>{rules.tableHeaders.week}</th>
                              <th>{rules.tableHeaders.qualifyingHand}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rules.tableData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.week}</td>
                                <td>{row.hand}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </li>
                  </React.Fragment>
                );
              })}
            </ol>
          </Tab>
          <Tab
            eventKey={toUrlFriendlyString(t("pokerRules.omahaJackpot"))}
            title={t("pokerRules.omahaJackpot")}
          >
            <SubTitleSection subTitle={t("pokerRules.omahaJackpot")} />
            <ol>
              {rules.omahaRules.map((rule, index) => (
                <li key={index}>
                  <ReactMarkdown>{rule}</ReactMarkdown>
                  {index === 17 && (
                    <ol type="a">
                      <li>{t("pokerRules.omahaJackpotPayoutA")}</li>
                      <li>{t("pokerRules.omahaJackpotPayoutB")}</li>
                      <li>{t("pokerRules.omahaJackpotPayoutC")}</li>
                      <li>
                        {t("pokerRules.omahaJackpotPayoutD")}
                        <ol type="i">
                          <li>{t("pokerRules.omahaJackpotPayoutD1")}</li>
                        </ol>
                      </li>
                    </ol>
                  )}
                </li>
              ))}
            </ol>
          </Tab>
          <Tab
            eventKey={toUrlFriendlyString(t("pokerRules.highStakesJackpot"))}
            title={t("pokerRules.highStakesJackpot")}
          >
            <SubTitleSection subTitle={t("pokerRules.highStakesJackpot")} />
            <ol>
              {rules.highStakesRules.map((rule, index) => (
                <li key={index}>
                  <ReactMarkdown>{rule}</ReactMarkdown>
                  {index === 15 && (
                    <ol type="a">
                      <li>
                        {t("pokerRules.highStakesPayoutA")}
                        <ol type="i">
                          <li>{t("pokerRules.highStakesPayoutA1")}</li>
                          <li>{t("pokerRules.highStakesPayoutA2")}</li>
                          <li>{t("pokerRules.highStakesPayoutA3")}</li>
                        </ol>
                      </li>
                      <li>
                        {t("pokerRules.highStakesPayoutB")}
                        <ol type="i">
                          <li>{t("pokerRules.highStakesPayoutB1")}</li>
                          <li>{t("pokerRules.highStakesPayoutB2")}</li>
                          <li>{t("pokerRules.highStakesPayoutB3")}</li>
                          <li>{t("pokerRules.highStakesPayoutB4")}</li>
                        </ol>
                      </li>
                    </ol>
                  )}
                </li>
              ))}
            </ol>
          </Tab>
        </Tabs>
      </container-div>
      
    </JackpotRulesDiv>
  );
};

const JackpotRulesDiv = styled.div`
  background: var(--lightblack);
  color: var(--white);
  width: 100%;
  height: fit-content;
  .title-section {
    margin-top: 9rem!important;
    @media (max-width: 991.8px) {
      margin-top: 7rem!important;
    }
  }
  .nav-tabs {
  border-bottom: solid 2px var(--white);
  }
  .nav-link {
    background-color: var(--darkgrey);
    color: white;
    border: solid 1px var(--black);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &:hover:not(.active) {
      color: black;
      background: var(--bluegrey);
    }
  }
  .tab-pane {
    padding: 1rem;
    h2 {
      margin-bottom: 1.5rem;
    }
    li {
      margin-bottom: 1.5rem;
    }
  }
`;

export default BadBeatJackpotRules;
