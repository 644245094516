import { homepageActionTypes as actions } from "./homepage.actions";

export const homepageReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.MENU_BAR_TOGGLE:
      return {
        ...state,
        menuBarToggle: payload,
      };
    case actions.CIRCLE_BTN:
      return {
        ...state,
        circleButton: payload,
      };
    case actions.READ_MORE:
      return {
        ...state,
        isReadMore: !state.isReadMore,
      };
    case actions.HOME_OF_PLAYGROUND:
      return {
        ...state,
        homeOfPlayground: !state.homeOfPlayground,
      };
    case actions.HOME_OF_PLAYGROUND_CART_IMG:
      return {
        ...state,
        homeOfPlaygroundCardImg: payload,
      };
    case actions.DARK_THEME:
      return {
        ...state,
        darkTheme: payload,
      };
    case actions.MEGA_MENU:
      return {
        ...state,
        megaMenu: payload,
      };
    case actions.MEGA_MENU_CONTENT:
      return {
        ...state,
        megaMenuContent: payload,
      };
    case actions.PAST_EVENTS_NAV:
      return {
        ...state,
        pastEventsNav: payload,
      };
    case actions.PAST_EVENTS_DATA:
      return {
        ...state,
        pastEventsData: payload,
      };
    case actions.BBJ_DATA:
      return {
        ...state,
        bbjData: payload,
      };
    case actions.OMAHA_DATA:
      return {
        ...state,
        omahaData: payload,
      };
    case actions.HIGHSTAKES_DATA:
      return {
        ...state,
        highstakesData: payload,
      };
    case actions.GAMING_MACHINE_JACKPOT_DATA:
      return {
        ...state,
        gamingMachineJackpotData: payload,
      };
    case actions.LIVE_GAMES_DATA:
      return {
        ...state,
        liveGamesData: payload,
      };
    case actions.TOURNAMENTS_DATA:
      return {
        ...state,
        tournamentsData: payload,
      };
    case actions.TOURNAMENTS_DETAILS:
      return {
        ...state,
        tournamentsDetails: payload,
      };
    case actions.TOURNAMENTS_SUBTABS:
      return {
        ...state,
        tournamentsSubtabs: payload,
      };
    case actions.SECTION_CATEGORY:
      return {
        ...state,
        sectionCategory: payload,
      };
    case actions.SECTION_DESCRIPTION:
      return {
        ...state,
        sectionDescription: payload,
      };
    default:
      return state;
  }
};
