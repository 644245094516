import { useReducer, useMemo, useContext, useCallback } from "react";
import { ersReducer } from "./ers.reducer";
import { ersActionTypes as actions } from "./ers.actions";
import { ErsState } from "./ers.state";
import { fetchErs } from "src/api/ers";

const ERS_STATE = {
  error: null,
  data: {},
};

export const ErsStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    ersReducer,
    ERS_STATE
  );

  const fetchData = useCallback(async (slug) => {
    try {
      const response = await fetchErs(slug);
      dispatch({ type: actions.ERS_DATA, payload: { slug, data: response.data } });
    } catch (error) {
      dispatch({ type: actions.ERS_DATA, payload: { error: error.message } });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      fetchData,
    }),
    [state, fetchData]
  );
  return (
    <ErsState.Provider value={value}>
      {children}
    </ErsState.Provider>
  );
};
export const useErs = () => useContext(ErsState);
