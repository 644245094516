import { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import CashBack from "./CashBack";
import EGDBenefits from "./EGDBenefits";
import FoodAndBeverage from "./FoodAndBeverage";
import Merchandise from "./Merchandise";
import PokerBenefits from "./PokerBenefits";
import Promotions from "./Promotions";
import Redemptions from "./Redemptions";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTabs from "../SectionalTabs/ScrollToTabs";

const HowToRedeem = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("redemptions");
  useEffect(() => {
    const tab = Object.keys(tabs)
      .map((category) => {
        if (location.pathname === tabs[category].url) {
          return category;
        } else if (
          location.pathname.includes(t("routes.howToRedeem") && !category)
        ) {
          return "redemptions";
        }
        return null;
      })
      .find((category) => category !== null);

    if (tab) {
      setIsActive(tab);
    } else if (
      location.pathname.includes(t("routes.howToRedeem")) &&
      !tab &&
      isActive === "redemptions" &&
      location.pathname !== t("routes.howToRedeem")
    ) {
      navigate(t("routes.error"));
    }
  }, [location.pathname]);

  const tabs = {
    redemptions: {
      label: t("rewards.redeem-nav-1"),
      url: t("routes.howToRedeemRedemptions"),
    },
    promotions: {
      label: t("rewards.redeem-nav-2"),
      url: t("routes.howToRedeemPromotions"),
    },
    merchandise: {
      label: t("rewards.redeem-nav-3"),
      url: t("routes.howToRedeemMerchandise"),
    },
    cashBack: {
      label: t("rewards.redeem-nav-5"),
      url: t("routes.howToRedeemCashBack"),
    },
  };

  return (
    <MainDiv>
      <ScrollToTabs isActive={isActive} scrollFix={t("routes.howToRedeem")} tabs={tabs} />

      {isActive === "redemptions" && <Redemptions />}
      {isActive === "promotions" && <Promotions />}
      {isActive === "merchandise" && <Merchandise />}
      {isActive === "foodAndBev" && <FoodAndBeverage />}
      {isActive === "cashBack" && <CashBack />}
    </MainDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  padding: 40px;
  background: var(--white);
  color: var(--black);
  transition: all 0.4s;
  & > h1 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
  }
  /* & > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    & > h2 {
      font-size: 18px;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 50px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        border: 1px solid var(--red);
        background: var(--red);
        color: var(--white);
      }
    }
    .isActive {
      border: 1px solid var(--red);
      background: var(--red);
      color: var(--white);
    }
  }
  & > div:last-child {
    animation: ${fadeIn} 0.3s linear;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
  /* padding: 20px;
    & > div:nth-child(2) {
      gap: 10px;
      & > h2 {
        text-align: center;
        width: 100%;
      }
    } */
  /* } */
`;

export default HowToRedeem;
