import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TitleSection from "src/components/TitleSection/TitleSection";

const ResponsibleGaming = () => {
  const { t } = useTranslation();
  const problems = Array.from({ length: 20 }, (_, index) => index + 1);
  return (
    <ResponsibleGamingDiv>
      <container-div>
        <TitleSection title={t("responsible-gaming.rg-title")} />
          <p>{t("responsible-gaming.rg-text")}</p>
          <h3>{t("responsible-gaming.rg-problem-title")}</h3>
          <p>{t("responsible-gaming.rg-problem-text")}</p>
          <ol>
            {problems.map((number) => (
              <li key={number}>
                {t(`responsible-gaming.rg-problem-li-${number}`)}
              </li>
            ))}
          </ol>
          <h3>{t("responsible-gaming.rg-exclusion-title")}</h3>
          <p>{t("responsible-gaming.rg-exclusion-text")}</p>
          <h3>{t("responsible-gaming.rg-org-title")}</h3>
          <p>{t("responsible-gaming.rg-org-text")}</p>
          <h3>{t("responsible-gaming.rg-gambling-title")}</h3>
          <ul>
            <li>
              {t("responsible-gaming.rg-gambling-li-1")}{" "}
              <a href="http://www.jeu-aidereference.qc.ca/en" target="_blank">
                http://www.jeu-aidereference.qc.ca/en
              </a>
            </li>
            <li>
              {t("responsible-gaming.rg-gambling-li-2")}{" "}
              <a href="mailto:jar@info-reference.qc.ca">
                jar@info-reference.qc.ca
              </a>
            </li>
            <li>
              {t("responsible-gaming.rg-gambling-li-3")}{" "}
              <a href="tel:15145270140">1-514-527-0140</a>
            </li>
            <li>
              {t("responsible-gaming.rg-gambling-li-4")}{" "}
              <a href="tel:18004610140">1-800-461-0140</a>
            </li>
          </ul>
          <h3>{t("responsible-gaming.rg-gamblers-title")}</h3>
          <ul>
            <li>
              {t("responsible-gaming.rg-gamblers-li-1")}{" "}
              <a href={t("responsible-gaming.ga-link")} target="_blank">
                {t("responsible-gaming.ga-link")}
              </a>
            </li>
            <li>
              {t("responsible-gaming.rg-gamblers-li-2")}{" "}
              <a href="mailto:webmaster@gamblingtherapy.org">
                webmaster@gamblingtherapy.org
              </a>
            </li>
            <li>
              {t("responsible-gaming.rg-gamblers-li-3")}{" "}
              <a href="tel:1-514-484-6666">1-514-484-6666</a>
            </li>
          </ul>
      </container-div>
    </ResponsibleGamingDiv>
  );
};


const ResponsibleGamingDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: var(--lightblack);
  padding: 140px 0;
  color: var(--mutedwhite);
  p:last-child {
    padding-top: 2rem;
  }
`;
export default ResponsibleGaming;
