import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const BoomingWednesday = () => {
  const { i18n } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl(
    "promotions/booming-wednesday-bg.png"
  );
  return (
    <PromoDetailDiv bgImage={responsiveBgImage}>
      <div>
        <ResponsiveImage
          src={`promotions/booming-wednesday-logo-${i18n.language}.png`}
          alt="booming-wednesday-logo-en"
        />
        {i18n.language === "en" ? (
          <div>
            <div>
              <h1>Booming Wednesday</h1>
              <h2>
                <span>From 7:00am to 3:00pm</span>
                All our members who are <strong> 60+ years young</strong> can
                enjoy <strong>a free offer and an exclusive promotion</strong>{" "}
                every Wednesday. Now that’s groovy!
              </h2>
            </div>
          </div>
        ) : (
          <div>
            <div>
              <h1>Mercredi Des Boomers</h1>
              <h2>
                <span>De 7h00 à 15h00</span>
                Tous nos membres <strong>
                  {" "}
                  jeunes de 60 ans et plus,
                </strong>{" "}
                peuvent bénéficier{" "}
                <strong>
                  d'une offre gratuite et d'une promotion exclusive
                </strong>{" "}
                chaque mercredi. Ça, c'est génial!
              </h2>
            </div>
          </div>
        )}
      </div>
      <div>
        {i18n.language === "en" ? (
          <div>
            <p>
              Claim your Free Play amount every Wednesday between 7:00am and
              3:00pm.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Tier</th>
                  <th>Free Play Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Silver</td>
                  <td>$5</td>
                </tr>
                <tr>
                  <td>Gold</td>
                  <td>$10</td>
                </tr>
                <tr>
                  <td>Red</td>
                  <td>$25</td>
                </tr>
                <tr>
                  <td>Black</td>
                  <td>$50</td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>Win your age in cash!</strong>
            </p>
            <p>
              You’ll definitely have a gas from{" "}
              <strong>7:00am to 3:00pm</strong>, one winner will be randomly
              selected <strong>every hour</strong> from all our active gaming
              machine players who are 60+ years young and they will be presented
              with their age in cash!
            </p>
            <small>
              *Only members that are 60 years of age or over on the day of the
              Booming Wednesday promotions are eligible to participate.
            </small>
          </div>
        ) : (
          <div>
            <p>
              Réclamez votre montant de crédits-jeu tous les mercredis entre
              7h00 et 15h00.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Niveau</th>
                  <th>Montant en crédits-jeu</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Argent</td>
                  <td>5$</td>
                </tr>
                <tr>
                  <td>Or</td>
                  <td>10$</td>
                </tr>
                <tr>
                  <td>Rouge</td>
                  <td>25$</td>
                </tr>
                <tr>
                  <td>Noir</td>
                  <td>50$</td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>Gagnez votre âge en argent comptant!</strong>
            </p>
            <p>
              Attachez vos tuques <strong>de 7h00 à 15h00</strong> - un gagnant
              sera tiré au sort <strong>toutes les heures</strong> parmi tous
              nos joueurs actifs de machines de jeu âgés de plus de 60 ans et
              recevra son âge en argent comptant!
            </p>
            <small>
              *Seuls les membres âgés de 60 ans ou plus le jour des promotions
              Mercredi des Boomers sont éligibles pour participer.
            </small>
          </div>
        )}
      </div>
      <NavbarSemiTransparentBg></NavbarSemiTransparentBg>
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;
  font-family: "Open Sans", sans-serif;
  background: #e8ebef;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 0% 0% fixed no-repeat;
    z-index: 0;
    height: fit-content;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > img {
      text-align: center;
      margin: 0 auto;
      max-width: 800px;
      width: 100%;
      vertical-align: middle;
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 20px 0 40px;
      & > div {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        & > h1 {
          color: #fff;
          font-weight: 900;
        }
        & > h2 {
          margin: 0;
          font-size: 30px;
          text-shadow: 2px 2px black;
          font-weight: 400;
          line-height: 40px;
          color: #fff;
          padding: 0 10px;
          & > span {
            background-color: #00b6cc;
            padding: 5px 30px;
            display: block;
            margin-bottom: 5px;
            text-shadow: none;
            & > strong {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  & > div:nth-child(2) {
    height: fit-content;
    width: 100%;
    margin-bottom: 20px;
    & > div {
      background: #fff;
      max-width: 1440px;
      width: 100%;
      margin: 40px auto;
      height: 100%;
      padding: 40px 40px 80px 40px;
      & > p {
        line-height: 28px;
      }
      & > p:first-child {
        font-size: 18px;
        text-align: left;
        padding: 0px 15px;
        color: #555;
        margin: 0 0 10px;
      }
      & > table:nth-child(2) {
        margin: 20px 0px 20px 0px;
        width: 100%;
        background: var(--white);
        border-spacing: 0;
        border-collapse: collapse;
        & > thead {
          & > tr {
            text-align: center;
            background: #131619;
            color: #fff;
            border-radius: 0px;
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            font-weight: 700;
            letter-spacing: 0.5px;
            & > th {
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: top;
              border: 1px solid #ddd;
              border-bottom: none;
            }
          }
        }
        & > tbody {
          & > tr {
            height: 40px;
            &:hover {
              background: #e8ebef;
            }
            & > td {
              border: 1px solid #ddd;
              text-align: center;
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: top;
              font-size: 14px;
            }
          }
        }
      }
      & > p:nth-child(3) {
        font-size: 18px;
        text-align: left;
        margin: 0 0 10px;
        color: #555;
        margin-top: 40px;
      }
      & > p:nth-child(4) {
        font-size: 18px;
        text-align: left;
        color: #555;
        margin: 0 0 10px;
      }
      & > small:nth-child(5) {
        font-size: 85%;
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div:first-child {
      background-position: center;
      & > div:nth-child(2) {
        padding: 40px 20px;
        & > div {
          & > h2 {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }
    }
    & > div:nth-child(2) {
      & > div {
        padding: 20px 20px 40px 20px;
        & > small:nth-child(5) {
          font-size: 12px;
        }
      }
    }
  }
`;
const NavbarSemiTransparentBg = styled.div`
  top: 0;
  position: absolute;
  height: 144px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
`;
export default BoomingWednesday;
