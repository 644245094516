import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useState } from "react";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const RestaurantCarousel = ({ slides }) => {
  const [slideRight, setSlideRight] = useState(true);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 700,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: true,
    beforeChange: (prev, next) => slideIndexChange(prev, next),
  };

  const slideIndexChange = (prev, next) => {
    const totalSlides = slides.length - 1;
    if (next > prev) {
      setSlideRight(true);
    } else if (next < prev && prev === totalSlides && next === 0) {
      setSlideRight(true);
    } else {
      setSlideRight(false);
    }
  };

  return (
    <Third slide={slideRight}>
      <Slider {...settings}>
        {slides.map((el) => (
          <div key={el.id}>
            <ResponsiveImage src={el.image} alt={el.title} />
          </div>
        ))}
      </Slider>
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </Third>
  );
};

const Third = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  .slick-track {
    cursor: pointer !important;
  }
  .slick-slider {
    height: 100% !important;
    max-height: 700px !important;
    width: 100%;
    & > div {
      height: 100%;
      max-height: 700px !important;
    }
    & > div > div > div > div > div {
      outline: none !important;
      position: relative;
      height: 100%;
      width: 100%;
      & > img {
        padding: 15px 5px;
        height: 100%;
        width: 100%;
        max-height: 1024px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  & > ul:last-child {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 10px;
    width: 90px;
    height: 35px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.4),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);
    list-style-type: none;
    & > li {
      margin: 0;
      padding: 5px;
      border-radius: 50%;
      background: var(--lightgrey);
    }
    & > li:first-child {
      padding: ${({ slide }) => slide === false && "4px"};
      background: ${({ slide }) => slide === false && "var(--grey)"};
    }
    & > li:last-child {
      padding: ${({ slide }) => slide === true && "4px"};
      background: ${({ slide }) => slide === true && "var(--grey)"};
    }
  }
  @media screen and (min-width: 992px) {
    & > ul:last-child {
      display: none;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    max-height: 1024px;
    overflow: hidden;
    .slick-slider {
      max-height: 1024px !important;
      & > div {
        max-height: 1024px !important;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .slick-slider {
      max-height: 700px !important;
      & > div > div > div > div > div {
        & > img {
          max-height: 440px !important;
        }
      }
    }
  }
`;

RestaurantCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
};

RestaurantCarousel.defaultProps = {
  slidesToShow: 3,
  slidesToScroll: 1,
};

export default RestaurantCarousel;
