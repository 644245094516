import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { useTranslation } from "react-i18next";
import Button from "src/components/Button/Button";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const PlaygroundCaresDetails = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const updateData = async () => {
      try {
        // Dynamically import JSON files based on the current language
        const playgroundCaresData = await import(
          `../../locales/${i18n.language}/playgroundCares.json`
        );
        const routesData = await import(
          `../../locales/${i18n.language}/routes.json`
        );

        // Find the key that matches the current pathname in the routes
        const currentKey = Object.keys(routesData.routes).find(
          (key) => routesData.routes[key] === location.pathname
        );

        console.log(currentKey);

        if (currentKey) {
          const currentItem =
            playgroundCaresData.playgroundCares.data[currentKey];
          if (currentItem) {
            setItem(currentItem);
          } else {
            console.warn(`No data found for key: ${currentKey}`);
            navigate(t("routes.playgroundCares"), { replace: true });
          }
        } else {
          console.warn(`No route matching pathname: ${location.pathname}`);
          navigate(t("routes.playgroundCares"), { replace: true });
        }
      } catch (error) {
        console.error(`Error loading data: ${error.message}`);
        navigate(t("routes.playgroundCares"), { replace: true });
      }
    };

    // Update data when language or location changes
    updateData();
  }, [i18n.language, location.pathname, navigate, t]);

  const content = item?.text.split("\n");
  const link = item?.link;

  return (
    <Div
      props={{ small_image: item?.small_image ? "true" : "false" }}
      bgImage={useResponsiveImageUrl(
        "playground_cares/generic_playground_cares_story_header.jpg"
      )}
    >
      <div>
        <div>
          <h1>{item?.title}</h1>
        </div>
      </div>
      <div>
        <Button
          color="var(--white)"
          hoverBgColor="var(--black)"
          hoverColor="var(--white)"
          label={t("button.go-back")}
          href={t("routes.playgroundCares")}
        />
      </div>
      <div>
        <ResponsiveImage src={item?.image} alt={item?.title} />
        <div>
          {link ? (
            <a href={link} target="_blank" rel="noreferrer">
              {link.substring(link.length - 3) === "pdf"
                ? i18n.language === "en"
                  ? "Press Release"
                  : "Article de presse"
                : i18n.language === "en"
                  ? "Event info"
                  : "Infos sur l'événement"}
            </a>
          ) : null}
          {content?.map((el, index) => (
            <p key={index}>{el}</p>
          ))}
          {item?.image_two ? (
            <ResponsiveImage src={item?.image_two} alt={item?.title} />
          ) : null}
        </div>
      </div>
      
    </Div>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Div = styled.div`
  height: auto;
  width: 100%;
  background: var(--lightgrey);

  & > div:first-child {
    background: url(${(props) => props.bgImage}) no-repeat;
    background-position: 50% 50%;
    padding: 150px 0px 110px;
    background-size: cover;
    z-index: 1;
    position: relative;
    border-bottom: 1px solid #222;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > div {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      height: fit-content;
      padding: 30px;
      text-align: center;
      & > h1 {
        font-size: 60px;
        line-height: 60px;
        font-weight: 700;
        margin: 0px;
        margin-bottom: 10px;
        font-weight: 700;
        color: #fff;
      }
    }
  }

  & > div:nth-child(2) {
    margin: 40px auto 20px auto;
    width: 100%;
    max-width: 1440px;
    & > a {
      margin: 0;
      max-width: 200px;
    }
  }

  & > div:nth-child(3) {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    & > div {
      background: var(--white);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      height: fit-content;
      width: 100%;
      padding: 20px;
      & > a {
        color: var(--red);
        font-size: 14px;
        position: relative;
        margin: 0 0 10px;
        display: block;
      }
      & > p {
        margin: 0 0 10px;
        color: #555;
        font-size: 14px;
      }
      & > img {
        animation: ${fadeIn} 0.3s linear;
        height: auto;
        width: ${({ props }) =>
          props.small_image === "true" ? "fit-content" : "100%"};
      }
      & > img:last-child {
        object-fit: contain;
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 991px) {
    & > div:first-child {
      border-bottom: 1px solid #222;
      padding: 130px 0 90px 0;
      & > div {
        & > h1 {
          font-size: 40px;
          line-height: 40px;
          padding: 0 15px;
        }
      }
    }
    & > div:nth-child(2),
    & > div:nth-child(3) {
      padding: 0 20px;
    }
  }
`;

export default PlaygroundCaresDetails;
