import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";


import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const BonVoyage2024 = () => {
  const [isActive, setIsActive] = useState("Boarding Pass");
  const { i18n } = useTranslation();
  const lng = i18n.language;
  const BoardingPassRef = useRef(null);
  const handleIsActive = (value) => {
    setIsActive(value);
  };

  useEffect(() => {
    // Check if there is a hash in the URL and update the active tab accordingly
    const hash = window.location.hash.substring(1); // Remove the '#' symbol
    if (hash) {
      handleIsActive("Boarding Pass");
    }
    // Scroll to the Boarding Pass section when the component mounts
    if (BoardingPassRef.current && hash !== "") {
      BoardingPassRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <MainDiv lng={i18n.language}>
      <div></div>
      <div>
        <div>
          {i18n.language === "en" ? (
            <p>
              Ever dream of going to <strong>Rio de Janeiro?</strong> What about
              visiting <strong>New Orleans?</strong> Is attending a{" "}
              <strong>Carnival Ball in Venice, Italy</strong> something you want
              to cross off your bucket list? Whatever your carnival dreams are
              made of, Playground wants to make it happen!
            </p>
          ) : (
            <p>
              Rêvez-vous d’aller à <strong>Rio de Janeiro?</strong> Ou encore de
              visiter la <strong>Nouvelle Orléans?</strong> Est-ce qu’assister à
              un bal <strong>Carnaval à Venise, Italie</strong> est parmi vos
              ambitions de voyage? Quelles que soient vos vacances de rêve,
              Playground veut vous les offrir!
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              Draws will be held{" "}
              <strong>every Sunday from February 26 to April 7,</strong> giving
              a total of <strong>50 players</strong> the chance to play Bon
              Voyage! Players earn one raffle entry for every 250 Rewards Points
              earned through gaming machines play during each earning period.
            </p>
          ) : (
            <p>
              Une série de tirages a lieu{" "}
              <strong>tous les dimanches du 26 février au 7 avril,</strong> pour
              donner la chance à <strong>50 joueurs</strong> de jouer à Bon
              Voyage! Les joueurs reçoivent une participation aux tirages pour
              chaque tranche de 250 Points Récompenses accumulés aux machine de
              jeux, et ce pendant la période d’accumulation.
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              Before playing Bon Voyage! contestants get to spin the{" "}
              <strong>bonus wheel</strong> to win additional selections. The
              game board has 15 pictures of carnival and travel related items.
              Contestants win by matching the pictures to the list in the sealed
              game envelope.{" "}
              <strong>Find five items that match the list</strong> and win the
              grand prize of{" "}
              <strong>
                2 Carnival themed Vacations of your choice valued at $25,000!
              </strong>
            </p>
          ) : (
            <p>
              Avant de jouer à Bon Voyage!, les participants font tourner la{" "}
              <strong>roue extra</strong> pour gagner des choix additionnels. Le
              grand tableau présente 15 photos de ces objets liés au voyage ou
              au carnaval. Les participants gagnent en faisant correspondre les
              photos des articles de voyage à la liste scellée dans l'enveloppe
              de jeu.{" "}
              <strong>
                Trouvez cinq articles qui correspondent à la liste et gagnez le
                grand prix: deux vacances à thème Carnaval de votre choix d'une
                valeur de 25 000$!
              </strong>
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              All contestants will also win a <strong>Boarding Pass</strong> to
              the <strong>Bon Voyage Finale!</strong>
            </p>
          ) : (
            <p>
              Tous les participants gagneront également une{" "}
              <strong>Carte d'embarquement</strong> pour la{" "}
              <strong>finale de Bon Voyage!</strong>
            </p>
          )}
        </div>
      </div>
      <div>
        <div>
          {i18n.language === "en" ? (
            <h1>
              Win 2 Carnival vacations of your choice <br />{" "}
              <span>valued at $25,000!</span>
            </h1>
          ) : (
            <h1>
              Gagnez 2 vacances Carnaval de votre choix d'une
              <br />
              <span>valeur de 25&nbsp;000$!</span>
            </h1>
          )}
          <h2>
            <strong>
              {i18n.language === "en" ? "EVERY SUNDAY" : "TOUS LES DIMANCHES"}
            </strong>
            <br />
            <strong>
              {i18n.language === "en"
                ? "FEBRUARY 26 - MARCH 31"
                : "DU 26 FÉVRIER AU 31 MARS"}
            </strong>
            <br />
            <strong>
              {i18n.language === "en"
                ? "2:00PM | 3:30PM | 5:00PM | 6:30PM | 8:00PM"
                : "14H00 | 15H30 | 17H00 | 18H30 | 20H00"}
            </strong>
          </h2>
        </div>
        <div>
          <ResponsiveImage
            src={`promotions/bon-voyage-lp-2023-2-${lng}.png`}
            alt="bon-voyage-lp-2023"
          />
          <ResponsiveImage
            src={`promotions/bon-voyage-lp-2023-10-${lng}.png`}
            alt="chinese-new-year-lp_05"
          />
          <ResponsiveImage
            src={`promotions/bon-voyage-lp-2023-50-${lng}.png`}
            alt="105-winner-gxfc-en"
          />
        </div>
      </div>
      <PartFour>
        <div>
          <ResponsiveImage
            src={`promotions/bon-voyage-lp-2024-finale-logo-${lng}.png`}
            alt="bon-voyage"
          />
          {i18n.language === "en" ? (
            <h2>
              <span>$25,000 IN PRIZES - 25 WINNERS - $10,000 GRAND PRIZE</span>
            </h2>
          ) : (
            <h2>
              <span>25 000$ EN PRIX - 25 GAGNANTS - GRAND PRIX DE 10 000$</span>
            </h2>
          )}
          <h2>{i18n.language === "en" ? "ALL GUARANTEED" : "TOUS GARANTIS"}</h2>
          {i18n.language === "en" ? (
            <p>
              <strong>Draws every 30 minutes from 1:00pm to 9:00pm</strong> for
              all Boarding Pass holders for a total 17 winners. Plus{" "}
              <strong>Hot Seat draws every hour from 1:30pm to 8:30pm</strong>.
              *The Hot Seat draws held at 2:30pm, 5:30pm and 8:30pm will be for
              Black tier members.
            </p>
          ) : (
            <p>
              <strong>Tirages toutes les 30 minutes de 13h00 à 21h00</strong>{" "}
              pour tous les détenteurs de Cartes d'embarquement pour un total de
              17 gagnants. Plus des{" "}
              <strong>
                tirages par Sièges Chanceux toutes les heures de 13h30 à 20h30.
              </strong>{" "}
              *Les tirages Sièges Chanceux de 14h30, 17h30 et 20h30 seront
              réservés aux joueurs de niveau noir.
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              Following the last draw at 9:00pm, all 25 winners will then
              present themselves to the Bon Voyage! Game Board where they will
              find 25 envelopes, each containing a prize amount and one
              containing the <strong>$10,000 Grand Prize</strong>.
            </p>
          ) : (
            <p>
              Après le dernier tirage à 21h00, les 25 gagnants se présenteront
              ensuite au plateau de jeu Bon Voyage! où ils trouveront 25
              enveloppes, chacun contenant un montant de prix et un contenant le{" "}
              <strong>grand prix de 10 000$.</strong>
            </p>
          )}
          {i18n.language === "en" ? (
            <p>
              envelopes will be selected one at a time,{" "}
              <strong>
                over the course of 5 rounds in groups of 5 winners, in the same
                order names were drawn throughout the day, and then the 5
                selected envelopes are opened simultaneously and their prize
                revealed
              </strong>
              . This process is then repeated until all winners have selected
              and opened their envelopes.
            </p>
          ) : (
            <p>
              Les enveloppes seront sélectionnés un par un,{" "}
              <strong>
                au cours de 5 tours, par groupes de 5 gagnants, dans le même
                ordre que celui dans lequel les noms ont été tirés tout au long
                de la journée, puis les 5 enveloppes sélectionnés seront ouverts
                simultanément et leur prix sera révélé.
              </strong>{" "}
              Ce processus est ensuite répété jusqu'à ce que tous les gagnants
              aient sélectionné et ouvert leur enveloppe.
            </p>
          )}
          <p>
            <strong>
              {i18n.language === "en" ? (
                <i>Carnivals bring out the child in everyone!</i>
              ) : (
                <i>Les carnavals font ressortir l'enfant en chacun de nous!</i>
              )}
            </strong>
          </p>
        </div>
      </PartFour>
      <PartFive>
        <ul>
          <li
            onClick={() => handleIsActive("Boarding Pass")}
            className={isActive === "Boarding Pass" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Boarding Pass" : "Carte d'embarquement"}
          </li>
          <li
            onClick={() => handleIsActive("Game Description")}
            className={isActive === "Game Description" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Game Description" : "Description du jeu"}
          </li>
          <li
            onClick={() => handleIsActive("How to Qualify")}
            className={isActive === "How to Qualify" ? "active" : undefined}
          >
            {i18n.language === "en" ? "How to Qualify" : "Comment se Qualifier"}
          </li>
          <li
            onClick={() => handleIsActive("Draw Process")}
            className={isActive === "Draw Process" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Draw Process" : "Tirages - Procédures"}
          </li>
          <li
            onClick={() => handleIsActive("Eligibility")}
            className={isActive === "Eligibility" ? "active" : undefined}
          >
            {i18n.language === "en" ? "Eligibility" : "Admissibilité"}
          </li>
          <li
            onClick={() => handleIsActive("General Rules")}
            className={isActive === "General Rules" ? "active" : undefined}
          >
            {i18n.language === "en" ? "General Rules" : "Règles Générales"}
          </li>
        </ul>
        <div>
          {isActive === "Boarding Pass" ? (
            <BoardingPass ref={BoardingPassRef}>
                  <ResponsiveImage
                    src={`promotions/boarding-pass-2023-logo-${lng}.png`}
                    alt="boarding-pass-2023-logo"
                  />
              {i18n.language === "en" ? (
                <>
                  <div>
                    <h3>How can you get your hands on a Boarding Pass?</h3>
                    <ol>
                      Players can collect them the following ways:
                      <li>
                        Boarding Passes are awarded to all players who get
                        called to play Bon Voyage Carnival Edition on any of the
                        draw days.
                      </li>
                      <li>
                        Boarding Passes are awarded, along with $50 in Free Play
                        via Hot Seat draw, every Thursday between February 29
                        and March 28, at a rate of 2 per hour from 11:00am to
                        11:00pm.
                      </li>
                      <li>
                        Boarding Passes are awarded via Hot Seat draw, every day
                        between April 1 and 5, at a rate of 2 per hour from
                        11:00am to 11:00pm.
                      </li>
                      <li>
                        Boarding Passes can also be randomly awarded by floor
                        staff.
                      </li>
                    </ol>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h3>
                      Comment pouvez-vous mettre la main sur une Carte
                      d'embarquement?
                    </h3>
                    <ol>
                      Vous pouvez en acquérir de différentes façons:
                      <li>
                        Une Carte d'embarquement est décernée à chaque joueur
                        sélectionné pour jouer à Bon Voyage! Édition Carnaval.
                      </li>
                      <li>
                        Des Cartes d'embarquement, ainsi que 50$ en crédits-jeu,
                        sont décernées par tirage Siège Chanceux, chaque jeudi
                        entre le 29 février et le 28 mars, à raison de 2 par
                        heure de 11h00 à 23h00.
                      </li>
                      <li>
                        Des Cartes d'embarquement sont décernées par tirage
                        Siège Chanceux, tous les jours entre le 1 et 5 avril, à
                        raison de 2 par heure de 11h00 à 23h00.
                      </li>
                      <li>
                        Des cartes d'embarquement peuvent être remises par le
                        personnel de façon aléatoire.
                      </li>
                    </ol>
                  </div>
                </>
              )}
            </BoardingPass>
          ) : null}
          {isActive === "Game Description" ? (
            i18n.language === "en" ? (
              <div>
                <h2>Game Description</h2>
                <ol>
                  <li>
                    The <strong>Bon Voyage!</strong> game board contains fifteen
                    (15) pictures, each showing a different carnival-related
                    item (ex: mask, tickets, etc.).
                  </li>
                  <li>
                    There is a sealed envelope, unique to each of the fifty (50)
                    games, containing five (5) randomly selected game cards from
                    the board.
                  </li>
                  <li>
                    Prior to beginning the game all players will get to spin the
                    bonus wheel, which will determine the number of items they
                    get to select. The bonus wheel contains the following
                    options:
                    <ol type="a">
                      <li>10 x 5 selections</li>
                      <li>4 x 6 selections</li>
                      <li>4 x 7 selections</li>
                      <li>2 x 8 selections</li>
                    </ol>
                  </li>
                  <li>
                    Prizing is as follows:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Number of matching Items</th>
                            <th>Prize</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0</td>
                            <td>$250</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>$500</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>$750</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>$1,000</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>$2,500</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Carnival Vacation! ($25,000 value)</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
                <p>
                  All contestants also win a <strong>Boarding Pass</strong>{" "}
                  (more details below)
                </p>
                <h3>Bon Voyage Finale Carnival Edition</h3>
                <ol start="5">
                  <li>
                    Only players that have collected a Boarding Pass between
                    February 26 and April 7, 2024 are eligible to play.
                  </li>
                  <li>
                    There will be twenty-five (25) envelopes at the{" "}
                    <strong>Bon Voyage! Finale</strong> game board.
                  </li>
                  <li>
                    Each envelope will contain one (1) of four (4) prize
                    amounts.
                  </li>
                  <li>
                    After the final draw at 9:00pm, the twenty-five (25) winners
                    will be brought back to the Bon Voyage! Finale game board.
                  </li>
                  <li>
                    Each winner will select one of{" "}
                    <strong>twenty-five (25) envelopes,</strong> which will be
                    selected one at a time,{" "}
                    <strong>
                      over the course of five (5) rounds in groups of five (5)
                      winners, in the same order names were drawn throughout the
                      day, and then the five (5) selected envelopes are opened
                      simultaneously.
                    </strong>{" "}
                    This process is then repeated until all winners have
                    selected and opened their envelopes.
                  </li>
                  <li>
                    Total prizing of twenty-five thousand dollars ($25,000) is
                    broken down as follows:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Amount to be won</th>
                            <th>Prize amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>4</td>
                            <td>$250</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>$500</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>$1,000</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>$10,000</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Description du jeu</h2>
                <ol>
                  <li>
                    Le tableau <strong>Bon Voyage!</strong> contient quinze (15)
                    photos, chacune représentant un article de carnaval distinct
                    (ex.: masque, tickets, etc.).
                  </li>
                  <li>
                    Il y a une enveloppe scellée, unique pour chacun des
                    cinquante (50) jeux, contenant cinq (5) cartes de jeu
                    choisies au hasard dans le tableau.
                  </li>
                  <li>
                    Avant de commencer le jeu, tous les joueurs auront la
                    possibilité de faire tourner la roue de bonus, ce qui
                    déterminera la quantité d’articles qu'ils peuvent choisir.
                    La roue de bonus contient les options suivantes:
                    <ol type="a">
                      <li>10 x 5 selections</li>
                      <li>4 x 6 selections</li>
                      <li>4 x 7 selections</li>
                      <li>2 x 8 selections</li>
                    </ol>
                  </li>
                  <li>
                    Voici les prixs possible:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Nombre des articles correspondants</th>
                            <th>Prix</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0</td>
                            <td>250$</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>500$</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>750$</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>1,000$</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>2,500$</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Bon Voyage Carnaval! (Valeur de 25 000$)</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
                <p>
                  Tous les joueurs gagnent aussi une{" "}
                  <strong>Carte d’embarquement.</strong> (plus de details en
                  bas)
                </p>
                <h3>Finale Bon Voyage Édition Carnaval</h3>
                <ol start="5">
                  <li>
                    Seuls les joueurs ayant obtenu une Carte d'embarquement
                    entre le 26 février et le 7 avril 2024 sont admissibles.
                  </li>
                  <li>
                    Il y aura vingt-cinq (25) enveloppes sur le plateau de jeu
                    de la finale de Bon Voyage!{" "}
                  </li>
                  <li>
                    Chaque enveloppe contiendra un (1) des quatre (4) montants
                    de prix.
                  </li>
                  <li>
                    Après le tirage final à 21h00, les vingt-cinq (25) gagnants
                    seront ramenés sur le plateau de jeu de la finale de Bon
                    Voyage!
                  </li>
                  <li>
                    Chaque gagnant choisira un des{" "}
                    <strong>vingt-cinq (25) enveloppes,</strong> qui seront
                    sélectionnés un par un,{" "}
                    <strong>
                      au cours de cinq (5) tours, par groupes de cinq (5)
                      gagnants, dans le même ordre que celui dans lequel les
                      noms ont été tirés au sort au cours de la journée, puis
                      les cinq (5) enveloppes sélectionnés seront ouverts
                      simultanément.
                    </strong>{" "}
                    Ce processus est ensuite répété jusqu'à ce que tous les
                    gagnants aient sélectionné et ouvert leur enveloppe.
                  </li>
                  <li>
                    Les prix, d'un montant total de vingt-cinq mille dollars (25
                    000 $), sont répartis comme suit:
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Nombres de prix</th>
                            <th>Montant des prix</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>4</td>
                            <td>250$</td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td>500$</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>1,000$</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>10,000$</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "How to Qualify" ? (
            i18n.language === "en" ? (
              <div>
                <h2>How to Qualify</h2>
                <p>
                  There are three (3) ways to participate in this promotion, as
                  detailed in rules 1, 3 and 4
                </p>
                <ol>
                  <li>
                    Players receive one (1) draw entry for every two hundred and
                    fifty (250) Rewards Points earned while playing gaming
                    machines.
                  </li>
                  <li>
                    After every gaming session, players must present themselves
                    to one of the cashiers, and request to have their entries
                    printed. Players then need to place their entries inside the
                    Bon Voyage Carnival Edition raffle drum located on the
                    Gaming machine gaming floor.
                  </li>
                  <li>
                    Red tier and Black tier members also received one (1)
                    electronic draw entry for every two hundred and fifty (250)
                    Rewards Points earned while playing Gaming machines.
                  </li>
                  <li>
                    Players may also purchase entries for two hundred and fifty
                    (250) points.
                  </li>
                  <li>
                    Players may redeem a maximum of twenty-five (25) draw
                    entries per day. Black and Red tier members can earn up to
                    an additional twenty-five (25) electronic draw entries per
                    day.
                  </li>
                  <li>
                    Draw entries are only available for collection on the day
                    they were earned.
                  </li>
                  <li>
                    For every draw day, players have up to one (1) week to earn
                    raffle entries.
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Earning Period</th>
                            <th>Draw Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>February 26 at 9:00am - March 3 at 7:55pm</td>
                            <td>March 3 (10 draws)</td>
                          </tr>
                          <tr>
                            <td>March 3 at 7:56pm - March 10 at 7:55pm</td>
                            <td>March 10 (10 draws)</td>
                          </tr>
                          <tr>
                            <td>March 10 at 7:56pm - March 17 at 7:55pm</td>
                            <td>March 17 (10 draws)</td>
                          </tr>
                          <tr>
                            <td>March 17 at 7:56pm - March 24 at 7:55pm</td>
                            <td>March 24 (10 draws)</td>
                          </tr>
                          <tr>
                            <td>March 24 at 7:56pm - March 31 at 7:55pm</td>
                            <td>March 31 (10 draws)</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                  <li>
                    To be part of a draw, a ballot must be deposited into the
                    Bon Voyage! draw drum by the participant before the
                    corresponding earning period ends.
                  </li>
                </ol>
                <h2>Bon Voyage Finale (How to qualify cont.)</h2>
                <ol>
                  <li value={9}>
                    Players collect Boarding Pass the following ways:
                    <ol type="a">
                      <li>
                        Boarding Passes are awarded to all players who get
                        called to play Bon Voyage Carnival Edition on any of the
                        draw days.
                      </li>
                      <li>
                        Boarding Passes are awarded, along with $50 in Free Play
                        via Hot Seat draw, every Thursday between February 29
                        and March 28, at a rate of 2 per hour from 11:00am to
                        11:00pm.
                      </li>
                      <li>
                        Boarding Passes are awarded via Hot Seat draw, every day
                        between April 1 and 5, at a rate of 2 per hour from
                        11:00am to 11:00pm.
                      </li>
                      <li>
                        Boarding Passes can also be randomly awarded by floor
                        staff.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Comment participer</h2>
                <p>
                  Il y a trois (3) façons de participer à cette promotion, comme
                  stipulé dans les règles 1, 3 et 4
                </p>
                <ol>
                  <li>
                    Les joueurs reçoivent une (1) participation aux tirages pour
                    chaque deux cent cinquante (250) Points Récompenses
                    accumulés en jouant aux machine de jeux.
                  </li>
                  <li>
                    Après chaque session de jeu, les joueurs doivent se
                    présenter au comptoir des Récompenses aux Joueurs ou à la
                    caisse machine de jeux pour faire imprimer leurs bulletins
                    de participation. Ils doivent ensuite les placer eux-mêmes
                    dans le tambour de la tombola Bon Voyage! Édition Carnaval
                    situé à l'étage des jeux machines de jeu.
                  </li>
                  <li>
                    Les membres des niveaux Rouge et Noir reçoivent également
                    une (1) participation au tirage électronique pour chaque
                    tranche de deux cent cinquante (250) points de récompense
                    gagnés en jouant aux machines de jeu.
                  </li>
                  <li>
                    Les joueurs peuvent aussi acheter des bulletins de
                    participation au coût de deux cent cinquante (250) Points
                    Récompenses.
                  </li>
                  <li>
                    Les joueurs peuvent accumuler jusqu’à vingt-cinq (25)
                    participations par jour. Les membres du niveau noir et rouge
                    peuvent accumuler jusqu'à vingt-cinq (25) participations
                    électroniques supplémentaires par jour.
                  </li>
                  <li>
                    Les participations doivent être réclamées le jour même de la
                    session de jeu correspondante.
                  </li>
                  <li>
                    Pour chaque jour de tirage, les joueurs ont jusqu’à une (1)
                    semaine pour accumuler des participations.
                    <TableDiv>
                      <table>
                        <thead>
                          <tr>
                            <th>Période d’accumulation</th>
                            <th>Jour de tirage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>26 février à 9h00 - 3 mars à 19h55</td>
                            <td>3 mars (10 tirages)</td>
                          </tr>
                          <tr>
                            <td>3 mars à 19h56 - 10 mars à 19h55</td>
                            <td>10 mars (10 tirages)</td>
                          </tr>
                          <tr>
                            <td>10 mars à 19h56 - 17 mars à 19h55</td>
                            <td>17 mars (10 tirages)</td>
                          </tr>
                          <tr>
                            <td>17 mars à 19h56 - 24 mars à 19h55</td>
                            <td>24 mars (10 tirages)</td>
                          </tr>
                          <tr>
                            <td>24 mars 19h56 - 31 mars à 19h55</td>
                            <td>31 mars (10 tirages)</td>
                          </tr>
                        </tbody>
                      </table>
                    </TableDiv>
                  </li>
                  <li>
                    Pour faire partie d’un tirage, un bulletin de participation
                    doit être déposé dans le tambour de tirage Bon Voyage!
                    Édition Carnaval par le participant avant que la période
                    d’accumulation correspondante ne soit terminée.
                  </li>
                </ol>
                <h2>
                  Finale Bon Voyage Édition Carnaval (Comment participer cont.)
                </h2>
                <ol>
                  <li value={9}>
                    Les joueurs peuvent acquérir des Cartes d'embarquement des
                    différentes manières suivantes:
                    <ol type="a">
                      <li>
                        Les Cartes d'embarquement sont décernées à tous les
                        joueurs qui sont appelés à jouer à Bon Voyage Édition
                        Carnaval lors de l'un des jours de tirage.
                      </li>
                      <li>
                        Des Cartes d'embarquement, ainsi que 50$ en crédits-jeu,
                        sont décernées par tirage Siège Chanceux, chaque jeudi
                        entre le 29 février et le 28 mars, à raison de 2 par
                        heure de 11h00 à 23h00.
                      </li>
                      <li>
                        Des Cartes d'embarquement sont décernées par tirage
                        Siège Chanceux, tous les jours entre le 1 et 5 avril, à
                        raison de 2 par heure de 11h00 à 23h00.
                      </li>
                      <li>
                        Les Cartes d'embarquement peuvent être remises par le
                        personnel de façon aléatoire.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "Draw Process" ? (
            i18n.language === "en" ? (
              <div>
                <h2>Draw Process</h2>
                <ol>
                  <li>
                    There are a total of fifty (50) prizes available to be won
                    between February 26 and April 7, 2024.
                  </li>
                  <li>
                    Ten (10) prizes will be awarded on each draw day, as
                    detailed in point #7 of the{" "}
                    <strong>“Game Description”</strong> section of these rules.
                  </li>
                  <li>
                    Draws are conducted five times per day, at 2:00pm, 3:30pm,
                    5:00pm, 6:30pm and 8:00pm, on the following days:
                    <ol type="a">
                      <li>Sunday, March 3</li>
                      <li>Sunday, March 10</li>
                      <li>Sunday, March 17</li>
                      <li>Sunday, March 24</li>
                      <li>Sunday, March 31</li>
                    </ol>
                  </li>
                  <li>
                    Two (2) contestants are picked at each draw time, for a
                    total of ten (10) contestants per draw day.
                    <ol type="a">
                      <li>
                        One (1) contestants will be selected from the raffle
                        drum, as per rules 1-4 in the How to Qualify section.
                      </li>
                      <li>
                        For the draws held at 2:00pm, 5:00pm and 8:00pm, one (1)
                        contestant will be selected by electronic raffle among{" "}
                        <strong>Black</strong> tier members.
                      </li>
                      <li>
                        For the draws held at 3:30pm and 6:30pm, one (1)
                        contestant will be selected by electronic raffle among{" "}
                        <strong>Red</strong> tier members.
                      </li>
                      <li>
                        To be eligible for their tier specific electronic draws,{" "}
                        <strong>Black and Red</strong> tier members are required
                        to check-in prior to the scheduled draw time. Check-in
                        is done by earning at least twenty five (25) Rewards
                        Points points, on any Gaming machine, within the 30
                        minute window prior to the draw. The periods are as
                        follows: 1:30pm to 2:00pm, 3:00pm to 3:30 pm, 4:30pm to
                        5:00pm, 6:00pm to 6:30pm and 7:30pm to 8:00pm.
                      </li>
                      <li>
                        If no black or red tier member has qualified for the
                        specific electronic draw, a draw ticket will be drawn
                        from the general draw drum.
                      </li>
                    </ol>
                  </li>
                </ol>
                <ol start={5}>
                  <li>
                    Winners must be in attendance at the time of the draw to
                    win. The selected names are announced over Playground’s
                    internal public address system on the gaming floor. The
                    selectee has five (5) minutes from the time of the
                    announcement to present themselves at the promotions area to
                    participate.
                  </li>
                  <li>
                    If, at the end of the above-mentioned time interval, an
                    eligible selectee has not presented themselves at the
                    designated area, a site representative draws one (1)
                    additional ballot. This process continues until an eligible
                    selectee is present.
                  </li>
                  <li>Guests are eligible to win once per draw day.</li>
                </ol>
                <h3>Bon Voyage Finale</h3>
                <ol start={8}>
                  <li>
                    There are a total of twenty five (25) prizes available to be
                    won on Sunday, April 7, 2024.
                  </li>
                  <li>
                    Draws are conducted every 30 minutes between 1:00pm to
                    9:00pm from the list of Boarding Pass holders, for a total
                    of 17 winners.
                  </li>
                  <li>
                    In addition to the above mentioned draws, 1 hot seat draw an
                    hour will be conducted from 1:30pm to 8:30pm for an
                    additional 8 winners.
                  </li>
                  <li>
                    3 of the 8 above mentioned Hot Seat draws on the day of the
                    finale, will be <strong>Black tier</strong> specific. The
                    draws in question are at 2:30pm, 5:30pm and 8:30pm.
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Tirages - procédures</h2>
                <ol>
                  <li>
                    Il y a un total de cinquante (50) prix à gagner entre le 19
                    février et le 19 mars 2022.
                  </li>
                  <li>
                    Dix (10) prix seront attribués chaque jour de tirage, comme
                    indiqué au point 9 de la section{" "}
                    <strong>"Description du jeu"</strong> du présent règlement.
                  </li>
                  <li>
                    Les tirages ont lieu cinq (5) fois par jour, à 14h00, 15h30,
                    17h00, 18h30 et 20h00. Jours de tirage:
                    <ol type="a">
                      <li>Dimanche 3 mars</li>
                      <li>Dimanche 10 mars</li>
                      <li>Dimanche 17 mars</li>
                      <li>Dimanche 24 mars</li>
                      <li>Dimanche 31 mars</li>
                    </ol>
                  </li>
                  <li>
                    Deux (2) concurrents sont sélectionnés à chacune des heures
                    mentionnées ci-dessus, pour un total de dix (10) concurrents
                    par jour de tirage.
                    <ol type="a">
                      <li>
                        Deux (2) concurrents seront pigés à partir du tambour de
                        la tombola, conformément aux règles 1 à 4 de la section
                        Comment se qualifier.
                      </li>
                      <li>
                        Pour les tirages au sort effectués à 14h00, 17h00 et
                        20h00, un (1) participant sera sélectionné par tirage
                        électronique parmi les membres de{" "}
                        <strong>niveau Noir.</strong>
                      </li>
                      <li>
                        Pour les tirages au sort de 15h30 et 18h30, un (1)
                        participant sera sélectionné par tirage au sort
                        électronique parmi les membres du{" "}
                        <strong>niveau Rouge.</strong>
                      </li>
                      <li>
                        Pour être admissibles aux tirages électroniques
                        spécifiques à leur niveau, les membres des{" "}
                        <strong>niveaux Noir et Rouge</strong> doivent
                        s'enregistrer avant l'heure prévue du tirage.
                        L'enregistrement s'effectue en accumulant au moins
                        vingt-cinq (25) points de récompense, sur n'importe quel
                        machine de jeux, dans la période de 30 minutes précédant
                        le tirage. Les périodes sont les suivantes 13h30 à
                        14h00, 15h00 à 15h30, 16h30 à 17h00, 18h00 à 18h30 et
                        19h30 à 20h00.
                      </li>
                      <li>
                        Si aucun membre de niveau noir ou rouge ne s'est
                        qualifié pour le tirage électronique spécifique, un
                        billet de tirage sera tiré du tambour général.
                      </li>
                    </ol>
                  </li>
                </ol>
                <ol start={5}>
                  <li>
                    Les gagnants doivent être présents au moment du tirage pour
                    gagner. Les noms sélectionnés sont annoncés par le biais du
                    système de sonorisation du Playground. Afin de participer,
                    le gagnant dispose de cinq (5) minutes à compter de
                    l'annonce pour se présenter à l'aire des promotions.
                  </li>
                  <li>
                    Si, à la fin de la période mentionnée ci-dessus, un gagnant
                    admissible sélectionné ne s'est pas présenté à l’aire des
                    promotions, un représentant pigera un (1) bulletin de
                    participation supplémentaire. Ce processus se poursuivra
                    jusqu'à ce qu'un gagnant admissible soit présent.
                  </li>
                  <li>
                    Les participants sont admissibles à gagner une (1) fois
                    seulement par jour de tirage.
                  </li>
                </ol>
                <h3>Finale Bon Voyage</h3>
                <ol start={8}>
                  <li>
                    Il y a un total de vingt-cinq (25) prix à gagner le dimanche
                    7 avril 2024.
                  </li>
                  <li>
                    Les tirages au sort sont effectués toutes les 30 minutes
                    entre 13h00 et 21h00 à partir de la liste des détenteurs de
                    cartes d'embarquement, pour un total de 17 gagnants.
                  </li>
                  <li>
                    En plus des tirages mentionnés ci-dessus, un tirage par
                    Sièges Chanceux par heure sera effectué de 13h30 à 20h30
                    pour huit (8) gagnants supplémentaires.
                  </li>
                  <li>
                    Trois (3) des huit (8) tirages par Sièges Chanceux
                    mentionnés ci-dessus, le jour de la finale, seront réservés
                    aux <strong>niveaux noirs.</strong> Les tirages en question
                    ont lieu à 14h30, 17h30 et 20h30.
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "Eligibility" ? (
            i18n.language === "en" ? (
              <div>
                <h2>Eligibility</h2>
                <ol>
                  <li>The promotion is open to all Playground members.</li>
                  <li>The promotion takes place at Playground.</li>
                  <li>
                    Employees of Playground shall be ineligible to participate
                    in the promotion.
                  </li>
                  <li>
                    The chance of being selected in any draw is dependent on the
                    number of entries received for that draw.
                  </li>
                </ol>
              </div>
            ) : (
              <div>
                <h2>Admissibilité</h2>
                <ol>
                  <li>
                    Cette promotion est ouverte à tous les membres du
                    Playground.
                  </li>
                  <li>
                    Tous les éléments de cette promotion se déploient au
                    Playground.
                  </li>
                  <li>
                    Les employés du Playground ne peuvent en aucun cas
                    participer à cette promotion.
                  </li>
                  <li>
                    Pour chaque tirage, les chances d’être sélectionné dépendent
                    du nombre de participations accumulées.
                  </li>
                </ol>
              </div>
            )
          ) : null}
          {isActive === "General Rules" ? (
            i18n.language === "en" ? (
              <div>
                <h2>General Rules</h2>
                <ol>
                  <li>
                    A player that matches all five (5) items to win 2 Vacations
                    will have the option to take a cash prize of $15,000 plus
                    $5,000 in free play instead.
                  </li>
                  <li>
                    In the event that the top prize of 2 Vacations is won, and
                    the winning player chooses to take the trip, the winning
                    player will have up to six (6) months to decide on the dates
                    of their trip, which can be taken at any point during the
                    2024 or 2025 calendar years. Winning players will have the
                    ability to build their own trip with a Playground designated
                    agent as well as consult with the same agent on several
                    pre-build packages. All bookings will be made by Playground.
                  </li>
                  <li>
                    When receiving their printed entries, it is the
                    participating patron’s responsibility to verify that the
                    correct number of ballots is issued.
                  </li>
                  <li>
                    It is the participating patron’s responsibility to make sure
                    they insert their ballot(s) into the Bon Voyage! Carnival
                    Edition raffle drum.
                  </li>
                  <li>
                    All participants must present a valid government-issued
                    photo identification upon request.
                  </li>
                  <li>
                    Playground reserves the right to change or alter this
                    promotion, as it deems appropriate, including, but not
                    limited to, making changes or alterations to these Rules and
                    Regulations relating to the accommodation of participants.
                  </li>
                  <li>
                    Patrons with self-excluded or banned status, as determined
                    by Playground, are not eligible for any of the gifts/prizes
                    associated with Playground contests, promotions, tournaments
                    or giveaways.
                  </li>
                  <li>
                    All references to times and dates reflect Eastern Time.
                  </li>
                  <li>
                    By participating in the promotion, winners consent to the
                    use of their names and photographs for promotional purposes
                    without additional compensation.
                  </li>
                </ol>
                <p>
                  <strong>
                    Questions relating to this promotion can be answered by
                    visiting the Player Rewards desk
                  </strong>
                </p>
              </div>
            ) : (
              <div>
                <h2>Règles Générales</h2>
                <ol>
                  <li>
                    Un joueur qui apparie les cinq (5) articles et remporte le
                    Voyage de Rêve peut alternativement choisir un paiement en
                    argent de 15 000$ plus 5000$ en crédits jeu.
                  </li>
                  <li>
                    Un joueur qui remporte les 2 Voyages et choisit de conserver
                    ce prix, dispose d’une période allant jusqu’à six (6) mois
                    pour décider les dates du voyage, et ces dates doivent être
                    comprises dans les années calendaires 2024 ou 2025. Le
                    gagnant aura le loisir de préparer son propre voyage avec un
                    agent désigné par le Playground ainsi que de consulter cet
                    agent dans le but de connaître les différents forfaits pré
                    arrangés. Toutes les réservations sont complétées par le
                    Playground.
                  </li>
                  <li>
                    Lorsque le participant demande de faire imprimer ses
                    bulletins de participation, il est de sa responsabilité de
                    vérifier que le nombre exact de bulletins est émis.
                  </li>
                  <li>
                    Il incombe au client participant de s'assurer qu'il insère
                    ses bulletins dans le tambour de la tombola Bon Voyage!
                    Édition Carnaval.
                  </li>
                  <li>
                    Tous les participants doivent présenter sur demande une
                    pièce d'identité avec photo émise par le gouvernement.
                  </li>
                  <li>
                    Playground se réserve le droit de changer ou de modifier
                    cette promotion à sa discrétion, y compris d’apporter des
                    changements ou des altérations aux détails du présent
                    Règlement.
                  </li>
                  <li>
                    Les clients dont le dossier affiche un statut
                    d'auto-exclusion ou d'interdiction d’accès, tel que
                    déterminé par Playground, ne sont pas admissibles aux
                    cadeaux/prix associés aux concours, promotions, tournois ou
                    cadeaux promotionnels du Playground.
                  </li>
                  <li>
                    Toutes les heures et dates mentionnées sont en heure de
                    l'Est.
                  </li>
                  <li>
                    En participant à la promotion, les gagnants consentent à
                    l'utilisation de leurs noms et photographies à des fins
                    promotionnelles sans compensation supplémentaire.
                  </li>
                </ol>
                <p>
                  <strong>
                    Les questions relatives à cette promotion peuvent être
                    posées au bureau des Récompenses aux Joueurs
                  </strong>
                </p>
              </div>
            )
          ) : null}
        </div>
      </PartFive>
      
      
    </MainDiv>
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const MainDiv = styled.div`
  height: auto;
  width: 100%;
  position: relative;
  z-index: 0;
  background: var(--black);
  & > div:first-child {
    z-index: 1;
    position: relative;
    border-bottom: 1px solid #222;
    float: left;
    width: 100%;
    background-image: ${({ lng }) =>
      `url(${
        lng === "en"
          ? "/images/promotions/bon-voyage-lp-2023-header-en.jpg"
          : "/images/promotions/bon-voyage-lp-2023-header-fr.jpg"
      })`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 829px;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  & > div:nth-child(2) {
    display: flex;
    height: auto;
    width: 100%;
    padding: 40px 0;
    color: var(--white);
    & > div {
      height: auto;
      width: 100%;
      margin: 0 auto;
      max-width: 1170px;
      text-align: center;
      & > p {
        /* font-size: 18px; */
      }
    }
  }
  & > div:nth-child(3) {
    background: url("/images/promotions/bon-voyage-lp-2023-center-bg.jpg") 50%
      50%;
    padding: 60px 0px;
    background-position: top;
    background-size: cover;
    & > div:first-child {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      clear: both;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--white);
      & > h1 {
        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
        padding: 10px 0 30px 0;
        text-transform: uppercase;
        margin-bottom: 0;
        text-shadow: 2px 2px 2px #000;
      }
      & > h2 {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 33px;
      }
    }
    & > div:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 80px;
      & > img {
        width: 100%;
        object-fit: contain;
        max-width: 30%;
      }
    }
    & > div:nth-child(3) {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      height: auto;
      color: var(--white);
      display: block;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      & > h1 {
        font-weight: 700;
        font-size: 77px;
        line-height: 57px;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 0px;
        text-transform: uppercase;
        margin-bottom: 20px;
        & > span {
          font-size: 32px;
        }
      }
      & > p {
        font-size: 20px;
        text-align: center;
        font-weight: 300;
        max-width: 1000px;
        margin: 0 auto;
        padding-bottom: 15px;
      }
      & > p:nth-child(2) {
        font-size: 30px;
      }
      & > p:nth-child(5) {
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      background-image: ${({ lng }) =>
        `url(${
          lng === "en"
            ? "/images/promotions/bon-voyage-lp-2023-header-en.jpg"
            : "/images/promotions/bon-voyage-lp-2023-header-fr.jpg"
        })`};
      background-position: top;
      margin-top: 80px;
      padding: 0;
      height: 220px;
    }
    & > div:nth-child(2) {
      padding: 40px 20px;
    }
    & > div:nth-child(3) {
      padding: 20px;
      & > div:first-child {
        & > h2 {
          font-size: 25px;
          line-height: 30px;
        }
      }
      & > div:nth-child(2) {
        flex-direction: column;
        gap: 20px;
        & > img {
          max-width: 100%;
        }
      }
      & > div:nth-child(3) {
        grid-template-columns: 1fr 1fr;
      }
      & > div:nth-child(4) {
        & > h1 {
          font-size: 60px;
          line-height: 50px;
          & > span {
            font-size: 28px;
          }
        }
        & > div:last-child {
          grid-template-columns: 1fr;
          & > h3 {
            padding: 10px 0;
            font-size: 28px;
            line-height: 28px;
          }
        }
      }
    }
    & > div:nth-child(4) {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 991px) {
  }
`;
const PartFour = styled.div`
  background: #000 url("/images/promotions/bon-voyage-lp-2023-finale-bg.jpg")
    50% 50%;
  background-size: cover;
  padding: 60px 20px 60px 20px;
  height: auto;
  width: 100%;
  & > div {
    width: 100%;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
    text-align: center;
    & > img {
      width: 100%;
    }
    & > h1 {
      border-width: 4px;
      border-style: solid;
      border-image: linear-gradient(to bottom, #bb1e71, #f2d411, #529041) 1;
      background-color: #fff;
      padding: 5px;
      margin-bottom: 15px;
      & > span {
        background: -webkit-linear-gradient(#bb1e71, #f2d411, #529041);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 40px;
        display: block;
      }
    }
    & > h2 {
      font-weight: 700;
      font-size: 62px;
      background: -webkit-linear-gradient(#bb1e71, #f2d411, #529041);
      background-clip: text;
      -webkit-text-stroke: 4px transparent;
      color: #fff;
      margin-top: 0px;
      line-height: 50px;
    }
    & > p {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 991px) {
    & > div {
      & > h2 {
        font-size: 30px;
      }
    }
  }
`;
const PartFive = styled.div`
  padding-bottom: 40px;
  max-width: 1440px;
  width: 100%;
  margin: 40px auto;
  height: auto;
  position: relative;
  & > ul:first-child {
    margin-bottom: 20px;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    & > li {
      width: 100%;
      text-align: center;
      padding: 20px 0;
      background-color: #232426;
      color: #fff;
      transition: 500ms;
      font-weight: 700;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        background-color: #539032;
        border: 0px solid #539032;
      }
      &.active {
        background-color: #539032;
        border: 0px solid #539032;
      }
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    background: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    & > div {
      animation: ${fadeIn} 0.3s linear;
      width: 100%;
      height: fit-content;
      padding: 15px;
      & > h2 {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        line-height: 33px;
        color: #555;
      }
      & > p {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-style: italic;
        line-height: 28px;
        color: #555;
      }
      & > ol {
        font-size: 15px;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 10px;
        & > li {
          color: #555;
        }
      }
      & > h3 {
        color: #555;
        margin: 20px 0;
        font-weight: 600;
        font-size: 19px;
        line-height: 27px;
        text-align: center;
      }
      & > p:last-child {
        font-size: 16px;
        padding: 0;
        margin: 0;
        font-style: italic;
        line-height: 28px;
        text-align: center;
        color: #555;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px;
    & > ul:first-child {
      flex-flow: wrap;
      justify-content: space-evenly;
      & > li {
        font-size: 14px;
        max-width: 45%;
      }
    }
    & > div:last-child {
      & > div {
        & > ol {
          font-size: 15px;
          padding-left: 20px;
        }
      }
    }
  }
`;
const BoardingPass = styled.div`
  animation: ${fadeIn} 0.3s linear;
  background: #000 url("/images/promotions/bon-voyage-lp-2023-finale-bg.jpg")
    50% 50%;
  background-size: cover;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: auto;
  & > img {
    margin: 0 auto;
    text-align: center;
    display: block;
    max-width: 100%;
    height: auto;
  }
  & > div {
    background-color: #fff;
    margin: 15px;
    padding: 15px;
    & > h3 {
      margin: 20px 0 0;
      font-weight: 600;
      font-size: 19px;
      line-height: 27px;
      color: #555;
    }
    & > ol {
      font-size: 15px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 10px;
      padding: 0;
      & > li {
        color: #555;
        margin-left: 40px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    & > div {
      & > ol {
        padding-left: 20px;
        & > li {
          margin-left: 0;
        }
      }
    }
  }
`;
const TableDiv = styled.div`
  height: fit-content;
  width: 100%;
  height: fit-content;
  margin: 20px 0;
  & > table:first-child {
    width: 100%;
    margin-bottom: 15px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: center;
    & > thead {
      & > tr:first-child {
        & > th {
          background: radial-gradient(
            circle,
            rgb(249 189 93) 0%,
            rgb(224 126 74) 50%,
            rgb(215 91 49) 100%,
            rgb(187 71 43) 100%
          );
          color: #000;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          padding: 10px 5px;
          font-size: 18px;
        }
      }
      & > tr:last-child {
        & > th {
          background: #000;
          color: #fff;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          padding: 10px 5px;
          font-size: 18px;
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          color: #fff;
          background: #000;
          font-family: "Poppins", sans-serif;
          font-weight: 700;
          padding: 15px 5px;
          font-size: 18px;
          border: 2px solid #fff;
        }
      }
    }
  }
`;
export default BonVoyage2024;
