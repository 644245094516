import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px 0;
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: ${({ textContent }) => (textContent ? "48%" : "100%")};
  margin-bottom: 1.5rem;

  iframe {
    width: 100%;
    height: ${({ textContent }) => (textContent ? "315px" : "500px")};
    border: none;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  max-width: 48%;
  text-align: center;

  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;

    strong {
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const TournamentVideoSection = ({ videoUrl, textContent }) => {
  return (
    <ContentWrapper>
      <VideoWrapper textContent={textContent}>
        <iframe
          src={videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </VideoWrapper>
      <TextWrapper>
        <ReactMarkdown>{textContent}</ReactMarkdown>
      </TextWrapper>
    </ContentWrapper>
  );
};

export default TournamentVideoSection;
