import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Hero            from "src/components/Hero/Hero";
import TheHostHotel    from "src/components/Hotels/TheHostHotel";
import VogueHotel      from "src/components/Hotels/VogueHotel";
import MarriottHotel   from "src/components/Hotels/MarriottHotel";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const Hotels = () => {
  const { t } = useTranslation();
  const bgImage = useResponsiveImageUrl("about/about-background.jpg");

  return (
    <HotelsDiv bgImage={bgImage}>
      <Hero
        bgColor=""
        bgImage={"hotels/hotels_header.jpg"}
        buttonLink=""
        buttonText=""
        description={t("hotels.hero.subtitle")}
        descriptionBgColor=""
        image=""
        imageAlt=""
        mainHeroText={t("hotels.hero.title")}
        title=""
      />
      <container-div>
        <HotelHolder>
          <Row xs={1} md={2}>
            <Col>
              <TheHostHotel />
            </Col>
            <Col>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2801.7193271192546!2d-73.61025388429901!3d45.3948323460902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91231e96a21ed%3A0xf892d134287ea82c!2sHost+Hotel!5e0!3m2!1sen!2sca!4v1506353001731" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
            </Col>
          </Row>
        </HotelHolder>
        <HotelHolder>
          <Row xs={1} md={2}>
            <Col>
              <VogueHotel />
            </Col>
            <Col>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11186.329878255276!2d-73.5756912!3d45.4983521!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3e1009e6ada5eaf!2sLoews+H%C3%B4tel+Vogue!5e0!3m2!1sen!2sca!4v1506352665233" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
            </Col>
          </Row>
        </HotelHolder>
        <HotelHolder>
          <Row xs={1} md={2}>
            <Col>
              <MarriottHotel />
            </Col>
            <Col>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d22391.932556475243!2d-73.739752!3d45.449826!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x255272c1e9248d5e!2sFairfield+Inn+%26+Suites+by+Marriott+Montreal+Airport!5e0!3m2!1sen!2sca!4v1520959954995" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
            </Col>
          </Row>
        </HotelHolder>
      </container-div>
    </HotelsDiv>
  );
};

const HotelsDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: #000 url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
  background-size: cover;
`;

const HotelHolder = styled.div`
  height: auto;
  width: 100%;
  color: var(--white);
  background: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  margin: 2rem 0;
  .subtitle-section {
    padding-top: 0;
  }
  img {
    margin: 1.5rem 0;
  }
  ul {
    margin-left: -0.5rem;
  }
  iframe {
    min-height: 400px;
  }
`;

export default Hotels;
