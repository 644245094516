import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const VogueHotel = ({headerAs='h2'}) => {
  const { t } = useTranslation();

  return (
    <>
      <SubTitleSection as={headerAs} subTitle={t("visit-us.stay-content-2-text-1")} />
      <p>
        {t("visit-us.stay-content-2-text-2")}
      </p>
      <ResponsiveImage src="hotels/vogue.jpg" alt="vogue" />
      <ul>
        <li>{t("visit-us.stay-content-2-li-1")}</li>
        <li>
          {t("visit-us.stay-content-2-li-2")}{" "}
          <a href="tel:+15142855555">1-514-285-5555</a>
        </li>
        <li>
          {t("visit-us.stay-content-2-li-3")}{" "}
          <a href="https://www.hilton.com" target="_blanc">
            https://www.hilton.com
          </a>
        </li>
        <li>{t("visit-us.stay-content-2-li-4")}</li>
        <li>
          {t("visit-us.stay-content-2-li-5")}{" "}
          <strong>Playground</strong>
        </li>
        <li>
          {t("visit-us.stay-content-2-li-6")}{" "}
          <strong>3276275</strong>
        </li>
      </ul>
    </>
  );
};

export default VogueHotel;
