import React, { useEffect, useState } from "react";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { subscription } from "../../api/accounts"; // Adjust the import path as needed
import { useTranslation } from "react-i18next";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const NewsletterSection = ({ setField }) => {
  const [newsletter, setNewsletter] = useState("true");
  const [initialNewsletterOptIn, setInitialNewsletterOptIn] =
    useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          throw new Error("Access token is missing");
        }

        const result = await subscription(accessToken);
        if (result.success) {
          const optInStatus = result.data.opt_in_email ? "true" : "false";
          setNewsletter(optInStatus);
          setInitialNewsletterOptIn(optInStatus);
          setField("newsletter", optInStatus);
          setField("initialNewsletterOptIn", optInStatus);
        } else {
          console.log(result.message);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchSubscription();
  }, []);

  const handleNewsletterChange = (newStatus) => {
    setNewsletter(newStatus);
    if(initialNewsletterOptIn !== newStatus) {
      setField("subscribe", newStatus);
    }
  };
  return (
    <div>
      <SubTitleSection
        subTitle={t("auth.register.subscribeTitle")}
        text={t("auth.register.subscribeInstructions")}
        className=""
      />
      <CustomRadioWrapper className="mb-3" checkmark={useResponsiveImageUrl("svg/Check.svg")}>
        <Form.Check
          inline
          label={t("auth.register.signMeUp")}
          id="signUp"
          type="radio"
          name="subscribe"
          value="true"
          checked={newsletter === "true"}
          onChange={() => handleNewsletterChange("true")}
        />
        <Form.Check
          inline
          label={t("auth.register.noThanks")}
          id="noThanks"
          type="radio"
          name="subscribe"
          value="false"
          checked={newsletter === "false"}
          onChange={() => handleNewsletterChange("false")}
        />
      </CustomRadioWrapper>
    </div>
  );
};

export const CustomRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    label {
      vertical-align: middle;
      height: 20px;
    }
    input {
      height: 30px;
      width: 30px;
      --bs-form-check-bg: inherit;
      cursor: pointer;
      &.form-check-input {
        border-color: var(--grey);
      }
      &.form-check-input:focus {
        border-width: 1px;
      }
      &.form-check-input:checked {
        --bs-form-check-bg-image: none;
        background-image: url(${({ checkmark }) => checkmark});

      }
    }
  }
`;

export default NewsletterSection;
