import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { t } from "i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const PokerNewYearsEve2025 = () => {
  return (
    <>
      <WrapperDiv className="mb-5">
        <Hero
          arrowBounce=""
          bgImage="promotions/poker/new-years-eve-2025/LP-NYE-2024-Banner-poker.jpg"
          buttonLink=""
          buttonText=""
          title=""
          description=""
          descriptionBgColor=""
          image={t("poker.pokerNewYearsEve2025.hero.image")}
          imageAlt={t("poker.pokerNewYearsEve2025.hero.title")}
          showArrow=""
        />
        <MainContentDiv
          bgImage={useResponsiveImageUrl(
            "entertainment/variety/new-years-eve-2025/nye-2024-background.jpg"
          )}
        >
          <container-div>
            <TitleSection
              as={"h1"}
              title={t("poker.pokerNewYearsEve2025.hero.title")}
              dark={false}
              borderColor="none"
            />
            <Row className="g-4 mb-5" id="">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  src={t("poker.pokerNewYearsEve2025.content.items.ring.image")}
                  alt={t("poker.pokerNewYearsEve2025.content.items.ring.title")}
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t(
                    "poker.pokerNewYearsEve2025.content.items.ring.title"
                  )}
                  borderColor="#D59947"
                />
                <ReactMarkdown>
                  {t("poker.pokerNewYearsEve2025.content.items.ring.text")}
                </ReactMarkdown>
              </Col>
            </Row>
            <Row className="g-4 mb-4" id="">
              <Col xs={12} md={6}>
                <ResponsiveImage
                  src={t(
                    "poker.pokerNewYearsEve2025.content.items.space.image"
                  )}
                  alt={t(
                    "poker.pokerNewYearsEve2025.content.items.space.title"
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <SubTitleSection
                  subTitle={t(
                    "poker.pokerNewYearsEve2025.content.items.space.title"
                  )}
                  borderColor="#D59947"
                />
                <ReactMarkdown>
                  {t("poker.pokerNewYearsEve2025.content.items.space.text")}
                </ReactMarkdown>
              </Col>
            </Row>
          </container-div>
        </MainContentDiv>
      </WrapperDiv>
    </>
  );
};

const WrapperDiv = styled.div`
  .outro {
    max-width: 80%;
    padding-bottom: 2rem;
    margin: 0 auto;
  }
  background: transparent;
  text-align: center;
  display: flex;
  flex-direction: column;
  .hero_image_container {
    border-bottom: 10px solid #d59947;
    img {
      max-height: 500px;
      max-width: 80%;
    }
  }
  .hero_text_container {
    display: none;
  }
  .grid-content {
    text-align: center;
  }
`;
const MainContentDiv = styled.div`
  background: ${(props) => `url(${props.bgImage})`};
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  container-div {
    background: rgba(0, 0, 0, 0.5);
  }
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  color: var(--white);
  text-align: left;
  .row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    &:nth-child(3) {
      flex-direction: row-reverse;
    }
    .col-12 {
      .subtitle-section {
        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
    }
  }
`;

export default PokerNewYearsEve2025;
