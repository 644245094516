import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { useErs } from "src/context/ers/ers.provider";
import { formatAmount } from "src/utils/formatters";

const MysteryHighHand = () => {
  const { i18n, t } = useTranslation();
  const { data, fetchData } = useErs();
  const lng = i18n.language;

  useEffect(() => {
    fetchData("mystery-high-hand");
  }, [fetchData, i18n.language]);

  // Function to add a dollar sign to the amount
  const addDollarSign = (amount) => formatAmount(amount, lng);

  const mysteryHighHands = data["mystery-high-hand"] || [];

  return (
    <MysteryHighHandDiv>
      <Hero
        bgImage="promotions/mystery_high_hand_bg_lp.png"
        descriptionBgColor="#aa0b0b4d"
        image={`promotions/mystery_high_hand_logo_lp_${i18n.language}.png`}
        imageAlt="Mystery High Hand logo"
      >
        <h1>{t("poker.mystery-high-hand.title")}</h1>
        <h2>{t("poker.mystery-high-hand.daily-cash")}</h2>
        <h3>{t("poker.mystery-high-hand.daily-hour")}</h3>
        <p>
          {t("poker.mystery-high-hand.header-text-one")}
          <strong> {t("poker.mystery-high-hand.header-text-two")}</strong>.{" "}
          {t("poker.mystery-high-hand.header-text-three")}{" "}
          <strong>{t("poker.mystery-high-hand.header-text-four")}</strong>,
          {t("poker.mystery-high-hand.header-text-five")}
          <strong>{t("poker.mystery-high-hand.header-text-six")}</strong>{" "}
          {t("poker.mystery-high-hand.header-text-seven")}
        </p>
      </Hero>
      <div>
        <h3>{t("poker.mystery-high-hand.content-need-to-know")}</h3>
        <p>
          <strong>
            {t("poker.mystery-high-hand.content-need-to-know-text-one-1")}
          </strong>{" "}
          {t("poker.mystery-high-hand.content-need-to-know-text-one-2")}
          <strong>
            {" "}
            {t("poker.mystery-high-hand.content-need-to-know-text-one-3")}
          </strong>{" "}
          {t("poker.mystery-high-hand.content-need-to-know-text-one-4")}
        </p>
        <p>{t("poker.mystery-high-hand.content-need-to-know-text-two")}</p>
        <p>
          <strong>
            {t("poker.mystery-high-hand.content-need-to-know-text-three-1")}
          </strong>{" "}
          {t("poker.mystery-high-hand.content-need-to-know-text-three-2")}
        </p>
        <p>
          <strong>
            {t("poker.mystery-high-hand.content-need-to-know-text-four-1")}
          </strong>{" "}
          {t("poker.mystery-high-hand.content-need-to-know-text-four-2")}{" "}
          <strong>
            {t("poker.mystery-high-hand.content-need-to-know-text-four-3")}
          </strong>
          ,{t("poker.mystery-high-hand.content-need-to-know-text-four-4")}
        </p>
        <div>
          <h4>{t("poker.mystery-high-hand.cash-prize-available")}</h4>
          <table>
            <thead>
              <tr>
                <th>{t("poker.mystery-high-hand.remaining")}</th>
                <th>{t("poker.mystery-high-hand.amount")}</th>
              </tr>
            </thead>
            <tbody>
              {mysteryHighHands?.map((highHand, index) => (
                <tr key={index}>
                  <td>{highHand.remaining}</td>
                  <td>{addDollarSign(highHand.amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>{t("poker.mystery-high-hand.period-one")}</p>
          <p>{t("poker.mystery-high-hand.period-two")}</p>
          <p>{t("poker.mystery-high-hand.period-three")}</p>
          <p>{t("poker.mystery-high-hand.period-four")}</p>
          <div>
            <p>
              {t("poker.mystery-high-hand.small-box-info-one")}
              <strong>
                {" "}
                {t("poker.mystery-high-hand.small-box-info-two")}
              </strong>
              , {t("poker.mystery-high-hand.small-box-info-three")}{" "}
              <strong>
                {t("poker.mystery-high-hand.small-box-info-four")}{" "}
              </strong>
              {t("poker.mystery-high-hand.small-box-info-five")}{" "}
              <a href={t("routes.cashGames")}>
                {t("poker.mystery-high-hand.small-box-info-six")}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <div>
        <h4>{t("poker.mystery-high-hand.general-rules")}</h4>
        <ol>
          <li>
            {t("poker.mystery-high-hand.general-rules-ol-1-1")}{" "}
            <strong>{t("poker.mystery-high-hand.general-rules-ol-1-2")}</strong>
            .
          </li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-2")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-3")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-4")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-5")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-6")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-7")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-8")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-9")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-10")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-11")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-12")}</li>
          <li>
            <strong>
              {t("poker.mystery-high-hand.general-rules-ol-13-1")}
            </strong>{" "}
            {t("poker.mystery-high-hand.general-rules-ol-13-2")}
          </li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-14")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-15")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-16")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-17")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-18")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-19")}</li>
          <li>{t("poker.mystery-high-hand.general-rules-ol-20")}</li>
          <li>
            {t("poker.mystery-high-hand.general-rules-ol-21")}
            <ol type="a">
              <li>
                <strong>$1,000</strong>
                <ol type="i">
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-a-1")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-a-2")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-a-3")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-a-4")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-a-5")}
                  </li>
                </ol>
              </li>
              <li>
                <strong>$5,000</strong>
                <ol type="i">
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-b-1")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-b-2")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-b-3")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-b-4")}
                  </li>
                </ol>
              </li>
              <li>
                <strong>$25,000</strong>
                <ol type="i">
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-c-1")}
                  </li>
                  <li>
                    {t("poker.mystery-high-hand.general-rules-ol-21-c-2")}
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </MysteryHighHandDiv>
  );
};
const MysteryHighHandDiv = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;

  & > div:nth-child(2) {
    padding: 60px 20px 0;
    width: 100%;
    max-width: 1440px;
    height: auto;
    margin: 0 auto;
    color: var(--white);
    & > h3 {
      font-weight: 700;
      font-size: 20px;
      margin-top: 0px;
      padding-bottom: 20px;
      text-transform: uppercase;
    }
    & > div {
      width: 100%;
      & > h4 {
        margin-bottom: 20px;
        margin-top: 40px;
        background: rgb(170, 11, 11);
        background: linear-gradient(
          45deg,
          rgba(170, 11, 11, 1) 0%,
          rgba(0, 0, 0, 1) 50%,
          rgba(170, 11, 11, 1) 100%
        );
        color: var(--white);
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        max-width: 500px;
        font-size: 30px;
        margin: 0 auto;
        border-radius: 5px;
        line-height: 25px;
      }
      & > table {
        width: 100%;
        max-width: 100%;
        margin: 20px 0px 40px 0px;
        background: var(--black);
        color: var(--white);
        border: 1px solid var(--lightblack);
        border-spacing: 0;
        border-collapse: collapse;
        & > thead {
          & > tr {
            & > th {
              text-align: center;
              font-size: 20px;
              font-weight: 400;
              width: 50%;
              background-color: #333;
              border: 1px solid #222;
              padding: 8px;
              line-height: 1.42857143;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td:first-child {
              text-align: center;
              font-size: 30px;
              font-weight: 700;
              width: 50%;
              color: var(--white);
              background-color: #230202;
              border: 1px solid #222;
              padding: 8px;
              line-height: 1.42857143;
            }
            & > td:last-child {
              background-color: #aa0b0b;
              text-align: center;
              font-size: 30px;
              font-weight: 700;
              width: 50%;
              color: #fff;
              border: 1px solid #222;
              padding: 8px;
              line-height: 1.42857143;
            }
          }
        }
      }
      & > p {
        margin: 0 0 10px;
        font-size: 18px;
        color: #aaa;
        text-align: center;
      }
      & > div {
        background-color: #333;
        & > p {
          padding: 20px;
          font-size: 16px;
          color: #aaa;
          margin: 0 0 10px;
          & > a {
            color: var(--red);
          }
        }
      }
    }
  }
  & > div:nth-child(3) {
    padding: 40px 20px;
    background-color: #111;
    margin-top: 40px;
    & > h4 {
      font-weight: 700;
      color: #999;
      text-transform: uppercase;
      text-align: center;
      padding: 15px;
      font-size: 30px;
      margin: 5px 0 10px 0;
    }
    & > ol {
      margin-top: 0;
      margin-bottom: 10px;
      & > li {
        font-size: 16px;
        color: #999;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      & > img {
        width: 100%;
      }
      & > div {
        & > h2 {
          padding: 10px;
          font-size: 20px;
          line-height: 1.35em;
        }
      }
    }
  }
`;
export default MysteryHighHand;
