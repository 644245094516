import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { useHomepage } from "src/context/homepage/homepage.provider";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const HighStakesBBJTv = () => {
  const { t, i18n } = useTranslation();
  const { highstakesData } = useHomepage();
  const formatAmount = (value) => {
    // Convert the string to a number and remove trailing zero
    let amountNumber = parseFloat(value);

    // Format the number with commas for every thousand values
    let formattedAmount = amountNumber.toLocaleString("en-US", {
      maximumFractionDigits: 0,
    });
    return formattedAmount
      ? i18n.language === "en"
        ? "$" + formattedAmount
        : formattedAmount + "$"
      : 0;
  };
  return (
    <PromoDetailDiv>
      <PartOne bgImage={useResponsiveImageUrl("promotions/bbj_bg.jpg")}>
        <div>
          <HeaderSection>
            <h1>{t("poker.highStakesBBJTv.title")}</h1>
            <div>
              <span>{formatAmount(highstakesData?.amount)}</span>
            </div>
            <div>
              <p>
                {t("poker.bbj.minHand")}{" "}
                <span className="mustard bold">
                  {t(`poker.bbj.${highstakesData?.min_qualifying_hand || "loading"}`)}
                </span>
              </p>
              <img
                src={`${process.env.REACT_APP_PGFE_IMG_ASSETS_PATH}pgcards/${t(`poker.bbj.image.${highstakesData?.min_qualifying_hand}`)}.png`}
              />
            </div>
          </HeaderSection>
        </div>
        <div>
          <video autoPlay muted loop playsInline>
            <source
              src="/videos/bbj-overlay.mp4"
              type="video/mp4"
            />
            <source
              src="/videos/hero-casino.webm"
              type="video/webm"
            />
          </video>
        </div>
        <div></div>
      </PartOne>
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background: var(--black);
  font-family: "Open Sans", sans-serif;
  `;
const PartOne = styled.div`
  position: relative;
  height: 100vh;
  & > div:first-child {
    height: 100vh;
    padding: 40px 0px 0;
    background: url(${(props) => props.bgImage}) 50% 30% fixed no-repeat;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;

  }
  & > div:nth-child(2) {
    position: absolute;
    top: 0px;
    left: 0;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    opacity: 0.05;
    & > video {
      height: 100%;
      width: 100%;
      display: inline-block;
      vertical-align: baseline;
    }
  }
  & > div:last-child {
    background: #294d93;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 0;
    &::after {
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
const HeaderSection = styled.section`
  position: relative;
  z-index: 2;
  width: 100%;
  height: fit-content;
  & > h1:first-child {
    line-height: 68px;
    font-weight: 700;
    padding: 15px 0;
    color: #fff;
    font-size: 60px;
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 0 0 10px 0;
  }
  & > div:nth-child(2) {
    color: #fff;
    font-weight: 900;
    position: relative;
    letter-spacing: 10px;
    padding: 0px;
    margin: 0px;
    z-index: 2;
    & > span {
      color: #ffb742;
      font-size: 250px;
      line-height: 200px;
      font-weight: 900;
      position: relative;
      letter-spacing: 10px;
      padding: 0px;
      margin: 0px;
      z-index: 2;
      text-align: center;
      background: linear-gradient(
        to right,
        #ffb742 20%,
        #fee261 40%,
        #fee261 60%,
        #ffb742 80%
      );
      background-size: 200% auto;
      color: #000;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 1.5s linear infinite;
    }
  }
  & > div:nth-child(3) {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px 0px;
    margin: 50px 0 0 0;
    & > p {
      font-size: 28px;
      color: #fff;
      text-align: center;
      padding: 0px;
      margin: 0px;
      line-height: 30px;
      display: inline-block;
      width: 100%;
      font-weight: 600;
      letter-spacing: 0.2px;
      & > strong {
        color: #fcb441;
        font-weight: 700;
      }
    }
    & > img {
      max-width: 700px;
      width: 100%;
      margin: 0 auto;
    }
  }
  & > div:last-child {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 20px 0px;
    margin-bottom: 70px;
    height: auto;
    & > h2:first-child {
      color: #fff;
      font-size: 40px;
      text-align: center;

      font-weight: 700;
      padding: 30px 0px 0px;
      line-height: 40px;
      z-index: 2;
      position: relative;
      margin: 0 0 20px 0;
    }
    & > small {
      color: #fff;
      font-size: 13px;
      max-width: 900px;
      margin: 30px auto 0px;
      text-align: center;
      display: block;
      position: relative;
      padding: 0px 15px;
    }
  }
`;
export default HighStakesBBJTv;
