import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { useState } from "react";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";

const DiningCarousel = () => {
  const { t } = useTranslation(); // Move useTranslation inside the component
  const [slideRight, setSlideRight] = useState(true);

const restaurantSlides = [
  {
    id: 11,
    title: "slide1",
    image: "restaurants/slide1.jpg",
      url: t("diningAndLounges.dining.drunkenDragon.link"),
  },
  {
    id: 22,
    title: "slide2",
    image: "restaurants/slide2.jpg",
      url: t("diningAndLounges.dining.theRail.link"),
  },
  {
    id: 33,
    title: "slide3",
    image: "restaurants/slide3.jpg",
      url: t("diningAndLounges.lounges.babel.link"),
  },
  {
    id: 44,
    title: "slide4",
    image: "restaurants/slide4.jpg",
      url: t("diningAndLounges.dining.etc.link"),
  },
  {
    id: 55,
    title: "slide5",
    image: "restaurants/slide5.jpg",
      url: t("diningAndLounges.dining.theRail.link"),
  },
  {
    id: 66,
    title: "slide6",
    image: "restaurants/slide6.jpg",
      url: t("diningAndLounges.dining.etc.link"),
  },
  {
    id: 77,
    title: "slide7",
    image: "restaurants/slide1.jpg",
      url: t("diningAndLounges.dining.drunkenDragon.link"),
  },
  {
    id: 88,
    title: "slide8",
    image: "restaurants/slide2.jpg",
      url: t("diningAndLounges.dining.theRail.link"),
  },
  {
    id: 99,
    title: "slide9",
    image: "restaurants/slide3.jpg",
      url: t("diningAndLounges.lounges.babel.link"),
  },
  {
    id: 111,
    title: "slide10",
    image: "restaurants/slide4.jpg",
      url: t("diningAndLounges.dining.etc.link"),
  },
];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 700,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    adaptiveHeight: true,
    beforeChange: (prev, next) => slideIndexChange(prev, next),
  };
  
  const slideIndexChange = (prev, next) => {
    const totalSlides = restaurantSlides.length - 1;
    if (next > prev) {
      setSlideRight(true);
    } else if (next < prev && prev === totalSlides && next === 0) {
      setSlideRight(true);
    } else {
      setSlideRight(false);
    }
  };
  
  return (
    <Third slide={slideRight}>
      <Slider {...settings}>
        {restaurantSlides.map((el) => (
          <div key={el.id}>
            <a href={el.url} target="_blank" rel="noopener noreferrer">
            <ResponsiveImage
              src={`restaurants/slide${el.image.slice(-5)}`}
              alt={el.title}
            />
            </a>
          </div>
        ))}
      </Slider>
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </Third>
  );
};
const Third = styled.div`
  height: auto;
  width: 100%;
  background: var(--black);
  position: relative;
  .slick-track {
    cursor: pointer !important;
  }
  .slick-slider {
    height: 100% !important;
    max-height: 700px !important;
    width: 100%;
    & > div {
      height: 100%;
      max-height: 700px !important;
    }
    & > div > div > div > div > div {
      outline: none !important;
      position: relative;
      height: 100%;
      width: 100%;
      & > img {
        height: 100%;
        width: 100%;
        max-height: 1024px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  & > ul:last-child {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 10px;
    width: 90px;
    height: 35px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.4),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);
    list-style-type: none;
    & > li {
      margin: 0;
      padding: 5px;
      border-radius: 50%;
      background: var(--lightgrey);
    }
    & > li:first-child {
      padding: ${({ slide }) => slide === false && "4px"};
      background: ${({ slide }) => slide === false && "var(--grey)"};
    }
    & > li:last-child {
      padding: ${({ slide }) => slide === true && "4px"};
      background: ${({ slide }) => slide === true && "var(--grey)"};
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
    & > ul:last-child {
      display: none;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    max-height: 1024px;
    overflow: hidden;
    .slick-slider {
      max-height: 1024px !important;
      & > div {
        max-height: 1024px !important;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    padding-top: 80px;
    .slick-slider {
      max-height: 700px !important;
      & > div > div > div > div > div {
        & > img {
          max-height: 440px !important;
        }
      }
    }
  }
`;
export default DiningCarousel;
