export const homepageActionTypes = {
  MENU_BAR_TOGGLE: "MENU_BAR_TOGGLE",
  CIRCLE_BTN: "CIRCLE_BTN",
  READ_MORE: "READ_MORE",
  HOME_OF_PLAYGROUND: "HOME_OF_PLAYGROUND",
  HOME_OF_PLAYGROUND_CART_IMG: "HOME_OF_PLAYGROUND_CART_IMG",
  DARK_THEME: "DARK_THEME",
  MEGA_MENU: "MEGA_MENU",
  MEGA_MENU_CONTENT: "MEGA_MENU_CONTENT",
  PAST_EVENTS_NAV: "PAST_EVENTS_NAV",
  PAST_EVENTS_DATA: "PAST_EVENTS_DATA",
  BBJ_DATA: "BBJ_DATA",
  OMAHA_DATA: "OMAHA_DATA",
  HIGHSTAKES_DATA: "HIGHSTAKES_DATA",
  GAMING_MACHINE_JACKPOT_DATA: "GAMING_MACHINE_JACKPOT_DATA",
  LIVE_GAMES_DATA: "LIVE_GAMES_DATA",
  TOURNAMENTS_DATA: "TOURNAMENTS_DATA",
  SECTION_CATEGORY: "SECTION_CATEGORY",
  SECTION_DESCRIPTION: "SECTION_DESCRIPTION",
  TOURNAMENTS_DETAILS: "TOURNAMENTS_DETAILS",
  TOURNAMENTS_SUBTABS: "TOURNAMENTS_SUBTABS",
};
