import { useTranslation } from "react-i18next";
import styled from "styled-components";

const RulesConditions = () => {
  const { t } = useTranslation();
  return (
    <MainDiv>
      <ol>
        <li>{t("rewards.rules-li-1")}</li>
        <li>{t("rewards.rules-li-2")}</li>
        <li>{t("rewards.rules-li-3")}</li>
        <li>{t("rewards.rules-li-4")}</li>
        <li>{t("rewards.rules-li-5")}</li>
        <li>{t("rewards.rules-li-6")}</li>
        <li>{t("rewards.rules-li-7")}</li>
        <li>{t("rewards.rules-li-8")}</li>
        <li>{t("rewards.rules-li-9")}</li>
        <li>{t("rewards.rules-li-10")}</li>
        <li>{t("rewards.rules-li-11")}</li>
        <li>{t("rewards.rules-li-12")}</li>
        <li>{t("rewards.rules-li-13")}</li>
        <li>{t("rewards.rules-li-14")}</li>
      </ol>
    </MainDiv>
  );
};
const MainDiv = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  padding: 40px;
  background: var(--white);
  color: var(--black);
  & > ol {
    & > li {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px;
    & > ol {
      padding: 0px 0px 0px 20px;
    }
  }
`;
export default RulesConditions;
