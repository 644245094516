import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const FoodAndBeverage = () => {
  const { t } = useTranslation();
  const foodBeverageText1 = t("rewards.food-beverage-text-1");
  const foodBeverageText2 = t("rewards.food-beverage-text-2");

  return (
    <>
      <h3 className="my-4">{t("rewards.redeem-nav-4")}</h3>
      <MainDiv>
        <div>
          <ResponsiveImage src="home/food-beverage-egd.jpg" alt="food-beverage" />
          <aside>
            <p>{foodBeverageText1}</p>
            <p>{foodBeverageText2}</p>
          </aside>
        </div>
      </MainDiv>
    </>
  );
};

const MainDiv = styled.div`
  height: fit-content;
  width: 100%;
  max-width: 1440px;
  padding: 40px;
  background: var(--white);
  color: var(--black);
  transition: all 0.4s;
  & > div {
    display: flex;
    gap: 20px;
    & > img {
      width: 49%;
    }
    & > aside {
      width: 49%;
      align-self: flex-start;
      & > p {
        font-weight: 300;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0;
    & > div {
      display: block;
      margin: 0;
      & > img {
        width: 100%;
        margin-bottom: 20px;
      }
      & > aside {
        width: 100%;
      }
    }
  }
`;
export default FoodAndBeverage;
