import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import TournamentHero from "src/components/Poker/Tournaments/TournamentHero";
import NavTabs from "src/components/Poker/Tournaments/NavTabs";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useTranslation } from "react-i18next";
import { getTournamentData } from "src/utils/tournamentUtils";
import TournamentContent from "./TournamentContent";
import { leaderboards } from "src/api/poker";
import CoverageAndResultsBanner from "src/components/Poker/Tournaments/CoverageAndResultsBanner";
import Page404 from "src/pages/Page404";

const TournamentDetails = () => {
  const { tournamentsData, tournamentDataFn } = useHomepage();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { tournamentSlug, tournamentTabSlug } = useParams();
  const tabSlug = tournamentTabSlug || "schedule"; // Default to 'schedule'
  const [tournamentContent, setTournamentContent] = useState(null);
  const [tournamentTabs, setTournamentTabs] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const lng = i18n.language;
  const [isNotFound, setIsNotFound] = useState(false);

  // Fetch tournament data when slugs or language change
  useEffect(() => {
    const fetchTournamentData = async () => {
      try {
        const data = await getTournamentData(location, i18n);
        if (data?.tournamentData) {
          setTournamentContent(data?.tournamentData);
          setTournamentTabs(data?.tabsData);
        } else {
          setIsNotFound(true);
        }
        setTournamentTabs(data.tabsData);
        // Update global state with event info
        data?.tournamentData?.eventInfo
          ? tournamentDataFn(
              data?.tournamentData?.eventInfo?.startDate,
              data?.tournamentData?.eventInfo?.endDate,
              data?.tournamentData?.eventInfo?.key,
              lng
            )
          : tournamentDataFn([]);
      } catch (error) {
        console.error("Failed to load tournament content:", error);
        setIsNotFound(true);
      }
    };

    fetchTournamentData();
  }, [location, lng, tournamentContent]); // Remove `chipCountSlug` and `location` from dependencies

  // Fetch leaderboard data once when the tournament content is available
  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const tab = tournamentContent?.leaderboardTabs
          ? Object.values(tournamentContent.leaderboardTabs).find(
              (tab) => tab.link === location.pathname
            )
          : null;

        if (tab && tab.key) {
          const data = await leaderboards(tab.key);
          setLeaderboardData(data);
        }
      } catch (error) {
        console.error("Failed to fetch leaderboard data:", error);
      }
    };

    if (tournamentContent?.leaderboardTabs) {
      fetchLeaderboardData();
    }
  }, [tournamentContent, location.pathname]);

  if (!tournamentContent && !isNotFound) {
    return null;
  }
  if (isNotFound) {
    return <Page404 />;
  }
  const { hero, bgColor, dropDownBgColor, textColor, leaderboardTabs } =
    tournamentContent;
  return (
    <Div>
      <TournamentHero
        image={hero?.image}
        imageAlt={hero?.imageAlt || "Tournament hero image"}
        bgColor={bgColor || "#000"}
        dropDownBgColor={dropDownBgColor || "#000"}
        logo={hero?.logo}
        fluidImage
      />
      {tournamentContent?.content?.hotels && (
        <CoverageAndResultsBanner
          label={t("poker.tournaments.placeToStayBanner")}
          resultsLink={t("routes.hotels")}
          hotels={tournamentContent?.content?.hotels}
        />
      )}
      {tournamentTabs && (
        <NavTabs
          tabLinks={Object.values(tournamentTabs)?.map((tab) => ({
            label: tab?.label,
            link: tab?.link,
            type: tab?.type,
            options: tab?.options,
          }))}
          bgColor={bgColor ?? "#000"}
          textColor={textColor ?? "#fff"}
        />
      )}
      <TournamentContent
        tournamentContent={tournamentContent}
        tournamentsData={tournamentsData}
        leaderboardData={leaderboardData}
        leaderboardTabs={leaderboardTabs}
      />
    </Div>
  );
};

const Div = styled.div`
  background-color: var(--white);
  text-align: center;
  overflow-x: hidden;
  height: auto;
  .hero_text_container {
    padding: 0;
  }
  .hero_container::before {
    background-color: var(--black);
  }
`;

export default TournamentDetails;
