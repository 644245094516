import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const WelcomeBackBonus = () => {
  const { t, i18n } = useTranslation();
  const welcomeBonus = t("egd.welcomeBonus", { returnObjects: true });
  return (
    <PromoDetailDiv
      bgImage={useResponsiveImageUrl("promotions/welcome-page-bg.jpg")}
    >
      <div>
        <div>
          <ResponsiveImage
            src={`promotions/welcome_bonus_logo_${i18n.language || "en"}.png`}
            alt="welcome_bonus_logo"
          />
        </div>
        <div>
          <div>
            <h1>{t("egd.welcomeBonus.title")}</h1>
            <h2>{t("egd.welcomeBonus.subtitle")}</h2>
            <p>{t("egd.welcomeBonus.description")}</p>
          </div>
        </div>
        <div></div> {/* Consider removing this empty div if not needed */}
      </div>
      <div>
        <h2>{t("egd.welcomeBonus.termsTitle")}</h2>
        <div>
          <ul>
            {welcomeBonus?.terms?.map((term, index) => (
              <li key={index}>{term}</li>
            )) || <li>No terms available</li>}
          </ul>
        </div>
      </div>
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  z-index: 0;
  background: #e8ebef;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 50% 0% fixed no-repeat;
    z-index: 0;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > div:first-child {
      max-width: 1440px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      & > img {
        max-width: 700px;
        width: 100%;
        margin: 40px auto;
      }
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      padding: 40px 0px;
      & > div {
        max-width: 800px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        height: fit-content;
        & > h1 {
          color: var(--white);
          font-weight: 900;
        }
        & > h2 {
          font-size: 28px;
          line-height: 40px;
          color: #fff;
          font-weight: 700;
          margin: 0px;
        }
        & > p:last-child {
          background-color: #492daa;
          border: 1px solid #694dca;
          width: 100%;
          max-width: 800px;
          padding: 20px;
          color: #fff;
          margin-top: 20px;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
    & > div:nth-child(3) {
      background: #5b45ff;
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      opacity: 0.4;
      z-index: -1;
    }
  }
  & > div:nth-child(2) {
    max-width: 1440px;
    width: 100%;
    height: fit-content;
    color: #555;
    margin: 0 auto 40px auto;
    & > h2:first-child {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin: 40px auto 20px auto;
    }
    & > div:last-child {
      width: 100%;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      text-align: left;
      & > ul {
        background: #fff;
        list-style-type: disc;
        padding: 30px;
        padding-left: 80px;
        & > li {
          padding: 3px;
          font-size: 14px;
          color: #555;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    & > div:first-child {
      background: url(${(props) => props.bgImage}) 70% 20% fixed no-repeat;
    }
  }
`;
export default WelcomeBackBonus;
