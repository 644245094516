import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "../components/Button/Button";
import useResponsiveImageUrl from "../utils/useResponsiveImageUrl";

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <ErrorDiv>
      <ErrorDivContent bgImage={useResponsiveImageUrl("home/404-background.jpg")}>
        <h1>{t("error.error404")}</h1>
        <h2>{t("error.oops")}</h2>
        <h3>{t("error.somethingWentWrong")}</h3>
        <Button
          bgColor="var(--red)"
          color="var(--white)"
          hoverColor="var(--white)"
          hoverBgColor="var(--red)"
          href={t("routes.homepage")}
          label={t("button.return-back-home")}
          width="wide"
          size="large"
        />
      </ErrorDivContent>
    </ErrorDiv>
  );
};
const ErrorDiv = styled.div`
  height: auto;
  width: 100%;
  color: white;
`;
const ErrorDivContent = styled.div`
  width: 100%;
  background-image: url(${props => props.bgImage});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  letter-spacing: 0.5px;
  & > h1 {
    font-size: 50px;
    margin: 0;
    margin-top: 140px;
  }
  & > h2 {
    margin: 5px;
    font-weight: 500;
    font-size: 40px;
  }
  & > h3 {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: 500;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 20px 20px 100px 20px;
    & > h1 {
      font-size: 40px;
      text-align: center;
    }
    & > h2 {
      text-align: center;
    }
    & > h3 {
      text-align: center;
    }
  }
`;
export default Page404;
