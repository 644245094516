import React from "react";
import styled from "styled-components";


import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const JackpotParties2024 = () => {
  const { i18n } = useTranslation();
  const lng = i18n.language.toUpperCase();
  const handleTermsClick = (e) => {
    e.preventDefault();
    // Assuming the terms section has an id of "terms"
    const termsSection = document.getElementById("terms");

    if (termsSection) {
      termsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <PromoDetailDiv>
      <ResponsiveImage
        src={`promotions/header_jp_august_2023-${lng}.jpg`}
        alt="header_jp_august_2023-EN"
      />
      <ResponsiveImage
        src={`promotions/header_jp_august_2023_mobile-${lng}.jpg`}
        alt="header_jp_august_2023_mobile-EN"
      />
      <FirstContent>
        {i18n.language === "en" ? (
          <>
            <div>
              <div>
                <h2>Tuesday, February 6, 2024</h2>
                <p>
                  <strong>From 8:00PM to 10:00PM</strong>
                </p>
                <p>
                  From <strong>January 12 to February 6</strong>, when you{" "}
                  <strong>hit a jackpot of $1,000 to $1,300</strong> while
                  playing on our gaming machines or table games you will get at
                  least <strong>1 invitation to this Jackpot party!</strong>
                </p>
                <p>
                  So hit the machines and spin ‘till you win and then… win
                  again!
                </p>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2">Guaranteed Prizes</th>
                    </tr>
                    <tr>
                      <th>Draw Time</th>
                      <th>Prize amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>8:00pm</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <td>8:15pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>8:30pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>8:45pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>9:00pm</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <td>9:15pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>9:30pm</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <td>9:45pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>10:00pm</td>
                      <td>$5,000 Grand Prize</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ResponsiveImage
                src={`promotions/jp_july_2023_V2_1500x1073_${lng}.jpg`}
                alt="jp_july_2023_V2_1500x1073_EN"
              />
            </div>
            <div>
              <p>
                Each winner that is drawn will present themselves to the
                promotion area to collect their prize.
              </p>
              <a
                href="/promotions/gaming-machines/jackpot-party-february-2024#terms"
                onClick={handleTermsClick}
              >
                VIEW TERMS AND CONDITIONS
              </a>
            </div>
          </>
        ) : (
          <>
            <div>
              <div>
                <h2>Mardi 6 février 2024</h2>
                <p>
                  <strong>De 20h00 à 22h00</strong>
                </p>
                <p>
                  Du <strong> 12 janvier au 6 février</strong>, lorsque vous{" "}
                  <strong>remportez un jackpot de 1 000$ à 1 300$</strong> en
                  jouant sur nos machines de jeu ou tables de jeu, vous
                  recevrez au moins une invitation à cette{" "}
                  <strong>Fête de Jackpot!</strong>
                </p>
                <p>Alors jouez pour gagner et ensuite…gagnez encore!</p>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2">Prix garantis</th>
                    </tr>
                    <tr>
                      <th>Heures des tirages</th>
                      <th>Montant du prix</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>20h00</td>
                      <td>1 000$</td>
                    </tr>
                    <tr>
                      <td>20h15</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>20h30</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>20h45</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>21h00</td>
                      <td>1 000$</td>
                    </tr>
                    <tr>
                      <td>21h15</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>21h30</td>
                      <td>1 000$</td>
                    </tr>
                    <tr>
                      <td>21h45</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>22h00</td>
                      <td>Grand Prix de 5 000$</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ResponsiveImage
                src={`promotions/jp_july_2023_V2_1500x1073_${lng}.jpg`}
                alt="jp_july_2023_V2_1500x1073_FR"
              />
            </div>
            <div>
              <p>
                Chaque gagnant tiré au sort se présentera dans la zone de
                promotion pour récupérer son prix.
              </p>
              <a
                href="/promotions/gaming-machines/jackpot-party-february-2024#terms"
                onClick={handleTermsClick}
              >
                Règles et conditions générales
              </a>
            </div>
          </>
        )}
      </FirstContent>
      <SecondContent>
        {i18n.language === "en" ? (
          <>
            <div>
              <ResponsiveImage
                src={`promotions/jp_july_2023-${lng}.jpg`}
                alt="jp_july_2023"
              />
              <div>
                <h2>Wednesday, February 7, 2024</h2>
                <p>
                  <strong>From 8:00PM to 10:00PM</strong>
                </p>
                <p>
                  From <strong>January 12 to February 7</strong>, when you{" "}
                  <strong>hit a jackpot of $1,300.01 or over</strong> while
                  playing on our gaming machines or table games you will get at
                  least <strong>1 invitation to this Jackpot party!</strong>
                </p>
                <p>
                  So hit the machines and spin ‘till you win and then… win
                  again!
                </p>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2">Guaranteed Prizes</th>
                    </tr>
                    <tr>
                      <th>Draw Time</th>
                      <th>Prize amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>8:00pm</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <td>8:15pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>8:30pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>8:45pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>9:00pm</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <td>9:15pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>9:30pm</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <td>9:45pm</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>10:00pm</td>
                      <td>$5,000 Grand Prize</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                Each winner that is drawn will present themselves to the
                promotion area to collect their prize.
              </p>
              <a
                href="/promotions/gaming-machines/jackpot-party-february-2024#terms"
                onClick={handleTermsClick}
              >
                VIEW TERMS AND CONDITIONS
              </a>
            </div>
          </>
        ) : (
          <>
            <div>
              <ResponsiveImage
                src={`promotions/jp_july_2023-${lng}.jpg`}
                alt="jp_july_2023"
              />
              <div>
                <h2>Mercredi 7 février 2024</h2>
                <p>
                  <strong>De 20h00 à 22h00</strong>
                </p>
                <p>
                  Du <strong>12 janvier au 7 février</strong>, lorsque vous{" "}
                  <strong>remportez un jackpot de 1 300.01$ ou plus</strong> en
                  jouant sur nos machines de jeu ou tables de jeu, vous
                  recevrez au moins une invitation à cette{" "}
                  <strong>Fête de Jackpot!</strong>
                </p>
                <p>Alors jouez pour gagner et ensuite…gagnez encore!</p>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="2">Prix garantis</th>
                    </tr>
                    <tr>
                      <th>Heures des tirages</th>
                      <th>Montant du prix</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>20h00</td>
                      <td>1 000$</td>
                    </tr>
                    <tr>
                      <td>20h15</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>20h30</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>20h45</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>21h00</td>
                      <td>1 000$</td>
                    </tr>
                    <tr>
                      <td>21h15</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>21h30</td>
                      <td>1 000$</td>
                    </tr>
                    <tr>
                      <td>21h45</td>
                      <td>500$</td>
                    </tr>
                    <tr>
                      <td>22h00</td>
                      <td>Grand Prix de 5 000$</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                Chaque gagnant tiré au sort se présentera dans la zone de
                promotion pour récupérer son prix.
              </p>
              <a
                href="/promotions/gaming-machines/jackpot-party-february-2024#terms"
                onClick={handleTermsClick}
              >
                Règles et conditions générales
              </a>
            </div>
          </>
        )}
      </SecondContent>
      {i18n.language === "en" ? (
        <div id="terms">
          <p>
            <strong>General Rules and Conditions</strong>
          </p>
          <ol>
            <li>
              Players receive one (1) invitation/draw entry to the{" "}
              <strong>Jackpot party</strong> on{" "}
              <strong>Tuesday, February 6</strong> for every jackpot of{" "}
              <strong>$1,000 to $1,199.99</strong> and two (2) invitations/draw
              entries for every jackpot of <strong>$1,200 to $1,300</strong>{" "}
              while playing our gaming machines or table games between Friday,
              January 12 at 9:00AM to February 6 at 9:55PM.
            </li>
            <li>
              Players receive one (1) invitation/draw entry to the{" "}
              <strong>Jackpot party</strong> on{" "}
              <strong>Wednesday, February 7</strong> for every jackpot of{" "}
              <strong>$1,300.01 to $2,999.99</strong> and two (2)
              invitations/draw entries for every jackpot of{" "}
              <strong>$3,000 or more</strong> while playing our gaming machines
              between Friday, January 12 at 9:00AM to February 7 at 9:55PM.
            </li>
            <li>
              After a player is given an invitation by a Playground
              representative, they will then write down their name and ID on the
              raffle portion of the invitation and drop it in the appropriate
              Jackpot Party raffle drum.
            </li>
            <li>You must bring your invitation to enter the Jackpot party.</li>
            <li>
              Starting at 8:00PM, the first draw will be held and will continue
              every 15 minutes until the grand prize draw of $5,000 at 10:00PM.
            </li>
            <li>
              Guests are eligible to win once during the Jackpot Party. In the
              event someone has their name called on more than one occasion, by
              way of the random draw, the guest will get to keep the greater of
              the 2 amounts won and an additional draw will be held for the
              lesser amount. If the amount is the same as their first prize the
              guest will receive $50 in Free Play.
            </li>
            <li>
              Draw winners will be given five (5) minutes to present themselves
              either to the draw area on the 2nd floor or the desk on the 1st
              floor to confirm their presence before making their way to the 2nd
              floor.
            </li>
            <li>
              All participants must present a valid government-issued photo
              identification upon request.
            </li>
            <li>
              Playground reserves the right to change or alter this promotion,
              as it deems appropriate, including, but not limited to, making
              changes or alterations to these Rules and Regulations relating to
              the accommodation of participants.
            </li>
            <li>The promotion is open to all Playground members.</li>
            <li>The promotion takes place at Playground.</li>
            <li>
              Employees of Playground shall be ineligible to participate in the
              promotion.
            </li>
            <li>
              Patrons with self-excluded or banned status, as determined by
              Playground, are not eligible for any of the gifts/prizes
              associated with Playground contests, promotions, tournaments or
              giveaways.
            </li>
            <li>All references to times and dates reflect Eastern Time.</li>
            <li>
              By participating in the promotion, winners consent to the use of
              their names and photographs for promotional purposes without
              additional compensation.
            </li>
          </ol>
          <p>
            <strong>
              Questions relating to this promotion can be answered by visiting
              the Player Rewards desk.
            </strong>
          </p>
        </div>
      ) : (
        <div id="terms">
          <p>
            <strong>Règles et conditions générales</strong>
          </p>
          <ol>
            <li>
              Les joueurs reçoivent une (1) invitation / participation pour la{" "}
              <strong>Fête de Jackpot</strong> du{" "}
              <strong>mardi 6 février</strong> pour chaque jackpot de{" "}
              <strong>1 000$ à 1 199.99$</strong> et deux (2) invitations /
              participations pour chaque jackpot de{" "}
              <strong>1 200$ à $1 300$</strong> en jouant à nos machines de jeu
              ou tables de jeu entre le vendredi 12 janvier à 9h00 et le mardi
              6 février à 21h55.
            </li>
            <li>
              Les joueurs reçoivent une (1) invitation / participation pour la{" "}
              <strong>Fête de Jackpot</strong> du{" "}
              <strong>mercredi 7 février</strong> pour chaque jackpot de{" "}
              <strong>1 300.01$ à 2 999.99$</strong> et deux (2) invitations /
              participations pour chaque jackpot de{" "}
              <strong>3 000$ et plus</strong> en jouant à nos machines de jeu
              ou tables de jeu entre le vendredi 12 janvier à 9h00 et le
              mercredi 7 février à 21h55.
            </li>
            <li>
              Après qu'un représentant de Playground ait remis à un joueur une
              invitation, il inscrit ensuite son nom et numéro de membre sur la
              partie tirage de l'invitation et le dépose dans le tambour de
              tirage au sort approprié de la Fête du Jackpot.
            </li>
            <li>
              Vous devez apporter votre invitation pour entrer dans la Fête du
              Jackpot.
            </li>
            <li>
              À partir de 20h00, le premier tirage aura lieu et se poursuivra
              toutes les 15 minutes jusqu'au tirage du grand prix de 5 000$ à
              22h00.
            </li>
            <li>
              Les invités ne peuvent gagner qu'une seule fois au cours de la
              soirée Jackpot. Si le nom d'un invité est appelé plus d'une fois,
              par le biais du tirage au sort, l'invité conservera le plus élevé
              des deux montants gagnés et un tirage supplémentaire sera effectué
              pour le montant le moins élevé. Si le montant est identique au
              premier prix, l'invité recevra 50$ en crédits-jeu.
            </li>
            <li>
              Les gagnants du tirage disposeront de cinq (5) minutes pour se
              présenter soit à la zone de tirage du 2e étage, soit au comptoir
              du 1er étage pour confirmer leur présence avant de se rendre au 2e
              étage.
            </li>
            <li>
              Tous les participants doivent présenter une pièce d'identité
              valide avec photo émise par le gouvernement sur demande.
            </li>
            <li>
              Playground se réserve le droit de modifier ou d'altérer cette
              promotion, comme elle le juge approprié, y compris, mais sans s'y
              limiter, en apportant des modifications ou des altérations à ces
              règles et règlements relatifs à la participation des membres.
            </li>
            <li>La promotion est ouverte à tous les membres de Playground.</li>
            <li>La promotion a lieu au Playground.</li>
            <li>
              Les employés de Playground ne sont pas autorisés à participer à la
              promotion.
            </li>
            <li>
              Les clients ayant un statut d'auto-exclusion ou de bannissement,
              tel que déterminé par Playground, ne sont pas admissibles aux
              cadeaux/prix associés aux concours, aux promotions, aux tournois
              ou aux cadeaux promotionnels de Playground.
            </li>
            <li>
              Toutes les heures et dates mentionnées sont en heure de l'Est.
            </li>
            <li>
              En participant à la promotion, les gagnants consentent à
              l'utilisation de leur nom et de leur photo à des fins
              promotionnelles sans compensation supplémentaire.
            </li>
          </ol>
          <p>
            <strong>
              Les questions relatives à cette promotion peuvent être posées au
              bureau des récompenses aux joueurs.
            </strong>
          </p>
        </div>
      )}
      
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background-color: #000;
  padding-bottom: 40px;
  & > img:first-child {
    z-index: 1;
    width: 100%;
    height: fit-content;
    vertical-align: middle;
  }
  & > img:nth-child(2) {
    z-index: 1;
    width: 100%;
    height: fit-content;
    display: none;
    vertical-align: middle;
  }
  & > div:nth-child(5) {
    margin: 20px auto;
    height: auto;
    width: 100%;
    max-width: 1440px;
    background-color: #fff;
    padding: 40px 20px;
    margin-bottom: 40px;
    & > p:first-child {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      color: #000;
      margin: 0 0 10px;
      & > strong {
        font-weight: 700;
      }
    }
    & > ol:nth-child(2) {
      margin-top: 0;
      margin-bottom: 10px;
      list-style-type: decimal;
      padding-left: 40px;
      & > li {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        color: #000;
      }
    }
    & > p:last-child {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      color: #000;
      text-align: center;
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      display: block;
    }
    & > div:nth-child(5) {
      & > ol:nth-child(2) {
        padding-left: 20px;
      }
    }
  }
`;
const FirstContent = styled.div`
  background-color: #fff;
  height: fit-content;
  padding: 60px 0;
  & > div:first-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    & > div {
      height: fit-content;
      width: 100%;
      max-width: 700px;
      & > h2:first-child {
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #000;
        margin: 0px;
      }
      & > p:nth-child(2) {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        color: #000;
        margin: 0 0 10px;
      }
      & > p:nth-child(3) {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        color: #000;
        margin: 0 0 10px;
        & > strong {
          font-weight: 700;
        }
      }
      & > p:nth-child(4) {
        font-size: 16px;
      }
      & > table:last-child {
        width: 100%;
        margin-bottom: 15px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
        & > thead {
          & > tr:first-child {
            & > th {
              background: radial-gradient(
                circle,
                rgb(249 189 93) 0%,
                rgb(224 126 74) 50%,
                rgb(215 91 49) 100%,
                rgb(187 71 43) 100%
              );
              color: #000;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
          & > tr:last-child {
            & > th {
              background: #000;
              color: #fff;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              color: #fff;
              background: #000;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              padding: 15px 5px;
              font-size: 18px;
              border: 2px solid #fff;
            }
          }
        }
      }
    }
    & > img {
      width: 100%;
      max-width: 650px;
      height: fit-content;
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    & > p:first-child {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      color: #000;
      margin: 0 0 10px;
    }
    & > a:last-child {
      background: radial-gradient(
        circle,
        rgb(249 189 93) 0%,
        rgb(224 126 74) 50%,
        rgb(215 91 49) 100%,
        rgb(187 71 43) 100%
      ) !important;
      color: #000;
      border: none;
      border-radius: 5px;
      min-width: 140px;
      min-height: 40px;
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      transition: 500ms;
      padding: 3.5px 20px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      &:active {
        outline: 0;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 60px 20px;
    & > div:first-child {
      flex-flow: row wrap-reverse;
      & > div {
        max-width: none;
      }
      & > img {
        max-width: none;
      }
    }
  }
`;
const SecondContent = styled.div`
  background-color: inherit;
  height: fit-content;
  padding: 60px 0;
  & > div:first-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    & > img {
      width: 100%;
      max-width: 650px;
      height: fit-content;
    }
    & > div {
      height: fit-content;
      width: 100%;
      max-width: 700px;
      & > h2:first-child {
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 10px;
        color: #fff;
        margin: 0px;
      }
      & > p:nth-child(2) {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        color: #fff;
        margin: 0 0 10px;
      }
      & > p:nth-child(3) {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        color: #fff;
        margin: 0 0 10px;
        & > strong {
          font-weight: 700;
        }
      }
      & > p:nth-child(4) {
        font-size: 16px;
      }
      & > table:last-child {
        width: 100%;
        margin-bottom: 15px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
        & > thead {
          & > tr:first-child {
            & > th {
              background: radial-gradient(
                circle,
                rgb(255 224 107) 0%,
                rgb(250 173 45) 50%,
                rgb(26 86 148) 100%,
                rgb(108 159 250) 100%
              );
              color: #000;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
          & > tr:last-child {
            & > th {
              background: #000;
              color: #fff;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              padding: 10px 5px;
              font-size: 18px;
            }
          }
        }
        & > tbody {
          & > tr {
            & > td {
              background: #fff;
              color: #000;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              padding: 15px 5px;
              font-size: 18px;
              border: 2px solid #000;
            }
          }
        }
      }
    }
  }
  & > div:last-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    & > p:first-child {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      color: #fff;
      margin: 0 0 10px;
    }
    & > a:last-child {
      background: radial-gradient(
        circle,
        rgb(255 224 107) 0%,
        rgb(250 173 45) 50%,
        rgb(26 86 148) 100%,
        rgb(108 159 250) 100%
      );
      color: #000;
      border: none;
      border-radius: 5px;
      min-width: 140px;
      min-height: 40px;
      font-family: "Poppins", sans-serif;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      transition: 500ms;
      padding: 3.5px 20px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      &:active {
        outline: 0;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 60px 20px;
    & > div:first-child {
      flex-flow: row wrap;
      & > img {
        max-width: none;
      }
      & > div {
        max-width: none;
      }
    }
  }
`;
export default JackpotParties2024;
