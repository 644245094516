import React from "react";
import TitleSection from "src/components/TitleSection/TitleSection";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
import { formatAmount, formatDate, formatTime } from "src/utils/formatters";

const Jackpots = ({ jackpotData, jackpotType }) => {
  const { t, i18n } = useTranslation();

  const bgImage = "egd/promo_bg.jpg";
  const responsiveImageUrl = useResponsiveImageUrl(bgImage);

  const jackpots = jackpotData?.[jackpotType === "topJackpots" ? "top_jackpots" : "recent_jackpots"];
  return (
    <MainDiv>
      <TitleSection
        dark={false}
        as={"h2"}
        title={t(`egd.${jackpotType}.title`)}
        text={t(`egd.${jackpotType}.description`)}
      />
      <Row xs={1} md={2} lg={3} className="g-4 mb-3 pb-4">
        {jackpots ? (
          jackpots.map((jackpot, index) => (
            <Col key={index}>
              <JackpotCard bgImage={responsiveImageUrl}>
                <div>
                  <p className="amount">{formatAmount(jackpot.amount, i18n.language)}</p>
                  <p className="date">
                    {formatDate(jackpot.paid_at, i18n.language)}
                    <br />
                    {formatTime(jackpot.paid_at, i18n.language)}
                  </p>
                </div>
              </JackpotCard>
            </Col>
          ))
        ) : (
          <p>Loading</p>
        )}
      </Row>
    </MainDiv>
  );
};

const MainDiv = styled.div`
  padding: 0 20px;
  color: var(--white);
  .title-section {
    position: relative;
    z-index: 2;
    h2 {
      color: var(--mustard);
    }
  }
`;

const JackpotCard = styled.div`
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-color: rgba(0, 0, 0, 0.7); */
  padding: 20px;
  /* border-radius: 10px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* border: 1px solid var(--darkmustard); */
  position: relative;
  > div {
    position: relative;
    z-index: 2;
  }
  .amount {
    font-size: 3rem;
    font-weight: 900;
    color: var(--mustard);
    margin-bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

export default Jackpots;
