/**
 * ScrollHandler component handles the scrolling behavior based on the current route (`pathname`)
 * and the scroll position state (`scrollPosition`). It uses the `ScrollPositionContext` to determine
 * whether to scroll to the top, a specific element, or a Y-coordinate.
 *
 * The component also ensures that scrolling is reset to the default after scrolling has completed.
 *
 * This scroll behavior is triggered whenever the `pathname` changes, making it perfect for handling
 * page navigations or dynamic tab interactions.
 *
 * @component
 * @example
 * // Scroll to top of the page after route changes
 * <ScrollHandler />
 *
 * @example
 * // Prevent scrolling when a dynamic nav tab is clicked:
 * // Inside the dynamic nav tab component, set scrollPosition to false
 * setScrollPosition(false);
 *
 * @example
 * // Scroll to a specific element by setting the scrollPosition to the element's ID:
 * setScrollPosition('my-element-id');
 *
 * @example
 * // Scroll to a specific Y position (e.g., 500px from the top):
 * setScrollPosition(500);
 */
import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ScrollPositionContext } from "../context/scrollPosition/scrollPosition.provider";

const ScrollHandler = () => {
  const { scrollPosition, setScrollPosition } = useContext(ScrollPositionContext);
  const { pathname } = useLocation();

  // First useEffect: Handle scrolling based on scrollPosition, but only when pathname changes
  useEffect(() => {
    // If scrollPosition is set to false, we skip scrolling (e.g., dynamic nav tab clicks)
    if (scrollPosition === false) return;

    // Scroll to top or specific element based on the current scrollPosition state
    if (scrollPosition === 'top' || scrollPosition === true) {
      window.scrollTo(0, 0); // Scroll to the top of the page
    } else if (typeof scrollPosition === 'string') {
      const element = document.getElementById(scrollPosition);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (typeof scrollPosition === 'number') {
      window.scrollTo(0, scrollPosition); // Scroll to a specific Y position
    }
  }, [pathname]); // Only triggers when pathname changes (navigation)

  // Second useEffect: Reset scrollPosition to true after handling the scroll
  useEffect(() => {
    // Immediately reset the scroll position to true after the navigation
    setScrollPosition(true);
  }, [pathname]); // Reset scrollPosition after every pathname change

  return null;
};

export default ScrollHandler;
