// src/context/toast/toast.reducer.js
import { toastActionTypes as actions } from "./toast.actions";

export const toastReducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SHOW_TOAST:
      return {
        ...state,
        toastData: [...state.toastData, { ...payload, id: Date.now() }]
      };
    case actions.HIDE_TOAST:
      return {
        ...state,
        toastData: state.toastData.filter(toast => toast.id !== payload)
      };
    default:
      return state;
  }
};
