import React, { useEffect, useState } from 'react';
import { Dropdown, Nav, Badge } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import ResponsiveImage from "../components/ImageWrapper/ResponsiveImage";
import { useTranslation } from 'react-i18next';
import { useHomepage } from "../context/homepage/homepage.provider";
import { useIsSmScreenDown, useIsMdScreenUp, useIsLgScreenUp } from '../utils/windowUtils';
import { toUrlFriendlyString } from '../utils/formatters';
import {
  FaGlassMartiniAlt,
  FaUtensils,
  FaTrophy
 } from "react-icons/fa";

 import { FaCircleDollarToSlot } from "react-icons/fa6";

 import { GiSpades, GiSevenPointedStar } from "react-icons/gi";
 import { TbCards, TbCardsFilled } from "react-icons/tb";
//  import { BsCashCoin } from "react-icons/bs";
//  import { BsCalendar2DateFill } from "react-icons/bs";
//  import { BiSolidBadgeDollar } from "react-icons/bi";
//  import { PiListHeartFill } from "react-icons/pi";
//  import { PiSpinnerFill } from "react-icons/pi";
//  import { ImHistory } from "react-icons/im";


 const iconMap = {
  poker: GiSpades,
  egd: FaCircleDollarToSlot,
  eatAndPlay: FaUtensils,
  pokerPromos: GiSevenPointedStar,
  egdPromos: GiSevenPointedStar,
  tableGames: TbCardsFilled,
  // cashGames: BsCashCoin,
  // tournamentCal: BsCalendar2DateFill,
  // tournament: FaTrophy,
  // food: FaUtensils,
  // drinks: FaGlassMartiniAlt,
  // pokerPromo: GiSpades,
  // egdPromo: FaCircleDollarToSlot,
  // gameList: PiListHeartFill,
  // blackJack: TbCards,
  // roulette: PiSpinnerFill,
  // baccarat: TbCardsFilled,
  // pastEvents: ImHistory
};


export const getAuthRoutes = (t) => [
  t("routes.login"),
  t("routes.forgotPassword"),
  t("routes.resetPassword"),
  t("routes.register"),
  t("routes.signUpExistingUser"),
];

// Function to set referrer in sessionStorage
export const setReferrer = (currentPath, t) => {
  const authRoutes = getAuthRoutes(t);

  // Check if the current route is an auth route
  const isAuthRoute = authRoutes.some((route) => currentPath === route);

  // If it's not an auth route, store the path in sessionStorage
  if (!isAuthRoute) {
    sessionStorage.setItem("referrer", currentPath);
  }
};

export const isScrolling = () => {
  return window.scrollY >= 66;
};

export const renderLinks = (links, closeAllMenus) => {
  const { t } = useTranslation();
  const isLgScreenUp = useIsLgScreenUp();
  const isMdScreenUp = useIsMdScreenUp();
  const isSmScreenDown = useIsSmScreenDown();
  const { setMenuBarToggle } = useHomepage();

  const [isMegaDropdownOpen, setMegaDropdownOpen] = useState(false);

  const handleMegaDropdownToggle = (isOpen) => {
    setMegaDropdownOpen(isOpen);
    setMenuBarToggle(isOpen);
  };

  return links.map((link, index) => (
    link.items ? (
      // First level links with dropdown
      <Dropdown show={isSmScreenDown ? true : isMegaDropdownOpen} key={index} className="nav-item dropdown" onToggle={handleMegaDropdownToggle}>
        {/* Toggle is hidden on < md */}
        <Dropdown.Toggle as={Link} className="nav-link dropdown-toggle d-none d-md-block" to="#" role="button">
          {t(link.title)}
          <FontAwesomeIcon icon={faAngleDown} className="toggle-icon" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {link.items.map((subLink, subIndex) => (
            subLink.items ? (
              <Dropdown show={isMdScreenUp ? true : undefined} key={subIndex} drop="end" autoClose={false} className="nav-item dropdown-submenu" >
                {/* The nested dropdown toggle is hidden on >= md */}
                <Dropdown.Toggle as={Link} className="d-md-none" to="#" role="button">
                    <span className="sub-link-title">{t(subLink.mobileTitle)}</span>
                  <FontAwesomeIcon icon={faAngleDown} className="toggle-icon" />
                </Dropdown.Toggle>
                {/* The nested dropdown menu is always shown on > md */}
                <Dropdown.Menu className={`${toUrlFriendlyString(t(subLink.title))}`}>
                  {subLink.items.map((nestedLink, nestedIndex) => (
                    <Dropdown.Item
                      as={nestedLink.isExternal ? 'a' : NavLink}
                      href={nestedLink.isExternal ? nestedLink.route : undefined}
                      to={!nestedLink.isExternal ? t(nestedLink.route) : undefined}
                      key={nestedIndex}
                      target={nestedLink.isExternal ? '_blank' : undefined}
                      rel={nestedLink.isExternal ? 'noopener noreferrer' : undefined}
                      onClick={closeAllMenus}
                      end={!link.isExternal}
                      className= {nestedLink.categoryTitle ? `category-title ${nestedLink.img ? 'img-link' : ''}` : ''}
                    >
                      {nestedLink.img && isMdScreenUp && (
                        <ResponsiveImage
                          src={nestedLink.img}
                          alt={t(nestedLink.imgAlt)}
                        />
                      )}
                      <span className={nestedLink.img ? "has-img" : ""}>
                        {nestedLink.icon && iconMap[nestedLink.icon] && (() => {
                          const IconComponent = iconMap[nestedLink.icon]; // Get the icon component
                          return <IconComponent />;
                        })()}
                        {t(nestedLink.title)}
                      </span>
                      {nestedLink.badge && (
                        <Badge bg={`${nestedLink.badge} mx-2`}>{t(`nav.${nestedLink.badge}`)}</Badge>
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown.Item
                as={subLink.isExternal ? 'a' : NavLink}
                href={subLink.isExternal ? subLink.route : undefined}
                to={!subLink.isExternal ? t(subLink.route) : undefined}
                key={subIndex}
                target={subLink.isExternal ? '_blank' : undefined}
                rel={subLink.isExternal ? 'noopener noreferrer' : undefined}
                onClick={closeAllMenus}
              >
                {t(subLink.title)}
              </Dropdown.Item>
            )
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : (
      // Regular links, first level, no dropdown
      <Nav.Item key={index}>
        <Nav.Link
          as={link.isExternal ? 'a' : NavLink}
          href={link.isExternal ? link.route : undefined}
          to={!link.isExternal ? t(link.route) : undefined}
          key={index}
          target={link.isExternal ? '_blank' : undefined}
          rel={link.isExternal ? 'noopener noreferrer' : undefined}
          onClick={closeAllMenus}
        >
          {t(link.title)}
        </Nav.Link>
      </Nav.Item>
    )
  ));
};
