import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useParams } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { shouldPublish } from "src/utils/publishUtils";

import Hero from "src/components/Hero/Hero";
import PgCard from "src/components/Card/PgCard";
import SectionalTabs from "src/components/SectionalTabs/SectionalTabs";
import TitleSection from "src/components/TitleSection/TitleSection";

const Entertainment = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const { sectionCategory, sectionDescription } = useHomepage();
  const promotionsData = require(
    `../locales/${i18n.language}/promotions.json`
  );
  const promotionsDataArr = Object.values(
    promotionsData.promotions.data || {}
  );
  return (
    <PromoPage>
      <Hero
        bgColor=""
        bgImage={"home/offers-header.jpg"}
        buttonLink=""
        buttonText=""
        description={t("promotions.hero.promotionsDescription")}
        descriptionBgColor=""
        image=""
        imageAlt=""
        mainHeroText={t("promotions.hero.playgroundPromotions")}
        title=""
      >
        <SectionalTabs
          routeData={{
            route: t("routes.promotions"),
            routeCategory: "promotions",
            sectionTitle: t("promotions.hero.liveAction"),
            selectCategory: t("promotions.hero.selectPromotion"),
            selectAll: t("entertainment.selectAll"),
          }}
          isDynamic={true}
        />
      </Hero>

      <div>
        <TitleSection
          title={
            sectionCategory ? sectionCategory : t("promotions.content.header")
          }
          text={
            sectionDescription
              ? sectionDescription
              : t("promotions.content.description")
          }
        />
      </div>

      <container-wide>
        <Row className="g-4 gx-3" xs={1} md={2} lg={3} >
          {promotionsDataArr.map((el, ind) => {
            // Check if the card should render based on the publish/unpublish logic
            const shouldRender = shouldPublish(el);

            // Additional condition for rendering based on category
            const meetsCategoryCondition = sectionCategory && params.category
              ? el.category === sectionCategory
              : true;

            // Combine all conditions to decide whether to render the card
            if (shouldRender && meetsCategoryCondition) {

              return (
                <Col key={el.title + ind}>
                  <PgCard
                    image={el.image}
                    imageAlt={el.imageAlt || el.title}
                    title={el.title}
                    subtitle={el.category}
                    text={el.content}
                    buttonSettings={{
                      href: el.url,
                      label: el.buttonLabel || t("button.read-more"),
                      className: !el.url ? 'd-none' : '',
                      disabled: !el.url
                    }}
                  />
                </Col>
              );
            }
          })}
        </Row>
      </container-wide>
    </PromoPage>
  );
};

const PromoPage = styled.div`
  .hero_text_container {
    p {
      margin-bottom: 1rem;
    }
  }
  .pg-card {
    .card-header {
      flex-grow: 0;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .card-text {
        flex-grow: 1;
      }
    }
  }
`;

const AnnouncementDiv = styled.div`
  &.announcement{
    width: 100%;
    max-width: 700px;
    margin: 0 auto 1.5rem;
    border-radius: 5px;
    background-color: #e7da4b;
    padding: 1rem;
    h2, p {
      padding: 10px;
      color: var(--black);
      text-shadow: none;
      margin: 0;
      padding: 0;
    }
    h2 {
      font-family: 'Poppins', sans-serif;
      font-weight: 900;
      font-size: 20px;
      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }
    p {
      font-size: 18px;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
`;


export default Entertainment;

