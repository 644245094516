import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Footer from "../Footer";
import Button from "../Button/Button";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const BlogSecond = () => {
  const [isActive, setIsActive] = useState("recent");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const blogData = require(
    `../../locales/${i18n.language}/blog.json`
  );
  const blogDataArr = Object.values(
    blogData.blog.data || {}
  );

  const getButtonClassName = (nav) => (isActive === nav ? "active" : undefined);

  const renderInitiative = (el, ind) => (
    <SecondMainCart key={el.title}>
      <ResponsiveImage
        src={el.image}
        alt={el.title}
        onClick={() => navigate(el.url)}
      />
      <div>
        <div>
          <h2>{el.title}</h2>
        </div>
        <p>
          {el.text}
          ...
        </p>
        <Button
          color="var(--white)"
          hoverBgColor="var(--black)"
          hoverColor="var(--white)"
          href={el.url}
          label={t("button.read-more")}
          size="small"
        />
      </div>
    </SecondMainCart>
  );
  return (
    <SecondPart>
      <div>
        <Button
          className={getButtonClassName("recent")}
          color="var(--white)"
          hoverBgColor="var(--black)"
          hoverColor="var(--white)"
          onClick={() => setIsActive("recent")}
          label={t("blog.nav-1")}
          size="small"
        />
        <Button
          className={getButtonClassName("all")}
          color="var(--white)"
          hoverBgColor="var(--black)"
          hoverColor="var(--white)"
          onClick={() => setIsActive("all")}
          label={t("blog.nav-2")}
          size="small"
        />
      </div>
      <SecondMain>
        {blogDataArr?.map((el, ind) =>
          isActive === "recent"
            ? ind < 8 && renderInitiative(el, ind)
            : renderInitiative(el, ind)
        )}
      </SecondMain>
      
    </SecondPart>
  );
};
const SecondPart = styled.div`
  padding: 60px 0;
  height: fit-content;
  width: 100%;
  background: var(--black);
  color: var(--white);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  letter-spacing: 1px;
  overflow-x: hidden;
  & > div:first-child {
    text-align: center;
    display: flex;
    gap: 20px;
    & > button {
      text-transform: uppercase;
      background: var(--white);
      color: var(--black);
      transition: all 0.3s;
      margin: 0;
      &.active {
        background: var(--red);
        color: var(--white);
      }
      &:hover {
        background: var(--red);
        color: var(--white);
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > div:first-child {
      & > button {
        font-size: 14px;
        padding: 8px 25px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0;
  }
`;
const SecondMain = styled.main`
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px;
    grid-template-columns: 1fr;
    margin-top: 60px;
    gap: 5px;
  }
`;
const SecondMainCart = styled.div`
  width: 100%;
  position: relative;
  & > img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  & > div {
    position: relative;
    top: -10%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    height: auto;
    max-height: 280px;
    background: var(--lightblack);
    & > div {
      position: unset;
      width: 100%;
      background: var(--white);
      color: var(--black);
      border-bottom: 4px solid var(--red);
      display: flex;
      align-items: center;
      min-height: 111px;
      & > h2 {
        margin: 0;
        padding: 15px;
        font-size: 18px;
      }
    }
    & > p {
      margin: 0;
      padding: 15px 15px 0 15px;
      text-align: left;
      font-size: 14px;
      text-transform: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  @media screen and (max-width: 991px) {
    & > div {
      & > div {
        min-height: unset;
      }
    }
  }
`;
export default BlogSecond;
