import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";

import { t } from "i18next";
import { Row, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import PgCard from "src/components/Card/PgCard";

const Halloween2024 = () => {
  const contentItems = t("entertainment.Halloween2024.content.part2", {
    returnObjects: true,
  });
  return (
    <>
      <Div>
        <Hero
          arrowBounce="true"
          bgImage={t("entertainment.Halloween2024.hero.image")}
          buttonLink=""
          buttonText=""
          descriptionBgColor="none"
        />
        <container-div>
          <ResponsiveImage
            src={t("entertainment.Halloween2024.content.logo")}
            alt="halloween-logo-2024"
            customStyles={{ width: "500px", margin: "0 auto", height: "300px" }}
          />
          <ReactMarkdown>
            {t("entertainment.Halloween2024.content.part1.description")}
          </ReactMarkdown>
          <div className="grid-content">
            <Row>
              {Object.values(contentItems)?.map((item, index) => (
                <Col xs={12} md={6} lg={4} key={item.id || index}>
                  {" "}
                  {/* Use item.id if available */}
                  <PgCard
                    image={item.image}
                    imageAlt={item.title}
                    title={item.title}
                    text={item.description}
                    highlightColor={item.color}
                    highlightTextColor="#fff"
                    variant="portrait"
                    className="item-card"
                  />
                </Col>
              ))}
            </Row>
          </div>
        </container-div>
      </Div>
    </>
  );
};

const Div = styled.div`
  background: var(--black);
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--white);

  .hero_container {
    min-height: 500px;
  }
  .hero_text_container {
    display: none;
  }
  .item-card {
    max-height: 500px;
    max-width: auto;
    margin: 20px 10px;
  }
`;
export default Halloween2024;
