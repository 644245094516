import React, { useEffect } from 'react';

const FacebookPixel = ({ pixelId, locale = 'en_US', events = ['PageView'] }) => {
  useEffect(() => {
    // If fbq is already defined, we don't want to initialize it again
    if (window.fbq) {
      return;
    }

    // Load the Facebook Pixel script
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', `https://connect.facebook.net/${locale}/fbevents.js`);

    // Initialize Facebook Pixel with the provided pixel ID
    fbq('init', pixelId);

    // Track specified events
    events.forEach((event) => {
      fbq('track', event);
    });

    return () => {
      // Cleanup function to avoid duplicate pixel scripts
      if (window.fbq) {
        window.fbq = null;
        delete window.fbq;
      }

      // Remove all existing Facebook Pixel script tags
      const existingScripts = document.querySelectorAll(
        `script[src*="connect.facebook.net"]`
      );
      existingScripts.forEach((script) => {
        script.parentNode.removeChild(script);
      });
    };
  }, [pixelId, locale, events]);


  return null; // No UI component needed
};

export default FacebookPixel;


/* Example usage:
<FacebookPixel pixelId="YOUR_PIXEL_ID" locale="en_CA" events={['PageView', 'Lead']} />
*/