import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";

const RestaurantDishesCard = ({ image, imageAlt, title, text }) => {
  return (
    <Col key={title}>
      <DishesCard>
        <ResponsiveImage variant="top" src={image} alt={imageAlt} sizes={"lg"} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
      </DishesCard>
    </Col>
  );
};

const DishesCard = styled(Card)`
  height: 100%;
  text-align: center;
  border-radius: 0;
  img {
    height: 265px;
    object-fit: cover;
    border-radius: 0;
  }
`;

RestaurantDishesCard.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default RestaurantDishesCard;
