import React, { useState } from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const FreePlayFriday = () => {
  const [viewBtn, setViewBtn] = useState(false);
  const { i18n } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl("promotions/free_play_friday_bg.jpg");

  return (
    <PromoDetailDiv bgImage={responsiveBgImage}>
      <div>
        <ResponsiveImage
          src={`promotions/free_play_friday_logo_${i18n.language}.png`}
          alt="Free Play Friday"
        />
        <div>
          {i18n.language === "en" ? (
            <div>
              <p>from 12:00pm to 6:00pm</p>
              <h2>$1,000 in Free Play paid out every hour!</h2>
            </div>
          ) : (
            <div>
              <p>De 12h00 à 20h00</p>
              <h2>1000$ en crédits-jeu sont versés toutes les heures!</h2>
            </div>
          )}
        </div>
      </div>
      {i18n.language === "en" ? (
        <div>
          <p>
            <strong>
              Playground’s latest Hot Seat promotion offers 2 players a chance
              to win $250 in free play every 30 minutes!
            </strong>
          </p>
          <p>
            <strong>From 12:00pm to 6:00pm,</strong> two winners will be
            randomly selected <strong>every 30 minutes</strong> from all our
            active gaming machines and table games players and they will be
            presented with a <strong>$250 free play voucher!</strong> There will
            be <strong>26 winners every Friday,</strong> and you could be one!
            View Terms and Conditions{" "}
          </p>
          <button onClick={() => setViewBtn(!viewBtn)}>
            View Terms and Conditions
            <span></span>
          </button>
          <div className={viewBtn === true ? "ul-active" : undefined}>
            <ul>
              <li>
                In order to be eligible players must be active on either an
                gaming machines or table games.
              </li>
              <li>
                2 Hot Seat draws will be held every 30 minutes from 12:00pm to
                6:00pm.
              </li>
              <li>
                The first Hot Seat draw will be held at 12:00pm and the last Hot
                Seat draw will be held at 8:00pm.
              </li>
              <li>
                Each Hot Seat draw winner will receive a $250 Free Play voucher.
              </li>
              <li>
                Free play vouchers are valid on all gaming machines and table
                games.
              </li>
              <li>
                All participants must present a valid government-issued photo
                identification upon request.
              </li>
              <li>
                Playground reserves the right to change or alter this promotion,
                as it deems appropriate, including, but not limited to, making
                changes or alterations to these Rules and Regulations relating
                to the accommodation of participants.
              </li>
              <li>
                Patrons with self-excluded or banned status, as determined by
                Playground, are not eligible for any of the gifts/prizes
                associated with Playground contests, promotions, tournaments or
                giveaways.
              </li>
              <li>The promotion is open to all Playground members.</li>
              <li>The promotion takes place at Playground.</li>
              <li>
                Employees of Playground shall be ineligible to participate in
                the promotion.
              </li>
              <li>All references to times and dates reflect Eastern Time.</li>
              <li>
                By participating in the promotion, winners consent to the use of
                their names and photographs for promotional purposes without
                additional compensation.
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <p>
            <strong>
              La plus récente promotion Sièges Chanceux du Playground offre aux
              joueurs une chance de gagner 250$ en crédits-jeu toutes les 30
              minutes!
            </strong>
          </p>
          <p>
            <strong>De 12h00 à 20h00,</strong> deux gagnants seront sélectionnés
            au hasard <strong>toutes les 30 minutes</strong> parmi tous nos
            joueurs machine de jeux et de table de jeux actifs et recevront un
            bon de <strong>250$ en crédits-jeu!</strong> Il y aura{" "}
            <strong>34 gagnants chaque vendredi,</strong> et vous pourrez être
            l'un d'entre eux.
          </p>
          <button onClick={() => setViewBtn(!viewBtn)}>
            Conditions générales
            <span></span>
          </button>
          <div className={viewBtn === true ? "ul-active" : undefined}>
            <ul>
              <li>
                Pour être éligibles, les joueurs doivent être actifs sur un
                machine de jeux ou de table de jeux.
              </li>
              <li>
                2 tirages au sort auront lieu toutes les 30 minutes de 12h00 à
                20h00.
              </li>
              <li>
                Le premier tirage au sort aura lieu à 12h00 et le dernier tirage
                au sort aura lieu à 20h00.
              </li>
              <li>
                Chaque gagnant du tirage au sort recevra un bon de 250$ en
                crédits-jeu.
              </li>
              <li>
                Les bons de Crédits-jeu sont valables sur tous les machines de
                jeux et de tables de jeu.
              </li>
              <li>
                Tous les participants doivent présenter une pièce d'identité
                valide avec photo émise par le gouvernement sur demande.
              </li>
              <li>
                Playground se réserve le droit de modifier ou d'altérer cette
                promotion, comme il le juge approprié, y compris, mais sans s'y
                limiter, en apportant des modifications ou des altérations à ces
                Règles et Règlements relatifs à l'accueil des participants.
              </li>
              <li>
                Les clients ayant un statut d'auto-exclusion ou de bannissement,
                tel que déterminé par Playground, ne sont pas admissibles aux
                cadeaux/prix associés aux concours, aux promotions, aux tournois
                ou aux cadeaux promotionnels de Playground.
              </li>
              <li>
                La promotion est ouverte à tous les membres de Playground.
              </li>
              <li>La promotion a lieu au Playground.</li>
              <li>
                Les employés de Playground ne sont pas autorisés à participer à
                la promotion.
              </li>
              <li>
                Toutes les heures et dates mentionnées sont en heure de l'Est.
              </li>
              <li>
                En participant à la promotion, les gagnants consentent à
                l'utilisation de leur nom et de leur photo à des fins
                promotionnelles sans compensation supplémentaire.
              </li>
            </ul>
          </div>
        </div>
      )}
      
    </PromoDetailDiv>
  );
};
const PromoDetailDiv = styled.div`
  height: fit-content;
  width: 100%;
  position: relative;
  font-family: "Open Sans", sans-serif;
  background: #e6e6e6;
  & > div:first-child {
    padding: 100px 0px 0;
    background: url(${(props) => props.bgImage}) 0% 100% fixed no-repeat;
    background-size: cover;
    z-index: 0;
    height: auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    top: 0;
    background-size: cover;
    & > img:first-child {
      max-width: 800px;
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: contain;
    }
    & > div:nth-child(2) {
      background: rgba(0, 0, 0, 0.3);
      height: fit-content;
      width: 100%;
      padding: 80px 20px;
      text-align: center;
      float: left;
      & > div {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto;
        height: fit-content;
        & > p {
          font-size: 28px;
          line-height: 30px;
          color: #f1f1f1;
          margin: 0 0 10px;
        }
        & > h2 {
          font-size: 32px;
          line-height: 40px;
          color: #fff;
          font-weight: 700;
          margin: 0px;
        }
      }
    }
  }
  & > div:nth-child(2) {
    max-width: 1440px;
    height: fit-content;
    width: 100%;
    margin: 40px auto;
    padding: 40px;
    padding-bottom: 20px;
    background: #fff;
    & > p:first-child,
    & > p:nth-child(2) {
      line-height: 30px;
      font-size: 18px;
      text-align: left;
      color: #555;
      margin: 0 0 10px;
      padding: 0px 15px;
      & > strong {
        font-weight: 700;
      }
    }
    & > button:nth-child(3) {
      width: 100%;
      max-width: 300px;
      border: none;
      height: 40px;
      background-color: #ddd;
      outline: 0;
      color: var(--black);
      margin: 20px 0;
      border-radius: 0;
      font-family: "Open Sans", sans-serif;
      padding: 0;
      & > span {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    & > div:last-child {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      & > ul {
        padding-left: 20px;
        margin: 0 0 60px 50px;
        list-style-type: disc;
        & > li {
          padding: 5px;
          font-size: 14px;
          color: #555;
        }
      }
      &.ul-active {
        max-height: 1050px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    & > div:first-child {
      background-position: center;
      & > div:nth-child(2) {
        padding: 40px 20px;
        & > div {
          & > h2 {
            font-size: 26px;
            line-height: 38px;
          }
        }
      }
    }
    & > div:nth-child(2) {
      padding: 20px;
      text-align: center;
      & > button:nth-child(3) {
        margin: 20px auto 20px auto;
        margin-bottom: 0px;
      }
      & > div:last-child {
        & > ul {
          padding-left: 20px;
          margin: 20px;
          list-style-type: disc;
          & > li {
            text-align: left;
            padding: 5px;
            font-size: 14px;
            color: #555;
          }
        }
      }
    }
  }
`;
export default FreePlayFriday;
