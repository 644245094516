import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const TournamentPrizePoolTableDiv = styled.div`
  width: 100%;
  & > div:first-child {
    & > h2 {
      text-align: center;
      font-size: 28px;
      padding-bottom: 10px;
      font-weight: 700;
      margin: 0px;
      line-height: 33px;
    }
    .highlighted {
      text-align: center;
      font-size: 24px;
      color: #fff;
      font-weight: 700;
      line-height: 27px;
      background-color: #cd0000;
      padding: 10px 50px;
      display: block;
      max-width: 400px;
      margin: 5px auto 10px auto;
    }
  }
  & > div:last-child {
    width: 100%;
    max-width: 1440px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    & > table {
      background: #131619;
      border: none;
      width: 100%;
      max-width: 100%;
      margin: 20px 0px 40px 0px;
      border-collapse: collapse;
      & > tbody {
        & > tr {
          color: #fff;
          .position {
            background-color: #0c0d0f;
            color: #fff;
            width: 10%;
            font-weight: 700;
          }
          & > td {
            width: 40%;
            font-weight: 700;
            border: 1px solid #24292e;
            font-size: 16px;
            text-align: center;
            padding: 8px;
            line-height: 1.42857143;
            vertical-align: middle;
          }
        }
      }
    }
  }
  @media screen and (max-width: 479px) {
    & > div:last-child {
      margin: 0;
      display: block;
      & > table {
        margin: 0;
        & > tbody {
          & > tr {
            & > td {
              font-size: 12px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
`;

const TournamentPrizePoolTable = ({ prizePool }) => {
  if (!prizePool?.list) return null;

  const half = Math.ceil(prizePool.list.length / 2);
  const firstHalf = prizePool.list.slice(0, half);
  const secondHalf = prizePool.list.slice(half);
  return (
    <TournamentPrizePoolTableDiv>
      <div>
        <ReactMarkdown>{prizePool?.content?.text}</ReactMarkdown>
        <ReactMarkdown className="highlighted">
          {prizePool?.content?.textTwo}
        </ReactMarkdown>
        <ReactMarkdown>{prizePool?.content?.textThree}</ReactMarkdown>
      </div>
      <div>
        <table>
          <tbody>
            {firstHalf.map((row, index) => (
              <tr key={index}>
                <td className="position">{row.position}</td>
                <td>{row.prize}</td>
                {secondHalf[index] && (
                  <>
                    <td className="position">{secondHalf[index].position}</td>
                    <td>{secondHalf[index].prize}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TournamentPrizePoolTableDiv>
  );
};

export default TournamentPrizePoolTable;
