/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";
// const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const MonterCarlo2024 = () => {
  const { i18n } = useTranslation();
  // const [data, setData] = useState({
  //   status: "",
  //   is1: "",
  //   is2: "",
  //   is3: "",
  //   is4: "",
  //   is5: "",
  //   is6: "",
  //   is7: "",
  //   is8: "",
  //   is9: "",
  //   is10: "",
  //   is11: "",
  //   is12: "",
  //   is13: "",
  //   is14: "",
  //   is15: "",
  //   is16: "",
  //   is17: "",
  //   is18: "",
  //   is19: "",
  //   is20: "",
  //   is21: "",
  //   is22: "",
  //   is23: "",
  //   is24: "",
  //   is25: "",
  //   is26: "",
  //   is27: "",
  //   is28: "",
  //   is29: "",
  //   is30: "",
  //   is31: "",
  //   is32: "",
  //   is33: "",
  //   is34: "",
  //   is35: "",
  //   is36: "",
  //   is37: "",
  //   is38: "",
  // });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://sheets.googleapis.com/v4/spreadsheets/${apiKey}/values/B1:B26`
  //       );

  //       const responseData = response.data.values;
  //       setData({
  //         status: true,
  //         is1: responseData[0][0],
  //         is2: responseData[1][0],
  //         is3: responseData[2][0],
  //         is4: responseData[3][0],
  //         is5: responseData[4][0],
  //         is6: responseData[5][0],
  //         is7: responseData[6][0],
  //         is8: responseData[7][0],
  //         is9: responseData[8][0],
  //         is10: responseData[9][0],
  //         is11: responseData[10][0],
  //         is12: responseData[11][0],
  //         is13: responseData[12][0],
  //         is14: responseData[13][0],
  //         is15: responseData[14][0],
  //         is16: responseData[15][0],
  //         is17: responseData[16][0],
  //         is18: responseData[17][0],
  //         is19: responseData[18][0],
  //         is20: responseData[19][0],
  //         is21: responseData[20][0],
  //         is22: responseData[21][0],
  //         is23: responseData[22][0],
  //         is24: responseData[23][0],
  //         is25: responseData[24][0],
  //         is26: responseData[25][0],
  //         is27: responseData[26][0],
  //         is28: responseData[27][0],
  //         is29: responseData[28][0],
  //         is30: responseData[29][0],
  //         is31: responseData[30][0],
  //         is32: responseData[31][0],
  //         is33: responseData[32][0],
  //         is34: responseData[33][0],
  //         is35: responseData[34][0],
  //         is36: responseData[35][0],
  //         is37: responseData[36][0],
  //         is38: responseData[37][0],
  //       });
  //     } catch (error) {
  //       setData({ status: false });
  //     }
  //   };

  //   fetchData();

  //   const intervalId = setInterval(fetchData, 5000);

  //   return () => clearInterval(intervalId);
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch("/.netlify/functions/googleSheet"); // Update with your serverless function endpoint
  //       const responseData = await response.json();

  //       setData(responseData.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  return (
    <MainDiv lng={i18n.language} bgImage={useResponsiveImageUrl("promotions/monte-carlo-bg.jpg")}>
      <div>
        <ResponsiveImage
          src="promotions/monte-carlo-logo.png"
          alt="monte-carlo-logo.png"
        />
        {i18n.language === "en" ? (
          <div>
            <h1>Over $25,000 in weekly cash prizes!</h1>
            <h2>From Sunday 8:00AM to Friday 8:00AM</h2>
            <p>
              Players are now under the gun from Sunday to Friday to claim these
              high hand jackpots before someone else does. Any unclaimed hand
              will rollover to the next time frame. So get ready for some huge
              payouts and tons of excitement.
            </p>
            <p>
              *4-card Pot Limit Omaha games are also eligible but the qualifying
              hand must be made on the flop.
            </p>
          </div>
        ) : (
          <div>
            <h1>Plus de 25 000$ de prix hebdomadaires!</h1>
            <h2>Du dimanche à 8h00 au vendredi à 8h00</h2>
            <p>
              Les joueurs sont désormais au pied du mur du dimanche au vendredi
              pour réclamer ces jackpots avant que quelqu'un d'autre ne les
              gagne. Toute main non réclamée sera reportée à la période
              suivante. Préparez-vous donc à des gains énormes et des sensations
              fortes.
            </p>
            <p>
              *Les parties Omaha à 4 cartes sont également éligibles, mais la
              main qualifiée doit être faite sur le flop.
            </p>
          </div>
        )}
      </div>
      <div id="monte-carlo">
        <h3>
          {i18n.language === "en"
            ? "Current progressive jackpot amounts"
            : "Montants courants des jackpots progressifs"}
        </h3>
        <h4>{i18n.language === "en" ? "4 of a Kind" : "Carré"}</h4>
        {/* <div>
          {[...Array(13)].map((_, index) => (
            <div key={index}>
              <img
                src={`promotions/pgcard-${
                  index + 2
                }.png`}
                alt={`pgcard-${index + 2}`}
              />
              <p>
                <span>$</span>
                {data[`is${index + 1}`]}
              </p>
            </div>
          ))}
        </div> */}
        <div>
          <div>
            <ResponsiveImage src="promotions/pgcard-2.png" alt="pgcard-2" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-3.png" alt="pgcard-3" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-4.png" alt="pgcard-4" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-5.png" alt="pgcard-5" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-6.png" alt="pgcard-6" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-7.png" alt="pgcard-7" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-8.png" alt="pgcard-8" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-9.png" alt="pgcard-9" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-10.png" alt="pgcard-10" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-j.png" alt="pgcard-j" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-q.png" alt="pgcard-q" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-k.png" alt="pgcard-k" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-a.png" alt="pgcard-a" />
            <p>
              <span>$</span>100<span>$</span>
            </p>
          </div>
        </div>
        <h4>
          {i18n.language === "en"
            ? "Straight Flush (Any suit)"
            : "quinte flush (N'importe quelle suite)"}
        </h4>
        <div>
          <div>
            <ResponsiveImage src="promotions/pgcard-a-5.png" alt="pgcard-a-5" />
            <p>
              <span>$</span>200<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-2-6.png" alt="pgcard-2-6" />
            <p>
              <span>$</span>200<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-3-7.png" alt="pgcard-3-7" />
            <p>
              <span>$</span>600<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-4-8.png" alt="pgcard-4-8" />
            <p>
              <span>$</span>400<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-5-9.png" alt="pgcard-5-9" />
            <p>
              <span>$</span>200<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage
              src="promotions/pgcard-6-10.png"
              alt="pgcard-6-10"
            />
            <p>
              <span>$</span>200<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-7-j.png" alt="pgcard-7-j" />
            <p>
              <span>$</span>200<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-8-q.png" alt="pgcard-8-q" />
            <p>
              <span>$</span>400<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage src="promotions/pgcard-9-k.png" alt="pgcard-9-k" />
            <p>
              <span>$</span>800<span>$</span>
            </p>
          </div>
        </div>
        <h4>{i18n.language === "en" ? "ROYAL Flush" : "quinte royale"}</h4>
        <div>
          <div>
            <ResponsiveImage
              src="promotions/pgcard-royal.png"
              alt="pgcard-royal"
            />
            <p>
              <span>$</span>1500<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage
              src="promotions/pgcard-royal2.png"
              alt="pgcard-royal2"
            />
            <p>
              <span>$</span>3000<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage
              src="promotions/pgcard-royal3.png"
              alt="pgcard-royal3"
            />
            <p>
              <span>$</span>3000<span>$</span>
            </p>
          </div>
          <div>
            <ResponsiveImage
              src="promotions/pgcard-royal4.png"
              alt="pgcard-royal4"
            />
            <p>
              <span>$</span>2500<span>$</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        {i18n.language === "en" ? (
          <div>
            <h4>General Rules</h4>
            <ol>
              <li>
                Minimum qualifying hand is <strong>2222x.</strong>
              </li>
              <li>Payouts are made immediately.</li>
              <li>
                Payouts are made in poker chips. Players may add chips to their
                stack at any time between hands, but the table limits must be
                respected.
              </li>
              <li>
                If a specific bonus hand has not been won during the day (8:00AM
                to 8:00AM the next day) unawarded prize money rolls over to the
                next day. (Ex. If 2222x has not been won from Monday 8:00AM to
                Tuesday 8:00AM ($100), the payout for 2222 on Tuesday will be
                $200)
              </li>
              <li>All qualifying hands can only be paid once per day.</li>
              <li>
                In the case of multiple boards, i.e., if players agree to run it
                more than once, only the first board shall be eligible to
                produce a qualifying Bonus Hand.
              </li>
              <li>
                The promotion concludes at 8:00AM on Friday. Any hand reaching
                showdown after this time will be considered invalid.
              </li>
              <li>
                All payouts related to these promotions are funded from the Bad
                Beat Jackpot pull. This will continue to be managed as specified
                in rule #11 of the Bad Beat Jackpot Rules and Procedures.
              </li>
              <li>
                The hand does not have to go to a showdown in order to qualify.
                However, the qualifying Bonus Hand must be tabled and
                acknowledged by the dealer.
              </li>
              <li>
                A minimum of five (5) players must be seated and dealt in at the
                beginning of the hand in order to be eligible for a High Hand if
                playing Hold’em.
              </li>
              <li>
                A minimum of three (3) players must be seated and dealt in at
                the beginning of the hand in order to be eligible for a Bonus
                Hand if playing Omaha (4 cards).
              </li>
              <li>
                <strong>Omaha players (4 cards)</strong> qualify on the flop
                only. However, the Bonus Hand may be tabled and acknowledged by
                the dealer on any street.
              </li>
              <li>
                Players must use both hole cards in Hold'em and two (2) of their
                four (4) hole cards in Omaha to make a qualifying hand.
              </li>
              <li>
                Players must play their hands independently. If any player,
                whether directly or indirectly involved in the hand, passes
                information or instructs another on how to act or reveals their
                hand to other players, the High Hand will be disqualified.
              </li>
              <li>
                Any discussion of the prize during the playing of the hand may
                void qualification for a High Hand payout.
              </li>
              <li>
                Management verifies eligibility of all qualifying Bonus Hands
                before any prize is awarded. There will be no recourse if the
                hand is mucked before surveillance was able to verify the hole
                cards and the community cards. Management will not be permitted
                to reconstruct the hand in any way.
              </li>
              <li>
                Prizes won are final. No substitution or transfer of prizes are
                permitted.
              </li>
              <li>
                Decisions of the Poker Room Supervisor are final and without
                recourse.
              </li>
              <li>
                Winners consent to the use of their name and photographs for
                promotional purposes without additional compensation.
              </li>
            </ol>
          </div>
        ) : (
          <div>
            <h4>Règles générales</h4>
            <ol>
              <li>
                La main qualifiante minimale est de <strong>2222x.</strong>
              </li>
              <li>Les paiements sont effectués immédiatement.</li>
              <li>
                Les paiements sont effectués en jetons de poker. Les joueurs
                peuvent ajouter des jetons à leur tapis à tout moment entre les
                mains, mais les limites de la table doivent être respectées.
              </li>
              <li>
                Si une main bonus spécifique n'a pas été remportée pendant la
                journée (de 8h00 à 8h00 le lendemain), l'argent du prix non
                attribué est reporté au jour suivant. (Ex. Si 2222x n'a pas été
                remporté du lundi 8h00 au mardi 8h00 ($100), le paiement pour
                2222 le mardi sera de $200)
              </li>
              <li>
                Toutes les mains qualifiantes ne peuvent être payées qu'une fois
                par jour.
              </li>
              <li>
                En cas de plusieurs plateaux, c'est-à-dire si les joueurs
                acceptent de le jouer plusieurs fois, seul le premier plateau
                sera éligible pour produire une main bonus qualifiante.
              </li>
              <li>
                La promotion se termine à 8h00 le vendredi. Toute main
                atteignant le showdown après cette heure sera considérée comme
                invalide.
              </li>
              <li>
                Tous les prix décernés dans le cadre de ces promotions sont
                financés par le fonds du Bad Beat Jackpot. Ceci est géré comme
                spécifié dans les règles du Bad Beat Jackpot au règlement No.
                11.
              </li>
              <li>
                La main n'a pas besoin d'aller jusqu'au showdown pour se
                qualifier. Cependant, la main bonus qualifiante doit être
                exposée sur la table et reconnue par le croupier.
              </li>
              <li>
                Un minimum de cinq (5) joueurs doit être assis et servis au
                début de la main pour être éligible à une Mains Fortes si vous
                jouez au Hold'em.
              </li>
              <li>
                Un minimum de trois (3) joueurs doivent être assis et servis au
                début de la main pour qu’une main d’Omaha (4 cartes) soit
                admissible à la promotion.
              </li>
              <li>
                <strong>Les joueurs d'Omaha (4 cartes)</strong> se qualifient
                seulement sur le flop. Cependant, la main bonus peut être
                exposée et reconnue par le croupier à n'importe quelle rue.
              </li>
              <li>
                Les joueurs doivent utiliser leurs deux cartes fermées au
                Hold'em et deux (2) de leurs quatre (4) cartes fermées à l'Omaha
                pour constituer une main qualifiante.
              </li>
              <li>
                Les joueurs doivent jouer de leur main de façon indépendante. Si
                un joueur, qu'il soit directement ou indirectement impliqué dans
                la main, passe des informations ou donne des instructions à un
                autre joueur sur la façon d'agir ou encore révèle sa main aux
                autres joueurs, une Main Forte produite par cette main serait
                disqualifiée.
              </li>
              <li>
                Toute discussion d’un prix potentiel pendant la main peut
                annuler l’admissibilité de celle-ci à cette promotion.
              </li>
              <li>
                La direction vérifie l'éligibilité de toutes les Mains Fortes
                qualifiantes avant d'attribuer les prix. Il n'y aura aucun
                recours si la main est jetée avant que la surveillance n'ait pu
                vérifier les cartes privées et communautaires. La direction ne
                peut en aucun cas et d’aucune façon reconstruire une main.
              </li>
              <li>
                Les prix remportés sont finaux. Aucune substitution ou transfert
                de prix n'est autorisé.
              </li>
              <li>
                Les décisions du superviseur de la salle de poker sont
                définitives et sans recours.
              </li>
              <li>
                Les gagnants consentent à l'utilisation de leur nom et de leurs
                photographies à des fins promotionnelles sans compensation
                supplémentaire.
              </li>
            </ol>
          </div>
        )}
      </div>
      
    </MainDiv>
  );
};
const MainDiv = styled.div`
  height: auto;
  position: relative;
  z-index: 0;
  background-color: var(--black);
  color: var(--white);
  & > div:first-child {
    background: url(${({ bgImage }) => bgImage}) no-repeat;
    padding: 130px 0px 60px;
    background-repeat: no-repeat;
    background-position: 50% 30%;
    border-bottom: none;
    background-size: cover;
    z-index: 1;
    position: relative;
    height: auto;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img {
      width: 100%;
      max-width: 760px;
      margin: 0 auto;
    }
    & > div {
      padding: 20px;
      background-color: rgba(20, 31, 41, 0.7);
      text-align: center;
      & > h1 {
        margin: 0;
      }
      & > h2 {
        margin: 0;
      }
      & > p {
        font-size: 14px;
      }
    }
  }
  & > div:nth-child(2) {
    height: auto;
    width: 100%;
    max-width: 1440px;
    margin: 40px auto;
    padding: 20px 0;
    & > h3:first-child {
      font-weight: 700;
      color: #000;
      text-transform: uppercase;
      text-align: center;
      padding: 15px;
      max-width: 500px;
      font-size: 30px;
      line-height: 25px;
      background: rgb(197, 149, 50);
      background: linear-gradient(
        0deg,
        rgba(197, 149, 50, 1) 0%,
        rgba(246, 236, 181, 1) 50%,
        rgba(197, 149, 50, 1) 100%
      );
      margin: 0 auto;
      border-radius: 5px;
    }
    & > h4 {
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
      font-weight: 700;
    }
    & > div:nth-child(3) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      column-gap: 40px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        & > img {
          max-width: 60px;
          margin: 0 auto;
          height: auto;
        }
        & > p {
          width: 100%;
          font-size: 60px;
          font-weight: 700;
          color: #fff;
          margin: 0;
          background-color: #010101;
          border: 2px solid #ad9053;
          border-radius: 5px;
          text-align: center;
          & > span {
            font-size: 40px;
            vertical-align: text-top;
            font-weight: 700;
            color: #ad9053;
          }
          & > span:first-child {
            display: ${({ lng }) => lng === "fr" && "none"};
          }
          & > span:nth-child(2) {
            display: ${({ lng }) => lng === "en" && "none"};
          }
        }
      }
    }
    & > div:nth-child(5),
    & > div:nth-child(7) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      column-gap: 40px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
          height: auto;
          max-height: 94px;
          max-width: 120px;
          margin: 0 auto;
        }
        & > p {
          background-color: #010101;
          border: 2px solid #ad9053;
          border-radius: 5px;
          text-align: center;
          font-size: 60px;
          font-weight: 700;
          color: #fff;
          margin: 0;
          width: 100%;
          & > span {
            font-size: 40px;
            vertical-align: text-top;
            font-weight: 700;
            color: #ad9053;
          }
          & > span:first-child {
            display: ${({ lng }) => lng === "fr" && "none"};
          }
          & > span:nth-child(2) {
            display: ${({ lng }) => lng === "en" && "none"};
          }
        }
      }
    }
  }
  & > div:nth-child(3) {
    height: auto;
    width: 100%;
    & > div {
      height: auto;
      width: 100%;
      max-width: 1440px;
      background: var(--lightblack);
      color: #999;
      padding: 40px 0;
      margin: 0 auto;
      & > h4 {
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        font-size: 30px;
        margin: 0;
      }
      & > ol {
        & > li {
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:nth-child(2) {
      padding: 20px;
      & > div:nth-child(3),
      & > div:nth-child(5),
      & > div:nth-child(7) {
        grid-template-columns: 1fr;
      }
    }
    & > div:nth-child(3) {
      padding: 20px;
      & > div {
        padding: 0 20px;
        & > ol {
          padding-left: 20px;
        }
      }
    }
  }
`;
export default MonterCarlo2024;
