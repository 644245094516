import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import FullCalendar       from "@fullcalendar/react";
import dayGridPlugin      from "@fullcalendar/daygrid";
import timeGridPlugin     from "@fullcalendar/timegrid";
import listPlugin         from "@fullcalendar/list";
import interactionPlugin  from "@fullcalendar/interaction";
import enLocale           from "@fullcalendar/core/locales/en-gb";
import frLocale           from "@fullcalendar/core/locales/fr";
import TournamentTypeToggle   from "src/components/Calendar/TournamentTypeToggle";
import CalendarModal          from "src/components/Calendar/CalendarModal";
import Hero                   from "src/components/Hero/Hero";

import { useIsSmScreenDown } from '../../utils/windowUtils';
import { tournamentTypeToString, isItemInCart, generateJSONLD } from '../../utils/tournamentUtils';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { tournaments, tournament } from "../../api/poker";
import { useAccount } from '../../context/account/account.provider';
import { useTranslation } from "react-i18next";
import moment from "moment";

// Function to fetch tournament data
const fetchTournamentData = async (start_date, end_date, locale) => {
  const data = await tournaments(start_date, end_date);
  return data?.map((event) => ({
    ...event,
    title: event[`name_${locale}`] || event.name_en,
    start: event.start_at,
    classNames: [`type_${event.type}`],
    target_description: locale == 'fr' ? event.target_description_fr : event.target_description_en,
    target: locale == 'fr' ? event.target_fr : event.target_en
  }));
};

// Function to transform event titles based on locale
const transformEventTitles = (events, locale) => {
  return events.map((event) => ({
    ...event,
    title: event[`name_${locale}`] || event.name_en,
  }));
};

// Function to merge new events with existing events
const mergeEvents = (existingEvents, newEvents) => {
  const mergedEvents = [...existingEvents];
  newEvents.forEach((newEvent) => {
    const exists = existingEvents.some((event) => event.id === newEvent.id);
    if (!exists) {
      mergedEvents.push(newEvent);
    }
  });
  return mergedEvents;
};

const Calendar = () => {
  const { accountCart } = useAccount();
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [activeTypes, setActiveTypes] = useState([1, 2, 4, 5]);
  const [localizedEvents, setLocalizedEvents] = useState([]);
  const [rangeKey, setRangeKey] = useState(null);  // Track the current date range
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLocale, setCurrentLocale] = useState(i18n.language);
  const isFetching = useRef(false);
  const fetchedRanges = useRef(new Set());  // Track fetched date ranges
  const isSmScreenDown = useIsSmScreenDown();
  const calendarRef = useRef(null);

  // Effect to monitor screen size and change view accordingly
  useEffect(() => {
    window.calendarApi = calendarRef.current?.getApi(); // Safely access FullCalendar API

    if (calendarApi) {
      const currentView = calendarApi.view.type; // Get the current view type
      if (isSmScreenDown && currentView === "timeGridWeek") {
        // Switch to listWeek if currently in timeGridWeek and resized to a small screen
        calendarApi.changeView("listWeek"); // Programmatically change the view
      }
    }
  }, [isSmScreenDown]);

  // Handle the event click
  const [eventDetails, setEventDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = async (event) => {
    // console.log("Event clicked", event);
    const gid = event.extendedProps.gid;
    try {
      const eventDetails = await tournament(gid);
      // console.log("Tournament details", eventDetails);
      setEventDetails(eventDetails);
      setShowDetails(true);
    } catch (error) {
      console.error("Error fetching tournament details:", error);
    }
  };

  // Function to load events
  // This function fetches data for a given date range and merges it with existing events
  const loadEvents = async (start_date, end_date) => {
    // console.log(`loadEvents called with ${start_date} to ${end_date}`);
    setLoading(true);
    isFetching.current = true;
    const fetchedData = await fetchTournamentData(start_date, end_date, i18n.language);
    setEvents((prevEvents) => mergeEvents(prevEvents, fetchedData));
    // console.log(`Fetched ${fetchedData.length} events`);
    isFetching.current = false;
    setLoading(false);
  };

  // Effect to localize event titles
  // This effect runs when events or locale change
  useEffect(() => {
    if (events.length > 0) {
      const updatedEvents = transformEventTitles(events, i18n.language);
      setLocalizedEvents(updatedEvents);
    }
  }, [events, i18n.language]);

  // Effect to update current locale
  // This effect runs when locale changes
  useEffect(() => {
    setCurrentLocale(i18n.language);
  }, [i18n.language]);

  // Effect to handle dates set
  // This effect runs when the calendar view changes
  const handleDatesSet = (arg) => {
    const startDate = moment(arg.startStr).format("YYYY-MM-DD");
    const endDate = moment(arg.endStr).format("YYYY-MM-DD");

    // console.log(`Dates set: ${startDate} to ${endDate}`);

    const newRangeKey = `${startDate}_${endDate}`;

    // Check if the current date range has already been fetched
    if (!fetchedRanges.current.has(newRangeKey)) {
      setRangeKey(newRangeKey);
      if (!isFetching.current) {
        loadEvents(startDate, endDate).then(() => {
          fetchedRanges.current.add(newRangeKey);  // Add the fetched range to the set
        });
      }
    } else {
      // console.log(`Data for range ${newRangeKey} has already been fetched.`);
    }
  };

  // Function to filter events based on active types
  // This function runs when active types or localized events change
  const filterEvents = () => {
    setFilteredEvents(localizedEvents.filter(event => activeTypes.includes(event.type)));
  };

  // Effect to filter events
  // This effect runs when active types or localized events change
  useEffect(() => {
    filterEvents();
  }, [activeTypes, localizedEvents]);

  // Function to handle toggle
  // Sets active types based on the type toggled
  const handleToggle = (type) => {
    setActiveTypes((prev) => {
      const newActiveTypes = prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type];
      return newActiveTypes;
    });
  };

  // Function to custom render event content
  const renderEventContent = (eventInfo) => {
    let tournamentId = eventInfo.event._def.publicId;
    let isInCart = isItemInCart(accountCart, tournamentId);
    return (
      <>
        <div className={`fc-event-custom-main tournament_type_${tournamentTypeToString(eventInfo.event.extendedProps.type)}`}>
          <div className="fc-event-title">{eventInfo.event.title}</div>
          {/* {eventInfo.event?.extendedProps?.target && (
             <div className="fc-event-subtitle">{eventInfo.event.extendedProps.target}</div>
           )}*/}
          <div className="fc-event-time">{eventInfo.timeText}</div>
          {/* { isInCart && (
            <div className="icon-holder">
              <FaCheckCircle className="check-icon"/>
              <FaShoppingCart className="cart-icon"/>
            </div>
          )} */}
        </div>
      </>
    );
  };

  // Function to custom render day cell content. I.e. format the day number
  const renderDayCellContent = (dayCellInfo) => {
    const dayNumber = moment(dayCellInfo.date).format('DD'); // Zero-padded day number
    return <span>{dayNumber}</span>;
  };

  const isPastDate = (date) => {
    const today = moment().startOf('day');
    return moment(date).isBefore(today);
  };

  const eventDidMount = (info) => {
    if (isPastDate(info.event.start)) {
      info.el.classList.add("past-event");
    }
  };

  const dayCellDidMount = (info) => {
    if (isPastDate(info.date)) {
      info.el.classList.add("fc-past-day");
    }
  };

  const today = new Date();

  // Render JSON-LD structured data using Helmet
  const renderJSONLD = (localizedEvents) => (
    <Helmet>
      {localizedEvents
        .filter(event => new Date(event.start) >= today) // Filter out events that have already started
        .map((event, index) => (
          <script key={index} type="application/ld+json">
            {JSON.stringify(generateJSONLD(event, i18n.language, t))}
          </script>
        ))}
    </Helmet>
  );

  return (
    <HelmetProvider>
      <CalendarPage>
        <Hero
          bgColor=""
          bgImage={"poker/calendar-hero-2.jpg"}
          buttonLink=""
          buttonText=""
          description=""
          descriptionBgColor=""
          image=""
          imageAlt=""
          mainHeroText={t("calendar.tournamentCalendarH1")}
          title=""
        >
          <FilterContainer>
            <Row className="g-1 align-items-center">
              <Col xs={12}>
                <h3>{t("calendar.typesOfTournament")}</h3>
              </Col>
              <Col xs={6} md={3} lg={true}>
                <TournamentTypeToggle
                  activeTypes={activeTypes}
                  handleToggle={handleToggle}
                  tournamentTypeName="daily"
                  tournamentTypeKey={1}
                />
              </Col>
              <Col xs={6} md={3} lg={true}>
                <TournamentTypeToggle
                  activeTypes={activeTypes}
                  handleToggle={handleToggle}
                  tournamentTypeName="satellite"
                  tournamentTypeKey={2}
                />
              </Col>
              <Col xs={6} md={3} lg={true}>
                <TournamentTypeToggle
                  activeTypes={activeTypes}
                  handleToggle={handleToggle}
                  tournamentTypeName="online"
                  tournamentTypeKey={4}
                />
              </Col>
              <Col xs={6} md={3} lg={true}>
                <TournamentTypeToggle
                  activeTypes={activeTypes}
                  handleToggle={handleToggle}
                  tournamentTypeName="festival"
                  tournamentTypeKey={5}
                />
              </Col>
            </Row>
          </FilterContainer>
        </Hero>
        <CalendarDiv>
          {loading && (
            <div className="spinner-container">
              <Spinner />
            </div>
          )}
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
            initialView={isSmScreenDown ? "listWeek" : "dayGridMonth"} // Set the initial view based on screen size
            headerToolbar={{
              left: isSmScreenDown ? 'dayGridMonth,timeGridDay,listWeek' : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              center: 'title',
              right: 'prev,next,today',
            }}
            contentHeight="auto"         // Ensures no internal scrollbars
            events={filteredEvents}
            locales={[enLocale, frLocale]}
            locale={currentLocale}
            lazyFetching={true}
            firstDay={0}
            datesSet={handleDatesSet}  // Handle the date range changes
            fixedWeekCount={false}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            eventContent={renderEventContent}
            dayCellContent={renderDayCellContent}
            eventClick={({event}) => handleShowDetails(event)}
            eventDidMount={eventDidMount} // Apply the style to past events
            dayCellDidMount={dayCellDidMount} // Apply the style to past day numbers
          />
        </CalendarDiv>
        <CalendarModal
          showDetails={showDetails}
          handleCloseDetails={handleCloseDetails}
          eventDetails={eventDetails}
          currentLocale={currentLocale}
        />
        {localizedEvents.length > 0 && renderJSONLD(localizedEvents)}
      </CalendarPage>
    </HelmetProvider>
  );
};

const FilterContainer = styled.div`
  h3 {
    margin: 0;
    text-align: left;
    font-size: 1rem;
  }
`;

const CalendarPage = styled.div`
  .hero_container {
    h1 {
      font-size: clamp(0.1rem, 12vw, 4rem);
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
      text-align: center;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

const CalendarDiv = styled.div`
  color: white;
  position: relative;
  padding: 1rem;
  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  .fc-event.past-event {
    opacity: 0.5;
  }
  .fc-daygrid-day.fc-past-day .fc-daygrid-day-number {
    opacity: 0.5;
  }
  .fc-daygrid-day.fc-day-today {
    background-color: var(--mutedred);
    .fc-daygrid-day-number {
      font-weight: bolder;
      text-align: center
    }
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    border: none;
  }
  th.fc-day {
    text-align: left;
    a.fc-col-header-cell-cushion {
      padding: 0.3rem 4px;
      text-transform: uppercase;
    }
  }
  .fc .fc-daygrid-day-number {
    padding-left: 0;
  }
  .fc-theme-standard td, .fc-theme-standard th {
      border: 1px solid var(--lightblack);
  }
  .fc-daygrid-day-top {
    flex-direction: row;
    .fc-daygrid-day-number {
      font-size: 2.7rem;
      font-weight: 200;
      line-height: 3.3rem;
    }
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 8px;
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 0;
  }
  .fc-event-custom-main {
    border-left: 4px solid transparent;
    border-right: 3px solid transparent;
    padding: 2px 0.6rem;
    margin-bottom: 0.6rem;
    transition: all 0.1s;
    @media (min-width: 768px) {
      width: 100%;
    }
    cursor: pointer;
    .icon-holder {
      @media (min-width: 768px) {
      position: absolute;
      right: 7px;
      bottom: 17px;
      }
      .cart-icon {
        font-size: 1.2rem;
        border-radius: 50%;
        color: var(--white);
        z-index: 30;
        @media (min-width: 768px) {
          position: absolute;
          bottom: 0;
          right: 4px;
        }
      }
      .check-icon {
        color: green;
        @media (min-width: 768px) {
          position: absolute;
          bottom: -5px;
          right: -4px;
          z-index: 100;
        }
      }
    }
    &:hover {
      border-left-width: 8px;
      border-right-width: 0px;
      padding-right: calc(0.6rem - 4px);
    }
    &.tournament_type_daily {
      border-left-color: var(--fc-tournament-daily);
      background-color: var(--fc-tournament-daily-muted);
      .fc-event-time, .fc-event-subtitle, .cart-icon {
        color: var(--fc-tournament-daily)
      }
    }
    &.tournament_type_satellite {
      border-left-color: var(--fc-tournament-satellite);
      background-color: var(--fc-tournament-satellite-muted);
      .fc-event-time, .fc-event-subtitle, .cart-icon {
        color: var(--fc-tournament-satellite);
      }
    }
    &.tournament_type_online {
      border-left-color: var(--fc-tournament-online);
      background-color: var(--fc-tournament-online-muted);
      .fc-event-time, .fc-event-subtitle, .cart-icon {
        color: var(--fc-tournament-online);
      }
    }
    &.tournament_type_festival {
      border-left-color: var(--fc-tournament-festival);
      background-color: var(--fc-tournament-festival-muted);
      .fc-event-time, .fc-event-subtitle {
        color: var(--fc-tournament-festival-time);
      }
      .cart-icon {
        color: var(--fc-tournament-festival);
      }
    }
    .fc-event-title {
      white-space: break-spaces;
      font-weight: normal;
      padding-bottom: 0.3rem;
    }
    .fc-event-subtitle {
      white-space: break-spaces;
      font-weight: normal;
      padding-bottom: 0.4rem;
    }
    .fc-event-time {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
  .fc-theme-standard {
    .fc-scrollgrid, .fc-list {
      border: 1px solid var(--lightblack);
    }
  }
  .fc-list-day-cushion {
    background-color: var(--black);
    padding: 0 1rem 1.2rem;
    font-size: 2rem;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 1.7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .fc-list-event-graphic {
    display: none;
  }
  .fc-list-event-title {
    padding-left: 0;
  }
  .fc-button {
    text-transform: uppercase;
    :empty {
      display: none;
    }
  }
  .fc {
    .fc-list-event:hover td {
      background-color: transparent;
    }
    .fc-list-empty {
      background-color: var(--lightblack);
    }
  }
  @media(max-width: 991px) {
    h2.fc-toolbar-title {
      text-align: center!important;
      margin: 1rem;
    }
  }
  @media(max-width: 767px) {
    .fc-dayGridMonth-view .fc-event,
    .fc-timeGridWeek-view .fc-event {
      .fc-event-title, .fc-event-subtitle {
        display: none;
      }
      .fc-event-custom-main {
        padding: 2px;
        border-left-width: 2px;
        transition: all 0.3s;
        &:hover {
          border-left-width: 2px;
          border-left-color: var(--white);
          background-color: var(--red);
          .fc-event-time {
            color: var(--white);
          }
        }
      }
    }
    .fc .fc-list-table td {
      &.fc-list-event-time {
        padding-top: 10px;
      }
      &.fc-list-event-title {
        padding-left: 0;
      }
    }
    .fc-toolbar-chunk {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .fc-button-group {
        flex-grow: 1;
        .fc-today-button {
          flex-grow: 1;
        }
      }
    }
    .fc-scrollgrid-sync-table {
    }
    .fc .fc-timegrid-axis-cushion {
      font-size: 0.9rem;
      text-align: right;
      font-weight: 600;
    }
    .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: column;
    }
    .fc-toolbar.fc-header-toolbar .fc-left {
      order: 3;
    }
    .fc-toolbar.fc-header-toolbar .fc-center {
      order: 1;
    }
    .fc-toolbar.fc-header-toolbar .fc-right {
      order: 2;
    }
    .fc-toolbar-title {
      margin: 1rem 0;
    }
    .fc-daygrid-day-top {
      .fc-daygrid-day-number {
        font-size: 1.7rem;
        font-weight: 300;
        line-height: 2rem;
      }
    }
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Calendar;
