import styled from "styled-components";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { RiParkingFill } from "react-icons/ri";
import { FaDollarSign } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import ReactMarkdown from "react-markdown";
import {getImageSrc} from 'src/utils/rewardsImageUtil.js'

const Promotions = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h3 className="my-4">{t("rewards.redeem-nav-2")}</h3>
      <Main>
        <li>
          <ResponsiveImage src={getImageSrc("1")} alt="redeem" />
          <div>
            <i>1</i>
            <ReactMarkdown>
              {t("rewards.promotions-cart-1-text-1")}
            </ReactMarkdown>
          </div>
        </li>
        <li>
          <ResponsiveImage src={getImageSrc("2")} alt="redeem" />
          <div>
            <i>2</i>
            <p>
              {t("rewards.promotions-cart-2-text-1")}{" "}
              <strong>{t("rewards.promotions-cart-2-text-2")}</strong>
            </p>
          </div>
        </li>
        <li>
          <ResponsiveImage src={getImageSrc("3")} alt="redeem" />
          <div>
            <i>3</i>
            <p>
              {t("rewards.promotions-cart-3-text-1")}{" "}
              <strong>{t("rewards.promotions-cart-3-text-2")}</strong>
            </p>
          </div>
        </li>
        <li>
          <ResponsiveImage src={getImageSrc("4a")} alt="redeem" />
          <div>
            <i>4</i>
            <p>
              {t("rewards.promotions-cart-4-text-1")} <IoIosArrowDropleftCircle />{" "}
              <IoIosArrowDroprightCircle />{" "}
              {t("rewards.promotions-cart-4-text-2")}{" "}
              <strong>{t("rewards.promotions-cart-4-text-3")}</strong>{" "}
              {t("rewards.promotions-cart-4-text-4")}
            </p>
          </div>
        </li>
        <li>
          <ResponsiveImage src={getImageSrc("5a")} alt="redeem" />
          <div>
            <i>5</i>
            <p>
              {t("rewards.promotions-cart-5-text-1")}{" "}
              <strong>{t("rewards.promotions-cart-5-text-2")}</strong>
            </p>
          </div>
        </li>
        <li>
          <ResponsiveImage src={getImageSrc("6a")} alt="redeem" />
          <div>
            <i>6</i>
            <p>
              {t("rewards.promotions-cart-6-text-1")}{" "}
              <strong>{t("rewards.promotions-cart-6-text-2")}</strong>{" "}
              {t("rewards.promotions-cart-6-text-3")}
            </p>
          </div>
        </li>
        <li>
          <ResponsiveImage src={getImageSrc("11")} alt="redeem" />
          <div>
            <i>7</i>
            <p>{t("rewards.promotions-cart-11-text")}</p>
          </div>
        </li>
      </Main>
    </>
  );
};
const Main = styled.ul`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 0;
  list-style: none;
  & > li {
    margin: 0;
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    & > img {
      width: 100%;
    }
    & > div {
      border: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      padding: 10px 20px;
      gap: 10px;
      margin: 0;
      & > i {
        background: var(--red);
        color: var(--white);
        padding: 5px 11px;
        border-radius: 50%;
      }
      & > p {
        align-self: flex-start;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        margin: 0;
        padding: 0;
        & > span {
          border: none;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    grid-template-columns: 1fr;
    gap: 20px;
    & > div {
      width: 100%;
      & > p {
        height: unset;
      }
    }
  }
`;
export default Promotions;
