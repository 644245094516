import React from "react";

import BlogHeader from "src/components/Blog/BlogHeader";
import BlogContent from "src/components/Blog/BlogContent";
import BlogContainer from "src/components/Blog/BlogContainer";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const AugustMillionFestival = () => {
  const { t } = useTranslation();
  return (
    <>
      <BlogHeader title={t("blog.august-million-festival.title")} />
      <BlogContainer>
        <BlogContent>
          <h3>{t("blog.august-million-festival.pg-august-million")}</h3>
          <p>{t("blog.august-million-festival.pg-august-million-text-1")}</p>
          <p>{t("blog.august-million-festival.pg-august-million-text-2")}</p>
          <p>{t("blog.august-million-festival.pg-august-million-text-3")}</p>
          <p>{t("blog.august-million-festival.pg-august-million-text-4")}</p>
          <p>{t("blog.august-million-festival.pg-august-million-text-5")}</p>
          <p>{t("blog.august-million-festival.pg-august-million-text-6")}</p>
          <p>{t("blog.august-million-festival.pg-august-million-text-7")}</p>
          <h3>{t("blog.august-million-festival.event-1")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-1.jpg"
            alt="bbj-august-2023-1"
          />
          <p>{t("blog.august-million-festival.event-1-text")}</p>
          <h3>{t("blog.august-million-festival.event-1-strategic-deal")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-2.jpg"
            alt="bbj-august-2023-2"
          />
          <p>{t("blog.august-million-festival.event-1-strategic-deal-text")}</p>
          <h3>{t("blog.august-million-festival.event-1-ultimate-dual")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-3.jpg"
            alt="bbj-august-2023-3"
          />
          <p>{t("blog.august-million-festival.event-1-ultimate-dual-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-4.jpg"
            alt="bbj-august-2023-4"
          />
          <h3>{t("blog.august-million-festival.event-2")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-5.jpg"
            alt="bbj-august-2023-5"
          />
          <p>{t("blog.august-million-festival.event-2-text")}</p>
          <h3>{t("blog.august-million-festival.event-2-strategic-deal")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-6.jpg"
            alt="bbj-august-2023-6"
          />
          <p>{t("blog.august-million-festival.event-2-strategic-deal-text")}</p>
          <h3>{t("blog.august-million-festival.event-2-the-deal")}</h3>
          <p>{t("blog.august-million-festival.event-2-the-deal-text")}</p>
          <h3>{t("blog.august-million-festival.event-2-heads-up")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-7.jpg"
            alt="bbj-august-2023-7"
          />
          <p>{t("blog.august-million-festival.event-2-heads-up-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-8.jpg"
            alt="bbj-august-2023-8"
          />
          <h3>{t("blog.august-million-festival.event-3")}</h3>
          <p>{t("blog.august-million-festival.event-3-text")}</p>
          <h3>{t("blog.august-million-festival.event-3-negociation")}</h3>
          <p>{t("blog.august-million-festival.event-3-negociation-text")}</p>
          <h3>{t("blog.august-million-festival.event-3-the-deal")}</h3>
          <p>{t("blog.august-million-festival.event-3-the-deal-text")}</p>
          <h3>{t("blog.august-million-festival.event-3-final-duel")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-9.jpg"
            alt="bbj-august-2023-9"
          />
          <p>{t("blog.august-million-festival.event-3-final-duel-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-10.jpg"
            alt="bbj-august-2023-10"
          />
          <h3>{t("blog.august-million-festival.event-4")}</h3>
          <p>{t("blog.august-million-festival.event-4-text")}</p>
          <h3>{t("blog.august-million-festival.event-4-heads-up")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-11.jpg"
            alt="bbj-august-2023-11"
          />
          <p>{t("blog.august-million-festival.event-4-heads-up-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-12.jpg"
            alt="bbj-august-2023-12"
          />
          <h3>{t("blog.august-million-festival.event-5")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-13.jpg"
            alt="bbj-august-2023-13"
          />
          <p>{t("blog.august-million-festival.event-5-text")}</p>
          <h3>{t("blog.august-million-festival.event-5-high-stakes")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-14.jpg"
            alt="bbj-august-2023-14"
          />
          <p>{t("blog.august-million-festival.event-5-high-stakes-text")}</p>
          <h3>{t("blog.august-million-festival.event-5-three-way")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-15.jpg"
            alt="bbj-august-2023-15"
          />
          <p>{t("blog.august-million-festival.event-5-three-way-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-16.jpg"
            alt="bbj-august-2023-16"
          />
          <h3>{t("blog.august-million-festival.event-6")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-17.jpg"
            alt="bbj-august-2023-17"
          />
          <p>{t("blog.august-million-festival.event-6-text")}</p>
          <h3>{t("blog.august-million-festival.event-6-high-stakes")}</h3>
          <p>{t("blog.august-million-festival.event-6-high-stakes-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-18.jpg"
            alt="bbj-august-2023-18"
          />
          <ResponsiveImage
            src="blog/bbj-august-2023-19.jpg"
            alt="bbj-august-2023-19"
          />
          <h3>{t("blog.august-million-festival.event-7")}</h3>
          <p>{t("blog.august-million-festival.event-7-text-1")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-20.jpg"
            alt="bbj-august-2023-20"
          />
          <p>{t("blog.august-million-festival.event-7-text-2")}</p>
          <h3>{t("blog.august-million-festival.event-final-agreement")}</h3>
          <p>{t("blog.august-million-festival.event-final-agreement-text")}</p>
          <h3>{t("blog.august-million-festival.event-last-heads-up")}</h3>
          <ResponsiveImage
            src="blog/bbj-august-2023-21.jpg"
            alt="bbj-august-2023-21"
          />
          <p>{t("blog.august-million-festival.event-last-heads-up-text")}</p>
          <ResponsiveImage
            src="blog/bbj-august-2023-22.jpg"
            alt="bbj-august-2023-22"
          />
          <h3>{t("blog.august-million-festival.conclusion")}</h3>
          <p>{t("blog.august-million-festival.conclusion-text-1")}</p>
          <p>{t("blog.august-million-festival.conclusion-text-2")}</p>
          <p>{t("blog.august-million-festival.conclusion-text-3")}</p>
        </BlogContent>
      </BlogContainer>
      
    </>
  );
};
export default AugustMillionFestival;
