import React from "react";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import TitleSection from "../TitleSection/TitleSection";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import { Row, Col } from "react-bootstrap";

const BlackJack = () => {
  const { t, i18n } = useTranslation();

  return (
    <BlackJackDiv>
      <container-div>
        <TitleSection title={t("etg.blackjack.title")} />
        <ResponsiveImage
          src={`promotions/blackjack_header_${i18n.language}.jpg`}
          alt={t("etg.blackjack.headerImageAlt")}
        />
        <SubTitleSection subTitle={t("etg.blackjack.subtitle")} />
        <ReactMarkdown>{t("etg.blackjack.description1")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.blackjack.description2")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.blackjack.description3")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.blackjack.description4")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.blackjack.description5")}</ReactMarkdown>
        <ReactMarkdown>{t("etg.blackjack.description6")}</ReactMarkdown>

        <SubTitleSection subTitle={t("etg.blackjack.sideBetsTitle")} />
        <ReactMarkdown>{t("etg.blackjack.sideBetsDescription")}</ReactMarkdown>

        <SubTitleSection subTitle={t("etg.blackjack.pokerBlackjackTitle")} />
        <ReactMarkdown>{t("etg.blackjack.pokerBlackjackDescription")}</ReactMarkdown>

        <SubTitleSection as={'h3'} subTitle={t("etg.blackjack.winningHandsTitle")} />
        <Row>
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <Col key={num} xs={6} md={4} lg={2}>
              <ResponsiveImage
                key={num}
                src={`promotions/blackjack_cards_${num}.png`}
                alt={`blackjack_cards_${num}`}
              />
            </Col>
          ))}
        </Row>

        <Table>
          <thead>
            <tr>
              <th colSpan={2}>{t("etg.blackjack.payTables.pokerBlackjackTitle")}</th>
            </tr>
            <tr>
              <th>{t("hand")}</th>
              <th>{t("payout")}</th>
            </tr>
          </thead>
          <tbody>
            {t("etg.blackjack.payTables.pokerBlackjack", { returnObjects: true }).map((row, index) => (
              <tr key={index}>
                <td>{row.hand}</td>
                <td>{row.payout}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <SubTitleSection subTitle={t("etg.blackjack.magic20Title")} />
        <ReactMarkdown>{t("etg.blackjack.magic20Description")}</ReactMarkdown>

        <Table>
          <thead>
            <tr>
              <th colSpan={2}>{t("etg.blackjack.payTables.magic20Title")}</th>
            </tr>
            <tr>
              <th>{t("hand")}</th>
              <th>{t("payout")}</th>
            </tr>
          </thead>
          <tbody>
            {t("etg.blackjack.payTables.magic20", { returnObjects: true }).map((row, index) => (
              <tr key={index}>
                <td>{row.hand}</td>
                <td>{row.payout}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <SubTitleSection subTitle={t("etg.blackjack.dealerScoreTitle")} />
        <ReactMarkdown>{t("etg.blackjack.dealerScoreDescription")}</ReactMarkdown>

        <Table>
          <thead>
            <tr>
              <th colSpan={2}>{t("etg.blackjack.payTables.dealerScoreTitle")}</th>
            </tr>
            <tr>
              <th>{t("hand")}</th>
              <th>{t("payout")}</th>
            </tr>
          </thead>
          <tbody>
            {t("etg.blackjack.payTables.dealerScore", { returnObjects: true }).map((row, index) => (
              <tr key={index}>
                <td>{row.hand}</td>
                <td>{row.payout}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <p className="mt-4">
          <strong>{t("etg.blackjack.helpNotice")}</strong>
        </p>
      </container-div>
    </BlackJackDiv>
  );
};

const BlackJackDiv = styled.div`
  background: #101010;
  color: var(--white);
  height: fit-content;
  width: 100%;
  & > img:first-child {
    width: 100%;
    height: fit-content;
    vertical-align: middle;
  }
  & > div:last-child {
    padding: 25px 60px;
    width: 100%;
    height: fit-content;
    & > p {
      font-size: 14px;
      color: var(--white);
      margin: 0 0 10px;
    }
    & > h2 {
      font-size: 30px;
      font-weight: 400;
      color: var(--white);
      text-transform: uppercase;
      text-decoration: underline;
      padding: 15px 0;
      line-height: 33px;
      margin: 0;
    }
    & > h3 {
      font-size: 20px;
      font-weight: 700;
      color: var(--white);
      text-transform: uppercase;
      margin: 5px 0px 10px;
    }
    & > div:nth-child(12) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > img {
        max-width: 15%;
        width: 100%;
        height: fit-content;
      }
    }
    & > p:last-child {
      text-align: center;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    & > div:last-child {
      padding: 20px 15px;
      & > h2 {
        font-size: 25px;
      }
    }
  }
`;

const Table = styled.table`
  background-color: #353535;
  margin: 0;
  border: none;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  & > thead {
    & > tr:first-child {
      & > th {
        background: var(--red);
        vertical-align: middle;
        color: var(--white);
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        padding: 8px;
        line-height: 1.42857143;
      }
    }
    & > tr:last-child {
      & > th {
        text-align: center;
        width: 50%;
        background: #131619;
        text-transform: uppercase;
        color: var(--white);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        padding: 8px;
        line-height: 1.42857143;
      }
    }
  }
  & > tbody {
    & > tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      & > td {
        color: var(--white);
        border: 1px solid #111;
        width: 50%;
        font-size: 16px;
        padding: 8px;
        line-height: 1.42857143;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 991px) {
    & > tbody {
      & > tr {
        & > td {
          font-size: 14px;
        }
      }
    }
  }
`;

export default BlackJack;