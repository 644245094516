/**
 * Groups events by date and calculates row spans, sorting events by time within each date.
 *
 * @param {Array} events - The array of event objects.
 * @returns {Array} - Array of grouped events with date and rowSpan.
 */
export function groupEventsByDate(events) {
  if (!Array.isArray(events)) return [];

  // Group events by start_date
  const groupedEvents = events?.reduce((acc, event) => {
    const date = event.start_date; // Use start_date field for grouping
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  // Convert the grouped object into an array of objects with date and events, and sort each group by start_time
  const groupedArray = Object.entries(groupedEvents)?.map(([date, events]) => ({
    date,
    events: events?.sort((a, b) => new Date(a.start_at) - new Date(b.start_at)), // Sort by start_at in ascending order
    rowSpan: events.length,
  }));

  // Sort the grouped array by date
  groupedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

  return groupedArray;
}
