import { useEffect, useRef, useState } from "react";
import { useIsLgScreenUp } from "src/utils/windowUtils"; // Import the hook
import styled from "styled-components";
import { FaVolumeHigh, FaVolumeXmark } from "react-icons/fa6";

const VideoComponent = ({ desktop, mobile, soundControl = true }) => {
  const videoRef = useRef(null);
  const isLgScreenUp = useIsLgScreenUp(); // Use the hook to determine if screen width is > 991
  const [isMuted, setIsMuted] = useState(true); // State to manage mute/unmute

  useEffect(() => {
    const video = videoRef.current;
    let observer;

    const updateVideoSrc = () => {
      if (video) {
        video.src = isLgScreenUp ? desktop : mobile;
        video.load();
        video.muted = isMuted; // Set muted based on state
      }
    };

    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        updateVideoSrc(); // Load the appropriate video source based on screen size
        observer.disconnect(); // Stop observing once the video starts loading
      }
    };

    if (video) {
      observer = new IntersectionObserver(handleIntersection, {
        rootMargin: "0px",
        threshold: 0.25, // Start loading when 25% of the video is visible
      });

      observer.observe(video);
    }

    return () => {
      if (observer && video) {
        observer.unobserve(video);
      }
    };
  }, [isLgScreenUp]);

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };

  return (
    <VideoHolder>
      <video
        ref={videoRef}
        autoPlay
        loop
        muted={isMuted}
        playsInline
        preload="none" // Use "none" to lazy load the video
        onLoadedMetadata={(e) => {
          e.target.muted = true;
        }}
      >
        <source type="video/mp4" />
      </video>
      {soundControl && (
        <SoundToggleButton onClick={toggleMute}>
          {isMuted ? <FaVolumeXmark size={24} /> : <FaVolumeHigh size={24} />}
        </SoundToggleButton>
      )}
    </VideoHolder>
  );
};

const VideoHolder = styled.div`
  margin-top: 80px;
  width: 100%;
  position: relative;
  /* Placeholder to maintain aspect ratio */
  padding-bottom: 75%; /* 4:3 aspect ratio for mobile */
  @media (min-width: 992px) {
    margin-top: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio for desktop */
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

// Styled component for the sound toggle button
const SoundToggleButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

export default VideoComponent;
