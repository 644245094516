import React from "react";
import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const StyledThead = styled.thead`
  background-color: var(--darkgrey);
  color: white;
`;

const StyledTh = styled.th`
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
`;

const StyledTd = styled.td`
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
`;

const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: var(--lightgrey);
  }
`;

const SeatsGuaranteedTable = ({ tableData }) => {
  const { columns, rows } = tableData;

  return (
    <TableWrapper>
      <StyledTable>
        <StyledThead>
          <tr>
            {columns.map((column, index) => (
              <StyledTh key={index}>{column}</StyledTh>
            ))}
          </tr>
        </StyledThead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <StyledTr key={rowIndex}>
              <StyledTd>{row.date}</StyledTd>
              <StyledTd>{row.time}</StyledTd>
              <StyledTd>{row.guarantee}</StyledTd>
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default SeatsGuaranteedTable;
