// scrollPosition.provider.js
import React, { createContext, useReducer } from 'react';
import { scrollPositionReducer } from './scrollPosition.reducer';
import { scrollPositionActionTypes } from './scrollPosition.actions';

const ScrollPositionContext = createContext(); // Create context

export const ScrollPositionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(scrollPositionReducer, {
    scrollPosition: 'top', // Initial state: scroll to top by default
  });

  // Action to update scroll position
  const setScrollPosition = (position) => {
    dispatch({ type: scrollPositionActionTypes.SET_SCROLL_POSITION, payload: position });
  };

  return (
    <ScrollPositionContext.Provider value={{ scrollPosition: state.scrollPosition, setScrollPosition }}>
      {children}
    </ScrollPositionContext.Provider>
  );
};

export { ScrollPositionContext };
