import React from "react";
import styled from "styled-components";
import { t } from "i18next";
import Button from "../Button/Button";
import { FaCheckCircle } from "react-icons/fa";

const TournamentTypeToggle = ({ activeTypes, handleToggle, tournamentTypeName, tournamentTypeKey }) => {
  return (
    <TournamentToggleHolder>
      <Button
        label={
          <>
            {t(`tournament.${tournamentTypeName}`)}
            {activeTypes.includes(tournamentTypeKey) && <FaCheckCircle className="checkmark" />}
          </>
        }
        key="tournament"
        value="tournament"
        size="small"
        className={`button-toggle-tournament-${tournamentTypeName} ${activeTypes.includes(tournamentTypeKey) ? "" : "toggled-off"}`}
        fluid
        onClick={() => handleToggle(tournamentTypeKey)}
      />
    </TournamentToggleHolder>
  );
}

const TournamentToggleHolder = styled.div`
  button {
    margin: 0;
    border-radius: 0;
  }
  span {
    color: white;
    text-shadow: none;
  }
  .button-toggle-tournament-all {
    display: none;
    background-color: var(--fc-tournament-all-muted);
    border-left: solid 4px var(--mutedwhite);
    border-right: solid 4px transparent;
    &:hover {
      border-left-color: var(--fc-tournament-all);
    }
    &.toggled-off {
      border-left-color: transparent;
    }
    svg { color: var(--fc-tournament-all)}
  }
  .button-toggle-tournament-daily {
    background-color: var(--fc-tournament-daily-muted);
    border-left: solid 4px var(--fc-tournament-daily);
    border-right: solid 4px var(--fc-tournament-daily-muted);
    &:hover {
      border-left-color: var(--fc-tournament-daily);
    }
    &.toggled-off {
      border-left-color: var(--fc-tournament-daily-muted);
    }
    svg { color: var(--fc-tournament-daily)}
  }
  .button-toggle-tournament-satellite {
    background-color: var(--fc-tournament-satellite-muted);
    border-left: solid 4px var(--fc-tournament-satellite);
    border-right: solid 4px var(--fc-tournament-satellite-muted);
    &:hover {
      border-left-color: var(--fc-tournament-satellite);
    }
    &.toggled-off {
      border-left-color: var(--fc-tournament-satellite-muted);
    }
    svg { color: var(--fc-tournament-satellite)}
  }
  .button-toggle-tournament-online {
    background-color: var(--fc-tournament-online-muted);
    border-left: solid 4px var(--fc-tournament-online);
    border-right: solid 4px var(--fc-tournament-online-muted);
    &:hover {
      border-left-color: var(--fc-tournament-online);
    }
    &.toggled-off {
      border-left-color: var(--fc-tournament-online-muted);
    }
    svg { color: var(--fc-tournament-online)}
  }
  .button-toggle-tournament-festival {
    background-color: var(--fc-tournament-festival-muted);
    border-left: solid 4px var(--fc-tournament-festival);
    border-right: solid 4px var(--fc-tournament-festival-muted);
    &:hover {
      border-left-color: var(--fc-tournament-festival);
    }
    &.toggled-off {
      border-left-color: var(--fc-tournament-festival-muted);
    }
    svg { color: var(--fc-tournament-festival)}
  }
  .checkmark {
    margin-left: 8px;
    position: absolute;
    left: 2px;
    bottom: 10px;
  }
`;

export default TournamentTypeToggle;