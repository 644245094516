import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const BlogFirst = () => {
  const { t } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl("blog/blog-bg.jpg");
  return (
    <FirstPart bgImage={responsiveBgImage}>
      <div>
        <ResponsiveImage src={"blog/blog-bg.jpg"} alt="blog-bg" />
        <h1>{t("blog.header-title")}</h1>
        <h2>{t("pg-cares.header-text")}</h2>
      </div>
    </FirstPart>
  );
};
const FirstPart = styled.div`
  z-index: 0;
  height: auto;
  width: 100%;
  transition: 0.5s all;
  padding: 200px 100px;
  background: url(${(props) => props.bgImage}) 0% 80% no-repeat;
  overflow: hidden;
  background-size: cover;
  & > div {
    width: 100%;
    margin: 0 auto;
    & > img {
      display: none;
    }
    & > h1 {
      font-size: 125px;
      font-weight: 900;
      text-transform: uppercase;
      color: #fff;
      line-height: 110px;
      margin: 0px;
      margin-bottom: 10px;
    }
    & > h2 {
      max-width: 800px;
      color: #fff;
      font-weight: 400;
      font-size: 22px;
      margin: 0px;
      line-height: 33px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phones and medium tablet styles */
    padding: 0;
    background-position: center;
    & > div {
      background: var(--black);
      text-align: center;
      padding: 40px 0;
      margin: 0;
      position: relative;
      & > img {
        display: block;
        max-width: 100%;
        height: auto;
        padding: 20px 0;
      }
      & > h1 {
        font-size: 45px;
        line-height: 42px;
        padding: 0 20px;
      }
      & > h2 {
        font-size: 19px;
        line-height: 30px;
        padding: 0 20px;
      }
    }
  }
`;
export default BlogFirst;
