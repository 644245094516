const BASE_API = process.env.REACT_APP_BASE_URL;

//fetch subscription status
export const subscription = async (access_token) => {
  try {
    const response = await fetch(`${BASE_API}/accounts/subscription`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: access_token,
      },
    });

    if (response.ok) {
      const { data } = await response.json();
      return { success: true, data };
    } else {
      const errorMessage = await response.text();
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    console.error("Error fetching subscription status:", error.message);
    return {
      success: false,
      message: "Error occurred while fetching subscription status",
    };
  }
};

//subscribe or unsubscribe
export const subscribe = async (access_token, newsletter, method) => {
  try {
    const response = await fetch(`${BASE_API}/accounts/subscription`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${access_token}`, // Include the user's access token
      },
      body: JSON.stringify({
        subscribe: newsletter,
        method: method,
      }),
    });
    if (response.ok) {
      return { success: true }; // Newsletter status updated successfully
    } else {
      return { success: false, message: "Error updating newsletter status" }; // Error updating newsletter status
    }
  } catch (error) {
    console.error("Error updating newsletter status: ", error.message);
    return {
      success: false,
      message: "Error occurred while updating newsletter status",
    }; // Error occurred while updating newsletter status
  }
};

// User registration with language preference for the response
export const register = async (userData) => {
  try {
    const formData = new FormData();

    // Append each field from userData to the FormData object
    for (let key in userData) {
      formData.append(key, userData[key]);
    }

    // Set header for Accept-Language based on the language parameter
    const headers = {
      Accept: "application/json",
    };

    const response = await fetch(`${BASE_API}/accounts/register`, {
      method: "POST",
      body: formData,
      headers: headers, // Include the headers object in the fetch options
    });

    if (response.ok) {
      console.log("User registered successfully.");
      return { success: true };
    } else {
      console.error(`Registration failed: ${response.message}`);
      return { success: false, error: response.message };
    }
  } catch (error) {
    console.error(`Error during registration: ${error.message}`);
    return { success: false, error: error.message };
  }
};

//fetch user info
export const show = async () => {
  try {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      return { success: false, message: "Access token is missing" };
    }

    const response = await fetch(`${BASE_API}/accounts/show`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: accessToken,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const { data, meta } = await response.json();
      if (response.ok) {
        return { data, meta };
      } else {
        localStorage.removeItem("access_token"); // Remove token from local storage
      }
    } else {
      console.error("Response is not JSON:", await response.text());
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error fetching account information:", error);
    throw new Error("Failed to fetch account information");
  }
};

// Account recovery with language preference for the response
export const recover = async (userData) => {
  try {
    const formData = new FormData();

    // Append each field from userData to the FormData object
    for (let key in userData) {
      formData.append(key, userData[key]);
    }

    // Set header for Accept-Language based on the language parameter
    const headers = {
      Accept: "application/json",
    };

    const response = await fetch(`${BASE_API}/accounts/recover`, {
      method: "POST",
      body: formData,
      headers: headers, // Include the headers object in the fetch options
    });

    if (response.ok) {
      console.log("Account recovery initiated successfully.");
      return { success: true };
    } else {
      console.error(`Recovery failed: ${response.message}`);
      return { success: false, error: response.message };
    }
  } catch (error) {
    console.error(`Error during account recovery: ${error.message}`);
    return { success: false, error: error.message };
  }
};
