import React from "react";

import BlogContainer from "src/components/Blog/BlogContainer";
import BlogContent from "src/components/Blog/BlogContent";
import BlogHeader from "src/components/Blog/BlogHeader";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const KahnawakePowWow = () => {
  const { t } = useTranslation();
  return (
    <>
      <BlogHeader title={t("blog.kahnawake-pow-wow.title")} />
      <BlogContainer>
        <BlogContent>
          <p>
            <strong>{t("blog.kahnawake-pow-wow.text-1")} </strong>
            <a
              href="https://kahnawakepowwow.com/"
              target="_blank"
              rel="noreferrer"
            >
              {t("blog.kahnawake-pow-wow.text-2")}
            </a>{" "}
            {t("blog.kahnawake-pow-wow.text-3")}{" "}
            <a
              href="https://kahnawaketourism.com/"
              target="_blank"
              rel="noreferrer"
            >
              {t("blog.kahnawake-pow-wow.text-4")}
            </a>{" "}
            {t("blog.kahnawake-pow-wow.text-5")}{" "}
            <a
              href="https://quebecattractions.ca/kahnawake-tourism-events-2023-pow-wow-festival-strawberries-but-harvesting-family-activity-monteregie"
              target="_blank"
              rel="noreferrer"
            >
              {t("blog.kahnawake-pow-wow.text-6")}
            </a>
          </p>
          <ResponsiveImage src="blog/pow-wow-2023-blog-1.jpg" alt="pow-wow-2023-blog-1" />
          <h3>{t("blog.kahnawake-pow-wow.content-1-title")}</h3>
          <p>
            {t("blog.kahnawake-pow-wow.content-1-text-1")},{" "}
            <a href="https://www.therail.ca/" target="_blank" rel="noreferrer">
              {t("blog.kahnawake-pow-wow.content-1-text-2")}
            </a>
            , {t("blog.kahnawake-pow-wow.content-1-text-3")}
          </p>
          <ResponsiveImage src="blog/pow-wow-2023-blog-2.jpg" alt="pow-wow-2023-blog-2" />
          <h3>{t("blog.kahnawake-pow-wow.content-2-title")}</h3>
          <p>
            {t("blog.kahnawake-pow-wow.content-2-text-1")}{" "}
            <a
              href="https://www.facebook.com/KahnawakePowWow/"
              target="_blank"
              rel="noreferrer"
            >
              {t("blog.kahnawake-pow-wow.content-2-text-2")}
            </a>
            . {t("blog.kahnawake-pow-wow.content-2-text-3")}
          </p>
          <ResponsiveImage src="blog/pow-wow-2023-blog-3.jpg" alt="pow-wow-2023-blog-3" />
          <h3>{t("blog.kahnawake-pow-wow.content-3-title")}</h3>
          <p>
            {t("blog.kahnawake-pow-wow.content-3-text-1")}{" "}
            <a
              href="https://www.mtl.org/en/what-to-do/festivals-and-events/pow-wow-kahnawake-montreal"
              target="_blank"
              rel="noreferrer"
            >
              {t("blog.kahnawake-pow-wow.content-3-text-2")}
            </a>{" "}
            {t("blog.kahnawake-pow-wow.content-3-text-3")}
          </p>
          <p>{t("blog.kahnawake-pow-wow.content-3-text-4")}</p>
          <ResponsiveImage src="blog/pow-wow-2023-blog-4.jpg" alt="pow-wow-2023-blog-4" />
          <h3>{t("blog.kahnawake-pow-wow.content-4-title")}</h3>
          <p>{t("blog.kahnawake-pow-wow.content-4-text-1")}</p>
          <p>{t("blog.kahnawake-pow-wow.content-4-text-2")}</p>
          <p>
            <a
              href="https://globalnews.ca/video/9819832/kahnawake-pow-wow-celebrates-indigenous-culture-in-sweltering-heat"
              target="_blank"
              rel="noreferrer"
            >
              {t("blog.kahnawake-pow-wow.global-news")}
            </a>
          </p>
        </BlogContent>
      </BlogContainer>
      
    </>
  );
};
export default KahnawakePowWow;
