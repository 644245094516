import React from "react";
import styled from "styled-components";

// Styled components
const TableWrapper = styled.div`
  width: 100%;
  margin: 20px auto;
  text-align: center;
`;

const StyledTable = styled.table`
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
  background-color: var(--lightblack);
  color: var(--white);
`;

const StyledTh = styled.th`
  background-color: var(--black); /* Header background color */
  color: white;
  padding: 10px;
  border: 1px solid var(--darkgrey);
`;

const StyledTd = styled.td`
  padding: 10px;
  border: 1px solid var(--darkgrey);
`;

const StyledTr = styled.tr`
  &:nth-child(even) {
    /* background-color: #f2f2f2; */
  }
`;

// Reusable table component
const TournamentDynamicTable = ({ tableData }) => {
  const { columns, rows } = tableData;
  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <StyledTr>
            {columns?.map((col, index) => (
              <StyledTh key={index}>{col.name}</StyledTh>
            ))}
          </StyledTr>
        </thead>
        <tbody>
          {rows?.map((row, rowIndex) => (
            <StyledTr key={rowIndex}>
              {columns?.map((col, colIndex) => (
                <StyledTd key={colIndex}>
                  {row[col.key] || ""} {/* Access cell data using column key */}
                </StyledTd>
              ))}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default TournamentDynamicTable;
