import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Function to dynamically import all JSON files in the locales directory
// Files to go in src/locales/en/namespace.json and same for fr
// You can access the translations like this:
// t("namespace.hello")
const importAll = (r) => {
  let translations = {};
  r.keys().forEach((key) => {
    const pathParts = key.split('/');
    const lang = pathParts[1];
    const namespace = pathParts[2].replace('.json', '');

    if (!translations[lang]) {
      translations[lang] = { translation: {} };
    }

    translations[lang].translation = {
      ...translations[lang].translation,
      ...r(key),
    };
  });
  return translations;
};

// Use require.context to load all JSON files in the locales directory
const resources = importAll(require.context('../locales', true, /\.json$/));

// Set the initial language from storage or use the language from URL
let selectedLanguage = localStorage.getItem("language"); // Assuming it's retrieved from storage
let defaultLanguage = "en"; // Define your default language

let languageFromURL = selectedLanguage || defaultLanguage;

// Check if the URL starts with /fr (this should include /fr followed by anything else)
if (
  window.location.pathname.startsWith("/fr") ||
  window.location.pathname.startsWith("/fre")
) {
  languageFromURL = "fr";
} else {
  languageFromURL = "en";
}

// Finally, set the determined language into local storage
localStorage.setItem("language", languageFromURL);

//i18N Initialization

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en", // Default language if detection fails
    lng: languageFromURL, // Set initial language from URL
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
