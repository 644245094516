// src/context/toast/toast.provider.js
import React, { useReducer, useMemo, useContext } from "react";
import { toastReducer } from "./toast.reducer";
import { toastActionTypes as actions } from "./toast.actions";
import { ToastState } from "./toast.state";

const TOAST_STATE = {
  toastData: []
};

export const ToastStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, TOAST_STATE);

  const value = useMemo(
    () => ({
      ...state,
      showToastFn: (value) => {
        dispatch({ type: actions.SHOW_TOAST, payload: { ...value, show: true } });
      },
      hideToastFn: (id) => {
        dispatch({ type: actions.HIDE_TOAST, payload: id });
      }
    }),
    [state]
  );

  return (
    <ToastState.Provider value={value}>{children}</ToastState.Provider>
  );
};

export const useToast = () => useContext(ToastState);
