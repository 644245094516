import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const contentTwo = Array.from({ length: 5 }, (_, index) => index + 1);
  const contentFour = Array.from({ length: 6 }, (_, index) => index + 1);
  return (
    <PrivacyPolicyDiv>
      <div>
        <h1>{t("privacy.title")}</h1>
        <span>{""}</span>
        <div>
          <ol>
            <li>
              <h3>{t("privacy.content-1-title")}</h3>
              <p>{t("privacy.content-1-text-1")}</p>
              <p>{t("privacy.content-1-text-2")}</p>
            </li>
            <li>
              <h3>{t("privacy.content-2-title")}</h3>
              <ul>
                {contentTwo.map((number) => (
                  <li key={number}>{t(`privacy.content-2-list-${number}`)}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t("privacy.content-3-title")}</h3>
              <p>{t("privacy.content-3-text-1")}</p>
              <p>{t("privacy.content-3-text-2")}</p>
            </li>
            <li>
              <h3>{t("privacy.content-4-title")}</h3>
              <p>{t("privacy.content-4-text")}</p>
              <ul>
                {contentFour.map((number) => (
                  <li key={number}>{t(`privacy.content-4-list-${number}`)}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t("privacy.content-5-title")}</h3>
              <p>{t("privacy.content-5-text")}</p>
            </li>
            <li>
              <h3>{t("privacy.content-6-title")}</h3>
              <p>{t("privacy.content-6-text")} </p>
              <ul>
                {contentFour.map((number) => (
                  <li key={number}>{t(`privacy.content-6-list-${number}`)}</li>
                ))}
              </ul>
            </li>
            <li>
              <h3>{t("privacy.content-7-title")}</h3>
              <p>{t("privacy.content-7-text")}</p>
            </li>
            <li>
              <h3>{t("privacy.content-8-title")}</h3>
              <p>{t("privacy.content-8-text")}</p>
            </li>
            <li>
              <h3>{t("privacy.content-9-title")}</h3>
              <p>{t("privacy.content-8-text")}</p>
            </li>
          </ol>
          <p>{t("privacy.bottom-text")}</p>
        </div>
      </div>
      
    </PrivacyPolicyDiv>
  );
};
const PrivacyPolicyDiv = styled.div`
  height: fit-content;
  width: 100%;
  background: var(--black);
  & > div:first-child {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    color: var(--white);
    padding: 140px 0 0 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > h1 {
      text-align: center;
      font-weight: bold;
      margin: 0;
    }
    & > span {
      margin-top: 10px;
      width: 100%;
      max-width: 180px;
      border-bottom: 2px solid var(--red);
    }
    & > div {
      margin: 20px 0;
      width: 100%;
      height: fit-content;
      background: var(--lightblack);
      padding: 20px;
      & > ol {
        padding-left: 20px;
        & > li {
          & > h3 {
            font-weight: 600;
            border-bottom: 1px solid var(--black);
          }
          & > p {
            margin: 10px 0;
          }
          & > ul {
            list-style: disc;
          }
        }
      }
      & > p:last-child {
        padding-left: 40px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      padding: 100px 20px 20px 20px;
      & > h1 {
        font-size: 22px;
      }
      & > span {
        max-width: 120px;
      }
      & > div {
        & > ol {
          & > li {
            & > h3 {
              font-size: 18px;
            }
            & > p {
              font-size: 14px;
            }
            ul {
              padding-left: 20px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
export default PrivacyPolicy;
