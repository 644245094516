import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {Row , Col} from "react-bootstrap";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Hero from "src/components/Hero/Hero";

const MercierBridgeTraffic = () => {
  const { i18n, t } = useTranslation();

  return (
    <MercierBridgeTrafficDiv>
      <Hero
        bgImage={"mercierbridge/mercier-bridge.jpg"}
        mainHeroText={t("mercierBridge.title")}
      />
      <container-wide>
        <Row sm={1} md={2} className="gx-4 g-4 my-4">
          <Col>
            <SubTitleSection subTitle={t("mercierBridge.liveTrafficCam")} />
            <video controls autoPlay>
              <source
                src="http://www.quebec511.info/diffusion/camera/camera.ashx?format=mp4&amp;id=3406"
                type="video/mp4"
              ></source>
            </video>
          </Col>
          <Col>
            <SubTitleSection subTitle={t("mercierBridge.recentPosts")} />
            <iframe
              scrolling="no"
              frameBorder="0"
              allowtransparency="true"
              allowFullScreen={true}
              title="Twitter Timeline"
              src="https://syndication.twitter.com/srv/timeline-profile/screen-name/pontHMBridge?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;maxHeight=500px&amp;origin=https%3A%2F%2Fwww.playground.ca%2Fmercier-bridge-traffic&amp;sessionId=417ba9291834a913b2240c3d1de9fe4198aabf54&amp;showHeader=true&amp;showReplies=false&amp;siteScreenName=PlaygroundPoker&amp;transparent=false&amp;widgetsVersion=aaf4084522e3a%3A1674595607486"
            ></iframe>
          </Col>
        </Row>
      </container-wide>
    </MercierBridgeTrafficDiv>
  );
};

const MercierBridgeTrafficDiv = styled.div`
  width: 100%;
  height: fit-content;
  background: var(--black);
  iframe, video {
      position: static;
      visibility: visible;
      width: 100%;
      aspect-ratio: 4 / 3;
  }
`;

export default MercierBridgeTraffic;
