import React from "react";
import styled from "styled-components/macro";
import Hero from "src/components/Hero/Hero";
import Button from "src/components/Button/Button";
import EventFinished from "src/components/EventFinished";

import { t } from "i18next";

const GirlsNightOut = () => {
  return (
    <>
      <Div>
        <Hero
          image={t("entertainment.girlsNightOut.hero.image")}
          imageAlt={t("entertainment.girlsNightOut.hero.imageAlt")}
          descriptionBgColor="#1f0510"
          title={t("entertainment.girlsNightOut.hero.title")}
          fluidImage="true"
        />
        <EventFinished date="2024-12-11" />
        <container-div>
          <ShowHeadliner>
            <h5>{t("entertainment.girlsNightOut.showHeadliner.time")}</h5>
            <h3>{t("entertainment.girlsNightOut.showHeadliner.title")}</h3>
            <h4>
              {t("entertainment.girlsNightOut.showHeadliner.description")}
            </h4>
          </ShowHeadliner>
          <Article>
            <h5>{t("entertainment.girlsNightOut.showBody.part1.time")}</h5>
            <h3>{t("entertainment.girlsNightOut.showBody.part1.title")}</h3>
            <h4>
              {t("entertainment.girlsNightOut.showBody.part1.description")}
            </h4>
          </Article>
          <Article>
            <h3 className="margin-top-40">
              {t("entertainment.girlsNightOut.showBody.part2.special")}
            </h3>
            <h5>{t("entertainment.girlsNightOut.showBody.part2.details")}</h5>
            <h4>
              {t("entertainment.girlsNightOut.showBody.part2.additional")}
            </h4>
          </Article>
          <Article>
            <h5 className="margin-top-40">
              {t("entertainment.girlsNightOut.showBody.part3.time")}
            </h5>
            <h3>{t("entertainment.girlsNightOut.showBody.part3.title")}</h3>
            <h4>
              {t("entertainment.girlsNightOut.showBody.part3.description")}
            </h4>
          </Article>
          <p>{t("entertainment.girlsNightOut.footer.notice")}</p>
          <Button
            bgColor="#FD70CA"
            href={t("entertainment.girlsNightOut.footer.buttonLink")}
            label={t("entertainment.girlsNightOut.footer.buttonText")}
            size="large"
            width="xwide"
            specialHover
          />
        </container-div>
      </Div>
      
    </>
  );
};

const Div = styled.div`
  background: #1f0510;
  text-align: center;
  color: #fff;
  padding-bottom: 50px;
  & > h1 {
    color: var(--rewards_grid);
    font-size: 100px;
  }
  div.hero_text_container {
    border-top: 2px solid #fd70ca;
    h1 {
      font-size: 24px;
      padding: 10px;
    }
  }
`;
const ShowHeadliner = styled.div`
  border: solid 1px #fd70ca;
  padding: 15px 15px 25px;
  margin: 0 auto 10px;
  max-width: 850px;
  display: block;
  h3,
  h4,
  h5 {
    margin: 5px 0 10px;
  }
  h5 {
    font-size: 24px;
    font-weight: 400;
  }
  h3 {
    font-size: 28px;
    font-weight: 700;
    color: #fd70ca;
    line-height: 36px;
  }
  h4 {
    font-size: 20px;
  }
`;

const Article = styled.div`
  padding: 15px;
  h3,
  h4,
  h5 {
    margin: 0 0 5px;
  }
  h5 {
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0 5px;
  }
  h3 {
    font-size: 28px;
    font-weight: 700;
    color: #fd70ca;
    line-height: 36px;
    margin-bottom: 0;
  }
  h4 {
    font-size: 20px;
  }
`;

export default GirlsNightOut;
