// components/EventsTable.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { tournament } from "src/api/poker";
import CalendarModal from "src/components/Calendar/CalendarModal";
import { displayValue } from "src/utils/displayValue";
import { formatAmount, formatDate, toSentenceCase } from "src/utils/formatters";
import { groupEventsByDate } from "src/utils/groupEventsByDate";
import { eventTableOrder, tournamentTypeToString } from "src/utils/tournamentUtils";
import styled from "styled-components";
import { formatTimeString } from "src/utils/timeUtils";

const EventsTable = ({ events, event_number = true }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  // Use the utility function to group events and calculate row spans
  const groupedEvents = groupEventsByDate(events);
  const [eventDetails, setEventDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = async (event) => {
    const gid = event.gid;
    try {
      const eventDetails = await tournament(gid);
      setEventDetails(eventDetails);
      setShowDetails(true);
    } catch (error) {
      console.error("Error fetching tournament details:", error);
    }
  };
  // console.log(eventDetails);
  return (
    <TournamentEventTableDiv>
      <table border="1">
        <thead>
          <tr>
            <th>{t("poker.tableHeaders.date")}</th>
            <th>{t("poker.tableHeaders.time")}</th>
            {event_number && <th>{t("poker.tableHeaders.eventNumber")}</th>}
            <th>{t("poker.tableHeaders.buyIn")}</th>
            <th>{t("poker.tableHeaders.tournament")}</th>
            <th align="right">{t("poker.tableHeaders.guarantee")}</th>
          </tr>
        </thead>
        <tbody>
          {groupedEvents?.map(({ date, events, rowSpan }) =>
            events?.map((event, index) => (
              <tr key={event.id}>
                {/* Only render the date cell with rowSpan for the first event of each date */}
                {index === 0 && (
                  <td rowSpan={rowSpan}>{toSentenceCase(formatDate(date, currentLocale, true))}</td>
                )}
                <td>{formatTimeString(event?.start_at, i18n.language)}</td>
                {event_number && (
                  <td>
                    {event?.event_number ? eventTableOrder(event?.event_number) : ""}
                  </td>
                )}
                <td>{formatAmount(event?.price, currentLocale)}</td>
                {/* Extracting buy-in from the name */}
                <td
                  onClick={() => handleShowDetails(event)}
                  className={`card-text tournament-table tournament-type-${tournamentTypeToString(event.type)}`}
                >
                  {event?.name_en}
                </td>
                <td>{displayValue(event, currentLocale)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <ModalDiv>
        <CalendarModal
          showDetails={showDetails}
          handleCloseDetails={handleCloseDetails}
          eventDetails={eventDetails}
          currentLocale={currentLocale}
        />
      </ModalDiv>
    </TournamentEventTableDiv>
  );
};
const TournamentEventTableDiv = styled.div`
  width: 100%;
  margin: 20px auto;
  padding-bottom: 40px;
  overflow-x: auto;
  max-width: 1200px;
  position: relative;
  table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: var(--rewards_grid); /* Dark background for the table */
    color: var(--white); /* White text for readability */
    border: 1px solid var(--darkgrey); /* Darker border to distinguish table edges */
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid var(--darkgrey); /* Subtle borders between cells */
  }

  th {
    text-align: center;
    background: #25292d; /* Dark header background */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase; /* Make header text uppercase for emphasis */
    letter-spacing: 0.5px;
  }

  td {
    text-align: center;
    font-size: 13px;
    line-height: 1.5;

    &.tournament-type-daily,
    &.tournament-type-satellite,
    &.tournament-type-online,
    &.tournament-type-festival {
      /* border-left-width: 5px; Add a colored border to the left of the cell */
    }
    &.tournament-type-daily {
      /* border-left-color: var(--fc-tournament-daily); */
      color: var(--white);
      background-color: var(--fc-tournament-daily-muted);
      &:hover {
        background-color: var(--fc-tournament-daily);
        color: var(--white);
      }
    }
    &.tournament-type-satellite {
      /* border-left-color: var(--fc-tournament-satellite); */
      color: var(--fc-tournament-satellite);
      background-color: var(--fc-tournament-satellite-muted);
      &:hover {
        background-color: var(--fc-tournament-satellite);
        color: var(--fc-tournament-satellite-muted);
      }
    }
    &.tournament-type-online {
      /* border-left-color: var(--fc-tournament-online); */
      color: var(--fc-tournament-online);
      background-color: var(--fc-tournament-online-muted);
      &:hover {
        background-color: var(--fc-tournament-online);
        color: var(--white);
      }
    }
    &.tournament-type-festival {
      /* border-left-color: var(--fc-tournament-festival); */
      color: var(--white);
      background-color: var(--fc-tournament-festival-muted);
      &:hover {
        background-color: var(--fc-tournament-festival);
        color: var(--white);
      }
    }
    &.red {
      color: var(--red);
      font-weight: bold;
    }
    &.card-text {
      cursor: pointer;
    }
  }
  tbody tr:hover {
    td:not(.card-text) {
      background-color: rgba(0,0,0,0.2); /* Lighten the background on hover */
      /* transform: scale(1.03); Hover effect to slightly enlarge the text */
      /* font-weight: bold;; */
    /* opacity: 0.7; Darker hover effect */
    }

  }

  a {
    color: #3498db; /* Blue for links */
    &:hover {
      text-decoration: underline;
    }
  }

  .wsop-highlight-dark {
    background: #fdbf99 !important;
  }

  @media screen and (max-width: 771px) {
    padding-bottom: 20px;
    th,
    td {
      padding: 8px; /* Reduced padding for smaller screens */
      font-size: 8px;
    }
  }
`;
const ModalDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
`;
export default EventsTable;
