import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  calculateTimeRemaining,
  determineStatus,
} from "../../utils/timeUtils";
import Spinner from "../../components/Spinner";

const CountdownTimer = ({ start_at, end_entry_time }) => {
  const { t } = useTranslation();

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(start_at, true)); // Initial remaining time
  const [status, setStatus] = useState(null); // Current status: "starting", "lateReg", or "closed"

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentStatus = determineStatus(start_at, end_entry_time);
      setStatus(currentStatus);

      // Update timeRemaining based on the current status
      if (currentStatus === "starting") {
        setTimeRemaining(calculateTimeRemaining(start_at, true));
      } else if (currentStatus === "lateReg") {
        setTimeRemaining(calculateTimeRemaining(end_entry_time, true));
      } else {
        setTimeRemaining("00:00:00"); // Closed state
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on unmount
  }, [start_at, end_entry_time]); // Removed targetTime dependency

  return (
    <TimerContainer className={`countdown-timer ${status}`}>
      {status === null ? (
        <Spinner variant="dark" />
      ) : status === "starting" && timeRemaining !== "00:00:00" ? (
        <>
          <span>
              {t("tournament.card.startsIn")} <br />
            </span>
          {timeRemaining}
        </>
      ) : status === "lateReg" && timeRemaining !== "00:00:00" ? (
        <>
          <span>
              {t("tournament.card.regEnds")} <br />
            </span>
          {timeRemaining}
        </>
      ) : status === "closed" ? (
        <>
          <span>
            {t("tournament.card.registrationClosed")}
          </span>
        </>
      ) : (
        <Spinner variant="dark" />
      )}
    </TimerContainer>
  );
};

const TimerContainer = styled.div`
  padding: 0.5rem 1rem 0.7rem;
  width: 100%;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.1;
  span {
    font-size: 0.9rem;
  }
  &.starting {
    background-color: var(--white);
    color: var(--black);
  }
  &.lateReg {
    background-color: var(--red);
  }
  &.closed {
    font-size: 1rem;
    background-color: var(--darkgrey);
  }
`;

export default CountdownTimer;
