import React, { memo, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const IMAGE_CDN_BASE_URL = process.env.REACT_APP_PGFE_IMG_ASSETS_PATH;

const imageSizes = {
  xxs: { size: "320x320", width: 1 },
  xs: { size: "320x320", width: 320 },
  sm: { size: "640x640", width: 640 },
  md: { size: "768x768", width: 768 },
  lg: { size: "992x992", width: 992 },
  xl: { size: "1200x1000", width: 1200 },
  xxl: { size: "1600x1200", width: 1400 },
};

const getImageUrl = (image, size) =>
  image ? `${IMAGE_CDN_BASE_URL}fit-in/${size}/${image}` : "";

const ResponsiveImage = memo(({ src, alt, sizes, customStyles, className="", ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageSrcSet = useMemo(
    () =>
      Object.values(imageSizes)
        .map(({ size, width }) => `${getImageUrl(src, size)} ${width}w`)
        .join(", "),
    [src]
  );

  const defaultImage = useMemo(
    () => getImageUrl(src, imageSizes.xs.size),
    [src]
  );

  return (
    <StyledImg
      src={defaultImage}
      srcSet={imageSrcSet}
      sizes={sizes}
      alt={alt}
      customStyles={customStyles}
      onLoad={() => setIsLoaded(true)}
      className={`${className} ${isLoaded ? "loaded" : ""} `}
      {...props}
    />
  );
});

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  sizes: PropTypes.string,
  customStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ResponsiveImage.defaultProps = {
  sizes: "(max-width: 991px) 100vw, 100vw",
  customStyles: {},
};

const StyledImg = styled.img`
  width: ${(props) => props.customStyles.width || "100%"};
  height: ${(props) => props.customStyles.height || "auto"};
  object-fit: ${(props) => props.customStyles.objectFit || "contain"};
  margin: ${(props) => props.customStyles.margin || "unset"};
  display: block;
  max-width: 100%;

  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `};
`;

export default ResponsiveImage;

/* <ResponsiveImage
  src={"alexandre-trouve-wyAMbXYLzI4-unsplash.jpg"}
  alt="cigars"
/> */
